export enum IUserRole {
  SuperAdmin = 'SuperAdmin',
  LocalAdmin = 'LocalAdmin',
  ActTeamLeader = 'ActTeamLeader',
  ActTeamMember = 'ActTeamMember',
  ProductOwner = 'ProductOwner',
  ProgramAssistant = 'ProgramAssistant',
}

export enum Role {
  SuperAdmin = 1,
  LocalAdmin = 2,
  ActTeamLeader = 3,
  ActTeamMember = 4,
}

export enum IOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface IOption {
  value: string | number;
  label: string;
  description?: string;
}
