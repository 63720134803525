import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { extractErrorMessage } from '~/utils/error';
import { medicationValidationSchema } from './validates';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Checkbox from '~/ui/components/inputs/Checkbox';

import { IOption } from '~/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IMedicationInitialValues, IRequestInfo } from './types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  showSecondSubmit: boolean;
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  defaultValues: IMedicationInitialValues;
  requestInfo: IRequestInfo;
  details: IDetails | null;
}

const AddMedication = ({
  showSecondSubmit,
  setDetails,
  setModalTitle,
  defaultValues,
  details,
  requestInfo,
}: IProps): ReactElement => {
  const [medications, setMedications] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: medicationValidationSchema });

  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onUpdateMedication, onCreateMedication } = useStoreActions(
    actions => actions.clientDetails,
  );

  const onMount = async () => {
    try {
      setLoading(true);

      const [medicationDictionaries] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Medication)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setMedications(medicationDictionaries);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const onSubmit = async (values: IMedicationInitialValues, evt: any) => {
    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: values,
        };
        onUpdateMedication(payload);
        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: values,
        };
        await onCreateMedication(payload);
        reset({ id: null, dose: '', note: '', injectable: false });
      }
      setModalTitle(name);

      const type = details?.id ? 'updated' : 'added';

      showNotify(`Medication successfully ${type}`);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectComponent
            isRelativeWindow
            name="id"
            options={medications}
            control={control}
            errors={errors}
            label="Select Medication"
            isDisabled={!!defaultValues?.id}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="dose"
            register={register}
            label="Medication Dose"
            multiline
            errors={errors}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            name="note"
            register={register}
            label="Medication Note"
            multiline
            errors={errors}
            minRows={3}
          />
        </Grid>
        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="injectable"
            control={control}
            errors={errors}
            label="Injectable"
          />
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        {showSecondSubmit && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            name="Add Medication"
            className={styles.margin}
          >
            Save and Add another
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddMedication;
