import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import { CLINIC_EDIT_LOCAL_ADMIN, CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from './Header.module.scss';

interface IProps {
  clinicName: string;
  clinicId: string;
  localAdminName: string;
  localAdminId: string | number;
}

const Header = ({ clinicName, clinicId, localAdminName, localAdminId }: IProps): ReactElement => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const breadcrumbsItems: Array<{ title: string; to: string | any }> = [
    {
      title: 'Clinic management',
      to: CLINIC_MANAGEMENT,
    },
    {
      title: `${clinicName}`,
      to: VIEW_CLINIC.replace(':id', clinicId),
    },
    {
      title: `Local Admin`,
      to: {
        pathname: VIEW_CLINIC.replace(':id', clinicId),
        state: { selectedTab: 2 },
      },
    },
    {
      title: 'Local Admin Profile',
      to: pathname,
    },
  ];

  return (
    <Grid container sm={12} className={styles.header}>
      <Grid item sm={9}>
        <Grid item sm={12}>
          <h1>{localAdminName}</h1>
        </Grid>
        <Grid item sm={12}>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
        </Grid>
      </Grid>

      <Grid item sm={3} className={styles.redirectButton}>
        <Button
          startIcon={<img src={editWhite} alt="edit" />}
          variant="contained"
          color="primary"
          onClick={() =>
            push(
              CLINIC_EDIT_LOCAL_ADMIN.replace(':id', clinicId).replace(
                ':localAdminId',
                String(localAdminId),
              ),
            )
          }
        >
          Edit Local Admin Profile
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
