import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProgramAssistantTable from './Table';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

type IParams = {
  id: string;
};

const ProgramAssistant = (): ReactElement => {
  const { id: clinicId } = useParams<IParams>();
  const [programAssistant, setProgramAssistant] = useState(null);

  const {
    onDeactivateProgramAssistant,
    onActivateProgramAssistant,
    onUnlockProgramAssistant,
    onReInviteProgramAssistant,
  } = useStoreActions(actions => actions.programAssistant);

  const getConfirmProps = (status: string) => {
    if (status === 'Active')
      return {
        confirmText: 'Deactivate',
        description: 'Are you sure you want to deactivate this user?',
        method: () =>
          onDeactivateProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
      };

    if (status === 'Deactivated')
      return {
        confirmText: 'Activate',
        description: 'Are you sure you want to activate this user?',
        method: () =>
          onActivateProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
      };

    if (status === 'Locked')
      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () =>
          onUnlockProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
      };

    return {
      confirmText: 'Resend',
      description: 'Are you sure you want to resend invitation?',
      method: () =>
        onReInviteProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
    };
  };

  const { confirmText, description, method } = getConfirmProps(programAssistant?.status);

  const onConfirm = async () => {
    try {
      await method();
      setProgramAssistant(false);
    } catch (e) {
      setProgramAssistant(extractErrorMessage(e));
    } finally {
      setProgramAssistant(false);
    }
  };

  return (
    <>
      <ProgramAssistantTable clinicId={clinicId} setProgramAssistant={setProgramAssistant} />
      {programAssistant && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onClose={() => setProgramAssistant(null)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default ProgramAssistant;
