import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreSecondaryDiagnosis, IClientDetailsStore } from '../types';

const onRestoreSecondaryDiagnosis: IThunkRestoreSecondaryDiagnosis = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.restoreSecondaryDiagnosis(payload);

    const diagnoses = localState.diagnoses.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: false },
    );

    setClientDetails({ diagnoses });
  },
);

export default onRestoreSecondaryDiagnosis;
