import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnDeactivateProgramAssistant } from '../types';

const onDeactivateProgramAssistant: IThunkOnDeactivateProgramAssistant = thunk(
  // @ts-ignore
  async ({ deactivateProgramAssistant }, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.deactivateProgramAssistant(clinicId, String(programAssistantId));

    deactivateProgramAssistant(programAssistantId);
  },
);

export default onDeactivateProgramAssistant;
