import { ReactElement } from 'react';
import {
  Box,
  Table as MUITable,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  Grid,
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select/Select';

import { useStoreState } from '~/store/hooks';
import { sortingOptions, statusesOptions } from '~/ui/constants/sortingOptions';
import { red } from '~/ui/constants/colors';
import { EDIT_CLIENT, VIEW_CLIENT } from '~/ui/constants/paths';
import { IParams, Sorting } from '~/services/api/types';
import { IClient } from '~/services/api/client/types';

import editIcon from '~/ui/assets/images/editGrey.svg';
import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import styles from './Table.module.scss';

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

interface IProps {
  isLocalAdmin: boolean;
  noActions?: boolean;
  list: IClient[];
  clinicId: number;
  teamId?: number;
  onGetClients: (payload: any) => void;
  setClient: (payload: IClient) => void;
}

const Table = ({
  list,
  isLocalAdmin,
  noActions,
  clinicId,
  teamId,
  onGetClients,
  setClient,
}: IProps): ReactElement => {
  const { push } = useHistory();
  const formMethods = useForm({ defaultValues });

  const pagination = useStoreState(state => state.client.pagination);

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = formMethods;

  const watchValues = watch();

  const onSubmit = (params: IParams) => {
    onGetClients({
      clinicId: String(clinicId),
      teamId: teamId ? String(teamId) : undefined,
      params: { ...params, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber },
    });
  };

  const handlePagination = (pageSize: number, pageNumber: number) => {
    onGetClients({
      clinicId: String(clinicId),
      teamId: teamId ? String(teamId) : undefined,
      params: { ...watchValues, pageSize, pageNumber },
    });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  register={register}
                  placeholder="Search clients"
                  name="name"
                  errors={errors}
                  startAdornment={<SearchIcon color="primary" />}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={statusesOptions}
                  color={red}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Assigned ACT Team</TableCell>
            <TableCell>Status</TableCell>
            {isLocalAdmin && (
              <TableCell>
                <span className={styles.actionCell}>Actions</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(item => (
            <TableRow key={item.id}>
              <TableCell>
                <Link
                  className={styles.link}
                  to={{
                    pathname: VIEW_CLIENT.replace(':clientId', String(item.id)).replace(
                      ':actTeamId',
                      String(item.actTeam.id),
                    ),
                    state: { actTeamId: String(item.actTeam.id) },
                  }}
                >
                  {item.firstName}
                </Link>
              </TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.actTeam.name}</TableCell>
              <TableCell>
                <Status isArchived={item.isArchived} />
              </TableCell>
              {!noActions &&
                (item.isArchived ? (
                  <TableCell>
                    <div
                      role="presentation"
                      className={styles.button}
                      onClick={() => setClient(item)}
                    >
                      Restore
                    </div>
                  </TableCell>
                ) : (
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        push(
                          EDIT_CLIENT.replace(':clientId', String(item.id)).replace(
                            ':actTeamId',
                            String(item.actTeam.id),
                          ),
                          {
                            actTeamId: String(item.actTeam.id),
                          },
                        )
                      }
                    >
                      <img src={editIcon} alt="editIcon" />
                    </IconButton>
                    <IconButton onClick={() => setClient(item)}>
                      <img src={archiveIcon} alt="archiveIcon" />
                    </IconButton>
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
