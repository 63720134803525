export enum SignInType {
  TickerTockerTV = 'tv',
}

export enum ErrorCodes {
  PASSWORD_EXPIRED = 'password_expired',
}

export interface ISignInProps {
  type?: string;
}

export default {};
