import onGetActTeamMembers from './onGetActTeamMembers';
import onGetActTeamMember from './onGetActTeamMember';
import onUnlockTeamMember from './onUnlockTeamMember';
import onActivateTeamMember from './onActivateTeamMember';
import onDeactivateTeamMember from './onDeactivateTeamMember';
import onGetAvailableActTeamMembers from './onGetAvailableActTeamMembers';

export default {
  onGetActTeamMembers,
  onGetActTeamMember,
  onUnlockTeamMember,
  onActivateTeamMember,
  onDeactivateTeamMember,
  onGetAvailableActTeamMembers,
};
