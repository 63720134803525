/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Grid, Paper } from '@material-ui/core';

import api from '~/services/api';
import Input from '~/ui/components/inputs/Input';
import SelectEmailDomain from '~/ui/components/inputs/SelectEmailDomain';
import Select from '~/ui/components/inputs/Select';
import InputMask from '~/ui/components/inputs/InputMask';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Loader from '~/ui/components/common/Loader';

import validate from './validate';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { ILocalAdminForm } from '~/services/api/localAdmin/types';
import { IOption } from '~/types';

import styles from './Form.module.scss';

interface IProps {
  defaultValues?: ILocalAdminForm;
  isEdit?: boolean;
  submitText?: string;
  isProcessing: boolean;
  leavePageDescription: string;
  cancelLinkTo: any;
  emailDomainOptions?: IOption[];
  isPending?: boolean;
  onSubmit: (vals: ILocalAdminForm) => void;
}

interface IOptions {
  states: IOption[];
  credentials: IOption[];
  educations: IOption[];
  employmentStatuses: IOption[];
  subRoles: IOption[];
}

const Form = ({
  defaultValues,
  isEdit = false,
  submitText = 'Send Invite',
  isProcessing,
  leavePageDescription,
  cancelLinkTo,
  emailDomainOptions,
  isPending,
  onSubmit,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onBlur', defaultValues, resolver: validate, shouldUnregister: true });

  const onMount = async () => {
    try {
      setLoading(true);
      const [states, credentials, educations, employmentStatuses, subRoles] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Credentials)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Education)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.EmploymentStatus)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.SubRole)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ states, credentials, educations, employmentStatuses, subRoles });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
    if (emailDomainOptions?.length) {
      setValue('emailDomain', emailDomainOptions[0].value as string);
    }
  }, []);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <p className={styles.formTitle}>Personal Details</p>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Input
                  tabIndex={1}
                  register={register}
                  errors={errors}
                  name="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  tabIndex={4}
                  isMulti
                  control={control}
                  errors={errors}
                  name="subRoleIds"
                  label="Sub-Roles"
                  options={options.subRoles}
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={2}
                  register={register}
                  errors={errors}
                  name="lastName"
                  label="Last Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={5}
                  register={register}
                  errors={errors}
                  name="address.city"
                  label="City"
                />
              </Grid>
              <Grid item sm={6}>
                <InputMask
                  tabIndex={3}
                  control={control}
                  errors={errors}
                  name="phone"
                  label="Phone"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={6}
                  register={register}
                  errors={errors}
                  name="address.line1"
                  label="Address 1"
                />
              </Grid>
              <Grid item sm={6}>
                <div className={styles.combinedInputWrapper}>
                  <Input
                    combinedInput={!isEdit || isPending}
                    disabled={isEdit && !isPending}
                    register={register}
                    errors={errors}
                    name="email"
                    label="Email"
                  />
                  {(!isEdit || isPending) && (
                    <SelectEmailDomain
                      isDisabled={isEdit}
                      name="emailDomain"
                      control={control}
                      errors={errors}
                      options={emailDomainOptions}
                    />
                  )}
                </div>
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={7}
                  register={register}
                  errors={errors}
                  name="address.line2"
                  label="Address 2"
                />
              </Grid>
              <Grid item sm={6}>
                <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
              </Grid>
              <Grid item sm={3}>
                <Select
                  tabIndex={8}
                  options={options.states}
                  name="address.stateId"
                  control={control}
                  errors={errors}
                  label="State"
                />
              </Grid>
              <Grid item sm={3}>
                <InputMask
                  tabIndex={9}
                  alwaysShowMask={false}
                  mask="99999"
                  name="address.zipCode"
                  control={control}
                  errors={errors}
                  label="Zip Code"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.paperContainer}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Work Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={10}
                    options={options.credentials}
                    control={control}
                    errors={errors}
                    name="workDetails.credentialsId"
                    label="Credentials"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={13}
                    options={options.employmentStatuses}
                    control={control}
                    errors={errors}
                    name="workDetails.employmentStatusId"
                    label="Employment Status"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={11}
                    options={options.educations}
                    control={control}
                    errors={errors}
                    name="workDetails.educationId"
                    label="Education"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={14}
                    type="number"
                    register={register}
                    errors={errors}
                    name="workDetails.experience"
                    label="Years of Experience"
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={12}
                    control={control}
                    errors={errors}
                    label="Hire Date"
                    name="workDetails.hireDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonWrapper}>
              <Link to={cancelLinkTo} className={styles.link}>
                <Button variant="outlined" color="primary" disabled={isProcessing}>
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isProcessing}
              tabIndex={15}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;
