export const multipleStatusesOptions = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Active' },
  { value: 3, label: 'De-Activated' },
  { value: 2, label: 'Pending' },
  { value: 4, label: 'Locked' },
];

export const statusesOptions = [
  { value: 0, label: 'All' },
  { value: 2, label: 'Active' },
  { value: 1, label: 'Archived' },
];

export const sortingOptions = [
  { value: 0, label: 'Name' },
  { value: 2, label: 'Last update' },
  { value: 1, label: 'Last created' },
];

export const dateSortingOptions = [
  {
    value: 0,
    label: 'Date Ascending',
  },
  {
    value: 1,
    label: 'Date Descending',
  },
];
