import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import { ADD_CLIENT, CLIENT, VIEW_CLIENT } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import { IClinic } from '~/services/api/clinic/types';
import { isFile } from '~/utils/file';
import { IInitialValues } from '../Form/initialValues';

const AddClient = (): ReactElement => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      await onGetMyClinic();
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  const { name: clinicName, id: clinicId } = clinic;

  const itemsForBreadcrumbs = [
    { to: CLIENT, title: `${clinicName} Clients` },
    { to: ADD_CLIENT, title: 'Add Client' },
  ];

  const onSubmit = async (vals: IInitialValues) => {
    try {
      const { teamId, medicaId, emrMrn, ...rest } = vals;
      setIsAdding(true);

      if (isFile(rest.photo)) {
        const formData = new FormData();
        formData.append('file', rest.photo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        rest.photo = fileName;
      }

      const { id, actTeam } = await api.client
        .addClient(
          { clinicId: String(clinicId), teamId: String(teamId) },
          {
            medicaId: medicaId === '' ? null : medicaId,
            emrMrn: emrMrn === '' ? null : emrMrn,
            ...rest,
          },
        )
        .then(r => r.data);

      showNotify('Client successfully added');

      push(VIEW_CLIENT.replace(':clientId', String(id)).replace(':actTeamId', String(actTeam.id)), {
        selectedTab: 1,
        actTeamId: String(actTeam.id),
      });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const description =
    'Are you sure you want to cancel “Add Client”? All the changes will be discarded.';

  return (
    <div>
      <h2>Add Client</h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        clinicId={String(clinicId)}
        isProcessing={isAdding}
        leavePageDescription={description}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AddClient;
