import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import api from '~/services/api';
import Checkbox from '~/ui/components/inputs/Checkbox';
import Button from '~/ui/components/common/Button';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import Input from '~/ui/components/inputs/Input';
import DatePicker from '~/ui/components/inputs/DatePicker';
import TimePicker from '~/ui/components/inputs/TimePicker';
import Loader from '~/ui/components/common/Loader';
import { buildDate } from '~/utils/buildDate';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { formatActTeamMemberOptions } from '~/utils/formatActTeamMemberOptions';
import { triggerNotificationOn } from '~/ui/constants/triggerNotificationOn';
import { extractErrorMessage } from '~/utils/error';
import { triggerFrequency } from '~/ui/constants/triggerFrequency';
import { triggerType } from '~/ui/constants/triggerType';
import { useStoreActions } from '~/store/hooks';
import { triggerValidationSchema } from './validates';

import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { ITriggerInitialValues, IRequestInfo } from './types';
import { TriggerType } from '~/services/api/clientDetails/types';
import { IOption } from '~/types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  defaultValues: ITriggerInitialValues;
  showSecondSubmit: boolean;
  details: IDetails | null;
  requestInfo: IRequestInfo;
}

interface IOptions {
  triggers: IOption[];
  teamMembers: IOption[];
}

const AddTrigger = ({
  setModalTitle,
  setDetails,
  defaultValues = { hasAlerts: false } as ITriggerInitialValues,
  showSecondSubmit,
  details,
  requestInfo,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>({} as IOptions);
  const [loading, setLoading] = useState(false);
  const {
    reset,
    unregister,
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ITriggerInitialValues>({
    defaultValues,
    resolver: triggerValidationSchema,
  });

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onCreateTrigger, onUpdateTrigger } = useStoreActions(actions => actions.clientDetails);
  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);

  const hasAlerts = watch('hasAlerts');
  const isRecurring = watch('type') === TriggerType.Recurring;

  const onMount = async () => {
    try {
      setLoading(true);
      const [triggers, teamMembers] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Trigger)
          .then(r => formatDictionaryOptions(r.data)),
        api.actTeamMember
          .getActTeamMemberList(requestInfo.clinicId, requestInfo.teamId)
          .then(r => formatActTeamMemberOptions(r.data)),
      ]);

      setOptions({ triggers, teamMembers });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (!hasAlerts) {
      unregister(['frequency', 'triggerDate', 'notificationOn', 'teamMembersToNotify', 'time']);
    }
  }, [hasAlerts, unregister]);

  const onSubmit = async (vals: ITriggerInitialValues, evt: any) => {
    const { name } = evt.nativeEvent.submitter;

    try {
      let date: string;
      const { time, triggerDate, ...rest } = vals;

      if (time && triggerDate) {
        date = buildDate(time, triggerDate);
      }

      const requestPayload = date ? { ...rest, triggerDate: date } : rest;

      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload,
        };

        await onUpdateTrigger(payload);
        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload,
        };

        await onCreateTrigger(payload);
        reset({ hasAlerts: false, note: '', triggerId: null, type: null });
      }

      setModalTitle(name);

      const type = details?.id ? 'updated' : 'added';

      showNotify(`Trigger successfully ${type}`);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectComponent
            isRelativeWindow
            options={options.triggers}
            name="triggerId"
            control={control}
            errors={errors}
            label="Trigger"
            maxMenuHeight={200}
            isDisabled={!showSecondSubmit}
          />
        </Grid>
        <Grid item sm={12}>
          <Input name="note" register={register} label="Trigger note" multiline errors={errors} />
        </Grid>
        <Grid item sm={12}>
          <SelectComponent
            options={triggerType}
            name="type"
            control={control}
            errors={errors}
            label="Trigger type"
          />
        </Grid>
        {isRecurring && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="hasAlerts"
              control={control}
              errors={errors}
              label="Set notification for trigger alert"
            />
          </Grid>
        )}
        {hasAlerts && isRecurring && (
          <>
            <Grid item sm={6}>
              <SelectComponent
                options={triggerFrequency}
                name="frequency"
                control={control}
                errors={errors}
                label="Frequency"
              />
            </Grid>
            <Grid item sm={6}>
              <DatePicker
                name="triggerDate"
                control={control}
                errors={errors}
                label="Trigger date"
              />
            </Grid>
            <Grid item sm={6}>
              <SelectComponent
                options={triggerNotificationOn}
                name="notificationOn"
                control={control}
                errors={errors}
                label="Notification on"
              />
            </Grid>
            <Grid item sm={6}>
              <TimePicker name="time" control={control} label="Time" errors={errors} />
            </Grid>
            <Grid item sm={12}>
              <SelectComponent
                isMulti
                options={options.teamMembers}
                name="teamMembersToNotify"
                control={control}
                label="ACT team member(s) to be notified"
                errors={errors}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        {showSecondSubmit && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            name="Add Trigger"
            className={styles.margin}
          >
            Save and Add another
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddTrigger;
