/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import api from '~/services/api';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import Select from '~/ui/components/inputs/Select';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';

import { useStoreActions } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import { CLINIC_MANAGEMENT } from '~/ui/constants/paths';
import validate from './validate';
import { IInitialValues } from './types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { extractErrorMessage } from '~/utils/error';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { IOption } from '~/types';
import styles from './Form.module.scss';

interface IProps {
  defaultValues: IInitialValues;
  isClinicAdding?: boolean;
  leavePageDescription: string;
  onSubmit: (values: IInitialValues) => Promise<void>;
}

interface IOptions {
  ehrs: IOption[];
  states: IOption[];
}

const Form = ({
  defaultValues,
  isClinicAdding,
  leavePageDescription,
  onSubmit,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
  } = useForm<IInitialValues>({
    defaultValues,
    resolver: validate,
    mode: 'onBlur',
  });

  const onMount = async () => {
    try {
      setLoading(false);
      const [ehrs, states] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Ehr)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ ehrs, states });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !options) return <Loader />;

  const emailDomains = defaultValues.emailDomains.map(str => ({
    value: str as string,
    label: str as string,
  }));

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formWrapper}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Clinic Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Input
                    tabIndex={1}
                    register={register}
                    name="name"
                    errors={errors}
                    label="Clinic Name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={4}
                    register={register}
                    name="address.city"
                    errors={errors}
                    label="City"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputMask
                    tabIndex={2}
                    control={control}
                    name="phone"
                    errors={errors}
                    label="Phone"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={5}
                    register={register}
                    name="address.line1"
                    errors={errors}
                    label="Address 1"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input tabIndex={3} register={register} name="url" errors={errors} label="URL" />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={6}
                    name="address.line2"
                    register={register}
                    errors={errors}
                    label="Address 2"
                  />
                </Grid>
                <Grid item sm={6}>
                  <AvatarUpload control={control} name="logo" errors={errors} />
                </Grid>
                <Grid item container spacing={2} sm={6}>
                  <Grid item sm={6}>
                    <Select
                      tabIndex={7}
                      options={options.states}
                      name="address.stateId"
                      control={control}
                      errors={errors}
                      label="State"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputMask
                      tabIndex={8}
                      mask="99999"
                      name="address.zipCode"
                      control={control}
                      errors={errors}
                      label="Zip Code"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Select
                      isMulti
                      isCreatable
                      options={emailDomains}
                      name="emailDomains"
                      control={control}
                      errors={errors}
                      label="Add domain for email validation"
                      placeholder="example: @vellohealth.com"
                    />
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <h5>Program Supervisor</h5>
                </Grid>

                <Grid item sm={6}>
                  <Input
                    tabIndex={9}
                    register={register}
                    name="programSupervisor.firstName"
                    errors={errors}
                    label="First Name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputMask
                    tabIndex={12}
                    control={control}
                    name="programSupervisor.phone"
                    errors={errors}
                    label="Phone"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={10}
                    register={register}
                    name="programSupervisor.lastName"
                    errors={errors}
                    label="Last Name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={13}
                    register={register}
                    name="programSupervisor.email"
                    errors={errors}
                    label="Email"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={11}
                    options={options.ehrs}
                    control={control}
                    name="ehrId"
                    errors={errors}
                    label="Electronic Health Record (EHR)"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <div className={styles.buttonsWrapper}>
            <div className={styles.button}>
              <Link to={CLINIC_MANAGEMENT} className={styles.link}>
                <Button color="primary" variant="outlined" disabled={isClinicAdding}>
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              tabIndex={14}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isClinicAdding}
              isLoading={isClinicAdding}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
      <NavigationConfirmModal
        when={isDirty && !isClinicAdding}
        description={leavePageDescription}
      />
    </div>
  );
};

export default Form;
