import { Fragment, ReactElement, useState } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import PrivateImage from '~/ui/components/common/PrivateImage';

import validate from './validate';
import { isoToLocal } from '~/utils/date';
import {
  prepareDefaultValues,
  formValuesMapper,
  ITeamMemberMapped,
  prepareUsers,
} from '~/utils/clientAllocation';
import { extractErrorMessage } from '~/utils/error';

import { MY_ACT_TEAM, VIEW_ACT_TEAM, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import { IClientAllocationDetailed } from '~/services/api/clientAllocation/types';
import { IOption } from '~/ui/components/inputs/Select/types';
import { IUserRole } from '~/types';
import smallAvatarIcon from '~/ui/assets/images/smallAvatar.svg';
import styles from './EditClientAllocation.module.scss';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  clientAllocation: IClientAllocationDetailed;
  actTeamId: string;
  clinicId: string;
}

interface IParams {
  clientAllocationId: string;
}

const Form = ({
  clientAllocation,
  teamMembers,
  clients,
  actTeamId,
  clinicId,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  // * details used to transfer data from form to modal
  const [details, setDetails] = useState(null);
  const { clientAllocationId } = useParams<IParams>();
  const { push } = useHistory();

  const {
    register,
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: clientAllocation.name,
      date: clientAllocation.date,
      ...prepareDefaultValues(clientAllocation.users),
    },
    resolver: validate,
  });

  const { roles } = useStoreState(state => state.user.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const isActTeamLeader = roles?.includes(IUserRole.ActTeamLeader);

  const { name, ...formValues } = watch();

  const unique = new Set(formValuesMapper(formValues));

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique.has(data.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
      };
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = async ({ name, ...vals }: any) => {
    try {
      setIsAdding(true);
      const users = prepareUsers(vals);

      const payload = {
        name,
        users,
      };

      if (!users.length) {
        showError('Please provide at least one client');
        return;
      }

      await api.clientAllocation.updateClientAllocation(
        { clinicId, teamId: actTeamId, clientAllocationId },
        payload,
      );

      showNotify('Client allocation successfully updated');
      if (isActTeamLeader) {
        push(MY_ACT_TEAM, { selectedTab: 0 });
      } else {
        push(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), { selectedTab: 0 });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const openConfirmationModal = ({ date, ...rest }: any) => {
    if (new Date(date) < new Date()) {
      setDetails(rest);
      return;
    }
    onSubmit(rest);
  };

  const leavePageDescription =
    'Are you sure you want to cancel Edit Client Allocation”? All the changes will be discarded.';

  const editPastAllocationDescription =
    'Caution. You are modifying a past date. Are you sure you want to proceed?';

  return (
    <form onSubmit={handleSubmit(openConfirmationModal)}>
      <Paper>
        <Box p={2}>
          <div className={styles.header}>
            <h5 className={styles.subTitle}>Client Allocation Details</h5>
            <h4 className={styles.date}>{isoToLocal(clientAllocation.date)}</h4>
          </div>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Input name="name" register={register} errors={errors} label="Allocation Name" />
            </Grid>
            <Grid item sm={6} />
            <Grid item sm={3}>
              <h5 className={styles.subTitle}>Team Members</h5>
            </Grid>
            <Grid item sm={9}>
              <h5 className={styles.subTitle}>Assigned Clients</h5>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={styles.formWrapper}>
            {teamMembers.map(teamMember => (
              <Fragment key={teamMember.id}>
                <Grid item sm={3}>
                  <div className={styles.row}>
                    <PrivateImage
                      className={styles.image}
                      src={teamMember.photo || smallAvatarIcon}
                      height={30}
                      alt="avatar"
                    />
                    <span className={styles.text}>{teamMember.fullName}</span>
                  </div>
                </Grid>
                <Grid item sm={9}>
                  <SelectComponent
                    closeMenuOnSelect={false}
                    showCustomComponents
                    hideSelectedOptions={false}
                    additionalStyleHandler={() => additionalStyleHandler()}
                    isMulti
                    label="Assign Clients"
                    options={clients}
                    name={`clients_${teamMember.id}`}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Paper>
      <div className={styles.buttons}>
        <div className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (isActTeamLeader) {
                push(
                  VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                    ':clientAllocationId',
                    String(clientAllocation.id),
                  ),
                );
              } else
                push(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), { selectedTab: 0 });
            }}
          >
            Cancel
          </Button>
        </div>
        <Button color="primary" variant="contained" type="submit">
          Save Changes
        </Button>
      </div>
      <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
      {/* this modal triggers only when allocation date is in the past */}
      {details && (
        <ConfirmModal
          onConfirm={() => {
            onSubmit(details);
          }}
          onClose={() => setDetails(null)}
          description={editPastAllocationDescription}
          confirmText="Proceed"
        />
      )}
    </form>
  );
};

export default Form;
