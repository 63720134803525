import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTriggers } from '../types';

const onGetTriggers: IThunkGetTriggers = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const triggers = await api.clientDetails
      .getClientTriggerList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ triggers });
  },
);

export default onGetTriggers;
