import React, { ReactElement } from 'react';
import { components } from 'react-select';

import styles from './CustomSingleValue.module.scss';

const CustomSingleValue = ({ ...rest }: any): ReactElement => (
  <components.SingleValue {...rest} className={styles.customSingleValue}>
    <span className={styles.label}>{rest.data.label}</span>
    {rest.data.description && <span className={styles.description}>{rest.data.description}</span>}
  </components.SingleValue>
);

export default CustomSingleValue;
