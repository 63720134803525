import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Loader from '~/ui/components/common/Loader';
import PersonalDetails from '~/ui/pages/ActTeam/reusable/PersonalDetails';
import Header from './Header';
import WorkDetails from './WorkDetails';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

interface IParams {
  id: string;
  programAssistantId: string;
}

const ViewProgramAssistant = (): ReactElement => {
  const { id: clinicId, programAssistantId } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(true);

  const currentClinic = useStoreState(states => states.clinic.current);
  const currentProgramAssistant = useStoreState(states => states.programAssistant.current);

  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetCurrentProgramAssistant = useStoreActions(
    actions => actions.programAssistant.onGetCurrentProgramAssistant,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetClinic(clinicId);
      await onGetCurrentProgramAssistant({ clinicId, programAssistantId: +programAssistantId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;
  return (
    <>
      <Header
        clinicId={clinicId}
        clinicName={currentClinic.name}
        programAssistantId={programAssistantId}
        programAssistantName={`${currentProgramAssistant.firstName} ${currentProgramAssistant.lastName}`}
      />
      <Grid container sm={12}>
        <PersonalDetails teamMember={currentProgramAssistant} />
      </Grid>
      <Grid container sm={12}>
        <WorkDetails teamMember={currentProgramAssistant} clinicName={currentClinic.name} />
      </Grid>
    </>
  );
};

export default ViewProgramAssistant;
