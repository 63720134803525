import React, { useState } from 'react';
import SendBird, { GroupChannelListQuery, GroupChannel } from 'sendbird';

import { getSendbirdData } from '~/services/auth';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import { IUserRole } from '~/types';

interface IUseChannels {
  loading: boolean;
  next: () => void;
  refresh: () => void;
}

const useChannels = (): IUseChannels => {
  const [query, setQuery] = useState<GroupChannelListQuery | null>(null);
  const [loading, setLoading] = useState(false);

  const sb = SendBird.getInstance();
  const { sendBirdUserId, roles } = useStoreState(state => state.user.current);

  const { accessToken } = getSendbirdData() || {};
  const isActTeam = roles?.some(role =>
    [IUserRole.ActTeamMember, IUserRole.ActTeamLeader].includes(role),
  );

  const { setChannels, updateChannel, updateMessage } = useStoreActions(
    actions => actions.coordinate,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const refresh = React.useCallback(() => {
    if (!sb) {
      return;
    }
    const newQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    if (newQuery) {
      setQuery(newQuery);
    }
  }, [sb]);

  const next = React.useCallback(async () => {
    if (query && query.hasNext && !query.isLoading) {
      setLoading(true);
      query.limit = 100;
      query.next((fetchedChannels, e) => {
        setLoading(false);
        if (!e) {
          setChannels(fetchedChannels);
        } else {
          showError(extractErrorMessage(e));
        }
      });
    }
  }, [query]);

  const currentUser: SendBird.User = React.useMemo(() => sb?.currentUser, [sb]);

  /// on channels event
  const channelHandler = React.useMemo(() => {
    if (sb) {
      return new sb.ChannelHandler();
    }
    return undefined;
  }, [sb]);

  if (channelHandler) {
    channelHandler.onUserJoined = (channel, user) => {
      if (user.userId === currentUser?.userId) {
        updateChannel(channel);
      }
    };
    channelHandler.onChannelChanged = channel => {
      updateChannel(channel as GroupChannel);
      sb.markAsDelivered(channel.url);
    };

    channelHandler.onMessageUpdated = (_channel, message) => {
      updateMessage(message);
    };
  }

  /// on connection event
  const connectionHandler = React.useMemo(() => {
    if (sb) {
      return new sb.ConnectionHandler();
    }
    return undefined;
  }, [sb]);

  if (connectionHandler) {
    connectionHandler.onReconnectSucceeded = () => {
      refresh();
    };
  }

  const connect = async () => {
    if (accessToken) {
      await sb.connect(sendBirdUserId, accessToken, (_user, e) => {
        if (!e) {
          refresh();
        }
      });
    }
  };

  React.useEffect(() => {
    if (!sb || !isActTeam || !sendBirdUserId) {
      return undefined;
    }
    if (channelHandler) {
      sb.addChannelHandler('channels', channelHandler);
    }
    if (connectionHandler) {
      sb.addConnectionHandler('channels', connectionHandler);
    }
    connect();

    return () => {
      sb.removeConnectionHandler('channels');
      sb.removeChannelHandler('channels');
    };
  }, [refresh, channelHandler, connectionHandler, sb, sendBirdUserId]);

  React.useEffect(() => {
    if (query && sb?.currentUser) {
      next();
    }
  }, [query, sb?.currentUser, next]);

  return { loading, next, refresh };
};

export default useChannels;
