import { ReactElement } from 'react';
import { components } from 'react-select';
import CustomCheckbox from '../../CustomCheckbox';

import styles from '../../Select/Select.module.scss';

const SelectAll = ({ children, isSelected, ...rest }: any): ReactElement =>
  rest.options.length > 1 ? (
    <components.MenuList {...rest}>
      <div className={styles.selectAll}>
        <CustomCheckbox
          size="small"
          checked={rest.selectAll}
          className={styles.customCheckbox}
          onChange={() => rest.setSelectAll(!rest.selectAll)}
        />
        <span>Select All</span>
      </div>
      {children}
    </components.MenuList>
  ) : (
    <components.MenuList {...rest}>{children}</components.MenuList>
  );

export default SelectAll;
