import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';
import { eachDayOfInterval, format, isSameDay, isSameMonth } from 'date-fns';
import { ChangeEvent, ReactElement, useCallback, useState } from 'react';

import { useStoreState } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import getFromDate from '../../helpers/getFromDate';
import getToDate from '../../helpers/getToDate';
import useQueryParam from '../../hooks/useQueryParam';
import CalendarSection from '../CalendarSection';
import MonthControls from '../MonthControls';

import styles from './MedicationDeliveryCalendar.module.scss';

interface IProps {
  teamId: number;
  weekMode?: boolean;
  onAdd?: (medicationId: number, date: string, injectable: boolean) => void;
  onEdit?: (id: number, name: string, isArchived?: boolean) => void;
  setWeekMode?: (weekMode: boolean) => void;
}

const MedicationDeliveryCalendar = ({
  teamId,
  weekMode,
  onAdd,
  onEdit,
  setWeekMode,
}: IProps): ReactElement => {
  const { current } = useStoreState(state => state.client);

  const query = useQuery();
  const includeWeekendsQuery = query.get('includeWeekends');
  const selectedMonthQuery = query.get('selectedMonth');
  const weekRangeQuery = query.get('weekRange');

  const [selectedMonth, setSelectedMonth] = useState(
    selectedMonthQuery ? new Date(selectedMonthQuery) : new Date(),
  );
  const [includeWeekends, setIncludeWeekends] = useState<boolean>(
    typeof includeWeekendsQuery === 'string' ? includeWeekendsQuery === 'true' : true,
  );

  const daysInterval = eachDayOfInterval({
    start: getFromDate(selectedMonth, true) as Date,
    end: getToDate(selectedMonth, true) as Date,
  });

  const getInitialRange = () => {
    let initialRange: number;

    daysInterval.forEach((item, index) => {
      const chunkIndex = Math.floor(index / 7);

      if (isSameDay(item, new Date())) {
        initialRange = chunkIndex;
      }
    }, []);

    return initialRange || 0;
  };

  const [weekRange, setWeekRange] = useState(
    weekRangeQuery ? parseInt(weekRangeQuery, 10) : getInitialRange(),
  );

  const toggleIncludeWeekends = (event: ChangeEvent<HTMLInputElement>) => {
    setIncludeWeekends(event.target.checked);
  };

  const toggleMode = (value: boolean) => {
    setWeekMode(value);
  };

  const getMonthRangePrefix = useCallback(() => {
    if (!weekMode) {
      return {};
    }
    const extendedStartDate = getFromDate(selectedMonth, true) as Date;
    const extendedEndDate = getToDate(selectedMonth, true) as Date;
    const startDateSameMonth = isSameMonth(extendedStartDate, selectedMonth);
    const endDateSameMonth = isSameMonth(extendedEndDate, selectedMonth);

    const maxWeekRange = Math.ceil(daysInterval.length / 7) - 1;

    const monthRangeStartPrefix =
      weekRange === 0 && !startDateSameMonth ? format(extendedStartDate, 'MMM') : undefined;

    const monthRangeEndPrefix =
      weekRange === maxWeekRange && !endDateSameMonth ? format(extendedEndDate, 'MMM') : undefined;

    return { monthRangeStartPrefix, monthRangeEndPrefix };
  }, [selectedMonth, weekRange, weekMode]);

  const { monthRangeStartPrefix, monthRangeEndPrefix } = getMonthRangePrefix();

  useQueryParam('weekMode', String(weekMode));
  useQueryParam('includeWeekends', String(includeWeekends));
  useQueryParam('weekRange', String(weekRange));
  useQueryParam('selectedMonth', format(selectedMonth, 'yyyy-MM-dd'));

  if (!current) return null;

  return (
    <Box className={classNames(styles.calendar)}>
      <Box className={classNames(styles.headerSection)}>
        <MonthControls
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          prefix={monthRangeStartPrefix}
          postfix={monthRangeEndPrefix}
        />
        <Box className={styles.rightColumn}>
          <Box className={classNames(styles.includeWeekend)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeWeekends}
                  color="primary"
                  onChange={toggleIncludeWeekends}
                />
              }
              label="Include weekends"
            />
          </Box>
          <Box className={classNames(styles.modeActions)}>
            <Button
              className={classNames(styles.modeButton)}
              color={weekMode ? 'primary' : 'secondary'}
              variant="outlined"
              onClick={() => toggleMode(true)}
            >
              Week
            </Button>
            <Button
              className={classNames(styles.modeButton)}
              color={weekMode ? 'secondary' : 'primary'}
              variant="outlined"
              onClick={() => toggleMode(false)}
            >
              Month
            </Button>
          </Box>
        </Box>
      </Box>
      <CalendarSection
        selectedMonth={selectedMonth}
        teamId={teamId}
        includeWeekends={includeWeekends}
        weekMode={weekMode}
        onAdd={onAdd}
        onEdit={onEdit}
        weekRange={weekRange}
        setWeekRange={setWeekRange}
      />
    </Box>
  );
};

export default MedicationDeliveryCalendar;
