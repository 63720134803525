import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatMedication } from '~/utils/formatMedication';
import { IThunkUpdateMedication, IClientDetailsStore } from '../types';

const onUpdateMedication: IThunkUpdateMedication = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const medication = await api.clientDetails
      .updateClientMedication(requestInfo, requestPayload)
      .then(r => formatMedication(r.data));

    const medications = localState.medications.map(item =>
      item.id !== medication.id ? item : medication,
    );

    setClientDetails({ medications });
  },
);

export default onUpdateMedication;
