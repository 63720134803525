import { action } from 'easy-peasy';
import { unionBy } from 'lodash';

import { IActionSetMessages } from '../types';

const setMessages: IActionSetMessages = action((state, payload) => {
  const { messages, channelUrl } = payload || {};

  const mergedMessages = unionBy(
    messages,
    state.messages[channelUrl]?.items || [],
    'messageId',
  ).sort((a, b) => a.createdAt - b.createdAt);

  const messagesMap: { [key: string]: boolean } = {};

  mergedMessages.forEach(m => {
    messagesMap[m.messageId] = true;
  });

  state.messages = {
    ...state.messages,
    [channelUrl]: {
      ...state.messages[channelUrl],
      messagesMap: { ...messagesMap },
      items: [...mergedMessages],
    },
  };
});

export default setMessages;
