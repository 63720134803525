import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { useStoreActions, useStoreState } from '~/store/hooks';

import Loader from '~/ui/components/common/Loader';
import VisitBreadcrumbs from './components/VisitBreadcrumbs';
import Form from '../Form';

import { formatVisitToForm } from '../../utils/formatVisitToForm';

const AddVisit = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const { visitId } = useParams<{ visitId: string }>();
  const location = useLocation<{ fromPreview?: boolean }>();
  const fromPreview = location.state?.fromPreview;

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { current: visit } = useStoreState(state => state.visits);
  const { onGetVisit } = useStoreActions(action => action.visits);

  const query = useQuery();
  const teamId = query.get('teamId');
  const clientId = query.get('clientId');

  const onMount = async () => {
    setLoading(true);
    if (visitId) {
      await onGetVisit({
        requestInfo: {
          clinicId: String(current?.clinic?.id),
          clientId: String(client?.id),
          visitId: String(visitId),
          teamId,
        },
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    onMount();
  }, [visitId]);

  const clientFullName = `${client?.firstName} ${client?.lastName}`;
  if (loading) return <Loader />;
  return (
    <>
      <h2>{clientFullName}</h2>
      <VisitBreadcrumbs
        clientId={clientId}
        teamId={teamId}
        clientName={clientFullName}
        isEdit={!!visitId}
        visitId={visitId}
      />
      <Form
        visitId={visitId}
        defaultValues={visitId ? formatVisitToForm(visit) : null}
        fromPreview={fromPreview}
      />
    </>
  );
};

export default AddVisit;
