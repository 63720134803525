import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { concat } from 'lodash';

import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions, useStoreState } from '~/store/hooks';
import api from '~/services/api';
import { isFile } from '~/utils/file';
import { extractErrorMessage } from '~/utils/error';
import { formatDefaultValues } from '~/utils/formatDefaultValues';

import { CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';
import { ILocalAdminForm } from '~/services/api/localAdmin/types';

type IParams = {
  id: string;
  localAdminId: string;
};

const breadcrumbsItem = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

const AddLocalAdmin = (): ReactElement => {
  const { id, localAdminId } = useParams<IParams>();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [isLocalAdminAdding, setIsLocalAdminAdding] = useState(false);

  const currentClinic = useStoreState(state => state.clinic.current);
  const currentLocalAdmin = useStoreState(state => state.localAdmin.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetLocalAdmin = useStoreActions(actions => actions.localAdmin.onGetLocalAdmin);

  const onMount = async () => {
    try {
      setLoading(true);
      const promisesArr = [onGetLocalAdmin({ clinicId: id, localAdminId })];
      if (!currentClinic) {
        promisesArr.push(onGetClinic(id));
      }

      await Promise.all(promisesArr);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (!currentClinic || !currentLocalAdmin || loading) return <Loader />;

  const breadcrumbsItems = concat(breadcrumbsItem, [
    {
      title: currentClinic.name,
      to: VIEW_CLINIC.replace(':id', id),
    },
    {
      title: 'Local Admins',
      to: {
        pathname: VIEW_CLINIC.replace(':id', id),
        state: { selectedTab: 2 },
      },
    },
    {
      title: `Edit ${currentLocalAdmin.firstName} ${currentLocalAdmin.lastName} local admin `,
      to: pathname,
    },
  ]);

  const isPending = currentLocalAdmin?.status === 'Pending';

  const onSubmit = async (values: ILocalAdminForm) => {
    setIsLocalAdminAdding(true);
    try {
      const { email, emailDomain, ...restVals } = values;
      const payload = isPending ? { email: email + emailDomain, ...restVals } : { ...values };

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);

        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

        payload.photo = fileName;
      }

      await api.localAdmin.updateLocalAdmin(id, localAdminId, payload);
      showNotify('Local admin edited successfully');
      push(VIEW_CLINIC.replace(':id', id), { selectedTab: 2 });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsLocalAdminAdding(false);
    }
  };

  const leavePageDescription = "Are you sure you want to cancel 'Edit Local Admin'?";
  const cancelLinkTo = {
    pathname: VIEW_CLINIC.replace(':id', id),
    state: { selectedTab: 2 },
  };

  const emailDomainOptions = currentClinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  return (
    <div>
      <h2>Edit Local Admin </h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        defaultValues={formatDefaultValues(currentLocalAdmin)}
        isEdit
        submitText="Save changes"
        isProcessing={isLocalAdminAdding}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
        isPending={isPending}
        emailDomainOptions={isPending ? emailDomainOptions : undefined}
      />
    </div>
  );
};

export default AddLocalAdmin;
