import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number, boolean, array } from 'yup';

import { requiredField } from '~/ui/constants/errorMessages';

const notificationValidationSchema = {
  frequency: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).nullable().typeError(requiredField),
    otherwise: string().nullable(),
  }),
  hasAlerts: boolean(),
  notificationOn: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  teamMembersToNotify: array().when('hasAlerts', {
    is: true,
    then: array().of(number()).min(1, requiredField).required(requiredField),
    otherwise: array().of(number()),
  }),
  time: string().when('hasAlerts', {
    is: true,
    then: string().required(requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
};

const dateValidation = string().when('hasAlerts', {
  is: true,
  then: string().required(requiredField).typeError(requiredField),
  otherwise: string().nullable(),
});

export const triggerValidationSchema = yupResolver(
  object().shape({
    ...notificationValidationSchema,
    note: string(),
    triggerDate: dateValidation,
    triggerId: number().required(requiredField).typeError(requiredField),
    type: string().required(requiredField).typeError(requiredField),
  }),
);

export const medicationValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string(),
    dose: string().required(requiredField),
    injectable: boolean(),
  }),
);

export const medicationDeliveryValidationSchema = yupResolver(
  object().shape({
    notDelivered: boolean(),
    teamMemberId: number().min(1, requiredField).required(requiredField),
    medicationIds: array().of(number().required(requiredField).typeError(requiredField)),
    note: string(),
    time: string().required(requiredField).typeError(requiredField),
    date: string().required(requiredField).typeError(requiredField),
    nonDeliveryReasonId: number().when('notDelivered', {
      is: true,
      then: number().required(requiredField).nullable().typeError(requiredField),
      otherwise: number().nullable(),
    }),
  }),
);

export const injectableMedicationDeliveryValidationSchema = yupResolver(
  object().shape({
    notDelivered: boolean(),
    teamMemberId: number().min(1, requiredField).required(requiredField),
    medicationIds: array().of(number().required(requiredField).typeError(requiredField)),
    note: string(),
    time: string().required(requiredField).typeError(requiredField),
    date: string().required(requiredField).typeError(requiredField),
    nonDeliveryReasonId: number().when('notDelivered', {
      is: true,
      then: number().required(requiredField).nullable().typeError(requiredField),
      otherwise: number().nullable(),
    }),
    lastInjection: boolean().nullable(),
    archiveMedication: boolean().nullable(),
    hasAlerts: boolean().nullable(),
    nextDueDate: string().when('lastInjection', {
      is: true,
      then: string().typeError(requiredField).nullable(),
      otherwise: string().required(requiredField).typeError(requiredField),
    }),
    notificationOn: string().when('hasAlerts', {
      is: true,
      then: string().required(requiredField).typeError(requiredField),
      otherwise: string().nullable(),
    }),
    notificationTime: string().when('hasAlerts', {
      is: true,
      then: string().required(requiredField).typeError(requiredField),
      otherwise: string().nullable(),
    }),
    teamMembersToNotify: array().when('hasAlerts', {
      is: true,
      then: array().of(number()).min(1, requiredField).required(requiredField),
      otherwise: array().of(number()),
    }),
  }),
);

export const deEscalationValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string(),
  }),
);

export const allergyValidationSchema = yupResolver(
  object().shape({
    id: number().required(requiredField).typeError(requiredField),
    note: string(),
  }),
);

export const secondaryDiagnosisValidationSchema = deEscalationValidationSchema;

export default {};
