import { thunk } from 'easy-peasy';
import { IThunkGetClientAllocations } from '../types';
import api from '~/services/api';

const onGetClientAllocations: IThunkGetClientAllocations = thunk(
  // @ts-ignore
  async ({ setClientAllocations }, payload) => {
    const { clinicId, teamId, params } = payload;

    const { items, total } = await api.clientAllocation
      .getClientAllocationList({ clinicId, teamId }, params)
      .then(r => r.data);

    const pagination = { ...params, total };
    setClientAllocations({ items, pagination });
  },
);

export default onGetClientAllocations;
