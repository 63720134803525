import { createStore } from 'easy-peasy';
import user from './user';
import snackbar from './snackbar';
import clinic from './clinic';
import actTeam from './actTeam';
import actTeamMember from './actTeamMember';
import localAdmin from './localAdmin';
import dictionaries from './dictionaries';
import client from './client';
import clientDetails from './clientDetails';
import clientAllocation from './clientAllocation';
import superAdmin from './superAdmin';
import app from './app';
import medicationLogistics from './medicationLogistics';
import coordinate from './coordinate';
import programAssistant from './programAssistant';
import visits from './visits';

import { IStoreModel } from './types';

const store = createStore<IStoreModel>(
  {
    app,
    user,
    clinic,
    snackbar,
    actTeam,
    actTeamMember,
    localAdmin,
    dictionaries,
    client,
    clientDetails,
    clientAllocation,
    superAdmin,
    medicationLogistics,
    coordinate,
    programAssistant,
    visits,
  },
  {
    name: 'VelloHealthStore',
  },
);

export default store;
