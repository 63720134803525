import { validateEmailDomain } from '~/ui/configs/form/validation';

import { IOption } from '~/types';

export const formatIsCreatableValues = (
  options: IOption[],
  showError: (str: string) => void,
): IOption[] => {
  const formattedOptions = options.map(item =>
    typeof item === 'string'
      ? item
      : {
          label: (item.label as string).toLowerCase(),
          value: (item.value as string).toLowerCase(),
        },
  );

  const atLeastOneInvalid = formattedOptions
    .map(item =>
      typeof item === 'string' ? validateEmailDomain(item) : validateEmailDomain(item.value),
    )
    .some(bool => !bool);

  if (atLeastOneInvalid) {
    showError('Invalid email domain');
    return formattedOptions.length >= 1 ? formattedOptions.slice(0, -1) : [];
  }

  return formattedOptions;
};

export const getCreatableValues = (value: IOption[], options: IOption[]): IOption[] =>
  value.map(item =>
    typeof item === 'string' ? options.filter(option => item === option.value)[0] : item,
  );

export default {};
