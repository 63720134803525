import onActivateProgramAssistant from './onActivateProgramAssistant';
import onDeactivateProgramAssistant from './onDeactivateProgramAssistant';
import onGetCurrentProgramAssistant from './onGetCurrentProgramAssistant';
import onGetProgramAssistants from './onGetProgramAssistants';
import onUnlockProgramAssistant from './onUnlockProgramAssistant';
import onReInviteProgramAssistant from './onReInviteProgramAssistant';

export default {
  onGetProgramAssistants,
  onGetCurrentProgramAssistant,
  onActivateProgramAssistant,
  onDeactivateProgramAssistant,
  onUnlockProgramAssistant,
  onReInviteProgramAssistant,
};
