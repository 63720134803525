import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetActTeamsAvailable } from '../types';

const onGetActTeamsAvailable: IThunkGetActTeamsAvailable = thunk(
  // @ts-ignore
  async ({ setAvailableActTeams }, payload) => {
    const { clinicId } = payload;

    const { data } = await api.actTeam.getActTeamsAvailable(clinicId);

    setAvailableActTeams(data);
  },
);

export default onGetActTeamsAvailable;
