import { privateAxios } from '../requestsCollection';
import { IResponse, IParams } from '../types';
import { ILocalAdmin, ILocalAdminForm } from './types';

export const getLocalAdmins = (
  clinicId: string,
  params?: IParams,
): IResponse<{ items: ILocalAdmin[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/local-admin/list`,
    params,
  });

export const activateLocalAdmin = (clinicId: string, localAdminId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/local-admin/${localAdminId}/activate`,
  });

export const deactivateLocalAdmin = (clinicId: string, localAdminId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/local-admin/${localAdminId}/deactivate`,
  });

export const resendInvitation = (clinicId: string, localAdminId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/local-admin/${localAdminId}/resend-invitation`,
  });

export const createLocalAdmin = (clinicId: string, data: ILocalAdminForm): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/local-admin`,
    data,
  });

export const getLocalAdmin = (clinicId: string, localAdminId: string): IResponse<ILocalAdminForm> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/local-admin/${localAdminId}`,
  });

export const updateLocalAdmin = (
  clinicId: string,
  localAdminId: string,
  data: ILocalAdminForm,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/local-admin/${localAdminId}`,
    data,
  });

export const unlockLocalAdmin = (clinicId: string, localAdminId: number): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/local-admin/${localAdminId}/unlock-account`,
  });
