import onGetTypeList from './onGetTypeList';
import onArchiveTypeItem from './onArchiveTypeItem';
import onRestoreTypeItem from './onRestoreTypeItem';
import onAddTypeItem from './onAddTypeItem';
import onUpdateTypeItem from './onUpdateTypeItem';

export default {
  onGetTypeList,
  onArchiveTypeItem,
  onRestoreTypeItem,
  onAddTypeItem,
  onUpdateTypeItem,
};
