import { ReactElement, useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import classNames from 'classnames';

import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import PersonalDetails from '../../reusable/PersonalDetails';
import HealthDetails from '../../reusable/HealthDetails';
import SupportContacts from '../../reusable/SupportContacts';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { CLIENT, EDIT_CLIENT, MY_CLIENTS } from '~/ui/constants/paths';

import { IClinic } from '~/services/api/clinic/types';

import EditIcon from '~/ui/assets/images/editWhiteSecond.svg';
import styles from './ClientProfile.module.scss';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import { IUserRole } from '~/types';

const ClientProfile = (): ReactElement => {
  const { push } = useHistory();
  const { clientId, actTeamId: teamId } = useParams<{ clientId: string; actTeamId: string }>();
  const location = useLocation<{ actTeamId: string; selectedTab: string }>();

  const [restore, setShowRestore] = useState(false);
  const [tab, setTab] = useState(location?.state?.selectedTab || 0);
  const [loading, setLoading] = useState(false);

  const client = useStoreState(state => state.client.current);

  const { roles, team } = useStoreState(state => state.user.current);

  const actTeamId = location.state?.actTeamId || team?.id?.toString() || teamId;

  const isActTeam = roles?.some(role =>
    [IUserRole.ActTeamLeader, IUserRole.ActTeamMember].includes(role),
  );
  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClient = useStoreActions(actions => actions.client.onGetClient);
  const onRestoreClient = useStoreActions(actions => actions.client.onRestoreClient);

  const onMount = async () => {
    try {
      setLoading(true);

      if (!clinic.id) {
        await onGetMyClinic();
      }

      if (clinic.id) {
        await onGetClient({
          clientId,
          teamId: actTeamId,
          clinicId: String(clinic.id),
        });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [clinic.id]);

  if (loading || !client || !clinic) return <Loader />;

  const itemsForBreadcrumbs = [
    isActTeam
      ? { to: MY_CLIENTS, title: `Clients` }
      : { to: CLIENT, title: `${clinic.name} Clients` },
    { to: location.pathname, title: 'Client Profile' },
  ];

  const restoreClient = async () => {
    try {
      await onRestoreClient({
        clientId,
        clinicId: String(clinic.id),
        teamId: String(client.actTeam.id),
      });

      await onGetClient({
        clientId,
        teamId: actTeamId,
        clinicId: String(clinic.id),
      });
      setShowRestore(false);
      showNotify('Client successfully restored');
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const pushToEditClient = () =>
    push({
      pathname: EDIT_CLIENT.replace(':clientId', clientId).replace(
        ':actTeamId',
        String(client.actTeam.id),
      ),
      state: { actTeamId: String(client.actTeam.id) },
    });

  const startIcon = !client.isArchived && <img src={EditIcon} alt="edit" />;
  const buttonText = client.isArchived ? 'Restore Client' : 'Edit Personal Details';
  const method = client.isArchived ? () => setShowRestore(true) : pushToEditClient;
  const hideButton = tab !== 0 || isActTeam;
  const description = `Are you sure you want to restore ${client.firstName} ${client.lastName}`;

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>
            {client.firstName} {client.lastName}
          </h2>
          <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
        </div>
        <div className={styles.headerRightSide}>
          <div className={classNames({ [styles.invisible]: hideButton })}>
            <Button
              color="primary"
              variant="contained"
              startIcon={startIcon}
              onClick={method}
              disabled={hideButton}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      <Tabs
        value={tab}
        textColor="primary"
        indicatorColor="primary"
        onChange={(_e, val) => setTab(val)}
        className={styles.tabsWrapper}
      >
        <Tab label="PERSONAL DETAILS" />
        <Tab label="HEALTH DETAILS" />
        <Tab label="COLLATERAL CONTACTS" />
      </Tabs>
      {tab === 0 && (
        <PersonalDetails client={client} clinicId={String(clinic.id)} actTeamId={actTeamId} />
      )}
      {tab === 1 && (
        <HealthDetails
          client={client}
          clinicId={String(clinic.id)}
          actTeamId={actTeamId}
          noActions={isActTeam}
        />
      )}
      {tab === 2 && (
        <SupportContacts
          client={client}
          clinicId={String(clinic.id)}
          actTeamId={actTeamId}
          noActions={isActTeam}
        />
      )}
      {restore && (
        <ConfirmModal
          confirmText="Restore"
          onConfirm={restoreClient}
          onClose={() => setShowRestore(false)}
          description={description}
        />
      )}
    </div>
  );
};

export default ClientProfile;
