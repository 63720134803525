import { ReactElement, useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import Button from '~/ui/components/common/Button';
import Table from '../../reusable/Table';
import styles from './ActTeamManagement.module.scss';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import { ADD_ACT_TEAM } from '~/ui/constants/paths';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

const ActTeamManagement = (): ReactElement => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [actTeam, setActTeam] = useState(null);

  const { list, pagination } = useStoreState(state => state.actTeam);
  const { onArchiveActTeam, onGetAllActTeams, onRestoreActTeam } = useStoreActions(
    actions => actions.actTeam,
  );

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      setLoading(true);
      await onGetAllActTeams({ pageNumber: 1, pageSize: pagination.pageSize });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  const description = actTeam?.isArchived
    ? `Are you sure you want to restore ${actTeam?.name}?`
    : `Are you sure you want to archive ${actTeam?.name}? All team members will be archived as well.`;

  const onConfirmText = actTeam?.isArchived ? 'Restore' : 'Archive';
  const onConfirmMethod = actTeam?.isArchived ? onRestoreActTeam : onArchiveActTeam;

  const onConfrim = async () => {
    try {
      const payload = { clinicId: actTeam.clinic.id, actTeamId: actTeam.id };
      await onConfirmMethod(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setActTeam(null);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>ACT Team Management</h2>
        <div>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={() => push(ADD_ACT_TEAM)}
          >
            Add New ACT Team
          </Button>
        </div>
      </div>
      <Table
        setActTeam={setActTeam}
        actTeams={list}
        pagination={pagination}
        onGetAllActTeams={onGetAllActTeams}
      />
      {actTeam && (
        <ConfirmModal
          onConfirm={onConfrim}
          confirmText={onConfirmText}
          description={description}
          onClose={() => setActTeam(null)}
        />
      )}
    </div>
  );
};

export default ActTeamManagement;
