import { ReactElement } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import SearchIcon from '@material-ui/icons/Search';

import { formatPhoneNumber } from '~/utils/formatPhoneNumber';
import { getAddress } from './helper';
import Status from '~/ui/components/common/Status';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';

import { EDIT_CLINIC, VIEW_CLINIC } from '~/ui/constants/paths';
import { red } from '~/ui/constants/colors';
import { IClinic } from '~/services/api/clinic/types';
import { statusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import { IPagination, IParams, Sorting } from '~/services/api/types';

import editGrey from '~/ui/assets/images/editGrey.svg';
import archiveGrey from '~/ui/assets/images/archiveIcon.svg';
import styles from './Table.module.scss';

interface IProps {
  clinics: IClinic[];
  pagination: IPagination;
  onGetClinics: (pagination: IPagination) => void;
  setClinic: (obj: { id: number; name: string }) => void;
  setIsArchiveClinic: (bool: boolean) => void;
}

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

const Table = ({
  clinics,
  pagination,
  onGetClinics,
  setClinic,
  setIsArchiveClinic,
}: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const watchValues = watch();

  const onSubmit = (vals: IParams) => {
    const { pageSize, pageNumber } = pagination;
    onGetClinics({ ...vals, pageSize, pageNumber });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search Clinics"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={statusesOptions}
                  color={red}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>Clinic Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <span className={styles.actionsCell}>Actions</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clinics.map(clinic => {
            const toEdit = EDIT_CLINIC.replace(':id', String(clinic.id));
            const toView = VIEW_CLINIC.replace(':id', String(clinic.id));

            return (
              <TableRow key={clinic.id}>
                <TableCell>
                  <Link to={toView} className={styles.linkView}>
                    {clinic.name}
                  </Link>
                </TableCell>
                <TableCell>{getAddress(clinic)}</TableCell>
                <TableCell>{formatPhoneNumber(clinic.phone)}</TableCell>
                <TableCell>
                  <Status isArchived={clinic.isArchived} />
                </TableCell>
                <TableCell className={styles.colPadding}>
                  {clinic.isArchived ? (
                    <button
                      className={styles.restoreButton}
                      type="button"
                      onClick={() => {
                        setClinic({ id: clinic.id, name: clinic.name });
                        setIsArchiveClinic(false);
                      }}
                    >
                      Restore
                    </button>
                  ) : (
                    <>
                      <Link to={toEdit}>
                        <IconButton>
                          <img src={editGrey} alt="edit" />
                        </IconButton>
                      </Link>
                      <IconButton
                        onClick={() => {
                          setClinic({ id: clinic.id, name: clinic.name });
                          setIsArchiveClinic(true);
                        }}
                      >
                        <img src={archiveGrey} alt="archive" />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          onGetClinics({
            pageNumber: pageNumber + 1,
            pageSize: pagination.pageSize,
            ...watchValues,
          });
        }}
        onRowsPerPageChange={e => {
          onGetClinics({ pageNumber: 1, pageSize: Number(e.target.value), ...watchValues });
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
