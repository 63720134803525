import { ReactElement } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HistoryListener } from 'react-router-navigation-confirm';
import { ThemeProvider } from '@material-ui/styles';
import { useStoreRehydrated } from 'easy-peasy';

import { useStoreActions } from '~/store/hooks';
import Loader from './components/common/Loader';
import Routes from './Routes';
import muiTheme from './themes/muiTheme';

import './assets/styles/app.scss';

const App = (): ReactElement => {
  const isRehydrated = useStoreRehydrated();
  const onCheckAuthorization = useStoreActions(actions => actions.user.onCheckAuthorization);
  onCheckAuthorization();

  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <HistoryListener>{isRehydrated ? <Routes /> : <Loader />}</HistoryListener>
      </Router>
    </ThemeProvider>
  );
};
export default App;
