import { ReactElement, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { useStoreState, useStoreActions } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { destructHours } from '~/utils/date';
import { splitSeconds } from '../../utils/splitSeconds';
import { getTypeString } from '../../utils/getTypeString';

import PreviewBreadcrumbs from './components/PreviewBreadcrumbs';
import Loader from '~/ui/components/common/Loader';
import ListItem from '~/ui/components/common/ListItem';

import { EDIT_VISIT } from '~/ui/constants/paths';

import styles from './PreviewVisit.module.scss';

const PreviewVisit = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const { push } = useHistory();
  const { visitId } = useParams<{ visitId: string }>();

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { current: visit } = useStoreState(state => state.visits);
  const { onGetVisit } = useStoreActions(action => action.visits);
  const { onGetClient } = useStoreActions(action => action.client);

  const query = useQuery();
  const clientId = query.get('clientId');
  const teamId = query.get('teamId');

  const redirectToEditPage = (): void => {
    push(
      EDIT_VISIT.replace(':visitId', String(visitId)).concat(
        `?teamId=${teamId}&clientId=${clientId}`,
      ),
      { fromPreview: true },
    );
  };

  const generateVisitedPersons = (): string => {
    let visitedPersons = '';
    if (visit.isClientVisited) {
      visitedPersons = visitedPersons.concat('Client');
    }
    if (visit.visitedPeople) {
      visit.visitedPeople.forEach(person => {
        visitedPersons = visitedPersons.concat(
          `${visit.isClientVisited ? ',' : ''} ${person.name} | ${person.relationship}`,
        );
      });
    }
    return visitedPersons;
  };
  const generateVisitors = (): string => {
    let visitors = '';
    if (visit.visitedBy) {
      visit.visitedBy.forEach((person, index: number) => {
        visitors = visitors.concat(
          `${person.name}${visit.visitedBy.length - 1 === index ? '' : ', '}`,
        );
      });
    }
    return visitors;
  };

  const onMount = async () => {
    setLoading(true);
    await onGetVisit({
      requestInfo: {
        clinicId: String(current?.clinic?.id),
        clientId,
        visitId: String(visitId),
        teamId,
      },
    });
    await onGetClient({ clinicId: String(current?.clinic?.id), teamId, clientId });
    setLoading(false);
  };
  useEffect(() => {
    onMount();
  }, []);

  const editedBy =
    visit?.editInformation &&
    `Edited By ${visit?.editInformation.name}, ${format(
      new Date(visit?.editInformation.date),
      'MMM dd',
    )} at ${format(new Date(visit?.editInformation.date), 'hh:mm a')}`;

  const address =
    visit?.address &&
    `${visit?.address.line1.trim()}, ${visit?.address.city.trim()}, ${visit?.address?.state?.name.trim()}, ${visit?.address.zipCode.trim()}`;
  const clientNoShowText = visit?.clientNoShow && (
    <div>
      Client No Show <div className={styles.noShowReason}>{`${visit?.noShowReason.name}`}</div>
    </div>
  );
  if (loading) return <Loader />;
  return (
    <div>
      <Grid container spacing={2} className={styles.headerContainer}>
        <Grid item sm={10}>
          <h2>
            {client?.firstName} {client?.lastName}
          </h2>
          <PreviewBreadcrumbs
            teamId={teamId}
            clientId={clientId}
            visitId={visitId}
            visitDate={new Date(visit.date)}
          />
          <Box className={styles.editedBy}>
            <p>{editedBy}</p>
          </Box>
        </Grid>
        <Grid item sm={2} className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              redirectToEditPage();
            }}
          >
            Edit Visit
          </Button>
        </Grid>
      </Grid>

      <Box className={styles.previewContainer}>
        <ListItem
          label="Visit Info"
          content={clientNoShowText}
          moreLabelWidth
          className={styles.noShowField}
          noActions
        />
        <ListItem
          label="Date of Visit"
          content={format(new Date(visit.date), 'MM/dd/yyyy')}
          moreLabelWidth
        />
        <ListItem
          label="Time of Visit"
          content={`${format(destructHours(visit.startTime), 'hh:mm a')} ${
            !visit.clientNoShow ? `(${splitSeconds(visit.durationSeconds).trim()})` : ''
          }`}
          moreLabelWidth
        />
        {!visit?.clientNoShow && (
          <ListItem label="Type of Visit" content={getTypeString(visit?.type)} moreLabelWidth />
        )}
        {!visit?.clientNoShow && (
          <ListItem label="Visited Person" content={generateVisitedPersons()} moreLabelWidth />
        )}
        <ListItem label="Visited By" content={generateVisitors()} moreLabelWidth />
        {!visit?.clientNoShow && visit.address && (
          <ListItem label="Address of Visit" content={address} moreLabelWidth />
        )}
        {!visit?.clientNoShow && <ListItem label="Note" content={visit.note} moreLabelWidth />}
      </Box>
    </div>
  );
};

export default PreviewVisit;
