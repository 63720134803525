import { useRef, ReactElement } from 'react';
import { FileDrop } from 'react-file-drop';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import PrivateImage from '../PrivateImage';

import editIcon from '~/ui/assets/images/editGrey.svg';
import deleteIcon from '~/ui/assets/images/trashBlack.svg';
import imgSrc from '~/ui/assets/images/undraw.svg';
import { isFile, validateFileType } from '~/utils/file';
import styles from './AvatarUpload.module.scss';

interface IProps {
  value?: any;
  acceptTypes?: string;
  maxFileSize?: number;
  onUpload: (file: File) => void;
  onRemove: () => void;
  onError: (err: string) => void;
  isPhoto?: boolean;
}

const AvatarUpload = ({
  value,
  acceptTypes = 'image/jpeg,image/png,image/gif',
  maxFileSize = 2, // 2Mb
  onUpload,
  onRemove,
  onError,
  isPhoto,
}: IProps): ReactElement => {
  const fileInputRef = useRef(null);

  const validateFile = (file: File) => {
    if (!isFile(file)) return 'No file';

    if (!validateFileType(file, acceptTypes)) {
      const shortTypeNames = acceptTypes
        .split(',')
        .map(type => type.split('/').pop().toLowerCase())
        .join(',');

      return `Only ${shortTypeNames} formats are valid`;
    }

    // convert fileSize from Mb to bytes (1 Mb = 1048576 Bytes)
    const maxFileSizeBytes = maxFileSize * 1048576;

    if (maxFileSize && file.size > maxFileSizeBytes) return `Max file size - ${maxFileSize}Mb`;

    return '';
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onChange = (file: File) => {
    const err = validateFile(file);

    if (err) {
      onError(err);
    } else {
      // Reset input value
      fileInputRef.current.value = '';
      onUpload(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={classnames(styles.dragAndDropContainer, { [styles.hidden]: Boolean(value) })}>
        <FileDrop
          onTargetClick={onTargetClick}
          onDrop={(files, event) => {
            event.preventDefault();
            onChange(files[0]);
          }}
          className={styles.dropzone}
          targetClassName={styles.dropzoneTarget}
          draggingOverTargetClassName={styles.draggingOverTargetClassName}
        />
        {/* Use input for onClick handler */}
        <input
          ref={fileInputRef}
          type="file"
          accept={acceptTypes}
          className={styles.hidden}
          onChange={event => {
            const { files } = event.target;
            onChange(files[0]);
          }}
        />
        <div className={styles.dragAndDropDisplayContent}>
          <div className={styles.contentIcon}>
            <img src={imgSrc} alt="undraw" />
          </div>
          <div>
            <div className={styles.contentTitle}>Upload {isPhoto ? 'Photo' : 'Logo'}</div>
            <div className={styles.contentDescription}>
              Drag and drop files or browse your computer
            </div>
          </div>
        </div>
      </div>
      {value && (
        <div className={styles.previewContainer}>
          <div className={styles.imagePreview}>
            <PrivateImage
              src={isFile(value) ? URL.createObjectURL(value) : value}
              className={styles.image}
              alt="Preview"
            />
          </div>
          <div>
            <IconButton onClick={onTargetClick} title="Edit">
              <img src={editIcon} alt="edit" />
            </IconButton>
            <IconButton onClick={onRemove} title="Remove">
              <img src={deleteIcon} alt="delete" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarUpload;
