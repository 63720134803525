import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClinic } from '../types';

// @ts-ignore
const onGetClinic: IThunkGetClinic = thunk(async ({ setClinic }, clinicId) => {
  const { data } = await api.clinic.getClinic(clinicId);

  setClinic(data);
});

export default onGetClinic;
