import { action } from 'easy-peasy';
import { IActionSetClientAllocations } from '../types';

const setClientAllocations: IActionSetClientAllocations = action((state, payload) => {
  const { items, pagination } = payload;

  if (pagination) {
    state.pagination.pageNumber = pagination.pageNumber;
    state.pagination.pageSize = pagination.pageSize;
    state.pagination.total = pagination.total;
  }
  state.list = items;
});

export default setClientAllocations;
