import { Box, IconButton } from '@material-ui/core';
import { ReactElement, useMemo, useState } from 'react';

import Initials from '~/ui/components/common/Initials';

import { IDictionary } from '~/services/api/dictionaries/types';
import arrowBack from '~/ui/assets/images/arrowBack.svg';
import styles from './MonitoredBy.module.scss';

interface IProps {
  monitoredBy: IDictionary[];
}

const MonitoredBy = ({ monitoredBy }: IProps): ReactElement => {
  const [activeItem, setActiveItem] = useState<IDictionary | null>(null);
  const [range, setRange] = useState(0);

  const count = monitoredBy.length;
  const maxRange = Math.ceil(count / 5) - 1;

  const selectItem = (item: IDictionary) => {
    setActiveItem(item);
  };

  const onClickLeft = () => {
    setRange(prev => (prev - 1 <= 0 ? 0 : prev - 1));
  };
  const onClickRight = () => {
    setRange(prev => (prev + 1 > maxRange ? maxRange : prev + 1));
  };

  const monitored = useMemo(
    () => monitoredBy.slice(5 * range, 5 * range + 5),
    [monitoredBy, range],
  );

  return (
    <Box className={styles.column}>
      <div className={styles.black}>Monitored by:</div>
      <div className={styles.row}>
        {range >= 1 && (
          <IconButton className={styles.arrowLeft} onClick={onClickLeft} title="">
            <img src={arrowBack} alt="arrow-left" />
          </IconButton>
        )}
        {monitored.map(item => (
          <div className={styles.initialsItem} key={`team-member-${item.id}`}>
            <Initials
              active={activeItem === item}
              name={item.name}
              onClick={() => selectItem(item)}
              className={styles.marginRight}
            />
            {activeItem === item && <span className={styles.name}>{activeItem?.name}</span>}
          </div>
        ))}
        {range < maxRange && (
          <IconButton className={styles.arrowRight} onClick={onClickRight} title="">
            <img src={arrowBack} alt="arrow-right" />
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default MonitoredBy;
