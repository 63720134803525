import React, { ReactElement } from 'react';

import ListItem from '../../ListItem';

import { IDictionary } from '~/services/api/dictionaries/types';
import { IUserPersonalDetails } from '../types';

import styles from '../UserProfile.module.scss';

interface IProps {
  user: IUserPersonalDetails;
}
const PersonalDetails = ({ user }: IProps): ReactElement => (
  <div className={styles.column}>
    <div className={styles.listItemBlock}>
      <div className={styles.item}>
        <h4>Personal Details</h4>
      </div>
      <ListItem label="First Name" content={String(user.firstName)} moreLabelWidth />
      <ListItem label="Last Name" content={String(user.lastName)} moreLabelWidth />
      <ListItem label="Phone" content={String(user.phone)} moreLabelWidth />
      <ListItem label="Email" content={String(user.email)} moreLabelWidth />
      <ListItem
        label="Sub-Role"
        content={user.subRoles.map((r: IDictionary) => r.name).join(', ')}
        moreLabelWidth
      />
    </div>
  </div>
);

export default PersonalDetails;
