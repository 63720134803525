import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

import styles from './CalendarCell.module.scss';

interface IProps {
  first?: boolean;
  weekend?: boolean;
  today?: boolean;
  hidden?: boolean;
  weekMode?: boolean;
  dayNumber?: number;
  dayOfWeek?: string;
  data?: ReactNode;
  isFuture?: boolean;
  onClick?: () => void;
}

const CalendarCell = ({
  first,
  data,
  dayNumber,
  dayOfWeek,
  weekend,
  today,
  hidden,
  weekMode,
  isFuture,
  onClick,
}: IProps): ReactElement => (
  <Box
    className={classNames(styles.calendarCell, {
      [styles.firstCell]: first,
      [styles.noBorder]: dayNumber === 0 && !data,
      [styles.weekend]: weekend,
      [styles.today]: today,
      [styles.hidden]: hidden,
      [styles.weekMode]: weekMode && !first,
      [styles.dayCell]: typeof dayNumber === 'number',
      [styles.withContent]: !first && typeof dayNumber !== 'number',
      [styles.noHover]: (!first && typeof dayNumber !== 'number' && data) || isFuture,
    })}
    onClick={onClick}
  >
    {dayNumber === 0 && !data && <span>Medication</span>}
    {data}
    {dayNumber || null}
    {dayOfWeek && (
      <div className={classNames(styles.dayOfWeek, { [styles.today]: today })}>{dayOfWeek}</div>
    )}
  </Box>
);

export default CalendarCell;
