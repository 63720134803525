import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveAvatar } from '../types';

// @ts-ignore
const onRemoveAvatar: IThunkRemoveAvatar = thunk(async ({ removeAvatar }) => {
  await api.users.uploadProfilePhoto('');
  removeAvatar();
});

export default onRemoveAvatar;
