import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetSuperAdmin } from '../types';

// @ts-ignore
const onGetSuperAdmin: IThunkGetSuperAdmin = thunk(async ({ setSuperAdmin }, superAdminId) => {
  const { data } = await api.superAdmin.getSuperAdmin(superAdminId);

  setSuperAdmin(data);
});

export default onGetSuperAdmin;
