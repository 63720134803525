import { useState, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import { useStoreActions } from '~/store/hooks';
import { extractErrorCode, extractErrorMessage } from '~/utils/error';
import { initialValues, validate } from './form';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';

import { FORGOT_PASSWORD, CHANGE_PASSWORD } from '~/ui/constants/paths';
import { ErrorCodes } from './types';
import { IFormValues } from './form/types';
import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './SignIn.module.scss';

const SignInForm = (): ReactElement => {
  const { push } = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const { email } = watch();

  const onLogin = useStoreActions(actions => actions.user.onLogin);

  const navigateToChangePassword = () => {
    history.push(CHANGE_PASSWORD, {
      email,
    });
  };

  const onSubmit = async (values: IFormValues) => {
    setIsLoading(true);

    try {
      await onLogin(values);
    } catch (e) {
      if (extractErrorCode(e) === ErrorCodes.PASSWORD_EXPIRED) {
        navigateToChangePassword();
      }
      setServerError(extractErrorMessage(e));
      setIsLoading(false);
    }
  };

  return (
    <>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      <Paper className={styles.signIn}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.title}>Log In</div>
          <div className={styles.row}>
            <Input
              name="email"
              register={register}
              errors={errors}
              size="medium"
              label="Email"
              placeholder="Email Address"
            />
          </div>
          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              register={register}
              errors={errors}
              size="medium"
              label="Password"
              placeholder="Password"
            />
          </div>
          <div className={styles.row}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              className={styles.btn}
              disabled={isLoading}
            >
              Log In
            </Button>
          </div>
          <div className={styles.divider} />
          <p className={styles.forgotPasswordText}>
            Forgot your password?
            <button
              type="button"
              className={styles.forgotPasswordButton}
              onClick={() => push(FORGOT_PASSWORD)}
            >
              Reset
            </button>
          </p>
          {serverError && <div className={styles.serverError}>{serverError}</div>}
          {isLoading && <Loader />}
        </form>
      </Paper>
    </>
  );
};

export default SignInForm;
