import React, { ReactElement } from 'react';

import ListItem from '../../ListItem';
import { IUserAddress } from '../types';

import styles from '../UserProfile.module.scss';

interface IProps {
  user: IUserAddress;
}

const Address = ({ user }: IProps): ReactElement => (
  <div className={styles.column}>
    <div className={styles.listItemBlock}>
      <div className={styles.item}>
        <h4>Address</h4>
      </div>
      <ListItem label="City" content={String(user.address.city)} moreLabelWidth />
      <ListItem label="Address 1" content={String(user.address.line1)} moreLabelWidth />
      <ListItem label="Address 2" content={String(user.address.line2)} moreLabelWidth />
      <ListItem label="State" content={String(user.address.state.abbreviation)} moreLabelWidth />
      <ListItem label="Zip Code" content={String(user.address.zipCode)} moreLabelWidth />
    </div>
  </div>
);

export default Address;
