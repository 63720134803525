import onGetLocalAdmins from './onGetLocalAdmins';
import onDeactivateLocalAdmin from './onDeactivateLocalAdmin';
import onActivateLocalAdmin from './onActivateLocalAdmin';
import onGetLocalAdmin from './onGetLocalAdmin';
import onUnlockLocalAdmin from './onUnlockLocalAdmin';

export default {
  onGetLocalAdmins,
  onDeactivateLocalAdmin,
  onActivateLocalAdmin,
  onGetLocalAdmin,
  onUnlockLocalAdmin,
};
