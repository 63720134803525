import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkRemoveMember } from '../types';

const onRemoveMember: IThunkRemoveMember = thunk(
  // @ts-ignore
  async ({ removeMember }, { channelUrl, userIds }) => {
    await api.coordinate.removeMember(channelUrl, userIds);

    removeMember({ channelUrl, userIds });
  },
);

export default onRemoveMember;
