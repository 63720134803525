import { ReactElement } from 'react';
import { Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import validate from './validate';

import styles from '../EmailForm/EmailForm.module.scss';

interface IProps {
  onSubmit: (val: any) => void;
  error: string;
  title: string;
  subTitle: string;
  buttonText: string;
}

const PasswordForm = ({ onSubmit, error, title, subTitle, buttonText }: IProps): ReactElement => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: validate });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={styles.signUp}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subTitle}>{subTitle}</p>
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="password"
            placeholder="Password"
          />
        </div>
        <Input
          type="password"
          register={register}
          errors={errors}
          name="passwordConfirmation"
          placeholder="Confirm Password"
        />
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {buttonText}
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default PasswordForm;
