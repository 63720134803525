import { privateAxios } from '../requestsCollection';
import { IResponse, IParams } from '~/services/api/types';
import { IActTeam } from './types';

export const getClinicActTeams = (
  clinicId: string,
  params: IParams,
): IResponse<{ items: IActTeam[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/list`,
    params,
  });

export const getClinicActTeam = (clinicId: string, actTeamId: string): IResponse<IActTeam> =>
  privateAxios({ method: 'get', url: `clinic/${clinicId}/act-team/${actTeamId}` });

export const getAllActTeams = (params: IParams): IResponse<{ items: IActTeam[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: 'act-team/list',
    params,
  });

export const archiveActTeam = (clinicId: string, actTeamId: string): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `/clinic/${clinicId}/act-team/${actTeamId}`,
  });

export const restoreActTeam = (clinicId: string, actTeamId: string): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/act-team/${actTeamId}/recover`,
  });

export const editClinicActTeam = (
  clinicId: string,
  actTeamId: string,
  data: IActTeam,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `/clinic/${clinicId}/act-team/${actTeamId}`,
    data,
  });

export const createClinicActTeam = (clinicId: string, data: IActTeam): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `/clinic/${clinicId}/act-team`,
    data,
  });

export const getActTeamsAvailable = (clinicId: string): IResponse<{ id: number; name: string }[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/available-list`,
  });

export default {};
