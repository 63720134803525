import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkAddTypeItem, IDictionariesStore } from '../types';

const onAddTypeItem: IThunkAddTypeItem = thunk(
  // @ts-ignore
  async ({ setTypeList }, payload, { getState }) => {
    const localState = getState() as IDictionariesStore;

    const { data } = await api.dictionaries.addTypeItem({ ...payload, isArchived: false });

    setTypeList({
      items: [data, ...localState.list].slice(0, localState.pagination.pageSize),
      pagination: { ...localState.pagination, total: (localState.pagination.total += 1) },
    });
  },
);

export default onAddTypeItem;
