import { IActTeamMemberStore } from './types';

const initStore: IActTeamMemberStore = {
  current: null,
  list: [],
  availableList: [],
  pagination: {
    pageSize: 5,
    pageNumber: 1,
    total: 0,
  },
};

export default initStore;
