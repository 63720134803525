import { ReactElement, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';

import styles from './EmailForm.module.scss';

interface IProps {
  onSubmit: (val: any) => void;
  error: string;
  resetError: () => void;
}

const EmailForm = ({ onSubmit, error, resetError }: IProps): ReactElement => {
  const { goBack } = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const emailValue = watch('email');

  useEffect(() => {
    resetError();
  }, [emailValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={styles.signUp}>
        <h2 className={styles.title}>Forgot Password?</h2>
        <p className={styles.subTitle}>Enter your email to get further instructions</p>
        <Input register={register} errors={errors} name="email" placeholder="Email" />
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Reset Password
          </Button>
        </div>
        <Button
          type="button"
          onClick={goBack}
          color="primary"
          fullWidth
          className={styles.backButton}
        >
          Go Back
        </Button>
      </Paper>
    </form>
  );
};

export default EmailForm;
