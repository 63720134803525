import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import { PROFILE } from '~/ui/constants/paths';
import Avatar from '~/ui/components/common/Avatar';

import logo from '~/ui/assets/images/logo.svg';
import styles from './Header.module.scss';

const Header = (): ReactElement => {
  const { push } = useHistory();

  const { firstName, lastName, photo } = useStoreState(state => state.user.current);
  const fullName = `${firstName} ${lastName}`;

  return (
    <header className={styles.header}>
      <div className={styles.leftContainer}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.block}>
          <div role="presentation" className={styles.inner} onClick={() => push(PROFILE)}>
            <Avatar url={photo} />
            <span className={styles.title}>{fullName || 'Nathaniel Weiss'}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
