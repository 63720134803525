import { useState, ReactElement, ReactNode } from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import EyeClosed from '~/ui/assets/images/viewClosed.svg';
import EyeOpen from '~/ui/assets/images/viewOpen.svg';
import styles from './Input.module.scss';

interface IProps {
  register: UseFormRegister<any>;
  errors: FieldErrors;
  type?: string;
  size?: 'medium' | 'small';
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  multiline?: boolean;
  tabIndex?: number;
  combinedInput?: boolean;
  autoFocus?: boolean;
  minRows?: number;
}

const Input = ({
  register,
  errors,
  type = 'text',
  size = 'small',
  name,
  label = '',
  disabled = false,
  placeholder = '',
  startAdornment,
  endAdornment,
  multiline,
  tabIndex,
  combinedInput,
  autoFocus = false,
  minRows = 1,
}: IProps): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  const { ref, ...registerProps } = register(name);

  const errorText = get(errors, `${name}.message`, '');
  const isError = Boolean(errorText);

  const getInputType = () => {
    if (type !== 'password') return type;
    return showPassword ? 'text' : 'password';
  };

  return (
    <FormControl
      variant="outlined"
      size={size}
      className={classNames(styles.inputContainer, {
        [styles.overwrittenInputContainer]: combinedInput,
      })}
    >
      {label && (
        <InputLabel shrink error={isError}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        autoFocus={autoFocus}
        multiline={multiline}
        onKeyPress={e => {
          if (e.code === 'Minus' && type === 'number') {
            e.preventDefault();
          }
        }}
        inputProps={{
          tabIndex,
          min: 0,
        }}
        classes={{
          root: combinedInput ? styles.root : null,
          notchedOutline: combinedInput ? styles.notchedOutline : null,
        }}
        {...registerProps}
        inputRef={ref}
        type={getInputType()}
        notched={!!label}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        startAdornment={startAdornment}
        endAdornment={
          endAdornment ||
          (type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                className={styles.icon}
                onMouseDown={() => setShowPassword(true)}
                onMouseUp={() => setShowPassword(false)}
                onMouseMove={() => setShowPassword(false)}
                edge="end"
              >
                {showPassword ? (
                  <img src={EyeOpen} alt="viewClosed" />
                ) : (
                  <img src={EyeClosed} alt="viewOpen" />
                )}
              </IconButton>
            </InputAdornment>
          ))
        }
        error={isError}
        minRows={minRows}
      />
      {isError && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
