import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import image from '~/ui/assets/images/404.png';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = (): ReactElement => {
  const { goBack } = useHistory();
  return (
    <div className={styles.accessDenied}>
      <p className={styles.title}>404: The page you are looking for isn’t here</p>
      <p className={styles.subTitle}>
        You either tried some shady route or you came here by mistake. Whichever it is, try using
        the navigation.
      </p>
      <img src={image} alt="404" />
      <Button color="primary" variant="outlined" size="large" onClick={goBack}>
        Back To Home
      </Button>
    </div>
  );
};
export default NotFoundPage;
