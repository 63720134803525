import { ReactElement, useState } from 'react';
import { Paper } from '@material-ui/core';

import Button from '~/ui/components/common/Button';
import Input from './Input';

import styles from '../EmailForm/EmailForm.module.scss';

interface IProps {
  onSubmit: (val: any) => void;
  error?: string;
  showBack?: boolean;
  buttonText?: string;
  goBack?: () => void;
  subTitle: string;
}

const CodeForm = ({
  onSubmit,
  error,
  showBack = false,
  buttonText = 'Verify Code',
  goBack,
  subTitle,
}: IProps): ReactElement => {
  const [code, setCode] = useState({});

  const focusPrevChar = (target: any) => {
    if (target.previousElementSibling !== null) {
      target.previousElementSibling.focus();
    }
  };

  const focusNextChar = (target: any) => {
    if (target.nextElementSibling !== null) {
      target.nextElementSibling.focus();
    }
  };

  const handleKeyDown = ({ target, key }: any) => {
    if (key === 'Backspace') {
      if (target.value === '' && target.previousElementSibling !== null) {
        target.previousElementSibling.value = '';
        setCode(prev => ({ ...prev, [target.name]: '' }));
        focusPrevChar(target);
      } else {
        target.value = '';
      }
    } else if (key === ' ') {
      focusNextChar(target);
    }
  };

  const handleChange = ({ target }: any) => {
    if (target.value.match(/^[0-9]$/)) {
      setCode(prev => ({ ...prev, [target.name]: target.value }));
      focusNextChar(target);
    }
  };

  const disabled = Object.values(code).length !== 6;

  return (
    <div>
      <Paper className={styles.signUp}>
        <h2 className={styles.title}>Enter Code</h2>
        <p className={styles.subTitle}>{subTitle}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v1" value={code} />
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v2" value={code} />
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v3" value={code} />
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v4" value={code} />
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v5" value={code} />
          <Input handleChange={handleChange} handleKeyDown={handleKeyDown} name="v6" value={code} />
        </div>
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onSubmit(code)}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
        {showBack && (
          <Button onClick={goBack} color="primary" fullWidth className={styles.backButton}>
            Go Back
          </Button>
        )}
      </Paper>
    </div>
  );
};
export default CodeForm;
