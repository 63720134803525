import { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker as MUITimePicker } from '@material-ui/pickers';
import get from 'lodash/get';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { ReactComponent as ClockIcon } from '~/ui/assets/images/clockIcon.svg';
import styles from './TimePicker.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const TimePicker = ({
  control,
  name,
  label,
  errors,
  placeholder,
  disabled,
  size = 'small',
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <>
          <FormControl variant="outlined" size={size} fullWidth>
            {label && <InputLabel shrink>{label}</InputLabel>}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MUITimePicker
                error={!!error}
                fullWidth
                inputVariant="outlined"
                size={size}
                value={value || null}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                InputProps={{
                  endAdornment: <ClockIcon />,
                }}
              />
            </MuiPickersUtilsProvider>
            {error && (
              <FormHelperText error className={styles.errorText}>
                {error}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
};

export default TimePicker;
