import { IOption } from '~/types';
import { IDictionary } from '../dictionaries/types';
import { IAddress, IAddressExtended, ICommonClientDetails } from '../types';

export type INotificationOn = 'SameDay' | 'OneDayBefore' | 'OneWeekBefore' | 'OneMonthBefore';
export type ITriggerFrequency = 'Yearly' | 'Monthly' | 'Weekly' | 'OncePerTwoWeeks';
export type IMedicationFrequency = 'Yearly' | 'Monthly' | 'Weekly' | 'OncePerTwoWeeks';
export type IClientTriggerType = 'Recurring' | 'NonRecurring';

export enum TriggerType {
  Recurring = 'Recurring',
  NoNRecurring = 'NoNRecurring',
}

export interface IRequestParams {
  clinicId: string;
  teamId: string;
  clientId: string;
  id?: string;
}

export interface StatusChangeLog {
  date: string;
  user: {
    id: number;
    name: string;
  };
  delivered: boolean;
}

export interface IClientDeEscalationTechnique extends ICommonClientDetails {
  deEscalationTechnique: IDictionary;
}

export interface IClientAllergy extends ICommonClientDetails {
  allergy: IDictionary;
}

export interface IClientDiagnosis extends ICommonClientDetails {
  diagnosis: IDictionary;
}

export interface IClientMedication extends ICommonClientDetails {
  dose: string;
  medication: IDictionary;
  injectable: boolean;
}

export interface IClientTrigger extends ICommonClientDetails {
  trigger: IDictionary;
  type: IClientTriggerType;
}

export interface IClientDeEscalationRequest {
  id: number;
  note: string;
}

export interface IClientDeEscalationResponse extends ICommonClientDetails {
  deEscalationTechnique: IDictionary;
}

export interface IClientAllergyRequest {
  id: number;
  note: string;
}

export interface IClientAllergyResponse extends ICommonClientDetails {
  allergy: IDictionary;
}

export type IClientSecondaryDiagnosisRequest = IClientDeEscalationRequest;
export type IClientSecondaryDiagnosisResponse = IClientDiagnosis;

export interface IClientMedicationRequest extends IClientDeEscalationRequest {
  dose: string;
  injectable: boolean;
}

export interface IClientMedicationDeliveryRequest extends IClientDeEscalationRequest {
  notDelivered: boolean;
  date: string;
  teamMemberId: number;
  medicationIds: number[];
  clientId: number;
  nextDueDate: string;
  medicationId?: number;
  nonDeliveryReasonId?: number;
  lastInjection?: boolean;
  archiveMedication?: boolean;
  hasAlerts?: boolean;
  statusChangeLog?: StatusChangeLog;
  notificationOn?: string;
  notificationTime?: any;
  teamMembersToNotify?: number[];
}

export interface IClientMedicationResponse extends ICommonClientDetails {
  medication: IDictionary;
  dose: string;
  frequency?: IMedicationFrequency;
  hasAlerts?: boolean;
  injectable?: boolean;
  notificationOn?: INotificationOn;
  teamMembersToNotify?: ITeamMemberToNotify[];
  editInformation?: {
    name: string;
    date: string;
  };
  medicationStartDate?: string;
}

export interface IClientMedicationDeliveryResponse extends ICommonClientDetails {
  client: IDictionary;
  medication: IDictionary & { injectable: boolean; isArchived: boolean; lastInjection: boolean };
  medicationIds: number[];
  teamMember: IDictionary;
  frequency: IMedicationFrequency;
  date: string;
  notDelivered: boolean;
  nextDueDate: string;
  editInformation: {
    name: string;
    date: string;
  };
  nonDeliveryReason?: IDictionary;
  lastInjection?: boolean;
  hasAlerts?: boolean;
  statusChangeLog?: StatusChangeLog;
  notificationOn?: string;
  notificationTime?: string;
  teamMembersToNotify?: IDictionary[];
}

export interface IClientTriggerRequest {
  frequency?: string;
  hasAlerts: boolean;
  note: string;
  notificationOn?: string;
  teamMembersToNotify?: number[];
  triggerDate?: string;
  triggerId: number;
  type: IClientTriggerType;
}

export interface IClientTriggerResponse {
  id: number;
  trigger: IDictionary;
  note: string;
  type: IClientTriggerType;
  hasAlerts: boolean;
  frequency: ITriggerFrequency;
  triggerDate: string;
  notificationOn: INotificationOn;
  editInformation: {
    name: string;
    date: string;
  };
  teamMembersToNotify: ITeamMemberToNotify[];
}

export interface IClientSupportContanctRequest {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  relationshipId: number;
  address: IAddress;
}

interface ExtendedDictionary extends IDictionary {
  abbreviation: string;
}

export type ISupportContactAddress = IAddressExtended & {
  state: ExtendedDictionary;
};

export interface IClientSupportContanctResponse {
  id: number;
  firstName: string;
  lastName: string;
  relationship: IDictionary;
  isArchived: boolean;
  phone: string;
  email: string;
  address: ISupportContactAddress;
}

interface ITeamMemberToNotify {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  active: boolean;
  subRoles: IDictionary[];
}
