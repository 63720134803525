import { IClinic } from '~/services/api/clinic/types';
import { statesAbbreviation } from '~/ui/constants/states';

const getStateAbbreviation = (state: string) => {
  const abbreviation = statesAbbreviation[state];
  if (abbreviation === undefined) return state;
  return abbreviation;
};

export const getAddress = (clinic: IClinic): string => {
  const {
    address: {
      city,
      line1,
      line2,
      zipCode,
      state: { name },
    },
  } = clinic;

  let address = line1;

  if (line2) {
    address += `, ${line2},`;
  }

  address += ` ${city}, ${getStateAbbreviation(name)} ${zipCode}`;

  return address;
};

export default {};
