import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatAllergy } from '~/utils/formatAllergy';
import { IClientDetailsStore, IThunkUpdateAllergy } from '../types';

const onUpdateAllergy: IThunkUpdateAllergy = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const allergy = await api.clientDetails
      .updateAllergy(requestInfo, requestPayload)
      .then(r => formatAllergy(r.data));

    const allergies = localState.allergies.map(item => (item.id !== allergy.id ? item : allergy));

    setClientDetails({ allergies });
  },
);

export default onUpdateAllergy;
