import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getAccessToken } from '~/services/auth';
import { IThunkCheckAuthorization } from '../types';

// @ts-ignore
const onCheckAuthorization: IThunkCheckAuthorization = thunk(async ({ setUser, setAuthorized }) => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    setAuthorized({ authorized: false });
    return;
  }

  try {
    const user = await api.auth.getCurrentUser().then(response => response.data);
    setUser(user);
    setAuthorized({ authorized: true });
  } catch (e) {
    setAuthorized({ authorized: false });
  }
});

export default onCheckAuthorization;
