import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetLocalAdmin } from '../types';

// @ts-ignore
const onGetLocalAdmin: IThunkGetLocalAdmin = thunk(async ({ setLocalAdmin }, payload) => {
  const { clinicId, localAdminId } = payload;
  const { data } = await api.localAdmin.getLocalAdmin(clinicId, localAdminId);

  setLocalAdmin(data);
});

export default onGetLocalAdmin;
