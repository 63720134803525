import { IDictionary } from '../dictionaries/types';

export interface IVisitsRequestInfo {
  clinicId: string;
  teamId?: string;
  clientId?: string;
  visitId?: string;
  includeDeleted?: boolean;
}

export interface IVisitRequestParams {
  sorting?: string;
  filtering?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
  personFiltering?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface IVisitParams {
  dateFrom: Date;
  dateTo: Date;
  filterBy: number;
  sortBy: number;
  pageNumber: number;
  pageSize: number;
}

export interface IVisitRequestPayload {
  clientId: number;
  clientNoShow: boolean;
  date: string;
  durationSeconds: number;
  isClientVisited: boolean;
  noShowReason: number;
  note?: string;
  startTime: string;
  type: string;
  visitedPeople: number[];
  visitors: number[];
  address?: {
    city: string;
    line1: string;
    zipCode: string;
    stateId: number;
  };
}

interface Visitor {
  id: number;
  name: string;
  photo: string;
  photoThumbnail: string;
  subRole: string;
}

export interface IVisit {
  client: IDictionary;
  clientNoShow: boolean;
  date: string;
  durationSeconds: 240;
  editInformation: { name: string; date: string };
  id: number;
  isArchived: boolean;
  isClientVisited: boolean;
  noShowReason: IDictionary;
  note: string;
  startTime: string;
  type: string;
  visitedBy: Visitor[];
  visitedPeople: [{ id: 0; name: string; relationship: string }];
  address?: {
    city?: string;
    zipCode?: string;
    line1?: string;
    state?: IDictionary;
  };
}

export interface IVisitForm {
  date: string;
  type: number;
  startTime: string;
  visitedPeople: number[];
  duration: string;
  visitors: number[];
  note: string;
  city?: string;
  stateId?: number;
  zipCode?: string;
  line1?: string;
  clientNoShow: boolean;
  noShowReason: number;
}

export enum FilterType {
  PERSON = 'person',
  TYPE = 'type',
  DATE = 'date',
}

export default {};
