import { red } from '~/ui/constants/colors';
import { calculateOptionsHeight } from '~/utils/calculateOptionsHeight';
import { IOption } from '~/types';

export const customStylesAdditionally = (
  options: IOption[],
  maxMenuHeight: number,
  error: any,
  minHeight: string,
  isRelativeWindow: boolean,
  color: string,
) => ({
  option: (provided: any, { data, isFocused }: any) => {
    const dataColor = data.color || red;
    const backgroundColor = data.backgroundColor || '#ebebeb';

    return {
      ...provided,
      wordBreak: 'break-word',
      fontWeight: 300,
      backgroundColor: isFocused ? backgroundColor : 'transparent',
      color: isFocused ? dataColor : 'black',
    };
  },
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    borderWidth: isFocused ? '2px' : '1px',
    borderStyle: 'solid',
    borderColor: isFocused || !!error ? '#d83713!important' : '#c4c4c4',
    borderRadius: '10px',
    minHeight: minHeight || '40px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    ':hover': {
      borderColor: '#1e1f21',
    },
  }),
  singleValue: (provided: any, { data }: any) => {
    const dataColor = data.color;
    return {
      ...provided,
      color: dataColor || color,
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    fontWeight: 300,
  }),
  menu: (provided: any) => ({
    ...provided,
    position: isRelativeWindow ? 'relative' : 'absolute',
    height: calculateOptionsHeight(options, maxMenuHeight),
    marginTop: '8px',
    marginBottom: '8px',
    overflow: 'hidden',
    transition: 'all 0.3s ease-in-out',
    zIndex: 2,
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: calculateOptionsHeight(options, maxMenuHeight),
  }),
});

export default {};
