import { action } from 'easy-peasy';

import { IActionSetVisits } from '../types';

const setVisits: IActionSetVisits = action((state, payload) => {
  const {
    visits: { items, total },
    monitoredBy,
  } = payload;
  state.list = items;
  state.pagination = { ...state.pagination, total };
  state.monitoredBy = monitoredBy;
});

export default setVisits;
