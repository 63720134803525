import { ISendbirdData } from '../api/auth/types';

interface ISetTokens {
  accessToken: string;
  refreshToken: string;
}

const setItem = (key: string, item: unknown) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
};

const getItem = (key: string) => {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const item = localStorage.getItem(key);

  return item && JSON.parse(item);
};

export const getAccessToken = () => localStorage.getItem('accessToken');
export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const setTokens = ({ accessToken, refreshToken }: ISetTokens) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const deleteTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const setSendbirdData = (data: ISendbirdData): void => setItem('sendbirdData', data);
export const getSendbirdData = (): ISendbirdData => getItem('sendbirdData');
export const deleteSendbirdData = (): void => {
  localStorage.removeItem('sendbirdData');
};
