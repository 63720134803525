import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeactivateTeamMember } from '../types';

const onDeactivateTeamMember: IThunkDeactivateTeamMember = thunk(
  // @ts-ignore
  async ({ deactivateTeamMember }, payload) => {
    const { clinicId, actTeamId, teamMemberId } = payload;

    await api.actTeamMember.deactivateTeamMember(clinicId, actTeamId, teamMemberId);

    deactivateTeamMember(teamMemberId);
  },
);

export default onDeactivateTeamMember;
