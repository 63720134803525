import { ReactElement } from 'react';
import classNames from 'classnames';
import { IActTeam } from '~/services/api/actTeam/types';
import styles from './Profile.module.scss';

interface IProps {
  actTeam: IActTeam;
}

const Profile = ({ actTeam }: IProps): ReactElement => (
  <div className={styles.container}>
    <div className={classNames(styles.item, styles.bold)}>ACT Team Details</div>
    <div className={styles.item}>
      <span className={styles.itemHeader}>Clinic</span>
      <span className={styles.itemDetails}>{actTeam?.clinic?.name}</span>
    </div>
    <div className={styles.item}>
      <span className={styles.itemHeader}>Team Name</span>
      <span className={styles.itemDetails}>{actTeam?.name}</span>
    </div>
    <div className={styles.item}>
      <span className={styles.itemHeader}>Type of Team</span>
      <span className={styles.itemDetails}>{actTeam?.type.name}</span>
    </div>
    <div className={styles.item}>
      <span className={styles.itemHeader}>ACT Team Census</span>
      <span className={styles.itemDetails}>{actTeam?.clientCensus}</span>
    </div>
  </div>
);

export default Profile;
