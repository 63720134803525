import { action } from 'easy-peasy';
import { IActionSetTeamMembers } from '../types';

const setActTeamMembers: IActionSetTeamMembers = action((state, payload) => {
  const { items, pagination } = payload;
  state.list = items;

  if (pagination) {
    state.pagination.pageNumber = pagination.pageNumber;
    state.pagination.pageSize = pagination.pageSize;
    state.pagination.total = pagination.total;
  }
});

export default setActTeamMembers;
