import { useEffect, useState, ReactElement } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { concat } from 'lodash';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import Form from '../Form';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { isFile } from '~/utils/file';
import { extractErrorMessage } from '~/utils/error';
import { CLINIC_MANAGEMENT } from '~/ui/constants/paths';
import { IOption } from '~/types';
import { IInitialValues } from '../Form/types';

const itemForBreadcrumbs = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

type IParams = {
  id: string;
};

const EditClinicManagement = (): ReactElement => {
  const { id } = useParams<IParams>();
  const { location, goBack } = useHistory();

  const [loading, setLoading] = useState(false);
  const [isClinicUpdating, setIsClinicUpdating] = useState(false);

  const current = useStoreState(state => state.clinic.current);
  const { onGetClinic, setClinic } = useStoreActions(actions => actions.clinic);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      setClinic(null);
      setLoading(false);
      await onGetClinic(id);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const onSubmit = async ({ emailDomains, ...values }: IInitialValues) => {
    setIsClinicUpdating(true);

    try {
      const domains = emailDomains.map(item =>
        typeof item === 'string' ? item : String((item as IOption).value),
      );
      const payload = { ...values, emailDomains: domains };

      if (isFile(payload.logo)) {
        const formData = new FormData();
        formData.append('file', payload.logo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        payload.logo = fileName;
      }

      await api.clinic.updateClinic(Number(id), payload);
      showNotify('Clinic successfully updated');
      goBack();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsClinicUpdating(false);
    }
  };

  if (loading || !current) return <Loader />;

  const itemsForBreadcrumbs = concat(itemForBreadcrumbs, {
    title: 'Edit Clinic',
    to: location.pathname,
  });

  const leavePageDescription = `Are you sure you want to cancel update “${current.name}” clinic? All the changes will be discarded.`;

  return (
    <div>
      <h2>Edit Clinic “{current?.name}” </h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        defaultValues={{
          ...current,
          address: { ...current.address, stateId: current.address.state.id },
          ehrId: current.ehr.id,
        }}
        isClinicAdding={isClinicUpdating}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default EditClinicManagement;
