import { IOption } from '~/types';

const CELL_HEIGHT = 37;

export const calculateOptionsHeight = (
  options: IOption[],
  maxMenuHeight: number,
  isCreatable = false,
): number => {
  if (isCreatable) return CELL_HEIGHT;

  const menuHeight = (options.length || 1) * CELL_HEIGHT;

  return menuHeight > maxMenuHeight ? maxMenuHeight : menuHeight;
};

export default {};
