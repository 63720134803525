import { IActTeamMemberAvailableList } from '~/services/api/actTeamMember/types';
import {
  IRelatedClientsForAllocation,
  IClientAllocationUser,
} from '~/services/api/clientAllocation/types';

export interface ITeamMemberMapped {
  id: number;
  fullName: string;
  photo: string | null;
}

export const formValuesMapper = (obj: any) =>
  Object.keys(obj).reduce((acc, val) => [...acc, ...(obj[val] || [])], []);

export const prepareUsers = (vals: any) => {
  const formKeys = Object.keys(vals);

  const users = formKeys.map(key => {
    const memberId = key.split('_')[1];
    return {
      id: memberId,
      clientIds: vals[key],
    };
  });

  const filteredUsers = users.filter(({ clientIds }) => clientIds && clientIds.length);

  return filteredUsers;
};

export const prepareDefaultValues = (users: IClientAllocationUser[]) => {
  const usr = users.reduce(
    (acc, currentValue) => ({
      ...acc,
      [`clients_${currentValue.id}`]: currentValue.clients.map(item => item.id),
    }),
    {},
  );
  return usr;
};

export const teamMemberMapper = (teamMember: IActTeamMemberAvailableList): ITeamMemberMapped => ({
  id: teamMember.id,
  fullName: `${teamMember.firstName} ${teamMember.lastName}`,
  photo: teamMember.photo,
});

export const clientMapper = (client: IRelatedClientsForAllocation) => ({
  value: client.id,
  label: `${client.firstName} ${client.lastName}`,
});

export default {};
