import { ReactElement, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Status from '~/ui/components/common/Status';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getButtonText } from '~/utils/getButtonText';

import { CLINIC_EDIT_LOCAL_ADMIN, CLINIC_VIEW_LOCAL_ADMIN } from '~/ui/constants/paths';
import { red } from '~/ui/constants/colors';
import { multipleStatusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import { IParams as IValsParams, Sorting } from '~/services/api/types';

import editGreyIcon from '~/ui/assets/images/editGrey.svg';
import editDisabledIcon from '~/ui/assets/images/editDisabled.svg';
import styles from './Table.module.scss';

type IParams = {
  id: string;
};

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

interface IProps {
  setLocalAdmin: (localAdmin: any) => void;
}

const Table = ({ setLocalAdmin }: IProps): ReactElement => {
  const { id } = useParams<IParams>();
  const { push } = useHistory();
  const formMethods = useForm({ defaultValues });
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const watchValues = watch();

  const { list: localAdmins, pagination } = useStoreState(state => state.localAdmin);
  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetLocalAdmins = useStoreActions(actions => actions.localAdmin.onGetLocalAdmins);

  const onMount = async () => {
    try {
      await onGetLocalAdmins({ clinicId: id, params: { pageNumber: 1, pageSize: 5 } });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onSubmit = (vals: IValsParams) => {
    const { pageSize, pageNumber } = pagination;

    onGetLocalAdmins({ clinicId: id, params: { pageSize, pageNumber, ...vals } });
  };
  useEffect(() => {
    onMount();
  }, []);

  const handlePagination = (pageNumber: number, pageSize: number) => {
    onGetLocalAdmins({
      clinicId: id,
      params: { pageNumber, pageSize, ...watchValues },
    });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search Local Admin"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={multipleStatusesOptions}
                  color={red}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {localAdmins.map(localAdmin => (
            <TableRow key={localAdmin.id}>
              <TableCell
                className={styles.link}
                onClick={() => {
                  push(
                    CLINIC_VIEW_LOCAL_ADMIN.replace(':id', id).replace(
                      ':localAdminId',
                      String(localAdmin.id),
                    ),
                  );
                }}
              >
                {localAdmin.firstName}
              </TableCell>
              <TableCell>{localAdmin.lastName}</TableCell>
              <TableCell>{localAdmin.email}</TableCell>
              <TableCell>
                <Status status={localAdmin.status} />
              </TableCell>
              <TableCell>
                {['Deactivated', 'Locked'].includes(localAdmin.status) ? (
                  <IconButton disabled>
                    <img src={editDisabledIcon} alt="disabled" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      push(
                        CLINIC_EDIT_LOCAL_ADMIN.replace(':id', id).replace(
                          ':localAdminId',
                          String(localAdmin.id),
                        ),
                      )
                    }
                  >
                    <img src={editGreyIcon} alt="edit" />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                <button
                  type="button"
                  className={styles.actionButton}
                  onClick={() => {
                    setLocalAdmin(localAdmin);
                  }}
                >
                  {getButtonText(localAdmin.status)}
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          handlePagination(pageNumber + 1, pagination.pageSize);
        }}
        onRowsPerPageChange={e => {
          handlePagination(1, Number(e.target.value));
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
