import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetSuperAdmins } from '../types';

// @ts-ignore
const onGetSuperAdmins: IThunkGetSuperAdmins = thunk(async ({ setSuperAdmins }, payload) => {
  const { items, total } = await api.superAdmin.getSuperAdmins(payload).then(r => r.data);
  const pagination = { ...payload, total };

  setSuperAdmins({ items, pagination });
});

export default onGetSuperAdmins;
