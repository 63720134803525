import { IVisitsStore } from './types';

const initStore: IVisitsStore = {
  current: null,
  list: [],
  monitoredBy: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 5,
  },
};

export default initStore;
