import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { addMinutes, isAfter } from 'date-fns';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { injectableMedicationDeliveryValidationSchema } from './validates';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { formatActTeamMemberOptions } from '~/utils/formatActTeamMemberOptions';
import { extractErrorMessage, IDefaultError } from '~/utils/error';
import { buildDate } from '~/utils/buildDate';
import { getCombinedDateTime } from '~/utils/date';
import getFromDate from '~/ui/pages/MedicationLogistics/helpers/getFromDate';
import getToDate from '~/ui/pages/MedicationLogistics/helpers/getToDate';

import Loader from '~/ui/components/common/Loader';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import Input from '~/ui/components/inputs/Input';
import DatePicker from '~/ui/components/inputs/DatePicker';
import TimePicker from '~/ui/components/inputs/TimePicker';
import Button from '~/ui/components/common/Button';
import Checkbox from '~/ui/components/inputs/Checkbox';
import MedicationChangeInfo from '~/ui/pages/MedicationLogistics/components/MedicationChangeInfo';
import MedicationDeliveryInfo from '~/ui/pages/MedicationLogistics/components/MedicationDeliveryInfo';

import { notificationOn } from '~/ui/constants/notificationOn';
import { IOption } from '~/types';
import { IDetails } from '../types';
import { IMedicationDeliveryInitialValues, IRequestInfo } from './types';
import { IMedication } from '~/services/api/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';

import styles from './Styles.module.scss';

interface IProps {
  defaultValues: IMedicationDeliveryInitialValues;
  requestInfo: IRequestInfo;
  details: IDetails | null;
  setModalTitle: (v: string | null) => void;
  setDetails: (v: IDetails | null) => void;
}
const AddInjectableMedicationDelivery = ({
  defaultValues,
  details,
  requestInfo,
  setDetails,
  setModalTitle,
}: IProps): ReactElement => {
  const [members, setMembers] = useState<IOption[]>([]);
  const [reasons, setReasons] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    unregister,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: injectableMedicationDeliveryValidationSchema,
  });

  const notDelivered = watch('notDelivered');
  const lastInjection = watch('lastInjection');
  const hasAlerts = watch('hasAlerts');
  const deliveryDate = watch('date');

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const {
    onGetMedicationDeliveries,
    onUpdateMedicationDelivery,
    onAddMedicationDelivery,
    setMedicationDelivery,
  } = useStoreActions(actions => actions.medicationLogistics);
  const { onGetClient } = useStoreActions(actions => actions.client);

  const { current } = useStoreState(state => state.client);
  const user = useStoreState(state => state.user.current);
  const currentMedicationDelivery = useStoreState(state => state.medicationLogistics.current);

  const medications = useMemo(
    () =>
      current.medications.reduce((acc: IOption[], obj: IMedication) => {
        if (!obj.isArchived && obj.injectable) {
          acc.push({
            label: `${obj.medication.name}`,
            value: obj.id,
            description: `${obj.dose}`,
          });
        }
        return acc;
      }, []),
    [current.medications],
  );

  const refetchDeliveries = (date: Date) => {
    onGetMedicationDeliveries({
      clinicId: String(requestInfo.clinicId),
      clientId: String(current.id),
      teamId: String(requestInfo.teamId),
      from: getFromDate(date) as string,
      to: getToDate(date) as string,
    });
    onGetClient({
      clinicId: String(user.clinic.id),
      clientId: String(current.id),
      teamId: String(requestInfo.teamId),
    });
  };

  const onSubmit = async (values: IMedicationDeliveryInitialValues, evt: any) => {
    const {
      time,
      date: medicationDeliveryDate,
      archiveMedication,
      statusChangeLog,
      notificationTime,
      nextDueDate,
      teamMembersToNotify,
      lastInjection: isLatestInjection,
      hasAlerts: medicationHasAlerts,
      ...rest
    } = values;

    const date = buildDate(time, medicationDeliveryDate);
    const notificationDate = new Date(notificationTime);
    const timestamp = notificationTime
      ? `${notificationDate.getHours()}:${notificationDate.getMinutes()}:00`
      : null;
    const timeZoneOffset = notificationTime ? notificationDate.getTimezoneOffset() : null;

    const nextDueDateTemp = nextDueDate
      ? addMinutes(getCombinedDateTime(nextDueDate, date), 1)
      : null;

    const requestPayload = {
      ...rest,
      date,
      timeZoneOffset,
      archiveMedication: !!archiveMedication,
      clientId: current.id,
      notificationTime: timestamp,
      teamMembersToNotify: medicationHasAlerts ? teamMembersToNotify : null,
      hasAlerts: medicationHasAlerts ? true : null,
      lastInjection: isLatestInjection ? true : null,
    };

    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const dueDate = (): Date => {
          if (nextDueDate !== defaultValues.nextDueDate) {
            return isAfter(nextDueDateTemp, new Date())
              ? nextDueDateTemp
              : addMinutes(getCombinedDateTime(nextDueDate), 1);
          }
          return new Date(nextDueDate);
        };
        const payload = {
          requestInfo,
          requestPayload: {
            ...requestPayload,
            medicationDeliveryId: String(details.id),
            nextDueDate: nextDueDateTemp ? dueDate()?.toISOString() : null,
          },
        };
        await onUpdateMedicationDelivery(payload);
        setDetails(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: {
            ...requestPayload,
            nextDueDate: nextDueDate ? nextDueDateTemp.toISOString() : null,
          },
        };
        await onAddMedicationDelivery(payload);
        reset({
          notDelivered: false,
          id: null,
          note: '',
          medicationIds: [],
          teamMemberId: null,
        });
      }
      refetchDeliveries(new Date(date));
      setModalTitle(name);
      setMedicationDelivery(null);

      const type = details?.id ? 'updated' : 'added';

      showNotify(`Medication delivery successfully ${type}`);
    } catch (e) {
      if ((e as IDefaultError).data?.code === 'invalid_next_due_date') {
        setValue('nextDueDate', defaultValues.nextDueDate);
      }
      if ((e as IDefaultError).data?.code === 'invalid_notification_time') {
        setValue('notificationOn', defaultValues.notificationOn);
        setValue('notificationTime', defaultValues.notificationTime);
      }
      showError(extractErrorMessage(e));
    }
  };

  const onMount = async () => {
    setValue('medicationId', details?.medicationId);
    try {
      setLoading(true);

      const [teamMembers, nonDeliveryReasons] = await Promise.all([
        api.actTeamMember
          .getActTeamMemberList(requestInfo.clinicId, requestInfo.teamId)
          .then(r => formatActTeamMemberOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.MedicationNonDeliveryReason)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setMembers(teamMembers);
      setReasons(nonDeliveryReasons);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (!notDelivered) {
      unregister(['nonDeliveryReasonId']);
    }
  }, [notDelivered, lastInjection, hasAlerts, unregister]);

  useEffect(() => {
    if (lastInjection) {
      unregister(['hasAlerts', 'nextDueDate']);
    }
  }, [lastInjection, unregister]);

  useEffect(() => {
    if (!hasAlerts) {
      unregister(['notificationOn', 'notificationTime', 'teamMembersToNotify']);
    }
  }, [hasAlerts, unregister]);

  const handleSubmitForm = useCallback(
    debounce(e => handleSubmit(onSubmit)(e), 500),
    [],
  );

  if (loading) return <Loader />;
  const showSecondSubmit = !details?.id;
  const fullName = `${current.firstName} ${current.lastName}`;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmitForm(e);
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MedicationDeliveryInfo
            name={fullName}
            medication={currentMedicationDelivery}
            fullInfo={!!details?.id}
          />
        </Grid>

        {!currentMedicationDelivery && (
          <Grid item sm={12}>
            <SelectComponent
              isRelativeWindow
              name="medicationId"
              control={control}
              errors={errors}
              options={medications}
              label="Select Medication"
              isDisabled={!!defaultValues?.id}
              customOption
              maxMenuHeight={150}
            />
          </Grid>
        )}

        <Grid item sm={12}>
          <Checkbox
            size="small"
            name="notDelivered"
            control={control}
            errors={errors}
            label="Medication not delivered"
          />
        </Grid>
        {defaultValues.statusChangeLog && (
          <Grid item sm={12}>
            <MedicationChangeInfo
              delivered={defaultValues.statusChangeLog.delivered}
              when={defaultValues.statusChangeLog.date}
              changedBy={defaultValues.statusChangeLog.user.name}
            />
          </Grid>
        )}

        {notDelivered && (
          <Grid item sm={12}>
            <SelectComponent
              options={reasons}
              name="nonDeliveryReasonId"
              control={control}
              errors={errors}
              label="Select Reason"
            />
          </Grid>
        )}
        <Grid item sm={12}>
          <SelectComponent
            options={members}
            name="teamMemberId"
            control={control}
            errors={errors}
            label="Select Responsible Team Member"
          />
        </Grid>
        <Grid item sm={12}>
          <Input name="note" register={register} label="Note" multiline errors={errors} />
        </Grid>

        <Grid item sm={6}>
          <DatePicker
            name="date"
            control={control}
            errors={errors}
            label="Delivery Date"
            minDate={deliveryDate}
            maxDate={new Date().toString()}
          />
        </Grid>
        <Grid item sm={6}>
          <TimePicker name="time" control={control} errors={errors} label="Delivery Time" />
        </Grid>

        <Grid item sm={6}>
          <Checkbox
            size="small"
            name="lastInjection"
            control={control}
            errors={errors}
            label="Last injection"
          />
        </Grid>
        {lastInjection ? (
          <Grid item sm={6}>
            <Checkbox
              size="small"
              name="archiveMedication"
              control={control}
              errors={errors}
              label="Archive Medication"
            />
          </Grid>
        ) : (
          <Grid item sm={6}>
            <DatePicker
              name="nextDueDate"
              control={control}
              errors={errors}
              label="Next Due Date"
              minDate={new Date().toString()}
            />
          </Grid>
        )}

        {!lastInjection && (
          <Grid item sm={12}>
            <Checkbox
              size="small"
              name="hasAlerts"
              control={control}
              errors={errors}
              label="Notify about injection"
            />
          </Grid>
        )}

        {hasAlerts && (
          <>
            <Grid item sm={6}>
              <SelectComponent
                options={notificationOn}
                name="notificationOn"
                control={control}
                errors={errors}
                label="Notification on"
              />
            </Grid>
            <Grid item sm={6}>
              <TimePicker
                name="notificationTime"
                control={control}
                errors={errors}
                label="Notification Time"
              />
            </Grid>
            <Grid item sm={12}>
              <SelectComponent
                isMulti
                options={members}
                name="teamMembersToNotify"
                control={control}
                errors={errors}
                label="ACT team member(s) to be notified"
                maxMenuHeight={150}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div className={classNames(styles.buttonsWrapper, styles.marginVertical)}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setMedicationDelivery(null);
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={styles.margin}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddInjectableMedicationDelivery;
