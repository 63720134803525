import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, boolean } from 'yup';

import { validateLengthField } from '~/ui/configs/form/validation';
import { requiredField, fieldMax } from '~/ui/constants/errorMessages';

export interface IFormValues {
  name: string;
  includeWeekends: boolean;
  to: string;
  from: string;
}

const validationSchema = object().shape({
  name: string().required(requiredField).test('length', fieldMax, validateLengthField),
  includeWeekends: boolean(),
  from: string().required(requiredField).typeError(requiredField),
  to: string().required(requiredField).typeError(requiredField),
});

export default yupResolver(validationSchema);
