import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkActivateLocalAdmin } from '../types';

const onActivateLocalAdmin: IThunkActivateLocalAdmin = thunk(
  // @ts-ignore
  async ({ activateLocalAdmin }, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.activateLocalAdmin(clinicId, localAdminId);

    activateLocalAdmin(localAdminId);
  },
);

export default onActivateLocalAdmin;
