import { action } from 'easy-peasy';
import { IActionSetClientDetails } from '../types';

const setClientDetails: IActionSetClientDetails = action((state, payload) => {
  const { deEscalationTechniques, diagnoses, medications, triggers, supportContacts, allergies } =
    payload;

  if (deEscalationTechniques) {
    state.deEscalationTechniques = deEscalationTechniques;
  }

  if (diagnoses) {
    state.diagnoses = diagnoses;
  }

  if (allergies) {
    state.allergies = allergies;
  }

  if (medications) {
    state.medications = medications;
  }

  if (triggers) {
    state.triggers = triggers;
  }
  if (supportContacts) {
    state.supportContacts = supportContacts;
  }
});

export default setClientDetails;
