import classNames from 'classnames';
import { ReactElement } from 'react';

import styles from './RefuseLabel.module.scss';

interface IProps {
  refuseReason: string;
  archived: boolean;
}

const RefuseLabel = ({ refuseReason, archived }: IProps): ReactElement => (
  <span className={classNames(styles.refuseLabel, { [styles.archived]: archived })}>
    {refuseReason}
  </span>
);

export default RefuseLabel;
