import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { setSendbirdData } from '~/services/auth';

import { IThunkGetSendbirdData } from '../types';

const onGetSendbirdData: IThunkGetSendbirdData = thunk(async () => {
  const sendbirdData = await api.auth.fetchSendbirdToken().then(response => response.data);
  setSendbirdData(sendbirdData);
});

export default onGetSendbirdData;
