import onGetDeEscalationTechniques from './onGetDeEscalationTechniques';
import onGetDiagnoses from './onGetDiagnoses';
import onGetMedications from './onGetMedications';
import onGetTriggers from './onGetTriggers';
import onCreateTrigger from './onCreateTrigger';
import onGetTrigger from './onGetTrigger';
import onUpdateTrigger from './onUpdateTrigger';
import onArchiveTrigger from './onArchiveTrigger';
import onRestoreTrigger from './onRestoreTrigger';
import onCreateMedication from './onCreateMedication';
import onUpdateMedication from './onUpdateMedication';
import onArchiveMedication from './onArchiveMedication';
import onRestoreMedication from './onRestoreMedication';
import onCreateDeEscalation from './onCreateDeEscalation';
import onUpdateDeEscalation from './onUpdateDeEscalation';
import onGetDeEscalation from './onGetDeEscalation';
import onArchiveDeEscalation from './onArchiveDeEscalation';
import onRestoreDeEscalation from './onRestoreDeEscalation';
import onCreateAllergy from './onCreateAllergy';
import onUpdateAllergy from './onUpdateAllergy';
import onGetAllergy from './onGetAllergy';
import onGetAllergies from './onGetAllergies';
import onArchiveAllergy from './onArchiveAllergy';
import onRestoreAllergy from './onRestoreAllergy';
import onCreateSecondaryDiagnosis from './onCreateSecondaryDiagnosis';
import onArchiveSecondaryDiagnosis from './onArchiveSecondaryDiagnosis';
import onRestoreSecondaryDiagnosis from './onRestoreSecondaryDiagnosis';
import onGetSecondaryDiagnosis from './onGetSecondaryDiagnosis';
import onUpdateSecondaryDiagnosis from './onUpdateSecondaryDiagnosis';
import onGetSupportContacts from './onGetSupportContacts';
import onArchiveSupportContact from './onArchiveSupportContact';
import onRestoreSupportContact from './onRestoreSupportContact';
import onGetSupportContact from './onGetSupportContact';
import onCreateSupportContact from './onCreateSupportContact';
import onUpdateSupportContact from './onUpdateSupportContact';
import onGetMedication from './onGetMedication';

export default {
  onGetDeEscalationTechniques,
  onGetDiagnoses,
  onGetMedications,
  onGetTriggers,
  onGetTrigger,
  onCreateTrigger,
  onUpdateTrigger,
  onArchiveTrigger,
  onRestoreTrigger,
  onCreateMedication,
  onUpdateMedication,
  onArchiveMedication,
  onRestoreMedication,
  onGetMedication,
  onCreateDeEscalation,
  onUpdateDeEscalation,
  onGetDeEscalation,
  onArchiveDeEscalation,
  onRestoreDeEscalation,
  onCreateSecondaryDiagnosis,
  onArchiveSecondaryDiagnosis,
  onRestoreSecondaryDiagnosis,
  onGetSecondaryDiagnosis,
  onUpdateSecondaryDiagnosis,
  onGetSupportContacts,
  onArchiveSupportContact,
  onRestoreSupportContact,
  onGetSupportContact,
  onCreateSupportContact,
  onUpdateSupportContact,
  onCreateAllergy,
  onUpdateAllergy,
  onGetAllergy,
  onGetAllergies,
  onArchiveAllergy,
  onRestoreAllergy,
};
