import { thunk } from 'easy-peasy';
import { IThunkGetClientAllocation } from '../types';
import api from '~/services/api';

const onGetClientAllocation: IThunkGetClientAllocation = thunk(
  // @ts-ignore
  async ({ setClientAllocation }, payload) => {
    const clientAllocation = await api.clientAllocation
      .getClientAllocation(payload)
      .then(r => r.data);

    setClientAllocation(clientAllocation);
  },
);

export default onGetClientAllocation;
