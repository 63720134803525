import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import api from '~/services/api';
import initialValues from './initialValues';

import { isFile } from '~/utils/file';
import { CLINIC_MANAGEMENT, ADD_CLINIC } from '~/ui/constants/paths';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { IOption } from '~/types';
import { IInitialValues } from '../Form/types';

const itemsForBreadcrumbs = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
  {
    title: 'Add Clinic',
    to: ADD_CLINIC,
  },
];

const AddClinicManagement = (): ReactElement => {
  const { push } = useHistory();

  const [isClinicAdding, setIsClinicAdding] = useState(false);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onSubmit = async ({ emailDomains, ...values }: IInitialValues) => {
    setIsClinicAdding(true);

    try {
      const domains = emailDomains.map(item => String((item as IOption).value));
      const payload = { ...values, emailDomains: domains };

      if (isFile(payload.logo)) {
        const formData = new FormData();
        formData.append('file', payload.logo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        payload.logo = fileName;
      }

      await api.clinic.addClinic(payload);
      showNotify('Clinic added successfully!');
      push(CLINIC_MANAGEMENT);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsClinicAdding(false);
    }
  };

  const leavePageDescription = 'Are you sure you want to discard?';

  return (
    <div>
      <h2>Add Clinic</h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        defaultValues={initialValues}
        isClinicAdding={isClinicAdding}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AddClinicManagement;
