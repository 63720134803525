import { yupResolver } from '@hookform/resolvers/yup';
import { array, boolean, date, number, object, string } from 'yup';
import { validateDuration } from '~/ui/configs/form/validation';
import { requiredField } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  date: date().required(requiredField),
  type: number().required(requiredField),
  visitedPeople: array().when('clientNoShow', {
    is: true,
    then: array().nullable(),
    otherwise: array().of(number()).required(requiredField).min(1, requiredField),
  }),
  note: string(),
  visitors: array().of(number()).required(requiredField).min(1, requiredField),
  startTime: date().required(requiredField).typeError(requiredField),
  duration: string().when('clientNoShow', {
    is: true,
    then: string().nullable(),
    otherwise: string()
      .required(requiredField)
      .typeError(requiredField)
      .test('maxDuration', 'Maximum 8 hours', val => validateDuration(val, 'max'))
      .test('minDuration', 'Minimum 1 minute', val => validateDuration(val, 'min')),
  }),
  clientNoShow: boolean().nullable(),
  noShowReason: string().when('clientNoShow', {
    is: true,
    then: string().required(requiredField).min(1, requiredField).typeError(requiredField),
    otherwise: string().nullable(),
  }),
  city: string().when('type', {
    is: 0,
    then: string().required(requiredField),
    otherwise: string().nullable(),
  }),
  stateId: number().when('type', {
    is: 0,
    then: number().required(requiredField),
    otherwise: number().nullable(),
  }),
  zipCode: string().when('type', {
    is: 0,
    then: string().min(5).max(5).required(requiredField),
    otherwise: string().nullable(),
  }),
  line1: string().when('type', {
    is: 0,
    then: string().required(requiredField),
    otherwise: string().nullable(),
  }),
});

export default yupResolver(validationSchema);
