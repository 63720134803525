import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import Form from '../reusable/Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';

import { ADD_SUPER_ADMIN, SUPER_ADMIN } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';

import { IFormValues } from '../reusable/Form/types';

const defaultValues: IFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  emailDomain: 0,
};

const AddSuperAdmin = (): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  const { push } = useHistory();

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const itemsForBreadcrumbs = [
    { to: SUPER_ADMIN, title: 'Super Admin' },
    { to: ADD_SUPER_ADMIN, title: 'Add Super Admin' },
  ];

  const onSubmit = async (formValues: IFormValues) => {
    try {
      const { emailDomain, email, ...values } = formValues;
      setIsAdding(true);

      const payload = { ...values, email: `${email}${emailDomain}` };

      await api.superAdmin.addSuperAdmin(payload);

      showNotify('Super admin successfully added');
      push(SUPER_ADMIN);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  return (
    <div>
      <h2>Add Super Admin</h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        isProcessing={isAdding}
        leavePageDescription="Are you sure you want to cancel “Add New Super Admin”? All the changes will be discarded."
      />
    </div>
  );
};

export default AddSuperAdmin;
