import { useMemo, ReactElement, SetStateAction, Dispatch } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, Grid, InputLabel } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { formatEnumToOptions } from '../../utils/formatEnumToOptions';
import SimpleSelectWithoutGrid from '../SimpleSelectWithoutGrid';

import { TypeOfVisit, VisitedPerson } from '../../constants/filterOptions';
import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';

interface IProps {
  sortBy: number;
  filterBy: number;
  dateFrom?: Date;
  dateTo?: Date;
  setFilterBy: Dispatch<SetStateAction<number>>;
  setDateFrom?: (date: Date) => void;
  setDateTo?: (date: Date) => void;
}

const VisitsTableFilters = ({
  sortBy,
  filterBy,
  dateFrom,
  dateTo,
  setFilterBy,
  setDateFrom,
  setDateTo,
}: IProps): ReactElement => {
  const label = 'Filter By';
  const filterElements = [
    {
      sortBy: 1,
      label: 'FilterByVisitedPerson',
      component: (
        <SimpleSelectWithoutGrid
          label={label}
          value={filterBy}
          setValue={setFilterBy}
          options={formatEnumToOptions(VisitedPerson)}
        />
      ),
    },
    {
      sortBy: 2,
      label: 'FilterByTypeOfVisit',
      component: (
        <SimpleSelectWithoutGrid
          label={label}
          value={filterBy}
          setValue={setFilterBy}
          options={formatEnumToOptions(TypeOfVisit)}
        />
      ),
    },
    {
      sortBy: 3,
      label: 'FilterByDateFrom',
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink>Date From</InputLabel>
            <DatePicker
              InputProps={{
                endAdornment: <CalendarIcon />,
              }}
              inputVariant="outlined"
              fullWidth
              size="small"
              value={dateFrom}
              onChange={setDateFrom}
              maxDate={dateTo}
            />
          </FormControl>
        </MuiPickersUtilsProvider>
      ),
    },
    {
      sortBy: 3,
      label: 'FilterByDateTo',
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel shrink>Date To</InputLabel>
            <DatePicker
              InputProps={{
                endAdornment: <CalendarIcon />,
              }}
              inputVariant="outlined"
              fullWidth
              size="small"
              value={dateTo}
              onChange={setDateTo}
              minDate={dateFrom}
            />
          </FormControl>
        </MuiPickersUtilsProvider>
      ),
    },
  ];

  const elements = useMemo(() => {
    switch (sortBy) {
      case 1:
        return filterElements
          .filter(item => item.sortBy === sortBy)
          .map(item => (
            <Grid item sm={4} key={item.label}>
              {item.component}
            </Grid>
          ));
      case 2:
        return filterElements
          .filter(item => item.sortBy === sortBy)
          .map(item => (
            <Grid item sm={3} key={item.label}>
              {item.component}
            </Grid>
          ));
      case 3:
        return filterElements
          .filter(item => item.sortBy === sortBy)
          .map(item => (
            <Grid item sm={3} key={item.label}>
              {item.component}
            </Grid>
          ));
      default:
        return <div />;
    }
  }, [sortBy, filterBy, dateFrom, dateTo]);

  return (
    <Grid container spacing={1}>
      {elements}
    </Grid>
  );
};

export default VisitsTableFilters;
