import { ReactElement } from 'react';

import VisitsHeader from './components/VisitsHeader';
import VisitsTable from './components/VisitsTable';

import styles from './Visits.module.scss';

const Visits = (): ReactElement => (
  <div className={styles.container}>
    <VisitsHeader />
    <VisitsTable />
  </div>
);

export default Visits;
