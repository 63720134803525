import { ReactElement, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { SendBirdProvider } from 'sendbird-uikit';

import { getSendbirdData } from '~/services/auth';
import { useStoreActions, useStoreState } from '~/store/hooks';
import GeneralLayout from '~/ui/layouts/GeneralLayout';

import { SIGN_IN } from '../constants/paths';

/**
 * Render private route
 * Only authenticated users can access to private route
 * Otherwise - redirect user to another allowed page route
 */

// TODO: temporary disable auth check, until method GET /User/current will be added on BE
const PrivateRoute = ({ component: Component, ...rest }: any): ReactElement => {
  const { authorized, authChecked } = useStoreState(state => state.user);
  const sendBirdUserId = useStoreState(state => state.user.current?.sendBirdUserId);

  const onGetSendbirdData = useStoreActions(actions => actions.user.onGetSendbirdData);

  const { accessToken, expiresAt } = getSendbirdData() || {};

  useEffect(() => {
    if (!expiresAt || expiresAt < new Date().getTime()) {
      onGetSendbirdData();
    }
  }, [expiresAt]);

  if (!authChecked) return null;

  return authorized ? (
    <Route
      {...rest}
      render={routeProps => (
        <GeneralLayout>
          <SendBirdProvider
            appId={
              '' /* process.env.REACT_APP_SENDBIRD_ID TODO revert back coordinate once it is been tested */
            }
            userId={sendBirdUserId}
            accessToken={accessToken}
          >
            <Component {...routeProps} />
          </SendBirdProvider>
        </GeneralLayout>
      )}
    />
  ) : (
    <Redirect to={SIGN_IN} />
  );
};

export default PrivateRoute;
