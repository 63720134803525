import { ReactElement, useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';

import { ICustomDictionaryItem, ICustomDictionaryType } from '~/services/api/dictionaries/types';
import styles from '../Dictionaries.module.scss';

interface IProps {
  hasCustomFields: boolean;
  dictionaryType: ICustomDictionaryType;
  dictionaryItem: ICustomDictionaryItem;
  handleDictionaryItemUpdateOrAdd: (item: ICustomDictionaryItem) => void;
  onClose: () => void;
  actionModalText: string;
}

const ModalForm = ({
  hasCustomFields,
  dictionaryType,
  dictionaryItem,
  handleDictionaryItemUpdateOrAdd,
  onClose,
  actionModalText,
}: IProps): ReactElement => {
  const [newDictionaryItem, setNewDictionaryItem] = useState<ICustomDictionaryItem>(
    dictionaryItem || ({} as ICustomDictionaryItem),
  );

  const modalDesc = `Please type a meaning${
    hasCustomFields && dictionaryItem?.abbreviation ? ' and an abbreviation' : ''
  } to define the “${dictionaryType.name}” dictionary`;

  return (
    <Modal open maxWidth="lg" onClose={onClose}>
      <>
        <p className={styles.description}>{modalDesc}</p>
        <FormControl variant="outlined" fullWidth>
          <InputLabel shrink>Dictionary Name</InputLabel>
          <OutlinedInput
            value={newDictionaryItem.value || ''}
            onChange={event => {
              setNewDictionaryItem({ ...newDictionaryItem, value: event.target.value });
            }}
          />
        </FormControl>
        {hasCustomFields &&
          dictionaryType.customFields.map(({ title, name }) => (
            <FormControl variant="outlined" fullWidth margin="normal" key={title}>
              <InputLabel shrink>Dictionary {title}</InputLabel>
              <OutlinedInput
                value={newDictionaryItem[name as unknown as keyof typeof newDictionaryItem] || ''}
                onChange={({ target }) => {
                  const temp = newDictionaryItem;
                  temp[name as unknown as keyof typeof newDictionaryItem] = target.value;
                  setNewDictionaryItem({ ...temp });
                }}
              />
            </FormControl>
          ))}
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonMargin}>
            <Button color="primary" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleDictionaryItemUpdateOrAdd(newDictionaryItem);
            }}
          >
            {actionModalText}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default ModalForm;
