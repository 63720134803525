import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import Button from '~/ui/components/common/Button';
import { ADD_CLINIC } from '~/ui/constants/paths';

import styles from './Header.module.scss';

const Header = () => {
  const history = useHistory();
  return (
    <div className={styles.header}>
      <h2>Clinic Management</h2>
      <div className={styles.buttonWrapper}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => history.push(ADD_CLINIC)}
        >
          Add Clinic
        </Button>
      </div>
    </div>
  );
};
export default Header;
