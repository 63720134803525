import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import {
  IClientTriggerRequest,
  IClientTriggerResponse,
  IClientDeEscalationTechnique,
  IClientDiagnosis,
  IClientMedication,
  IClientMedicationResponse,
  IClientMedicationRequest,
  IClientTrigger,
  IRequestParams,
  IClientDeEscalationRequest,
  IClientDeEscalationResponse,
  IClientSecondaryDiagnosisRequest,
  IClientSecondaryDiagnosisResponse,
  IClientSupportContanctRequest,
  IClientSupportContanctResponse,
  IClientAllergyRequest,
  IClientAllergyResponse,
  IClientAllergy,
} from './types';

/* CLIENT DE-ESCALATION TECHNIQUES */

export const createDeEscalationTechnique = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientDeEscalationRequest,
): IResponse<IClientDeEscalationResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques`,
    data,
  });

export const updateDeEscalationTechnique = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientDeEscalationRequest,
): IResponse<IClientDeEscalationResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques/${id}`,
    data,
  });

export const archiveDeEscalationTechnique = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques/${id}`,
  });

export const restoreDeEscalation = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques/${id}/recover`,
  });

export const getClientDeEscalationTechnique = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<IClientDeEscalationResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques/${id}`,
  });

export const getClientDeEscalationTechniqueList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientDeEscalationTechnique[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/deescalation-techniques/list`,
  });

/* CLIENT ALLERGIES */

export const createAllergy = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientAllergyRequest,
): IResponse<IClientAllergyResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy`,
    data,
  });

export const updateAllergy = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientAllergyRequest,
): IResponse<IClientAllergyResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy/${id}`,
    data,
  });

export const archiveAllergy = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy/${id}`,
  });

export const restoreAllergy = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy/${id}/recover`,
  });

export const getClientAllergy = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<IClientAllergyResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy/${id}`,
  });

export const getClientAllergyList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientAllergy[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/allergy/list`,
  });

/* CLIENT SECONDARY DIAGNOSIS */

export const createClientSecondaryDiagnosis = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientSecondaryDiagnosisRequest,
): IResponse<IClientSecondaryDiagnosisResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/secondary-diagnoses`,
    data,
  });

export const updateClientSecondaryDiagnosis = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientSecondaryDiagnosisRequest,
): IResponse<IClientSecondaryDiagnosisResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/secondary-diagnoses/${id}`,
    data,
  });

export const updateClientPrimaryDiagnosis = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: { id: number },
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/primary-diagnosis`,
    data,
  });

export const archiveClientSecondaryDiagnosis = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/secondary-diagnoses/${id}`,
  });

export const restoreSecondaryDiagnosis = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/secondary-diagnoses/${id}/recover`,
  });

export const getClientDiagnosisList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientDiagnosis[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/secondary-diagnoses/list`,
  });

/* CLIENT MEDICATIONS */

export const createClientMedication = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientMedicationRequest,
): IResponse<IClientMedicationResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications`,
    data,
  });

export const getClientMedication = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<IClientMedicationResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}`,
  });

// TODO: Wait to discover if we need this method
export const updateClientMedication = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientMedicationRequest,
): IResponse<IClientMedicationResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}`,
    data,
  });

export const archiveClientMedication = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}`,
  });

export const restoreMedication = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}/recover`,
  });

export const getClientMedicationList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientMedication[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/list`,
  });

/* CLIENT MEDICATION DELIVERY */

export const createClientMedicationDelivery = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientMedicationRequest,
): IResponse<IClientMedicationResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications`,
    data,
  });

export const updateClientMedicationDelivery = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientMedicationRequest,
): IResponse<IClientMedicationResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}`,
    data,
  });

export const archiveClientMedicationDelivery = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}`,
  });

export const restoreMedicationDelivery = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/medications/${id}/recover`,
  });

/* CLIENT TRIGGERS */

export const createClientTrigger = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientTriggerRequest,
): IResponse<IClientTriggerResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers`,
    data,
  });

export const updateClientTrigger = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientTriggerRequest,
): IResponse<IClientTriggerResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers/${id}`,
    data,
  });

export const archiveClientTrigger = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers/${id}`,
  });

export const restoreClientTrigger = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers/${id}/recover`,
  });

export const getClientTrigger = ({
  clinicId,
  teamId,
  clientId,
  id,
}: IRequestParams): IResponse<IClientTriggerResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers/${id}`,
  });

export const getClientTriggerList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientTrigger[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/triggers/list`,
  });

/* CLIENT COLLATERAL CONTACTS */

export const getClientSupportContactList = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientSupportContanctResponse[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/support-contacts/list`,
  });

export const archiveSupportContact = (
  clinicId: string,
  teamId: string,
  clientId: string,
  id: string,
): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/support-contacts/${id}`,
  });

export const restoreSupportContact = (
  clinicId: string,
  teamId: string,
  clientId: string,
  id: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/support-contacts/${id}/recover`,
  });

export const createSupportContact = (
  { clinicId, teamId, clientId }: IRequestParams,
  data: IClientSupportContanctRequest,
): IResponse<IClientSupportContanctResponse> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/support-contacts`,
    data,
  });

export const updateSupportContact = (
  { clinicId, teamId, clientId, id }: IRequestParams,
  data: IClientSupportContanctRequest,
): IResponse<IClientSupportContanctResponse> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/support-contacts/${id}`,
    data,
  });

export default {};
