import React, { ReactElement } from 'react';
import { components } from 'react-select';

import styles from './CustomOption.module.scss';

const CustomOption = ({ label, description, isSelected, ...rest }: any): ReactElement => (
  <components.Option {...rest} className={styles.customOption}>
    <span className={styles.label}>{label}</span>{' '}
    {rest.data.description && <span className={styles.description}>{rest.data.description}</span>}
  </components.Option>
);

export default CustomOption;
