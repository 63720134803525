import { format, getMinutes, addMinutes } from 'date-fns';

export const isoToFormat = (date: string, dateFormat = 'MM/dd/yyyy'): string =>
  format(new Date(date), dateFormat);

export const getLocalDateNoFormat = (date: string): Date => {
  const tempDate = new Date(date);
  return addMinutes(tempDate, tempDate.getTimezoneOffset());
};

export const isoToLocal = (date: string, dateFormat = 'MM/dd/yyyy'): string =>
  format(getLocalDateNoFormat(date), dateFormat);

export const buildTime = (triggerDate: string): Date =>
  new Date(2020, 11, 17, new Date(triggerDate).getUTCHours(), getMinutes(new Date(triggerDate)), 0);

export const destructHours = (hours: string): Date => {
  const date = new Date();
  const time = hours.split(':');
  date.setHours(Number(time[0]), Number(time[1]), 0);

  return date;
};

export const getCombinedDateTime = (date: string, time?: string): Date =>
  time
    ? new Date(`${date?.split('T')[0]}T${new Date().toISOString()?.split('T')[1]}`)
    : new Date(`${date?.split('T')[0]}T${time?.split('T')[1]}`);

export default {};
