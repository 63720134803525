/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import { useStoreActions } from '~/store/hooks';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import Loader from '~/ui/components/common/Loader';
import Select from '~/ui/components/inputs/Select';
import statesOptions from '~/ui/constants/states';
import suicideRiskOptions from '~/ui/constants/suicideRisks';
import { extractErrorMessage } from '~/utils/error';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { IInitialValues } from './initialValues';
import validate from './validate';
import { IOption } from '~/types';

import styles from './Form.module.scss';

interface IProps {
  isProcessing: boolean;
  leavePageDescription: string;
  clinicId: string;
  onSubmit: (val: IInitialValues) => void;
  submitText?: string;
  defaultValues?: IInitialValues;
}

interface IOptions {
  gender: IOption[];
  referralSource: IOption[];
  actTeams: IOption[];
}

const Form = ({
  isProcessing,
  leavePageDescription,
  clinicId,
  onSubmit,
  submitText = 'Add Client',
  defaultValues = { aot: false } as IInitialValues,
}: IProps): ReactElement => {
  const { goBack } = useHistory();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOptions>(null);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isDirty },
  } = useForm<IInitialValues>({ defaultValues, resolver: validate, mode: 'onBlur' });

  const onMount = async () => {
    const { getAvailableTypeList } = api.dictionaries;
    try {
      setLoading(true);
      const [gender, referralSource, actTeams] = await Promise.all([
        getAvailableTypeList(IDictionaryTypes.Gender).then(r => formatDictionaryOptions(r.data)),
        getAvailableTypeList(IDictionaryTypes.ReferralSource).then(r =>
          formatDictionaryOptions(r.data),
        ),
        api.actTeam.getActTeamsAvailable(clinicId).then(r => formatDictionaryOptions(r.data)),
      ]);

      setLoading(false);
      setOptions({ gender, referralSource, actTeams });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <p className={styles.formTitle}>Personal Details</p>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Input
                  tabIndex={1}
                  register={register}
                  errors={errors}
                  name="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  tabIndex={7}
                  options={options.gender}
                  name="genderId"
                  control={control}
                  errors={errors}
                  label="Gender"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={2}
                  register={register}
                  errors={errors}
                  name="lastName"
                  label="Last Name"
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  tabIndex={8}
                  options={suicideRiskOptions}
                  name="suicideRisk"
                  control={control}
                  errors={errors}
                  label="Suicide Risk"
                />
              </Grid>
              <Grid item sm={6}>
                <InputMask
                  tabIndex={3}
                  control={control}
                  errors={errors}
                  name="phone"
                  label="Phone"
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  tabIndex={9}
                  options={options.actTeams}
                  name="teamId"
                  control={control}
                  errors={errors}
                  label="Assigned Act Team"
                  isDisabled={!!defaultValues?.email}
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={4}
                  register={register}
                  errors={errors}
                  name="email"
                  label="Email"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={10}
                  register={register}
                  errors={errors}
                  name="address.city"
                  label="City"
                />
              </Grid>
              <Grid item sm={6}>
                <DatePicker
                  tabIndex={5}
                  control={control}
                  errors={errors}
                  label="Date of Birth"
                  name="dateOfBirth"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={11}
                  register={register}
                  errors={errors}
                  name="address.line1"
                  label="Address 1"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={6}
                  register={register}
                  errors={errors}
                  name="medicaId"
                  label="Medicaid #"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={12}
                  register={register}
                  errors={errors}
                  name="address.line2"
                  label="Address 2"
                />
              </Grid>
              <Grid item sm={6}>
                <Input
                  tabIndex={6}
                  register={register}
                  errors={errors}
                  name="emrMrn"
                  label="EMR MRN"
                />
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Select
                      tabIndex={13}
                      options={statesOptions}
                      name="address.stateId"
                      control={control}
                      errors={errors}
                      label="State"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputMask
                      tabIndex={14}
                      alwaysShowMask={false}
                      mask="99999"
                      name="address.zipCode"
                      control={control}
                      errors={errors}
                      label="Zip Code"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
              </Grid>
              <Grid item sm={6}>
                <Checkbox size="small" name="aot" control={control} errors={errors} label="AOT" />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.marginTop}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Admission Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={15}
                    options={options.referralSource}
                    name="admissionDetails.referralSourceId"
                    control={control}
                    errors={errors}
                    label="Referral Source"
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={16}
                    control={control}
                    errors={errors}
                    label="Date of Admission"
                    name="admissionDetails.admissionDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonWrapper}>
            <Button variant="outlined" color="primary" onClick={goBack}>
              Cancel
            </Button>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isProcessing}
            tabIndex={17}
          >
            {submitText}
          </Button>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;
