import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetLocalAdmins } from '../types';

// @ts-ignore
const onGetLocalAdmins: IThunkGetLocalAdmins = thunk(async ({ setLocalAdmins }, payload) => {
  const { clinicId, params } = payload;

  const { total, items } = await api.localAdmin.getLocalAdmins(clinicId, params).then(r => r.data);
  const pagination = { ...params, total };

  setLocalAdmins({ items, pagination });
});

export default onGetLocalAdmins;
