import { thunk } from 'easy-peasy';
import { IThunkGetSecondaryDiagnosis, IClientDetailsStore } from '../types';

const onGetSecondaryDiagnosis: IThunkGetSecondaryDiagnosis = thunk(
  // @ts-ignore
  ({ setCurrent }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const current = localState.diagnoses.find(item => item.id === payload);

    setCurrent(current);
  },
);

export default onGetSecondaryDiagnosis;
