import { ReactElement, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { curry } from 'lodash';
import { saveAs } from 'file-saver';

import api from '~/services/api';
import MedicationDeliveryCalendar from './components/MedicationDeliveryCalendar';
import ClientInfo from './components/ClientInfo';
import Modal from '~/ui/components/common/Modal';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import SimpleSelect from './components/SimpleSelect';
import Forms from '../Clients/reusable/HealthDetails/Forms';
import MedicationDeliveryPreview from './components/MedicationDeliveryPreview';

import { extractErrorMessage } from '~/utils/error';
import { formatActTeamsOptions } from '~/utils/formatActTeamsOptions';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import useQueryParam from './hooks/useQueryParam';

import { IDetails, ITypeDetails } from '../Clients/reusable/HealthDetails/types';
import { IUserRole } from '~/types';
import reportDownload from '~/ui/assets/images/reportDownload.svg';
import styles from './MedicationLogistics.module.scss';

const MedicationLogistics = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(null);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);

  const query = useQuery();
  const actTeamId = query.get('actTeamId');
  const clientId = query.get('clientId');

  const weekModeQuery = query.get('weekMode');
  const [weekMode, setWeekMode] = useState(weekModeQuery === 'true' || false);

  const user = useStoreState(state => state.user.current);
  const { availableActTeams } = useStoreState(state => state.actTeam);
  const { availableClients, current } = useStoreState(state => state.client);
  const currentMedicationDelivery = useStoreState(state => state.medicationLogistics.current);
  const deliveriesList = useStoreState(state => state.medicationLogistics.list);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);
  const onGetMedicationDelivery = useStoreActions(
    actions => actions.medicationLogistics.onGetMedicationDelivery,
  );
  const { onGetAvailableClients, onGetClient, setAvailableClients } = useStoreActions(
    actions => actions.client,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const actTeamOptions = formatActTeamsOptions(availableActTeams);

  const isLocalAdmin = user?.roles?.includes(IUserRole.LocalAdmin);
  const isProgramAssistant = user?.roles?.includes(IUserRole.ProgramAssistant);

  const showTeamSelect = isLocalAdmin || isProgramAssistant;

  const initialTeamId = showTeamSelect ? parseInt(actTeamId, 10) : user.team?.id;

  const [team, setTeam] = useState<number | null>(initialTeamId || null);

  const teamId = showTeamSelect && team ? String(team) : String(user.team?.id);

  const onMount = async () => {
    try {
      setLoading(true);

      if (showTeamSelect) {
        await Promise.all([
          onGetMyClinic(),
          onGetActTeamsAvailable({ clinicId: String(user.clinic.id) }),
        ]);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const selectActTeam = async () => {
    if (team) {
      try {
        setLoading(true);
        await onGetAvailableClients({
          clinicId: String(user.clinic.id),
          teamId: String(team),
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const selectClient = async (id: number) => {
    if (id) {
      try {
        setClientLoading(true);
        await onGetClient({
          clinicId: String(user.clinic.id),
          teamId: String(teamId),
          clientId: String(id),
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setClientLoading(false);
      }
    }
  };

  const requestInfo = {
    clientId: String(current?.id),
    clinicId: String(user?.clinic.id),
    teamId,
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const onAdd = curry((title: string, medicationId: number, date: string, injectable: boolean) => {
    setDetails({ medicationId, date, injectable });
    setModalTitle(title);
  });

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
      isArchived: boolean,
    ) => {
      if (isArchived) {
        setOpenPreview(true);
        setDetails({ type, id });
      } else {
        let title = `Edit “${healthDetailsName}” ${healthDetailsType}`;

        if (removeHealthDetailsName) {
          title = title.replace(/“[\w\s]*”/gi, '');
        }

        setModalTitle(title);
        setDetails({ type, id });
      }
    },
  );

  const loadDetails = async () => {
    try {
      if (details?.id) {
        setLoadingDetails(true);
        const payload = {
          clinicId: String(user.clinic.id),
          clientId: String(current.id),
          teamId: String(teamId),
          medicationDeliveryId: String(details?.id),
        };

        await onGetMedicationDelivery(payload);
      }

      setLoadingDetails(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const generatePDF = async (): Promise<void> => {
    setReportLoading(true);
    try {
      const selectedInterval = query.get('selectedMonth').split('-');
      await api.medicationLogistics
        .getMedicationDeliveryReport(
          { clinicId: String(user.clinic.id), teamId },
          {
            clientId: String(current?.id),
            year: selectedInterval[0],
            month: selectedInterval[1],
            timeZoneOffset: new Date().getTimezoneOffset(),
          },
        )
        .then(({ data }: { data: BlobPart }) => {
          const blob = new Blob([data]);
          const link = window.URL.createObjectURL(blob);
          saveAs(
            link,
            `${current.firstName}_${current.lastName}_Delivery_data_${selectedInterval[0]}${selectedInterval[1]}${selectedInterval[2]}.pdf`,
          );
          setReportLoading(false);
        });
    } catch (e) {
      showError(extractErrorMessage(e));
      setReportLoading(false);
    }
  };

  const renderHeader = () => (
    <div className={styles.header}>
      <h2>Medication Logistics</h2>
    </div>
  );

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    loadDetails();
  }, [details]);

  useEffect(() => {
    setAvailableClients([]);
    selectActTeam();
  }, [teamId]);

  useQueryParam('actTeamId', showTeamSelect && team ? String(team) : undefined);

  if (loading || !user) return <Loader />;

  return (
    <div className={styles.container}>
      {showTeamSelect && renderHeader()}
      <Box className={styles.titleSection}>
        {showTeamSelect && !!actTeamOptions.length ? (
          <SimpleSelect
            label="Assigned ACT Team"
            value={team}
            setValue={setTeam}
            options={actTeamOptions}
          />
        ) : (
          renderHeader()
        )}

        {team && (
          <Button
            isLoading={reportLoading}
            loaderColor="white"
            variant="contained"
            color="primary"
            startIcon={<img src={reportDownload} alt="download" />}
            disabled={deliveriesList?.length === 0 || weekMode}
            onClick={() => generatePDF()}
          >
            Export PDF
          </Button>
        )}
      </Box>
      {!!team && !!availableClients.length && (
        <ClientInfo
          clientId={availableClients.find(c => c.id === parseInt(clientId, 10))?.id}
          selectClient={selectClient}
          loading={clientLoading}
        />
      )}
      {!!team && !!availableClients.length && !!current && (
        <MedicationDeliveryCalendar
          teamId={parseInt(teamId, 10)}
          weekMode={weekMode}
          setWeekMode={setWeekMode}
          onAdd={onAdd('Add Medication Delivery')}
          onEdit={onEdit('medicationDelivery', 'Medication Delivery', true)}
        />
      )}
      {(openPreview || (details && details.id)) && !modalTitle && (
        <Modal
          className={styles.modal}
          open
          onClose={() => {
            setOpenPreview(null);
            setDetails(null);
          }}
        >
          <MedicationDeliveryPreview
            medicationDelivery={currentMedicationDelivery}
            onClose={() => {
              setOpenPreview(null);
              setDetails(null);
            }}
          />
        </Modal>
      )}
      {(modalTitle || (details && details.id)) && !openPreview && (
        <Modal
          className={styles.modal}
          open
          onClose={() => {
            setModalTitle(null);
            setDetails(null);
          }}
        >
          {loadingDetails ? (
            <Loader />
          ) : (
            <div className={styles.modalWrapper}>
              <h3 className={styles.modalTitle}>{modalTitle}</h3>
              <Forms
                formType={
                  currentMedicationDelivery?.medication?.injectable || details?.injectable
                    ? 'injectableMedicationDelivery'
                    : 'medicationDelivery'
                }
                setModalTitle={setModalTitle}
                setDetails={setDetails}
                requestInfo={requestInfo}
                details={details}
                showSecondSubmit={showSecondSubmit}
                current={
                  currentMedicationDelivery || {
                    medicationIds: [details.medicationId],
                    date: details.date,
                    teamMember: !showTeamSelect ? { id: user.id, name: user.fullName } : undefined,
                  }
                }
                primaryDiagnosis={null}
              />
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default MedicationLogistics;
