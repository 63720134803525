import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeactivateSuperAdmin, ISuperAdminStore } from '../types';

const onDeactivateSuperAdmin: IThunkDeactivateSuperAdmin = thunk(
  // @ts-ignore
  async ({ setSuperAdmins }, superAdminId, { getState }) => {
    const localState = getState() as ISuperAdminStore;
    await api.superAdmin.deactivateSuperAdmin(superAdminId);

    const updatedList = localState.list.map(superAdmin =>
      superAdmin.id !== superAdminId ? superAdmin : { ...superAdmin, status: 'Deactivated' },
    );

    setSuperAdmins({ items: updatedList });
  },
);

export default onDeactivateSuperAdmin;
