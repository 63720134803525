import { object, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { validateLengthField, phone, zipCode } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, validEmail } from '~/ui/constants/errorMessages';

const validateSchema = object().shape({
  firstName: string().required(requiredField),
  lastName: string().required(requiredField),
  email: string()
    .test('length', fieldMax, validateLengthField)
    .email(validEmail)
    .required(requiredField),
  phone,
  address: object().shape({
    line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
    line2: string().test('length', fieldMax, validateLengthField),
    city: string().required(requiredField).test('length', fieldMax, validateLengthField),
    stateId: number().required(requiredField),
    zipCode,
  }),
  relationshipId: number().required(requiredField),
});

export default yupResolver(validateSchema);
