import { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import { CLINIC_MANAGEMENT, MY_CLINIC } from '~/ui/constants/paths';
import { IUserRole } from '~/types';
/**
 * Render public route
 * Only unlogged users can access to public route
 * Otherwise - redirect user to another allowed page route
 */
const PublicRoute = (props: any): ReactElement => {
  const { authorized, authChecked, current } = useStoreState(state => state.user);

  if (!authChecked) return null;

  const to =
    current?.roles?.includes(IUserRole.SuperAdmin) ||
    current?.roles?.includes(IUserRole.ProductOwner)
      ? CLINIC_MANAGEMENT
      : MY_CLINIC;

  return authorized ? <Redirect to={to} /> : <Route {...props} />;
};

export default PublicRoute;
