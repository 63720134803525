import {
  IClientAllergy,
  IClientDeEscalationTechnique,
  IClientDiagnosis,
  IClientMedication,
  IClientTrigger,
} from '~/services/api/clientDetails/types';
import { IHealthDetailItem, IHealthDetailGroup } from './types';

export type HealthDetailsArray = Array<
  | IClientDeEscalationTechnique
  | IClientDiagnosis
  | IClientMedication
  | IClientTrigger
  | IClientAllergy
>;

const getProperty = (item: any) =>
  item.diagnosis || item.trigger || item.medication || item.deEscalationTechnique || item.allergy;

export const formatHealthDetailsItem = (item: any): IHealthDetailItem => ({
  name: getProperty(item).name,
  note: item.note,
  id: item.id,
  isArchived: item.isArchived,
  type: item.type,
  dose: item.dose,
  injectable: item.injectable,
});

export const groupByStatus = (arr: HealthDetailsArray): IHealthDetailGroup =>
  arr.reduce(
    (acc, item) => {
      const formatted = formatHealthDetailsItem(item);

      if (item.isArchived) {
        acc.archived.push(formatted);
      } else {
        acc.active.push(formatted);
      }

      return acc;
    },
    { active: [], archived: [] },
  );

export default {};
