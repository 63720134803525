import { IOption } from '~/types';

export const formatDictionaryOptions = (
  dictionaryOptions: {
    value?: string;
    id: number;
    name?: string;
    abbreviation?: string;
    icd10?: string;
  }[],
): IOption[] =>
  dictionaryOptions.map(item => ({
    label:
      (item.value || item.name) +
      (item.abbreviation ? ` | ${item.abbreviation}` : '') +
      (item.icd10 ? ` (${item.icd10}) ` : ''),
    value: item.id,
  }));
export default {};
