import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { useStoreState } from '~/store/hooks';
import Avatar from '~/ui/components/common/Avatar';
import Loader from '~/ui/components/common/Loader';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import { formatClientsOptions } from '~/utils/formatClientsOptions';
import MonitoredBy from '../MonitoredBy';
import Allergies from '../Allergies';
import useQueryParam from '../../hooks/useQueryParam';

import { VIEW_CLIENT } from '~/ui/constants/paths';
import { IClientDetailed } from '~/services/api/client/types';
import { IClientDetailsStore } from '~/store/clientDetails/types';
import { suicideRiskColor } from '~/ui/constants/suicideRisks';
import arrowUpIcon from '~/ui/assets/images/arrowUp.svg';
import clientInfoIcon from '~/ui/assets/images/clientInfo.svg';
import styles from './ClientInfo.module.scss';

interface IProps {
  loading: boolean;
  clientId?: number;
  selectClient: (clientId: number) => void;
  onVisitsPage?: boolean;
}

const ClientInfo = ({
  clientId,
  loading,
  selectClient,
  onVisitsPage = false,
}: IProps): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);

  const { availableClients, current } = useStoreState(state => state.client);
  const { monitoredBy: visitsMonitoredBy } = useStoreState(state => state.visits);
  const monitoredBy = useStoreState(state => state.medicationLogistics.monitoredBy);

  const clientsOptions = formatClientsOptions(availableClients);

  const initialClientId = useMemo(
    () => (clientsOptions.find(c => c.value === clientId) || clientsOptions[0])?.value,
    [clientsOptions, clientId],
  );

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { client: initialClientId } });

  const { client } = watch();

  useEffect(() => {
    selectClient(client as number);
  }, [client]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useQueryParam('clientId', String(client));

  if (loading) return <Loader />;

  if (!current) return null;

  const {
    photo,
    medicaId,
    address,
    primaryDiagnosis,
    firstName,
    lastName,
    suicideRisk,
    aot,
    gender,
    dateOfBirth,
    allergies,
  } = current as IClientDetailed & IClientDetailsStore;

  const clientAddress = `${address.line1}, ${address.city}, ${address.state.abbreviation} ${address.zipCode}`;

  const color = suicideRiskColor[suicideRisk];

  const CustomLink = ({ children, red }: { children: ReactNode; red?: boolean }) => (
    <Link
      className={classNames(styles.link, { [styles.red]: red })}
      to={{
        pathname: VIEW_CLIENT.replace(':clientId', String(current.id)).replace(
          ':actTeamId',
          String(current.actTeam.id),
        ),
        state: { actTeamId: String(current.actTeam.id) },
      }}
    >
      {children}
    </Link>
  );

  return (
    <Box className={classNames(styles.infoSection, { [styles.collapsedInfoSection]: collapsed })}>
      <>
        <Box className={classNames(styles.row, styles.marginRight, styles.wide)}>
          {collapsed && (
            <>
              <img className={styles.marginRight} src={clientInfoIcon} alt="client info" />
              <CustomLink red>
                <span className={styles.name}>{`${firstName} ${lastName}`}</span>
              </CustomLink>
            </>
          )}
          <Avatar
            url={photo}
            className={classNames(styles.avatar, { [styles.hidden]: collapsed })}
          />
          {!collapsed && (
            <Box className={styles.general}>
              <div style={{ color }} className={styles.name}>
                <CustomLink>
                  <span
                    className={classNames(styles.black, styles.marginRight)}
                  >{`${firstName} ${lastName}`}</span>
                </CustomLink>{' '}
                SR
                {aot ? <span className={styles.black}>/AOT</span> : ''}
              </div>
              <div className={styles.textItem}>{`${medicaId ? `${medicaId} / ` : ''}${
                gender.name
              } / ${format(new Date(dateOfBirth), 'MM.dd.yyyy')}`}</div>
              {primaryDiagnosis?.diagnosis.name && (
                <div
                  className={styles.textItem}
                >{`Primary Dx: ${primaryDiagnosis?.diagnosis.name}`}</div>
              )}
              <div className={styles.textItem}>{`Address: ${clientAddress}`}</div>
            </Box>
          )}
        </Box>
        {!collapsed && (
          <>
            <MonitoredBy monitoredBy={onVisitsPage ? visitsMonitoredBy : monitoredBy} />
            <Allergies allergies={allergies} />
            <Box className={classNames(styles.column, styles.centerItems)}>
              <SelectComponent
                options={clientsOptions}
                name="client"
                control={control}
                label="Select Client"
                errors={errors}
              />
            </Box>
          </>
        )}
      </>
      <Box
        className={classNames(styles.collapseButton, { [styles.collapsedIcon]: collapsed })}
        onClick={handleCollapse}
      >
        <img src={arrowUpIcon} alt="arrow-up" />
      </Box>
    </Box>
  );
};

export default ClientInfo;
