import setClients from './setClients';
import setClient from './setClient';
import setAvailableClients from './setAvailableClients';
import resetCurrentClient from './resetCurrentClient';

export default {
  setClients,
  setClient,
  setAvailableClients,
  resetCurrentClient,
};
