import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import {
  IClientAllocation,
  IRelatedClientsForAllocation,
  IClientAllocationDetailed,
} from './types';

interface RequestInfo {
  clinicId: string;
  teamId: string;
  clientAllocationId?: string;
}

export const getClientAllocationList = (
  { clinicId, teamId }: RequestInfo,
  params: IParams,
): IResponse<{ total: number; items: IClientAllocation[] }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/list`,
    params,
  });

export const getRelatedClients = (
  clinicId: string,
  teamId: string,
): IResponse<IRelatedClientsForAllocation[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/available-list`,
  });

export const addClientAllocation = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: any,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation`,
    data: requestPayload,
  });

export const updateClientAllocation = (
  { clinicId, teamId, clientAllocationId }: RequestInfo,
  requestPayload: any,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/${clientAllocationId}`,
    data: requestPayload,
  });

export const getClientAllocation = ({
  clinicId,
  teamId,
  clientAllocationId,
}: RequestInfo): IResponse<IClientAllocationDetailed> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/${clientAllocationId}`,
  });

export const validateDateRange = (
  { clinicId, teamId }: RequestInfo,
  requestPayload: any,
): IResponse<{ isValid: boolean; unavailableDates: string[] }> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/validate-dates`,
    data: requestPayload,
  });

export default {};
