import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUnlockLocalAdmin } from '../types';

const onUnlockLocalAdmin: IThunkUnlockLocalAdmin = thunk(
  // @ts-ignore
  async ({ activateLocalAdmin }, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.unlockLocalAdmin(clinicId, localAdminId);

    activateLocalAdmin(localAdminId);
  },
);

export default onUnlockLocalAdmin;
