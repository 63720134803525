import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Loader from '~/ui/components/common/Loader';
import ClinicProfile from './ClinicProfile';
import ActTeam from './ActTeam';
import LocalAdminTable from './LocalAdmin/Table';
import ProgramAssistant from './ProgramAssistant';
import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';

import { extractErrorMessage } from '~/utils/error';

import Header from './Header';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

type IParams = {
  id: string;
};

const ViewClinic = (): ReactElement => {
  const { id } = useParams<IParams>();
  const location = useLocation<{ selectedTab: number }>();

  const [tab, setTab] = useState(location.state?.selectedTab || 0);
  const [loading, setLoading] = useState(false);
  const [localAdmin, setLocalAdmin] = useState(null);

  const current = useStoreState(state => state.clinic.current);
  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const { onDeactivateLocalAdmin, onActivateLocalAdmin, onUnlockLocalAdmin } = useStoreActions(
    actions => actions.localAdmin,
  );

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetClinic(id);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !current) return <Loader />;

  const getConfirmProps = (status: string) => {
    if (status === 'Active')
      return {
        confirmText: 'Deactivate',
        description: 'Are you sure you want to deactivate this user?',
        method: () => onDeactivateLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    if (status === 'Deactivated')
      return {
        confirmText: 'Activate',
        description: 'Are you sure you want to activate this user?',
        method: () => onActivateLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    if (status === 'Locked')
      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockLocalAdmin({ clinicId: id, localAdminId: localAdmin?.id }),
      };

    return {
      confirmText: 'Resend',
      description: 'Are you sure you want to resend invitation?',
      method: () => api.localAdmin.resendInvitation(id, localAdmin?.id),
    };
  };

  const { confirmText, description, method } = getConfirmProps(localAdmin?.status);

  const onConfirm = async () => {
    try {
      await method();
      setLocalAdmin(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLocalAdmin(false);
    }
  };

  return (
    <div>
      <Header name={current.name} tab={tab} setTab={setTab} id={id} currentTab={tab} />
      {tab === 0 && <ClinicProfile clinic={current} />}
      {tab === 1 && <ActTeam />}
      {tab === 2 && <LocalAdminTable setLocalAdmin={setLocalAdmin} />}
      {tab === 3 && <ProgramAssistant />}
      {localAdmin && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onClose={() => setLocalAdmin(null)}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default ViewClinic;
