import { useEffect, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import Loader from '~/ui/components/common/Loader';
import Table from '../../reusable/Table';
import ModalForm from './ModalForm';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';

import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { IClient } from '~/services/api/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { ADD_CLIENT } from '~/ui/constants/paths';

import styles from './ClientsManagement.module.scss';

const ClientsManagements = (): ReactElement => {
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<IClient>(null);
  const [dictionaries, setDictionaries] = useState<{ id: number; value: string }[]>([]);

  const clinicId = useStoreState(state => state.clinic.current?.id);

  const {
    list: clients,
    pagination: { pageSize, pageNumber },
  } = useStoreState(state => state.client);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicClients = useStoreActions(actions => actions.client.onGetClinicClients);
  const onRestoreClient = useStoreActions(actions => actions.client.onRestoreClient);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      setLoading(true);

      if (!clinicId) {
        await onGetMyClinic();
      }
      if (clinicId) {
        await onGetClinicClients({ clinicId: String(clinicId), params: { pageSize, pageNumber } });
      }
      if (!dictionaries.length) {
        const { data } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.DischargeReason,
        );
        setDictionaries(data);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [clinicId]);

  if (loading) return <Loader />;

  const options = dictionaries.map(({ id, value }) => ({ label: value, value: id }));
  const description = `Are you sure you want to restore ${client?.firstName} ${client?.lastName}?`;
  const onConfirmRestore = async () => {
    try {
      await onRestoreClient({
        clinicId: String(clinicId),
        teamId: String(client?.actTeam.id),
        clientId: String(client.id),
      });
      showNotify('Client successfully restored');
      setClient(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Clients</h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => push(ADD_CLIENT)}
        >
          Add Client
        </Button>
      </div>
      <Table
        isLocalAdmin
        list={clients}
        onGetClients={onGetClinicClients}
        clinicId={clinicId}
        setClient={setClient}
      />
      {client && !client.isArchived && (
        <ModalForm client={client} clinicId={clinicId} setClient={setClient} options={options} />
      )}
      {client && client.isArchived && (
        <ConfirmModal
          confirmText="Restore"
          onConfirm={onConfirmRestore}
          description={description}
          onClose={() => setClient(null)}
        />
      )}
    </div>
  );
};

export default ClientsManagements;
