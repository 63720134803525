import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import Form from '../../../Clinics/ViewClinic/LocalAdmin/Form';
import Loader from '~/ui/components/common/Loader';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { formatDefaultValues } from '~/utils/formatDefaultValues';
import { extractErrorMessage } from '~/utils/error';
import { ACT_TEAM_MANAGEMENT, VIEW_ACT_TEAM } from '~/ui/constants/paths';
import { isFile } from '~/utils/file';

import styles from './EditTeamMember.module.scss';

const EditTeamMember = (): ReactElement => {
  const { teamMemberId, actTeamId } = useParams<{ teamMemberId: string; actTeamId: string }>();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const { clinic: userClinic } = useStoreState(state => state.user.current);
  const clinic = useStoreState(state => state.clinic.current);
  const teamMember = useStoreState(state => state.actTeamMember.current);
  const actTeam = useStoreState(state => state.actTeam.current);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamMember = useStoreActions(actions => actions.actTeamMember.onGetActTeamMember);
  const onGetActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      setLoading(true);

      await Promise.all([
        onGetMyClinic(),
        onGetActTeamMember({ teamMemberId, actTeamId, clinicId: String(userClinic.id) }),
        onGetActTeam({ clinicId: String(userClinic.id), actTeamId }),
      ]);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const isPending = teamMember?.status === 'Pending';

  const onSubmit = async (vals: any) => {
    try {
      const { email, emailDomain, ...restVals } = vals;
      const payload = isPending ? { email: email + emailDomain, ...restVals } : { ...vals };
      setIsLoadingSubmit(true);

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        payload.photo = fileName;
      }

      await api.actTeamMember.updateTeamMember(
        String(userClinic.id),
        actTeamId,
        teamMemberId,
        payload,
      );

      showNotify('Member successfully updated.');

      push(VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), { selectedTab: 1 });
    } catch (e) {
      setIsLoadingSubmit(false);
      showError(extractErrorMessage(e));
    }
  };

  if (loading || !teamMember || !actTeam || !clinic) return <Loader />;

  const emailDomainOptions = clinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  const breadcrumbsItems = [
    {
      title: `${userClinic.name} ACT Teams`,
      to: ACT_TEAM_MANAGEMENT,
    },
    {
      title: actTeam.name,
      to: { pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), state: { selectedTab: 1 } },
    },
    {
      title: 'Edit Team Member Profile',
      to: pathname,
    },
  ];

  const leavePageDescription = `Are you sure you want to cancel editting ${teamMember.firstName} ${teamMember.lastName} profile?   All the changes will be discarded`;
  const cancelLinkTo = {
    pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId),
    state: { selectedTab: 1 },
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Edit Team Member Profile</h2>
      </div>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        defaultValues={formatDefaultValues(teamMember)}
        isEdit
        submitText="Save Changes"
        isProcessing={isLoadingSubmit}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
        isPending={isPending}
        emailDomainOptions={isPending ? emailDomainOptions : undefined}
      />
    </div>
  );
};

export default EditTeamMember;
