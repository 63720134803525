import { useEffect, useState, ReactElement } from 'react';
import Header from './components/Header';
import Table from './components/Table';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

const ClinicManagement = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [clinic, setClinic] = useState(null);
  const [isArchiveClinic, setIsArchiveClinic] = useState(null);

  const { list, pagination } = useStoreState(state => state.clinic);
  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onGetClinics, onArchiveClinic, onRestoreClinic } = useStoreActions(
    actions => actions.clinic,
  );

  const onMount = async () => {
    const { pageSize, pageNumber } = pagination;
    try {
      setLoading(true);
      await onGetClinics({ pageSize, pageNumber });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const confirmText = isArchiveClinic ? 'Archive' : 'Restore';
  const onConfrimMethod = isArchiveClinic ? onArchiveClinic : onRestoreClinic;
  let description = isArchiveClinic
    ? 'Are you sure you want to archive “clinicName” clinic?'
    : 'Are you sure you want to restore “clinicName” clinic?';
  description = description.replace('clinicName', clinic?.name);

  const onConfirm = async (clinicId: number) => {
    try {
      setLoading(true);
      await onConfrimMethod(clinicId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
      setClinic(null);
    }
  };

  return (
    <div>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <Table
          clinics={list}
          pagination={pagination}
          onGetClinics={onGetClinics}
          setClinic={setClinic}
          setIsArchiveClinic={setIsArchiveClinic}
        />
      )}
      {clinic && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onConfirm={() => {
            onConfirm(clinic.id);
          }}
          onClose={() => setClinic(null)}
        />
      )}
    </div>
  );
};

export default ClinicManagement;
