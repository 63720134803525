import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveSupportContact, IClientDetailsStore } from '../types';

const onArchiveSupportContact: IThunkArchiveSupportContact = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { clientId, clinicId, id, teamId } = payload;

    await api.clientDetails.archiveSupportContact(clinicId, teamId, clientId, id);

    const supportContacts = localState.supportContacts.map(item =>
      item.id !== Number(id) ? item : { ...item, isArchived: true },
    );

    setClientDetails({ supportContacts });
  },
);

export default onArchiveSupportContact;
