import { IDictionariesStore } from './types';

const initStore: IDictionariesStore = {
  list: [],
  pagination: {
    pageSize: 5,
    pageNumber: 1,
    total: 0,
  },
};

export default initStore;
