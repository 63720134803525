import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatTrigger } from '~/utils/formatTrigger';
import { IThunkCreateTrigger, IClientDetailsStore } from '../types';

const onCreateTrigger: IThunkCreateTrigger = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const clientTrigger = await api.clientDetails
      .createClientTrigger(requestInfo, requestPayload)
      .then(r => r.data);

    const triggers = [formatTrigger(clientTrigger), ...localState.triggers];

    setClientDetails({ triggers });
  },
);

export default onCreateTrigger;
