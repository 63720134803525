import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement } from 'react';

import extractInitials from '~/utils/extractInitials';

import styles from './CalendarInitials.module.scss';

interface IProps {
  name: string;
  refused: boolean;
  archived: boolean;
}

const CalendarInitials = ({ name, refused, archived }: IProps): ReactElement => (
  <Box
    className={classNames(styles.calendarInitials, {
      [styles.refused]: refused,
      [styles.archived]: archived,
    })}
  >
    <div
      className={classNames(styles.icon, {
        [styles.refusedIcon]: refused,
        [styles.archivedIcon]: archived,
      })}
    />
    {extractInitials(name)}
  </Box>
);

export default CalendarInitials;
