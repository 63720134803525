import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetMyClinic } from '../types';

// @ts-ignore
const onGetMyClinic: IThunkGetMyClinic = thunk(async ({ setClinic }, _payload) => {
  const { data } = await api.clinic.getMyClinic();

  setClinic(data);
});

export default onGetMyClinic;
