import { privateAxios } from '../requestsCollection';
import { IResponse } from '~/services/api/types';
import { ICreateUserRequest } from './types';

export const createLocalAdmin = (data: ICreateUserRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'Users/local-admin',
    data,
  });

export const createActTeamLead = (data: ICreateUserRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'Users/act-team-lead',
    data,
  });

export const createActTeamMember = (data: ICreateUserRequest): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'Users/act-team-member',
    data,
  });

export const uploadProfilePhoto = (photo: string): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `Account/profile-photo`,
    data: { photo },
  });
