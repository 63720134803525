import { thunk } from 'easy-peasy';
import { IMedicationLogisticsStore, IThunkAddMedicationDelivery } from '../types';
import api from '~/services/api';

const onAddMedicationDelivery: IThunkAddMedicationDelivery = thunk(
  // @ts-ignore
  async ({ setMedicationDeliveries }, payload, { getState }) => {
    const localState = getState() as IMedicationLogisticsStore;
    const { requestInfo, requestPayload } = payload;

    const { data } = await api.medicationLogistics.addMedicationDelivery(
      requestInfo,
      requestPayload,
    );
    const deliveries = [...data, ...localState.list];

    setMedicationDeliveries({ deliveries, monitoredBy: localState.monitoredBy });
  },
);

export default onAddMedicationDelivery;
