import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveActTeam, IActTeamStore } from '../types';

const onArchiveActTeam: IThunkArchiveActTeam = thunk(
  // @ts-ignore
  async ({ setActTeams }, payload, { getState }) => {
    const localState = getState() as IActTeamStore;
    const { clinicId, actTeamId } = payload;

    await api.actTeam.archiveActTeam(clinicId, actTeamId);

    const updatedList = localState.list.map(item =>
      item.id !== Number(actTeamId) ? item : { ...item, isArchived: true },
    );

    setActTeams({ items: updatedList });
  },
);

export default onArchiveActTeam;
