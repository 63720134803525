import { isSunday, startOfMonth, previousSunday } from 'date-fns';

const getFromDate = (date: Date, dateFormat?: boolean): string | Date => {
  const startOfMonthDate = startOfMonth(date);
  const sunday = isSunday(startOfMonthDate);
  const prevSunday = sunday ? startOfMonthDate : previousSunday(startOfMonthDate);
  if (dateFormat) {
    return prevSunday;
  }
  return new Date(prevSunday.setHours(0)).toISOString();
};

export default getFromDate;
