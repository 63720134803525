import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnReInviteProgramAssistant } from '../types';

const onReInviteProgramAssistant: IThunkOnReInviteProgramAssistant = thunk(
  // @ts-ignore
  async ({ reInviteProgramAssistant }, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.reInviteProgramAssistant(clinicId, String(programAssistantId));

    reInviteProgramAssistant(programAssistantId);
  },
);

export default onReInviteProgramAssistant;
