import { IClientMedicationResponse, IClientMedication } from '~/services/api/clientDetails/types';

export const formatMedication = (medication: IClientMedicationResponse): IClientMedication => ({
  id: medication.id,
  note: medication.note,
  medication: { id: medication.medication.id, name: medication.medication.name },
  isArchived: false,
  dose: medication.dose,
  injectable: medication.injectable,
});

export default {};
