import { thunk } from 'easy-peasy';
import { IClientDetailsStore, IThunkGetSupportContact } from '../types';

// @ts-ignore
const onGetSupportContact: IThunkGetSupportContact = thunk(({ setCurrent }, id, { getState }) => {
  const localState = getState() as IClientDetailsStore;

  const current = localState.supportContacts.find(item => item.id === id);

  setCurrent(current);
});

export default onGetSupportContact;
