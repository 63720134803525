export const triggerType = [
  {
    label: 'Recurring',
    value: 'Recurring',
  },
  {
    label: 'Non-recurring',
    value: 'NonRecurring',
  },
];

export default {};
