import { IOption } from '~/types';

const initialValues = {
  name: '',
  phone: '',
  url: '',
  emailDomains: [] as IOption[],
  address: { line1: '', line2: '', city: '', zipCode: '', stateId: null as number },
  logo: '',
  programSupervisor: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  ehrId: null as number,
};

export default initialValues;
