import { useState, useEffect, ReactElement } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import {
  TableContainer,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import SelectComponent from '~/ui/components/inputs/Select/Select';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Loader from '~/ui/components/common/Loader';
import { extractErrorMessage } from '~/utils/error';
import { red } from '~/ui/constants/colors';
import { dateSortingOptions } from '~/ui/constants/sortingOptions';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { isoToLocal } from '~/utils/date';
import { IParams } from '~/services/api/types';
import {
  EDIT_CLIENT_ALLOCATION,
  VIEW_CLIENT_ALLOCATION,
  DUPLICATE_CLIENT_ALLOCATION,
} from '~/ui/constants/paths';

import editIcon from '~/ui/assets/images/editGrey.svg';
import styles from './ClientAllocation.module.scss';

const defaultValues = {
  name: '',
  sorting: '',
};

interface IProps {
  clinicId: string;
  actTeamId: string;
  noActions?: boolean;
}

const ClientAllocation = ({ clinicId, actTeamId, noActions }: IProps): ReactElement => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const { onGetClientAllocations } = useStoreActions(actions => actions.clientAllocation);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const {
    list: clientsAllocation,
    pagination: { pageSize, pageNumber, total },
  } = useStoreState(state => state.clientAllocation);

  const formMethods = useForm({
    defaultValues: { name: '', sorting: dateSortingOptions[0].value, from: null, to: null },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = formMethods;

  const watchValues = watch();
  const { from, to } = watchValues;

  const onSubmit = (vals: IParams) =>
    onGetClientAllocations({
      clinicId,
      teamId: actTeamId,
      params: { pageSize, pageNumber, ...vals },
    });

  const handlePagination = (pSize: number, pNumber: number) =>
    onGetClientAllocations({
      clinicId,
      teamId: actTeamId,
      params: { pageSize: pSize, pageNumber: pNumber, ...watchValues },
    });

  const onMount = async () => {
    try {
      const payload = {
        clinicId,
        teamId: actTeamId,
        params: { pageSize, pageNumber: 1, sorting: dateSortingOptions[0].value },
      };
      setLoading(true);
      await onGetClientAllocations(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  register={register}
                  placeholder="Search allocation"
                  name="name"
                  errors={errors}
                  startAdornment={<SearchIcon color="primary" />}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={dateSortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <DatePicker
                  label="From"
                  control={control}
                  name="from"
                  errors={errors}
                  maxDate={to}
                />
              </Grid>
              <Grid item sm={2}>
                <DatePicker label="To" control={control} name="to" errors={errors} minDate={from} />
              </Grid>
            </Grid>
            <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Allocation Name</TableCell>
            <TableCell>Date</TableCell>
            {!noActions && <TableCell align="right">Actions</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {clientsAllocation.map(clientAllocation => (
            <TableRow key={clientAllocation.id}>
              <TableCell>
                <Link
                  to={VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                    ':clientAllocationId',
                    String(clientAllocation.id),
                  )}
                  className={styles.linkView}
                >
                  {clientAllocation.name}
                </Link>
              </TableCell>
              <TableCell>{isoToLocal(clientAllocation.date)}</TableCell>
              {!noActions && (
                <>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        push(
                          EDIT_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                            ':clientAllocationId',
                            String(clientAllocation.id),
                          ),
                        )
                      }
                    >
                      <img src={editIcon} alt="editIcon" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <span
                      role="presentation"
                      onClick={() =>
                        push(
                          DUPLICATE_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                            ':clientAllocationId',
                            String(clientAllocation.id),
                          ),
                        )
                      }
                      className={styles.duplicateButton}
                    >
                      Duplicate
                    </span>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        onPageChange={(_, pNumber) => handlePagination(pageSize, pNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pageNumber - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default ClientAllocation;
