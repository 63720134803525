/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import Loader from '~/ui/components/common/Loader';
import InputMask from '~/ui/components/inputs/InputMask';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import Button from '~/ui/components/common/Button';

import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import validate from './validate';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import { IRequestInfo } from '../../HealthDetails/Forms/types';
import { IInitialValues } from './types';
import styles from '../SupportContacts.module.scss';

interface IOptions {
  states: IOption[];
  relationships: IOption[];
}

interface IProps {
  defaultValues: IInitialValues;
  onClose: () => void;
  formType: 'create' | 'edit';
  requestInfo: IRequestInfo;
  supportContactId: number;
}

const Form = ({
  onClose,
  formType,
  defaultValues,
  requestInfo,
  supportContactId,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOptions>(null);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onCreateSupportContact, onUpdateSupportContact } = useStoreActions(
    actions => actions.clientDetails,
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IInitialValues>({ defaultValues, resolver: validate });

  const onMount = async () => {
    try {
      setLoading(true);
      const [states, relationships] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Relationship)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ states, relationships });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (vals: IInitialValues) => {
    try {
      if (formType === 'create') {
        const payload = {
          requestInfo,
          requestPayload: vals,
        };
        await onCreateSupportContact(payload);
      } else {
        const payload = {
          requestInfo: { id: String(supportContactId), ...requestInfo },
          requestPayload: vals,
        };
        await onUpdateSupportContact(payload);
      }

      showNotify(`Collateral contact successfully ${formType}d`);
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const formTitle = formType === 'create' ? 'Add Collateral Contact' : 'Edit Collateral Contact';

  if (loading || !options) return <Loader />;

  return (
    <Box p={3}>
      <h3 className={styles.center}>{formTitle}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Input
              register={register}
              name="firstName"
              errors={errors}
              label="First Name"
              autoFocus
              tabIndex={1}
            />
          </Grid>
          <Grid item sm={6}>
            <Input
              register={register}
              name="address.city"
              errors={errors}
              label="City"
              tabIndex={6}
            />
          </Grid>
          <Grid item sm={6}>
            <Input
              register={register}
              name="lastName"
              errors={errors}
              label="Last Name"
              tabIndex={2}
            />
          </Grid>
          <Grid item sm={6}>
            <Input
              register={register}
              name="address.line1"
              errors={errors}
              label="Address 1"
              tabIndex={7}
            />
          </Grid>
          <Grid item sm={6}>
            <InputMask
              alwaysShowMask={false}
              name="phone"
              control={control}
              errors={errors}
              label="Phone"
              tabIndex={3}
            />
          </Grid>
          <Grid item sm={6}>
            <Input
              register={register}
              name="address.line2"
              errors={errors}
              label="Address 2"
              tabIndex={8}
            />
          </Grid>
          <Grid item sm={6}>
            <Input register={register} name="email" errors={errors} label="Email" tabIndex={4} />
          </Grid>
          <Grid item sm={3}>
            <Select
              isRelativeWindow
              maxMenuHeight={170}
              options={options.states}
              name="address.stateId"
              control={control}
              errors={errors}
              label="State"
              tabIndex={9}
            />
          </Grid>
          <Grid item sm={3}>
            <InputMask
              alwaysShowMask={false}
              mask="99999"
              name="address.zipCode"
              control={control}
              errors={errors}
              label="Zip Code"
              tabIndex={10}
            />
          </Grid>
          <Grid item sm={6}>
            <Select
              isRelativeWindow
              maxMenuHeight={170}
              options={options.relationships}
              name="relationshipId"
              control={control}
              errors={errors}
              label="Relationship"
              tabIndex={5}
            />
          </Grid>
        </Grid>
        <div className={styles.buttonsWrapper}>
          <Button
            variant="outlined"
            color="primary"
            className={styles.margin}
            onClick={onClose}
            tabIndex={11}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" tabIndex={12}>
            Save
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default Form;
