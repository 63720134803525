import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetActTeamMember } from '../types';

// @ts-ignore
const onGetActTeamMember: IThunkGetActTeamMember = thunk(async ({ setActTeamMember }, payload) => {
  const { clinicId, actTeamId, teamMemberId } = payload;

  const { data } = await api.actTeamMember.getActTeamMember(clinicId, actTeamId, teamMemberId);

  setActTeamMember(data);
});

export default onGetActTeamMember;
