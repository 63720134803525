import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Table from './Table';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import { extractErrorMessage } from '~/utils/error';

type IParams = {
  id: string;
};

const ActTeam = (): ReactElement => {
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [isArchiveActTeam, setisArchiveActTeam] = useState(null);
  const [actTeam, setActTeam] = useState(null);

  const { list, pagination } = useStoreState(state => state.actTeam);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onGetClinicActTeams, onArchiveActTeam, onRestoreActTeam } = useStoreActions(
    actions => actions.actTeam,
  );

  const onMount = async () => {
    onGetClinicActTeams({
      clinicId: id,
      params: { pageNumber: 1, pageSize: pagination.pageSize },
    });
  };

  useEffect(() => {
    onMount();
  }, []);

  const confirmText = isArchiveActTeam ? 'Archive' : 'Restore';
  let description = isArchiveActTeam
    ? 'Are you sure you want to archive “actTeamName” ACT Team? All team members will be archived as well. '
    : 'Are you sure you want to restore “actTeamName” ACT Team?';

  description = description.replace('actTeamName', actTeam?.name);

  const onConfrimMethod = isArchiveActTeam ? onArchiveActTeam : onRestoreActTeam;

  const onConfirm = async (actTeamId: string) => {
    try {
      setLoading(true);
      await onConfrimMethod({ clinicId: id, actTeamId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
      setActTeam(null);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Table
          setActTeam={setActTeam}
          setisArchiveActTeam={setisArchiveActTeam}
          onGetActTeams={onGetClinicActTeams}
          pagination={pagination}
          actTeams={list}
          clinicId={id}
        />
      )}
      {actTeam && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          onConfirm={() => onConfirm(actTeam.id)}
          onClose={() => setActTeam(null)}
        />
      )}
    </div>
  );
};

export default ActTeam;
