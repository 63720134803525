import { ReactElement } from 'react';

import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';

import styles from './ConfirmModal.module.scss';

interface IProps {
  description?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  confirmText?: string;
}

const ConfirmModal = ({
  description,
  isLoading = false,
  confirmText = 'Discard',
  onClose,
  onConfirm,
}: IProps): ReactElement => {
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <Modal open className={styles.modal} onClose={handleClose}>
      <div className={styles.inner}>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={styles.btnCancel}
            disabled={isLoading}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
