import { string } from 'yup';
import {
  requiredField,
  phoneInvalid,
  zipCodeInvalid,
  fieldMax,
  validEmail,
} from '~/ui/constants/errorMessages';

const emailDomainRegexp = /^@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const validateLengthField = (val: string): boolean =>
  typeof val === 'string' && val.length < 100;

const validatePhone = (val: string) => {
  const strOnlyNumbers = typeof val === 'string' && val.replace(/[-_]/g, '');
  return strOnlyNumbers.length === 10;
};

const validateZip = (val: string) => {
  const strOnlyNumbers = typeof val === 'string' && val.replace('_', '');
  return strOnlyNumbers.length === 5;
};

export const phone = string().required(requiredField).test('length', phoneInvalid, validatePhone);

export const zipCode = string().required(requiredField).test('length', zipCodeInvalid, validateZip);

export const name = string().required(requiredField).test('length', fieldMax, validateLengthField);

export const validateEmailDomain = (emailDomain: string): boolean =>
  !!emailDomain.match(emailDomainRegexp);

interface IThis {
  parent: Record<string, string>;
}

export const validateEmail = (email: string, emailDomain: string) =>
  async function buildAndValidateEmail(this: IThis): Promise<boolean> {
    const $email = this.parent[email];
    const $emailDomain = this.parent[emailDomain];

    const buildEmail = `${$email}${$emailDomain}`;

    const isValid = await string().email(validEmail).isValid(buildEmail);

    return isValid;
  };

export const validateDuration = (value: string, type: 'min' | 'max'): boolean => {
  const [hours, minutes] = value.split(':');
  const total = Number(hours) * 60 + Number(minutes);
  if (type === 'min') {
    return total >= 1;
  }
  return total <= 8 * 60;
};
