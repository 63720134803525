import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTypeList } from '../types';

// @ts-ignore
const onGetTypeList: IThunkGetTypeList = thunk(async ({ setTypeList }, payload) => {
  const { type, params } = payload;

  const { items, total } = await api.dictionaries.getTypeList(type, params).then(r => r.data);

  const pagination = { ...params, total };

  setTypeList({ items, pagination });
});

export default onGetTypeList;
