import { TypeOfVisit } from '../constants/filterOptions';

export const getTypeString = (type: string): string => {
  const typeNumber = Object.keys(TypeOfVisit)
    .filter(x => Number.isNaN(Number(x)))
    .map(t => t.replace(' ', ''))
    .indexOf(type);
  return TypeOfVisit[typeNumber];
};

export default {};
