import { red } from '~/ui/constants/colors';

const customStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: red,
    backgroundColor: '#FFFBFA',
    border: `1px solid ${red}`,
    borderRadius: 25,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#323232',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    ':hover': {
      cursor: 'pointer',
    },
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 10000,
  }),
};

export default customStyles;
