import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';

import { validateLengthField, phone, zipCode } from '~/ui/configs/form/validation';
import { requiredField, fieldMax } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  name: string().required(requiredField).test('length', fieldMax, validateLengthField),
  phone,
  url: string().required(requiredField).url('Enter valid URL'),
  ehrId: number().required(requiredField).typeError(requiredField),
  address: object().shape({
    line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
    line2: string().test('length', fieldMax, validateLengthField),
    city: string().required(requiredField).test('length', fieldMax, validateLengthField),
    stateId: number().required(requiredField).typeError(requiredField),
    zipCode,
  }),
  programSupervisor: object().shape({
    firstName: string().required(requiredField).test('length', fieldMax, validateLengthField),
    lastName: string().required(requiredField).test('length', fieldMax, validateLengthField),
    email: string()
      .required(requiredField)
      .email('Enter valid email')
      .test('length', fieldMax, validateLengthField),
    phone,
  }),
});

export default yupResolver(validationSchema);
