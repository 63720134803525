import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import api from '~/services/api';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import validate from './validate';
import { useStoreActions } from '~/store/hooks';

import { IOption } from '~/ui/components/inputs/Select/types';
import { extractErrorMessage } from '~/utils/error';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import styles from './Form.module.scss';

interface IInitialValues {
  name?: string;
  typeId?: number;
  clinicId?: string;
}

interface IProps {
  isEdit?: boolean;
  defaultValues?: IInitialValues;
  clinicSelectOptions: IOption[];
  isProcessing: boolean;
  leavePageDescription: string;
  cancelLinkTo: any;
  onSubmit: (val: IInitialValues) => void;
}

const Form = ({
  isEdit,
  defaultValues,
  clinicSelectOptions,
  isProcessing,
  cancelLinkTo,
  leavePageDescription,
  onSubmit,
}: IProps): ReactElement => {
  const [typeOfTeamOptions, setTypeOfTeamOptions] = useState<IOption[]>([]);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    register,
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({ defaultValues, resolver: validate, mode: 'onBlur' });

  const onMount = async () => {
    try {
      const { data } = await api.dictionaries.getAvailableTypeList(IDictionaryTypes.TeamType);
      setTypeOfTeamOptions(formatDictionaryOptions(data));
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Box sx={{ p: 2 }}>
          <h5>ACT Team Details</h5>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Select
                options={clinicSelectOptions || []}
                control={control}
                name="clinicId"
                errors={errors}
                label="Clinic"
                isDisabled={isEdit}
              />
            </Grid>
            <Grid item sm={6} />
            <Grid item sm={6}>
              <Input register={register} errors={errors} name="name" label="Team Name" />
            </Grid>
            <Grid item sm={6} />
            <Grid item sm={6}>
              <Select
                options={typeOfTeamOptions}
                control={control}
                name="typeId"
                errors={errors}
                label="Type of Team"
              />
            </Grid>
          </Grid>
          <div className={styles.buttonsWrapper}>
            <div className={styles.button}>
              <Link to={cancelLinkTo} className={styles.link}>
                <Button type="button" color="primary" variant="outlined">
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isProcessing}
              isLoading={isProcessing}
            >
              Save
            </Button>
          </div>
        </Box>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </Paper>
  );
};

export default Form;
