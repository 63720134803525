import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { validateLengthField, phone, name, validateEmail } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, validEmail } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  firstName: name,
  lastName: name,
  phone,
  email: string()
    .required(requiredField)
    .test('length', fieldMax, validateLengthField)
    .test(
      'Build and validate email from email prefix and email domain',
      validEmail,
      validateEmail('email', 'emailDomain'),
    ),
});

export default yupResolver(validationSchema);
