import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClinicActTeam } from '../types';

// @ts-ignore
const onGetClinicActTeam: IThunkGetClinicActTeam = thunk(async ({ setActTeam }, payload) => {
  const { clinicId, actTeamId } = payload;
  const { data } = await api.actTeam.getClinicActTeam(clinicId, actTeamId);

  setActTeam(data);
});

export default onGetClinicActTeam;
