import { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';

import Loader from './components/common/Loader';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import NotFound from './pages/NotFoundPage';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Profile from './pages/Profile';
import EditProfile from './pages/Profile/EditProfile';
import ClinicManagement from './pages/Clinics/ClinicManagement';
import AddClinic from './pages/Clinics/AddClinic';
import EditClinic from './pages/Clinics/EditClinic';
import ViewClinic from './pages/Clinics/ViewClinic';
import MyClinic from './pages/Clinics/MyClinic';
import ClinicActTeam from './pages/Clinics/ViewClinic/ActTeam/ViewActTeam';
import ClinicEditActTeam from './pages/Clinics/ViewClinic/ActTeam/EditActTeam';
import ClinicCreateActTeam from './pages/Clinics/ViewClinic/ActTeam/CreateActTeam';
import ClinicAddLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/AddLocalAdmin';
import ClinicEditLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/EditLocalAdmin';
import ViewLocalAdmin from './pages/Clinics/ViewClinic/LocalAdmin/ViewLocalAdmin';
import ActTeamManagement from './pages/ActTeam/SuperAdmin/ActTeamManagement';
import ActTeamManagementLocalAdmin from './pages/ActTeam/LocalAdmin/ActTeamManagement';
import ViewActTeamLocalAdmin from './pages/ActTeam/LocalAdmin/ViewActTeam';
import ViewTeamMemberProfile from './pages/ActTeam/reusable/TeamMemberProfile';
import AddActTeam from './pages/ActTeam/SuperAdmin/AddActTeam';
import EditTeamMember from './pages/ActTeam/LocalAdmin/EditTeamMember';
import AddTeamMember from './pages/ActTeam/LocalAdmin/AddTeamMember';
import Dictionaries from './pages/Dictionaries';
import ClientsManagement from './pages/Clients/LocalAdmin/ClientsManagement';
import MyClients from './pages/Clients/ActTeam/MyClients';
import MyActTeam from './pages/ActTeam/ActTeam/MyActTeam';
import AddClient from './pages/Clients/LocalAdmin/AddClient';
import EditClient from './pages/Clients/LocalAdmin/EditClient';
import ClientProfile from './pages/Clients/LocalAdmin/ClientProfile';
import AddClientAllocation from './pages/ActTeam/LocalAdmin/AddClientAllocation';
import ViewClientAllocation from './pages/ActTeam/LocalAdmin/ViewClientAllocation';
import EditClientAllocation from './pages/ActTeam/LocalAdmin/EditClientAllocation/EditClientAllocation';
import DuplicateClientAllocation from './pages/ActTeam/LocalAdmin/DuplicateClientAllocation';
import MedicationLogistics from './pages/MedicationLogistics';
import Coordinate from './pages/Coordinate';
import SuperAdminsManagement from './pages/SuperAdmins/SuperAdminsManagement';
import AddSuperAdmin from './pages/SuperAdmins/AddSuperAdmin';
import EditSuperAdmin from './pages/SuperAdmins/EditSuperAdmin';
import AddProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/AddProgramAssistant';
import EditProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/EditProgramAssistant';
import ViewProgramAssistant from './pages/Clinics/ViewClinic/ProgramAssistant/ViewProgramAssistant';
import Visits from './pages/Visits';
import AddVisit from './pages/Visits/components/AddVisit';
import PreviewVisit from './pages/Visits/components/PreviewVisit';

import { useStoreState } from '~/store/hooks';

import {
  CLINIC_MANAGEMENT,
  ADD_CLINIC,
  EDIT_CLINIC,
  SIGN_IN,
  SIGN_UP,
  VIEW_CLINIC,
  CLINIC_VIEW_ACT_TEAM,
  CLINIC_EDIT_ACT_TEAM,
  CLINIC_ADD_LOCAL_ADMIN,
  CLINIC_EDIT_LOCAL_ADMIN,
  CLINIC_VIEW_LOCAL_ADMIN,
  CLINIC_CREATE_ACT_TEAM,
  ACT_TEAM_MANAGEMENT,
  ADD_ACT_TEAM,
  FORGOT_PASSWORD,
  PROFILE,
  EDIT_PROFILE,
  MY_CLINIC,
  VIEW_ACT_TEAM,
  EDIT_TEAM_MEMBER,
  ADD_TEAM_MEMBER,
  DICTIONARIES,
  CLIENT,
  ADD_CLIENT,
  EDIT_CLIENT,
  VIEW_CLIENT,
  ADD_CLIENT_ALLOCATION,
  VIEW_CLIENT_ALLOCATION,
  EDIT_CLIENT_ALLOCATION,
  DUPLICATE_CLIENT_ALLOCATION,
  MY_ACT_TEAM,
  MY_CLIENTS,
  SUPER_ADMIN,
  ADD_SUPER_ADMIN,
  EDIT_SUPER_ADMIN,
  VIEW_ACT_TEAM_MEMBER,
  CHANGE_PASSWORD,
  MEDICATION_LOGISTICS,
  COORDINATE,
  CLINIC_EDIT_PROGRAM_ASSISTANT,
  CLINIC_ADD_PROGRAM_ASSISTANT,
  CLINIC_VIEW_PROGRAM_ASSISTANT,
  VISITS,
  ADD_VISIT,
  PREVIEW_VISIT,
  EDIT_VISIT,
} from './constants/paths';

import { IUserRole } from '~/types';

const Routes = (): ReactElement => {
  const { authChecked, current } = useStoreState(state => state.user);

  if (!current?.roles && !authChecked) return <Loader />;

  const isSuperAdmin = current?.roles?.includes(IUserRole.SuperAdmin);
  const isLocalAdmin = current?.roles?.includes(IUserRole.LocalAdmin);
  const isActTeam = current?.roles?.some(role =>
    [IUserRole.ActTeamMember, IUserRole.ActTeamLeader].includes(role),
  );
  const isActTeamLeader = current?.roles?.includes(IUserRole.ActTeamLeader);
  const isProductOwner = current?.roles?.includes(IUserRole.ProductOwner);
  const isProgramAssistant = current?.roles?.includes(IUserRole.ProgramAssistant);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <PublicRoute exact path={SIGN_UP} component={SignUp} />
      <PublicRoute exact path={SIGN_IN} component={SignIn} />
      <PublicRoute exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <PublicRoute exact path={CHANGE_PASSWORD} component={ChangePassword} />
      <PrivateRoute exact path={PROFILE} component={Profile} />
      <PrivateRoute exact path={EDIT_PROFILE} component={EditProfile} />
      {isSuperAdmin || isProductOwner ? (
        <PrivateRoute exact path={CLINIC_MANAGEMENT} component={ClinicManagement} />
      ) : (
        <PrivateRoute exact path={MY_CLINIC} component={MyClinic} />
      )}
      <PrivateRoute exact path={ADD_CLINIC} component={AddClinic} />
      <PrivateRoute exact path={EDIT_CLINIC} component={EditClinic} />
      <PrivateRoute exact path={VIEW_CLINIC} component={ViewClinic} />
      <PrivateRoute exact path={CLINIC_VIEW_ACT_TEAM} component={ClinicActTeam} />
      <PrivateRoute exact path={CLINIC_EDIT_ACT_TEAM} component={ClinicEditActTeam} />
      <PrivateRoute exact path={CLINIC_ADD_LOCAL_ADMIN} component={ClinicAddLocalAdmin} />
      <PrivateRoute exact path={CLINIC_EDIT_LOCAL_ADMIN} component={ClinicEditLocalAdmin} />
      <PrivateRoute exact path={CLINIC_VIEW_LOCAL_ADMIN} component={ViewLocalAdmin} />
      <PrivateRoute exact path={CLINIC_ADD_PROGRAM_ASSISTANT} component={AddProgramAssistant} />
      <PrivateRoute exact path={CLINIC_EDIT_PROGRAM_ASSISTANT} component={EditProgramAssistant} />
      <PrivateRoute exact path={CLINIC_VIEW_PROGRAM_ASSISTANT} component={ViewProgramAssistant} />
      <PrivateRoute exact path={CLINIC_CREATE_ACT_TEAM} component={ClinicCreateActTeam} />
      {(isSuperAdmin || isProductOwner) && (
        <PrivateRoute exact path={ACT_TEAM_MANAGEMENT} component={ActTeamManagement} />
      )}
      {isProductOwner && [
        <PrivateRoute
          exact
          path={SUPER_ADMIN}
          component={SuperAdminsManagement}
          key={SUPER_ADMIN}
        />,
        <PrivateRoute
          exact
          path={ADD_SUPER_ADMIN}
          component={AddSuperAdmin}
          key={ADD_SUPER_ADMIN}
        />,
        <PrivateRoute
          exact
          path={EDIT_SUPER_ADMIN}
          component={EditSuperAdmin}
          key={EDIT_SUPER_ADMIN}
        />,
      ]}
      {(isLocalAdmin || isProgramAssistant) && [
        <PrivateRoute
          exact
          path={ACT_TEAM_MANAGEMENT}
          component={ActTeamManagementLocalAdmin}
          key={ACT_TEAM_MANAGEMENT}
        />,
        <PrivateRoute
          exact
          path={VIEW_ACT_TEAM}
          component={ViewActTeamLocalAdmin}
          key={VIEW_ACT_TEAM}
        />,
        <PrivateRoute
          exact
          path={EDIT_TEAM_MEMBER}
          component={EditTeamMember}
          key={EDIT_TEAM_MEMBER}
        />,
        <PrivateRoute
          exact
          path={ADD_TEAM_MEMBER}
          component={AddTeamMember}
          key={ADD_TEAM_MEMBER}
        />,
        <PrivateRoute exact path={CLIENT} component={ClientsManagement} key={CLIENT} />,
        <PrivateRoute exact path={ADD_CLIENT} component={AddClient} key={ADD_CLIENT} />,
        <PrivateRoute exact path={EDIT_CLIENT} component={EditClient} key={EDIT_CLIENT} />,
        <PrivateRoute exact path={VIEW_CLIENT} component={ClientProfile} key={VIEW_CLIENT} />,
        <PrivateRoute
          exact
          path={VIEW_ACT_TEAM_MEMBER}
          component={ViewTeamMemberProfile}
          key={VIEW_ACT_TEAM_MEMBER}
        />,
      ]}
      {(isLocalAdmin || isProgramAssistant || isActTeamLeader) && [
        <PrivateRoute
          exact
          path={ADD_CLIENT_ALLOCATION}
          component={AddClientAllocation}
          key={ADD_CLIENT_ALLOCATION}
        />,
        <PrivateRoute
          exact
          path={VIEW_CLIENT_ALLOCATION}
          component={ViewClientAllocation}
          key={VIEW_CLIENT_ALLOCATION}
        />,
        <PrivateRoute
          exact
          path={EDIT_CLIENT_ALLOCATION}
          component={EditClientAllocation}
          key={EDIT_CLIENT_ALLOCATION}
        />,
        <PrivateRoute
          exact
          path={DUPLICATE_CLIENT_ALLOCATION}
          component={DuplicateClientAllocation}
          key={DUPLICATE_CLIENT_ALLOCATION}
        />,
      ]}
      {isActTeam && [
        <PrivateRoute exact path={MY_ACT_TEAM} component={MyActTeam} key={MY_ACT_TEAM} />,
        <PrivateRoute exact path={MY_CLIENTS} component={MyClients} key={MY_CLIENTS} />,
        <PrivateRoute exact path={VIEW_CLIENT} component={ClientProfile} key={VIEW_CLIENT} />,
        <PrivateRoute
          exact
          path={VIEW_ACT_TEAM_MEMBER}
          component={ViewTeamMemberProfile}
          key={VIEW_ACT_TEAM_MEMBER}
        />,
      ]}
      {(isLocalAdmin || isActTeam || isProgramAssistant) && [
        <PrivateRoute
          exact
          path={MEDICATION_LOGISTICS}
          component={MedicationLogistics}
          key={MEDICATION_LOGISTICS}
        />,
        <PrivateRoute exact path={VISITS} component={Visits} key={VISITS} />,
        <PrivateRoute exact path={ADD_VISIT} component={AddVisit} key={ADD_VISIT} />,
        <PrivateRoute exact path={EDIT_VISIT} component={AddVisit} key={EDIT_VISIT} />,
        <PrivateRoute exact path={PREVIEW_VISIT} component={PreviewVisit} key={PREVIEW_VISIT} />,
      ]}
      {false /* isActTeam TODO revert back coordinate once it is been tested */ && [
        <PrivateRoute exact path={COORDINATE} component={Coordinate} key={COORDINATE} />,
      ]}
      <PrivateRoute exact path={ADD_ACT_TEAM} component={AddActTeam} />
      <PrivateRoute exact path={DICTIONARIES} component={Dictionaries} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
