import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreClinic, IClinicStore } from '../types';

const onRestoreClinic: IThunkRestoreClinic = thunk(
  // @ts-ignore
  async ({ setClinics }, clinicId, { getState }) => {
    const localState = getState() as IClinicStore;
    await api.clinic.restoreClinic(clinicId);

    const updatedList = localState.list.map(clinic =>
      clinic.id !== clinicId ? clinic : { ...clinic, isArchived: false },
    );

    setClinics({ items: updatedList });
  },
);

export default onRestoreClinic;
