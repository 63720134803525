import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { concat } from 'lodash';

import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { isFile } from '~/utils/file';
import { extractErrorMessage } from '~/utils/error';
import { formatDefaultValues } from '~/utils/formatDefaultValues';

import { CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';
import { IProgramAssistantForm } from '~/services/api/programAssistant/types';

type IParams = {
  id: string;
  programAssistantId: string;
};

const breadcrumbsItem = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

const EditProgramAssistant = (): ReactElement => {
  const { id, programAssistantId } = useParams<IParams>();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [isProgramAssistantAdding, setIsProgramAssistantAdding] = useState(false);

  const currentClinic = useStoreState(state => state.clinic.current);
  const currentProgramAssistant = useStoreState(state => state.programAssistant.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetCurrentProgramAssistant = useStoreActions(
    actions => actions.programAssistant.onGetCurrentProgramAssistant,
  );

  const onMount = async () => {
    try {
      setLoading(true);
      const promisesArr = [
        onGetCurrentProgramAssistant({
          clinicId: id,
          programAssistantId: Number(programAssistantId),
        }),
      ];
      if (!currentClinic) {
        promisesArr.push(onGetClinic(id));
      }

      await Promise.all(promisesArr);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (!currentClinic || !currentProgramAssistant || loading) return <Loader />;

  const breadcrumbsItems = concat(breadcrumbsItem, [
    {
      title: currentClinic.name,
      to: VIEW_CLINIC.replace(':id', id),
    },
    {
      title: 'Program Assistant',
      to: {
        pathname: VIEW_CLINIC.replace(':id', id),
        state: { selectedTab: 3 },
      },
    },
    {
      title: `Edit ${currentProgramAssistant.firstName} ${currentProgramAssistant.lastName} program assistant `,
      to: pathname,
    },
  ]);

  const isPending = currentProgramAssistant?.status === 'Pending';

  const onSubmit = async (values: IProgramAssistantForm) => {
    setIsProgramAssistantAdding(true);
    try {
      const { email, emailDomain, ...restVals } = values;
      const payload = isPending ? { email: email + emailDomain, ...restVals } : { ...values };

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);

        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

        payload.photo = fileName;
      }

      await api.programAssistant.updateProgramAssistant(id, programAssistantId, payload);
      showNotify('Program Assistant edited successfully');
      push(VIEW_CLINIC.replace(':id', id), { selectedTab: 3 });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsProgramAssistantAdding(false);
    }
  };

  const leavePageDescription = "Are you sure you want to cancel 'Edit Program Assistant'?";
  const cancelLinkTo = {
    pathname: VIEW_CLINIC.replace(':id', id),
    state: { selectedTab: 3 },
  };

  const emailDomainOptions = currentClinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  return (
    <div>
      <h2>Edit Program Assistant</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        defaultValues={formatDefaultValues(currentProgramAssistant)}
        isEdit
        submitText="Save changes"
        isProcessing={isProgramAssistantAdding}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
        isPending={isPending}
        emailDomainOptions={isPending ? emailDomainOptions : undefined}
      />
    </div>
  );
};

export default EditProgramAssistant;
