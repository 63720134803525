import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetMedication } from '../types';

// @ts-ignore
const onGetDeEscalation: IThunkGetMedication = thunk(async ({ setCurrent }, payload) => {
  const current = await api.clientDetails.getClientMedication(payload).then(r => r.data);

  setCurrent(current);
});

export default onGetDeEscalation;
