import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number, array } from 'yup';

import { validateLengthField, phone, zipCode, validateEmail } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, validEmail } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  firstName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  lastName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  primaryRole: string().typeError(requiredField).required(requiredField),
  subRoleIds: array().min(1, requiredField).typeError(requiredField).required(requiredField),
  email: string()
    .required(requiredField)
    .test('length', fieldMax, validateLengthField)
    .test(
      'Build and validate email from email prefix and email domain',
      validEmail,
      validateEmail('email', 'emailDomain'),
    ),
  phone,
  address: object().shape({
    line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
    line2: string().test('length', fieldMax, validateLengthField),
    city: string().required(requiredField).test('length', fieldMax, validateLengthField),
    stateId: number().required(requiredField),
    zipCode,
  }),
  workDetails: object().shape({
    credentialsId: number().required(requiredField),
    employmentStatusId: number().required(requiredField),
    educationId: number().required(requiredField),
    experience: number()
      .typeError(requiredField)
      .required(requiredField)
      .integer('Plese provide integer')
      .moreThan(-1, 'Should be positive or equal zero'),
    hireDate: string().required(requiredField),
  }),
});

export default yupResolver(validationSchema);
