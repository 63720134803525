import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import {
  eachDayOfInterval,
  format,
  isFuture,
  isToday,
  isWeekend,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';

import CalendarCell from '../CalendarCell';
import MedicationInfo from '../MedicationInfo';
import CellContent from '../CellContent';
import getToDate from '../../helpers/getToDate';
import getFromDate from '../../helpers/getFromDate';

import {
  IMedicationDeliveryMedication,
  IMedicationDelivery,
} from '~/services/api/medicationLogistics';
import { IGroupedData } from '../CalendarSection/types';
import arrowForwardRed from '~/ui/assets/images/arrowForwardRed.svg';
import styles from './CalendarRow.module.scss';

interface IProps {
  selectedMonth: Date;
  hidden?: boolean;
  medication?: IMedicationDeliveryMedication;
  medicationDeliveries?: IMedicationDelivery[];
  last?: boolean;
  weekMode: boolean;
  includeWeekends: boolean;
  weekRange: number;
  nextDueDate?: string;
  setWeekRange: Dispatch<SetStateAction<number>>;
  onAdd?: (medicationId: number, date: string, injectable: boolean) => void;
  onEdit?: (id: number, name: string, isArchived?: boolean) => void;
}

const CalendarRow = ({
  selectedMonth,
  hidden,
  medication,
  last,
  weekMode,
  medicationDeliveries,
  includeWeekends,
  weekRange,
  nextDueDate,
  setWeekRange,
  onAdd,
  onEdit,
}: IProps): ReactElement => {
  const daysInterval = useMemo(
    () =>
      eachDayOfInterval({
        start: weekMode ? (getFromDate(selectedMonth, true) as Date) : startOfMonth(selectedMonth),
        end: weekMode ? (getToDate(selectedMonth, true) as Date) : lastDayOfMonth(selectedMonth),
      }),
    [weekMode, selectedMonth],
  );

  const daysIntervalLength = daysInterval.length;

  const maxWeekRange = Math.ceil(daysIntervalLength / 7) - 1;

  const onClickPrev = () => {
    setWeekRange(prev => (prev - 1 <= 0 ? 0 : prev - 1));
  };
  const onClickNext = () => {
    setWeekRange(prev => (prev + 1 > maxWeekRange ? maxWeekRange : prev + 1));
  };

  const days = useMemo(
    () => (weekMode ? daysInterval.slice(7 * weekRange, 7 * weekRange + 7) : daysInterval),
    [daysInterval, weekRange, weekMode, 7],
  );

  const groupedByDayMedicationDeliveries = useMemo(
    () =>
      medicationDeliveries?.reduce((acc: IGroupedData<typeof medicationDeliveries>, obj) => {
        const key = format(new Date(obj.date), 'dd/MM');
        const exist = acc[key];
        if (exist) {
          acc[key] = [...exist, obj];
        } else {
          acc[key] = [obj];
        }
        return acc;
      }, {}),
    [medicationDeliveries],
  );

  return (
    <Box
      className={classNames(styles.calendarRow, {
        [styles.lastRow]: last,
        [styles.firstRow]: !medication,
        [styles.hidden]: hidden,
      })}
    >
      {weekMode && weekRange >= 1 && (
        <Button
          onClick={onClickPrev}
          className={classNames(styles.arrowButton, styles.prev, { [styles.hidden]: !weekMode })}
        >
          <img src={arrowForwardRed} alt="button prev" />
        </Button>
      )}
      <CalendarCell
        weekMode={weekMode}
        first
        key={`calendar-cell-${medication?.id || 'day'}-0`}
        dayNumber={0}
        data={
          medication ? (
            <MedicationInfo medication={medication} nextDueDate={nextDueDate} />
          ) : undefined
        }
      />
      {days.map(item => {
        const deliveries = groupedByDayMedicationDeliveries?.[format(item, 'dd/MM')];
        const weekend = isWeekend(item);
        const today = isToday(item);
        const currentDay = item.toISOString();
        const isInFuture = isFuture(item);
        return (
          <CalendarCell
            weekMode={weekMode}
            weekend={weekend}
            today={today}
            hidden={!includeWeekends && weekend}
            key={`calendar-cell-${medication?.id || 'day'}-${format(item, 'dd-MM')}`}
            dayNumber={medication ? undefined : parseInt(format(item, 'd'), 10)}
            dayOfWeek={medication ? undefined : format(item, 'eee')}
            data={
              deliveries?.length ? (
                <CellContent
                  medicationDeliveries={deliveries}
                  medication={medication}
                  onEdit={onEdit}
                  weekMode={weekMode}
                />
              ) : undefined
            }
            isFuture={isInFuture}
            onClick={() =>
              medication && !medication.isArchived && !isInFuture
                ? onAdd(medication.id, currentDay, medication.injectable)
                : undefined
            }
          />
        );
      })}
      {weekMode && weekRange < maxWeekRange && (
        <Button
          onClick={onClickNext}
          className={classNames(styles.arrowButton, styles.next, { [styles.hidden]: !weekMode })}
        >
          <img src={arrowForwardRed} alt="button next" />
        </Button>
      )}
    </Box>
  );
};

export default CalendarRow;
