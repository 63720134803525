import { thunk } from 'easy-peasy';
import { IMedicationLogisticsStore, IThunkUpdateMedicationDelivery } from '../types';
import api from '~/services/api';

const onUpdateMedicationDelivery: IThunkUpdateMedicationDelivery = thunk(
  // @ts-ignore
  async ({ setMedicationDeliveries }, payload, { getState }) => {
    const localState = getState() as IMedicationLogisticsStore;
    const { requestInfo, requestPayload } = payload;

    const { data } = await api.medicationLogistics.updateMedicationDelivery(
      requestInfo,
      requestPayload,
    );
    const deliveries = localState.list.map(item => (item.medication.id !== data.id ? item : data));

    setMedicationDeliveries({ deliveries, monitoredBy: localState.monitoredBy });
  },
);

export default onUpdateMedicationDelivery;
