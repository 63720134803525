import { ReactElement, MouseEvent } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { parseISO, isValid } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import get from 'lodash/get';

import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';
import styles from './DatePicker.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  dateFormat?: string;
  size?: 'medium' | 'small';
  tabIndex?: number;
}

const DatePickerComponent = ({
  control,
  errors,
  name,
  minDate,
  maxDate,
  label = '',
  placeholder = '',
  disabled = false,
  dateFormat = 'MM/dd/yyyy',
  size = 'small',
  tabIndex,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const handleChange = (date: [Date, Date] | Date) => {
    if (Array.isArray(date) || !isValid(date)) return '';

    return date.toISOString();
  };

  const handleClear = (
    e: MouseEvent<HTMLButtonElement>,
    onChange: (...event: unknown[]) => void,
  ) => {
    e.stopPropagation();
    onChange(null);
  };

  const formatValue = (val: any) => {
    const date = parseISO(val);

    if (!isValid(date)) return null;
    return date;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <>
          <FormControl variant="outlined" size={size} fullWidth>
            {label && (
              <InputLabel shrink error={isError}>
                {label}
              </InputLabel>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                InputProps={{
                  tabIndex,
                  endAdornment: <CalendarIcon />,
                }}
                error={!!error}
                openTo="year"
                views={['year', 'month', 'date']}
                fullWidth
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
                inputVariant="outlined"
                size={size}
                format={dateFormat}
                value={formatValue(value)}
                onChange={val => onChange(handleChange(val))}
                disabled={disabled}
                placeholder={placeholder}
              />
            </MuiPickersUtilsProvider>

            {error && (
              <FormHelperText error className={styles.errorText}>
                {error}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
};

export default DatePickerComponent;
