import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { setSendbirdData, setTokens } from '~/services/auth';

import { IThunkLogin } from '../types';

// @ts-ignore
const onLogin: IThunkLogin = thunk(async ({ setUser, setAuthorized }, payload) => {
  const { accessToken, refreshToken } = await api.auth
    .login(payload)
    .then(response => response.data);
  setTokens({ accessToken, refreshToken });
  const user = await api.auth.getCurrentUser().then(response => response.data);
  setUser(user);
  const { data } = await api.auth.fetchSendbirdToken();
  setSendbirdData(data);
  setAuthorized({ authorized: true });
});

export default onLogin;
