import { ReactElement, useEffect, useState } from 'react';
import Select, { ValueType, components } from 'react-select';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

import SelectAll from './components/SelectAll';
import Option from './components/Option';
import MenuList from './components/MenuList';
import { customStylesAdditionally } from './customStylesAdditionally';

import { IOption } from '../Select/types';
import customStyles from '../Select/customStyles';
import styles from '../Select/Select.module.scss';

type IValue = string | number;
type IsMulti = boolean;

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  isMulti?: boolean;
  placeholder?: string;
  color?: string;
  minHeight?: string;
  isRelativeWindow?: boolean;
  hideSelectedOptions?: boolean;
  additionalStyleHandler?: () => any;
  showCustomComponents?: boolean;
  closeMenuOnSelect?: boolean;
  showSelectAll?: boolean;
  setAll?: (all?: boolean) => void;
}

const SelectComponent = ({
  control,
  errors,
  name,
  options,
  label,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  maxMenuHeight = 200,
  placeholder = '',
  color = 'black',
  minHeight,
  isRelativeWindow = true,
  hideSelectedOptions = true,
  showCustomComponents = false,
  closeMenuOnSelect = true,
  showSelectAll = false,
  additionalStyleHandler = () => {},
  setAll,
}: IProps): ReactElement => {
  const [selectAll, setSelectAll] = useState(false);
  const [skip, setSkip] = useState(true);
  const [trigger, setTrigger] = useState(false);
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const formatValue = (option: ValueType<IOption | IOption[], IsMulti>) => {
    const value = isMulti
      ? (option as IOption[]).map((item: IOption) => item.value)
      : (option as IOption)?.value ?? '';
    return value;
  };

  const getValue = (value: IValue | IValue[]): IOption | IOption[] => {
    if (options) {
      return isMulti && Array.isArray(value)
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value) || ('' as any);
    }

    return isMulti ? [] : ('' as any);
  };

  const handleChange = (option: ValueType<IOption | IOption[], IsMulti>) => {
    if (Array.isArray(option)) {
      if (option.length === options.length) {
        setSelectAll(true);
        return formatValue(option);
      }
      if (option.length === 0) {
        setSelectAll(false);
      } else {
        setSkip(true);
        setTrigger(!trigger);
        setSelectAll(false);
      }
    }
    return formatValue(option);
  };

  const mergedStyles = {
    ...customStyles,
    ...customStylesAdditionally(options, maxMenuHeight, error, minHeight, isRelativeWindow, color),
    ...additionalStyleHandler(),
  };
  const labelStyles = classNames(styles.label, { [styles.labelError]: error });

  // Selecting custom dropdown header component
  const MenuHeader = showSelectAll
    ? (optionProps: any) => (
        <SelectAll {...optionProps} selectAll={selectAll} setSelectAll={setSelectAll} />
      )
    : MenuList;

  useEffect(() => {
    if (skip) {
      setSkip(false);
      return;
    }
    if (selectAll) setAll(true);
    else setAll();
  }, [selectAll, trigger]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={labelStyles} error={isError}>
            {label}
          </FormLabel>
          <Select
            tabSelectsValue={false}
            instanceId={name}
            styles={mergedStyles}
            isDisabled={isDisabled}
            closeMenuOnSelect={closeMenuOnSelect}
            isLoading={isLoading}
            isSearchable={isSearchable}
            hideSelectedOptions={hideSelectedOptions}
            maxMenuHeight={maxMenuHeight}
            name={name}
            components={{
              Option: showCustomComponents ? Option : components.Option,
              MenuList: showCustomComponents ? MenuHeader : components.MenuList,
            }}
            menuShouldScrollIntoView
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            value={getValue(value)}
            // @ts-ignore
            onChange={val => onChange(handleChange(val))}
            onBlur={() => onBlur()}
            menuPortalTarget={document.body}
          />
          {error && (
            <FormHelperText error className={styles.error}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
