import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClinics } from '../types';

// @ts-ignore
const onGetClinics: IThunkGetClinics = thunk(async ({ setClinics }, payload) => {
  const { items, total } = await api.clinic.getClinics(payload).then(r => r.data);
  const pagination = { ...payload, total };

  setClinics({ items, pagination });
});

export default onGetClinics;
