import onLogin from './onLogin';
import onLogout from './onLogout';
import onCheckAuthorization from './onCheckAuthorization';
import onUploadAvatar from './onUploadAvatar';
import onRemoveAvatar from './onRemoveAvatar';
import onGetSendbirdData from './onGetSendbirdData';

export default {
  onCheckAuthorization,
  onLogin,
  onLogout,
  onUploadAvatar,
  onRemoveAvatar,
  onGetSendbirdData,
};
