import { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useQueryParam from '~/ui/pages/MedicationLogistics/hooks/useQueryParam';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';

import { formatActTeamsOptions } from '~/utils/formatActTeamsOptions';
import { extractErrorMessage } from '~/utils/error';

import SimpleSelect from '~/ui/pages/MedicationLogistics/components/SimpleSelect';
import Button from '~/ui/components/common/Button';
import ClientInfo from '~/ui/pages/MedicationLogistics/components/ClientInfo';

import { IUserRole } from '~/types';
import { ADD_VISIT } from '~/ui/constants/paths';

import styles from './VisitsHeader.module.scss';

const VisitsHeader = (): ReactElement => {
  const [clientLoading, setClientLoading] = useState<boolean>(true);
  const { push } = useHistory();

  const query = useQuery();
  const clientId = query.get('clientId');
  const teamId = query.get('teamId');

  const [selectedActTeam, setSelectedActTeam] = useState<number | null>(
    teamId ? Number(teamId) : null,
  );

  const { current } = useStoreState(state => state.user);
  const { availableActTeams } = useStoreState(state => state.actTeam);
  const { availableClients } = useStoreState(state => state.client);
  const { onGetActTeamsAvailable } = useStoreActions(action => action.actTeam);
  const { onGetAvailableClients, onGetClient, setAvailableClients } = useStoreActions(
    action => action.client,
  );
  const { showError } = useStoreActions(action => action.snackbar);

  const showTeamSelect =
    current?.roles.includes(IUserRole.LocalAdmin) ||
    current?.roles.includes(IUserRole.ProgramAssistant);

  const redirectToAddVisit = (): void => {
    push(ADD_VISIT.concat(`?teamId=${selectedActTeam}`).concat(`&clientId=${clientId}`));
  };

  const selectClient = async (id: number) => {
    if (id) {
      try {
        setClientLoading(true);
        await onGetClient({
          clinicId: String(current?.clinic.id),
          teamId: String(selectedActTeam),
          clientId: String(id),
        });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setClientLoading(false);
      }
    }
  };
  const onTeamSelect = async () => {
    if (selectedActTeam) {
      await onGetAvailableClients({
        clinicId: String(current?.clinic?.id),
        teamId: String(selectedActTeam),
      });
    }
  };

  const onMount = async (): Promise<void> => {
    if (showTeamSelect) {
      onGetActTeamsAvailable({ clinicId: String(current?.clinic?.id) });
    } else {
      setSelectedActTeam(current?.team?.id);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    setAvailableClients([]);
    onTeamSelect();
  }, [selectedActTeam]);

  useQueryParam('teamId', selectedActTeam ? String(selectedActTeam) : teamId);
  return (
    <Grid container spacing={2}>
      <Grid container item sm={12}>
        <Grid item sm={10}>
          <h2>Visits</h2>
        </Grid>
        {showTeamSelect && (
          <Grid item sm={10}>
            <SimpleSelect
              label="Assigned ACT Team"
              value={selectedActTeam}
              setValue={setSelectedActTeam}
              options={formatActTeamsOptions(availableActTeams)}
            />
          </Grid>
        )}
        {selectedActTeam && !!availableClients.length && (
          <Grid item sm={2} className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                redirectToAddVisit();
              }}
            >
              Add Visit
            </Button>
          </Grid>
        )}
      </Grid>

      {selectedActTeam && !!availableClients.length && (
        <Grid item sm={12}>
          <ClientInfo
            loading={clientLoading}
            selectClient={selectClient}
            clientId={availableClients.find(c => c.id === parseInt(clientId, 10))?.id || null}
            onVisitsPage
          />
        </Grid>
      )}
    </Grid>
  );
};

export default VisitsHeader;
