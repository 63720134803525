import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetActTeamMembers } from '../types';

const onGetActTeamMembers: IThunkGetActTeamMembers = thunk(
  // @ts-ignore
  async ({ setActTeamMembers }, payload) => {
    const { clinicId, actTeamId, params } = payload;

    const { items, total } = await api.actTeamMember
      .getActTeamMembers(clinicId, actTeamId, params)
      .then(r => r.data);
    const pagination = { ...params, total };

    setActTeamMembers({ items, pagination });
  },
);

export default onGetActTeamMembers;
