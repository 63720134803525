import { ReactElement, useEffect, useState } from 'react';

import Table from '../../reusable/Table';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import styles from './ActTeamManagement.module.scss';

const ActTeamManagement = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const clinic = useStoreState(state => state.clinic.current);
  const user = useStoreState(state => state.user.current);
  const { list, pagination } = useStoreState(state => state.actTeam);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeams = useStoreActions(actions => actions.actTeam.onGetClinicActTeams);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      setLoading(true);
      await Promise.all([
        onGetMyClinic(),
        onGetClinicActTeams({
          clinicId: String(user.clinic.id),
          params: { pageNumber: 1, pageSize: pagination.pageSize },
        }),
      ]);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !clinic) return <Loader />;

  return (
    <div>
      <div className={styles.header}>
        <h2>{clinic.name} ACT Teams</h2>
      </div>
      <Table
        isLocalAdmin
        actTeams={list}
        pagination={pagination}
        onGetAllActTeams={params =>
          onGetClinicActTeams({ clinicId: String(user.clinic.id), params })
        }
      />
    </div>
  );
};

export default ActTeamManagement;
