import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateSupportContact, IClientDetailsStore } from '../types';

const onUpdateSupportContact: IThunkUpdateSupportContact = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { requestInfo, requestPayload } = payload;

    const updatedSupportContact = await api.clientDetails
      .updateSupportContact(requestInfo, requestPayload)
      .then(r => r.data);

    const updatedList = localState.supportContacts.map(item =>
      item.id !== updatedSupportContact.id ? item : updatedSupportContact,
    );

    setClientDetails({ supportContacts: updatedList });
  },
);

export default onUpdateSupportContact;
