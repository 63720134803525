import { privateAxios } from '../requestsCollection';
import { IResponse, IParams } from '../types';
import { IActTeamMember, IActTeamMemberAvailableList } from './types';
import { ICreateUserRequest } from '../users/types';

export const getActTeamMembers = (
  clinicId: string,
  actTeamId: string,
  params: IParams,
): IResponse<{ items: IActTeamMember[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `/clinic/${clinicId}/act-team/${actTeamId}/team-member/list`,
    params,
  });

export const getActTeamMemberList = (
  clinicId: string,
  teamId: string,
): IResponse<IActTeamMemberAvailableList[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/team-member/available-list`,
  });

export const getActTeamMember = (
  clinicId: string,
  actTeamId: string,
  teamMemberId: string,
): IResponse<IActTeamMember> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}`,
  });

export const updateTeamMember = (
  clinicId: string,
  actTeamId: string,
  teamMemberId: string,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}`,
    data,
  });

export const createTeamMember = (
  clinicId: number,
  actTeamId: number,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member`,
    data,
  });

export const createTeamLeader = (
  clinicId: number,
  actTeamId: number,
  data: ICreateUserRequest,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-lead`,
    data,
  });

export const unlockTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/unlock-account`,
  });

export const resendInvitation = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/resend-invitation`,
  });

export const activateTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/activate`,
  });

export const deactivateTeamMember = (
  clinicId: number,
  actTeamId: number,
  teamMemberId: number,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${actTeamId}/team-member/${teamMemberId}/deactivate`,
  });

export default {};
