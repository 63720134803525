import { ReactElement } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import Button from '~/ui/components/common/Button';

import { VIEW_CLIENT } from '~/ui/constants/paths';
import styles from './MedicationDeliveryEmpty.module.scss';

interface IProps {
  clientId: number;
  teamId: number;
}

const MedicationDeliveryEmpty = ({ clientId, teamId }: IProps): ReactElement => {
  const { push } = useHistory();

  const addMedication = () => {
    push(VIEW_CLIENT.replace(':clientId', String(clientId)).replace(':actTeamId', String(teamId)), {
      selectedTab: 1,
      actTeamId: String(teamId),
    });
  };

  return (
    <div className={styles.medicationDeliveryEmpty}>
      <div className={styles.pillIcon} />
      <span className={styles.message}>Please add Medications to provide Medication Logistics</span>
      <Button
        className={styles.customButton}
        variant="contained"
        color="primary"
        onClick={addMedication}
      >
        <AddIcon /> Add Medications
      </Button>
    </div>
  );
};

export default MedicationDeliveryEmpty;
