import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetSupportContacts } from '../types';

const onGetSupportContacts: IThunkGetSupportContacts = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload) => {
    const { clinicId, teamId, clientId } = payload;

    const supportContacts = await api.clientDetails
      .getClientSupportContactList(clinicId, teamId, clientId)
      .then(r => r.data);

    setClientDetails({ supportContacts });
  },
);

export default onGetSupportContacts;
