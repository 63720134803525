import { ReactElement } from 'react';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';

import danger from '~/ui/assets/images/danger.svg';
import styles from './InfoMessage.module.scss';

interface IProps {
  message: string;
  className?: string;
  messageClassName?: string;
  icon?: React.ReactNode;
}

const InfoMessage = ({
  className,
  message,
  messageClassName,
  icon = <img src={danger} alt="warning" />,
}: IProps): ReactElement => (
  <Box className={classNames(styles.container, className)}>
    {icon}
    <Typography variant="body2" className={classNames(styles.message, messageClassName)}>
      {message}
    </Typography>
  </Box>
);

export default InfoMessage;
