import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveClient, IClientStore } from '../types';

const onArchiveClient: IThunkArchiveClient = thunk(
  // @ts-ignore
  async ({ setClients }, payload, { getState }) => {
    const localState = getState() as IClientStore;
    const { clientId, clinicId, teamId, dischargeReasonId, dischargeDate } = payload;

    await api.client.archiveClient(
      { clinicId, teamId, clientId },
      { dischargeReasonId, dischargeDate },
    );

    const updatedList = localState.list.map(item =>
      String(item.id) !== clientId ? item : { ...item, isArchived: true },
    );

    setClients({ items: updatedList });
  },
);

export default onArchiveClient;
