import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreActTeam, IActTeamStore } from '../types';

const onRestoreActTeam: IThunkRestoreActTeam = thunk(
  // @ts-ignore
  async ({ setActTeams }, payload, { getState }) => {
    const localState = getState() as IActTeamStore;

    const { clinicId, actTeamId } = payload;

    await api.actTeam.restoreActTeam(clinicId, actTeamId);

    const updatedList = localState.list.map(item =>
      item.id !== Number(actTeamId) ? item : { ...item, isArchived: false },
    );

    setActTeams({ items: updatedList });
  },
);

export default onRestoreActTeam;
