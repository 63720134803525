import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import Loader from '~/ui/components/common/Loader';
import Button from '~/ui/components/common/Button';
import Table from './Table';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { ADD_SUPER_ADMIN } from '~/ui/constants/paths';

import styles from './SuperAdminsManagement.module.scss';

const SuperAdminsManagement = (): ReactElement => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const { list, pagination } = useStoreState(state => state.superAdmin);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetSuperAdmins } = useStoreActions(actions => actions.superAdmin);

  const onMount = async () => {
    const { pageNumber, pageSize } = pagination;
    try {
      setLoading(true);
      await onGetSuperAdmins({ pageNumber, pageSize });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      <div className={styles.header}>
        <h2>Super Admins</h2>
        <div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => push(ADD_SUPER_ADMIN)}
          >
            Add Super Admin
          </Button>
        </div>
      </div>
      <Table superAdmins={list} />
    </div>
  );
};

export default SuperAdminsManagement;
