import { ReactElement } from 'react';
import { components } from 'react-select';
import CustomCheckbox from '../../CustomCheckbox';

const Option = ({ label, isSelected, ...rest }: any): ReactElement => (
  <components.Option {...rest}>
    <CustomCheckbox size="small" checked={isSelected} />
    <span>{label}</span>
  </components.Option>
);

export default Option;
