import React, { ReactElement } from 'react';

import ListItem from '../../ListItem';
import { isoToLocal } from '~/utils/date';

import { IDictionary } from '~/services/api/dictionaries/types';
import { IUserWorkDetails } from '../types';

import styles from '../UserProfile.module.scss';

interface IProps {
  user: IUserWorkDetails;
  clinicName?: string;
}
const WorkDetails = ({ user, clinicName }: IProps): ReactElement => (
  <div className={styles.column}>
    <div className={styles.listItemBlock}>
      <div className={styles.item}>
        <h4>Work Details</h4>
      </div>
      {clinicName && <ListItem label="Associated Clinic" content={clinicName} moreLabelWidth />}
      <ListItem
        label="Employment Status"
        content={String(user.workDetails.employmentStatus.name)}
        moreLabelWidth
      />
      <ListItem label="Credentials" content={user.workDetails.credentials.name} moreLabelWidth />
      <ListItem label="Education" content={user.workDetails.education.name} moreLabelWidth />
      <ListItem
        label="Years of Experience"
        content={String(user.workDetails.experience)}
        moreLabelWidth
      />
      {
        // ACT Team Member have only one team
        user.team && <ListItem label="ACT Team" content={String(user.team.name)} moreLabelWidth />
      }
      {user.teams && (
        <ListItem
          label="Assigned ACT Teams"
          content={user.teams.map((t: IDictionary) => t.name).join(', ')}
          moreLabelWidth
        />
      )}
      <ListItem label="Hire Date" content={isoToLocal(user.workDetails.hireDate)} moreLabelWidth />
    </div>
  </div>
);

export default WorkDetails;
