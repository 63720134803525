import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { CLINIC_MANAGEMENT, MY_CLINIC, SIGN_IN } from '~/ui/constants/paths';
import { useStoreState } from '~/store/hooks';
import { IUserRole } from '~/types';

const Home = (): ReactElement => {
  const { authorized } = useStoreState(state => state.user);

  const roles = useStoreState(state => state.user.current?.roles);

  const to =
    roles?.includes(IUserRole.SuperAdmin) || roles?.includes(IUserRole.ProductOwner)
      ? CLINIC_MANAGEMENT
      : MY_CLINIC;

  return authorized ? <Redirect to={to} /> : <Redirect to={SIGN_IN} />;
};

export default Home;
