import { ReactElement, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '~/services/api';
import Form from '../reusable/Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';

import { EDIT_SUPER_ADMIN, SUPER_ADMIN } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions, useStoreState } from '~/store/hooks';

import { IFormValues } from '../reusable/Form/types';

interface IParams {
  superAdminId: string;
}

const EditSuperAdmin = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { superAdminId } = useParams<IParams>();
  const { push } = useHistory();

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onGetSuperAdmin } = useStoreActions(actions => actions.superAdmin);
  const { current } = useStoreState(state => state.superAdmin);

  const itemsForBreadcrumbs = [
    { to: SUPER_ADMIN, title: 'Super Admin' },
    { to: EDIT_SUPER_ADMIN, title: 'Edit Super Admin' },
  ];

  const isPending = current?.status === 'Pending';

  const onSubmit = async (vals: IFormValues) => {
    const { email, emailDomain, ...restVals } = vals;
    const payload = isPending ? { email: email + emailDomain, ...restVals } : { ...vals };

    try {
      setIsUpdating(true);
      await api.superAdmin.editSuperAdmin(superAdminId, payload);

      showNotify('Super admin successfully updated');
      push(SUPER_ADMIN);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUpdating(false);
    }
  };

  const onMount = async () => {
    try {
      setLoading(true);
      await onGetSuperAdmin(superAdminId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !current) return <Loader />;

  return (
    <div>
      <h2>Edit Super Admin</h2>
      <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
      <Form
        onSubmit={onSubmit}
        defaultValues={current}
        isProcessing={isUpdating}
        isPending={isPending}
        isEmailInputDisabled={!isPending}
        submitText="Save Changes"
        leavePageDescription="Are you sure you want to cancel “Edit Super Admin”? All the changes will be discarded."
      />
    </div>
  );
};

export default EditSuperAdmin;
