import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import concat from 'lodash/concat';

import Form from '../Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions, useStoreState } from '~/store/hooks';
import api from '~/services/api';

import { isFile } from '~/utils/file';
import { CLINIC_MANAGEMENT, VIEW_CLINIC } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import { ILocalAdminForm } from '~/services/api/localAdmin/types';

type IParams = {
  id: string;
};

const breadcrumbsItem = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

const AddLocalAdmin = (): ReactElement => {
  const { id } = useParams<IParams>();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const currentClinic = useStoreState(state => state.clinic.current);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);

  const onMount = async () => {
    try {
      setLoading(true);
      if (!currentClinic) {
        await onGetClinic(id);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (!currentClinic || loading) return <Loader />;

  const breadcrumbsItems = concat(breadcrumbsItem, [
    {
      title: currentClinic.name,
      to: VIEW_CLINIC.replace(':id', id),
    },
    {
      title: 'Add New Local Admin',
      to: pathname,
    },
  ]);

  const emailDomainOptions = currentClinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  const onSubmit = async ({ email, emailDomain, ...values }: ILocalAdminForm) => {
    try {
      setIsCreating(true);

      const payload = { ...values, email: `${email}${emailDomain}` };

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);

        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

        payload.photo = fileName;
      }
      await api.localAdmin.createLocalAdmin(id, payload);
      showNotify('Local admin added successfully');
      push(VIEW_CLINIC.replace(':id', id), { selectedTab: 2 });
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  const leavePageDescription = "Are you sure you want to cancel 'Add Local Admin'?";
  const cancelLinkTo = {
    pathname: VIEW_CLINIC.replace(':id', id),
    state: { selectedTab: 2 },
  };

  return (
    <div>
      <h2>Add New Local Admin </h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        isProcessing={isCreating}
        leavePageDescription={leavePageDescription}
        cancelLinkTo={cancelLinkTo}
        onSubmit={onSubmit}
        emailDomainOptions={emailDomainOptions}
      />
    </div>
  );
};

export default AddLocalAdmin;
