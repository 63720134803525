import { Box } from '@material-ui/core';
import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';

import Loader from '~/ui/components/common/Loader';
import CalendarRow from '../CalendarRow';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import MedicationDeliveryEmpty from '../MedicationDeliveryEmpty';
import getFromDate from '../../helpers/getFromDate';
import getToDate from '../../helpers/getToDate';

import styles from './CalendarSection.module.scss';

interface IProps {
  selectedMonth: Date;
  teamId: number;
  includeWeekends: boolean;
  weekMode: boolean;
  weekRange: number;
  setWeekRange: Dispatch<SetStateAction<number>>;
  onAdd?: (medicationId: number, date: string, injectable: boolean) => void;
  onEdit?: (id: number, name: string, isArchived?: boolean) => void;
}

const CalendarSection = ({
  selectedMonth,
  teamId,
  weekMode,
  includeWeekends,
  weekRange,
  setWeekRange,
  onAdd,
  onEdit,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);

  const { current } = useStoreState(state => state.client);
  const userClinic = useStoreState(state => state.user.current.clinic);
  const { list } = useStoreState(state => state.medicationLogistics);

  const activeMedicationsIds = useMemo(() => list?.map(item => item.medication.id), [list]);
  const activeMedications = useMemo(
    () =>
      current.medications.filter(
        medication => activeMedicationsIds?.includes(medication.id) || !medication.isArchived,
      ),
    [current.medications, activeMedicationsIds],
  );

  const onGetMedicationDeliveries = useStoreActions(
    actions => actions.medicationLogistics.onGetMedicationDeliveries,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      await onGetMedicationDeliveries({
        clinicId: String(userClinic.id),
        clientId: String(current.id),
        teamId: String(teamId),
        from: getFromDate(selectedMonth) as string,
        to: getToDate(selectedMonth) as string,
      });
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const filteredList = useMemo(
    () =>
      activeMedications
        .map(medication => ({
          medication,
          deliveries: list?.find(item => item.medication.id === medication.id)?.deliveries,
          nextDueDate: list?.find(item => item.medication.id === medication.id)?.nextDueDate,
        }))
        ?.sort(
          (a, b) =>
            Number(a?.medication?.isArchived) - Number(b?.medication.isArchived) ||
            +(a > b) ||
            -(a < b),
        ),
    [list, activeMedications],
  );

  useEffect(() => {
    if (userClinic && current && teamId && selectedMonth) {
      onMount();
    }
  }, [userClinic, current, teamId, selectedMonth, list?.length]);

  if (loading) return <Loader />;
  return (
    <Box className={styles.calendarSection}>
      <CalendarRow
        includeWeekends={includeWeekends}
        selectedMonth={selectedMonth}
        weekMode={weekMode}
        key="calendar-row-days"
        weekRange={weekRange}
        setWeekRange={setWeekRange}
      />
      {filteredList?.map((medicationDelivery, index) => (
        <CalendarRow
          weekMode={weekMode}
          includeWeekends={includeWeekends}
          selectedMonth={selectedMonth}
          medication={medicationDelivery?.medication}
          hidden={medicationDelivery?.medication?.isArchived && !medicationDelivery?.deliveries}
          medicationDeliveries={medicationDelivery?.deliveries}
          nextDueDate={medicationDelivery?.nextDueDate}
          key={`calendar-row-${medicationDelivery?.medication.id}`}
          last={filteredList?.length - 1 === index}
          onAdd={onAdd}
          onEdit={onEdit}
          weekRange={weekRange}
          setWeekRange={setWeekRange}
        />
      ))}
      {filteredList.length === 0 && (
        <MedicationDeliveryEmpty teamId={teamId} clientId={current.id} />
      )}
    </Box>
  );
};

export default CalendarSection;
