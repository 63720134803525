import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '~/ui/components/common/Loader';
import UserProfile from '~/ui/components/common/UserProfile';
import Header from './Header';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

interface IParams {
  id: string;
  localAdminId: string;
}

const ViewLocalAdmin = (): ReactElement => {
  const { id: clinicId, localAdminId } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(true);

  const currentClinic = useStoreState(states => states.clinic.current);
  const currentLocalAdmin = useStoreState(states => states.localAdmin.current);

  const onGetClinic = useStoreActions(actions => actions.clinic.onGetClinic);
  const onGetLocalAdmin = useStoreActions(actions => actions.localAdmin.onGetLocalAdmin);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetClinic(clinicId);
      await onGetLocalAdmin({ clinicId, localAdminId });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;
  return (
    <>
      <Header
        clinicId={clinicId}
        clinicName={currentClinic.name}
        localAdminId={localAdminId}
        localAdminName={`${currentLocalAdmin.firstName} ${currentLocalAdmin.lastName}`}
      />
      <UserProfile
        user={currentLocalAdmin}
        clinicName={currentClinic.name}
        personalDetails
        address
        workDetails
      />
    </>
  );
};

export default ViewLocalAdmin;
