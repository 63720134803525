import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnActivateProgramAssistant } from '../types';

const onGetCurrentProgramAssistant: IThunkOnActivateProgramAssistant = thunk(
  // @ts-ignore
  async ({ setCurrentProgramAssistant }, payload) => {
    const { clinicId, programAssistantId } = payload;
    const { data } = await api.programAssistant.getProgramAssistant(
      clinicId,
      String(programAssistantId),
    );

    setCurrentProgramAssistant(data);
  },
);

export default onGetCurrentProgramAssistant;
