import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClient } from '../types';

// @ts-ignore
const onGetClient: IThunkGetClient = thunk(async ({ setClient }, payload) => {
  const { clinicId, teamId, clientId } = payload;

  const { data } = await api.client.getClient(clinicId, teamId, clientId);

  setClient(data);
});

export default onGetClient;
