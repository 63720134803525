import { ReactElement, useState } from 'react';

import api from '~/services/api';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

import ListItem from '~/ui/components/common/ListItem';
import ProfilePhotoUpload from '~/ui/components/common/ProfilePhotoUpload';
import { suicideRiskColor } from '~/ui/constants/suicideRisks';
import { isFile } from '~/utils/file';
import { isoToLocal } from '~/utils/date';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import { IClientDetailed } from '~/services/api/client/types';

import styles from './PersonalDetails.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
}

const PersonalDetails = ({ client, clinicId, actTeamId }: IProps): ReactElement => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [value, setValue] = useState(client.photo || null);
  const [error, setError] = useState(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { address, suicideRisk } = client;

  const formattedBirth = isoToLocal(client.dateOfBirth);
  const formattedAdmission = isoToLocal(client.admissionDetails.admissionDate);
  const formattedDischarge = isoToLocal(client.admissionDetails.dischargeDate);

  const color = suicideRiskColor[suicideRisk];

  const onUpload = async (file: any) => {
    try {
      setLoadingPhoto(true);

      if (!isFile(file)) return;

      const formData = new FormData();
      formData.append('file', file);
      const { fileName } = await api.file.uploadFile(formData).then(r => r.data);

      await api.client.uploadClientPhoto(
        { clinicId, clientId: String(client.id), teamId: actTeamId },
        fileName,
      );

      setValue(file);
      showNotify('Photo successfully updated');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onRemove = async () => {
    try {
      setLoadingPhoto(true);

      await api.client.uploadClientPhoto(
        { clinicId, clientId: String(client.id), teamId: actTeamId },
        '',
      );

      setValue('');
      setShowConfirm(false);
      showNotify('Photo successfully removed!');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const clientAddress = `${address.line1}, ${address.line2.length ? `${address.line2},` : ''} ${
    address.city
  }, ${address.state.name} ${address.zipCode}`;
  const description = 'Are you sure you want to remove client photo?';

  return (
    <div className={styles.content}>
      <div className={styles.column}>
        <div className={styles.listItemBlock}>
          <div className={styles.item}>
            <h4>Client Profile Details</h4>
            <h4 style={{ color }}>
              SR{client.aot ? <span className={styles.black}>/AOT</span> : ''}
            </h4>
          </div>
          <ListItem label="First Name" content={client.firstName} moreLabelWidth />
          <ListItem label="Last Name" content={client.lastName} moreLabelWidth />
          <ListItem label="Gender" content={client.gender.name} moreLabelWidth />
          <ListItem label="Date of Birth" content={formattedBirth} moreLabelWidth />
          <ListItem label="Medicaid #" content={client.medicaId} moreLabelWidth />
          <ListItem label="EMR MRN" content={client.emrMrn} moreLabelWidth />
          <ListItem label="Assigned ACT Team" content={client.actTeam.name} moreLabelWidth />
          <ListItem
            label="Referral Source"
            content={client.admissionDetails.referralSource.name}
            moreLabelWidth
          />
          <ListItem label="Date of Admission" content={formattedAdmission} moreLabelWidth />
        </div>
        <div className={styles.listItemBlock}>
          <div className={styles.item}>
            <h4>Contact Information</h4>
          </div>
          <ListItem label="Address" content={clientAddress} noActions />
          <ListItem label="Phone" content={client.phone} noActions />
          <ListItem label="Email" content={client.email} noActions />
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.uploaderWrapper}>
          <ProfilePhotoUpload
            disabled={loadingPhoto || client.isArchived}
            value={value}
            onUpload={onUpload}
            onError={setError}
            onRemove={() => setShowConfirm(true)}
            showButtons={!client.isArchived}
          />
          {error && <div className={styles.red}>{error}</div>}
        </div>
        {client.isArchived && (
          <div className={styles.dischargeDetails}>
            <h4 className={styles.red}>ARCHIVED CLIENT</h4>
            <div className={styles.listItemBlock}>
              <div className={styles.item}>
                <h4>Details of Discharge</h4>
              </div>
              <ListItem label="Date of Discharge" content={formattedDischarge} moreLabelWidth />
              <ListItem
                label="Reason for Discharge"
                content={client.admissionDetails.dischargeReason.name}
                moreLabelWidth
              />
            </div>
          </div>
        )}
      </div>
      {showConfirm && (
        <ConfirmModal
          description={description}
          confirmText="Remove"
          onClose={() => setShowConfirm(false)}
          onConfirm={() => onRemove()}
        />
      )}
    </div>
  );
};

export default PersonalDetails;
