import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveTypeItem, IDictionariesStore } from '../types';

const onArchiveTypeItem: IThunkArchiveTypeItem = thunk(
  // @ts-ignore
  async ({ setTypeList }, payload, { getState }) => {
    const { type, id } = payload;
    const localState = getState() as IDictionariesStore;

    await api.dictionaries.archiveTypeItem(type, id);

    const updatedList = localState.list.map(item =>
      item.id !== payload.id ? item : { ...item, isArchived: true },
    );
    setTypeList({ items: updatedList });
  },
);

export default onArchiveTypeItem;
