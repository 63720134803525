import { AxiosPromise } from 'axios';
import { IDictionary } from './dictionaries/types';

export type IResponse<Model> = AxiosPromise<Model>;

export interface ITokenParams {
  accessToken: string;
  refreshToken: string;
}

export interface IRefreshAccessToken {
  accessToken: string;
  refreshToken: string;
  userId: string;
}
export interface IPagination {
  total?: number;
  pageNumber?: number;
  pageSize?: number;
}

enum Status {
  Any,
  Archived,
  Active,
}

export enum Sorting {
  ByName,
  ByCreateDate,
  ByEditDate,
}
export interface IParams {
  name?: string;
  total?: number;
  pageNumber?: number;
  pageSize?: number;
  status?: Status;
  sorting?: string | Sorting;
}

export interface IAddress {
  stateId: number;
  city: string;
  zipCode: string;
  line1: string;
  line2: string;
}

export interface IAddressExtended extends Omit<IAddress, 'stateId'> {
  state: IDictionary & { abbreviation: string };
  lat: number;
  lng: number;
}

export interface ICommonClientDetails {
  id: number;
  note: string;
  isArchived: boolean;
}
