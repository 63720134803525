import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClinicActTeams } from '../types';

// @ts-ignore
const onGetClinicActTeams: IThunkGetClinicActTeams = thunk(async ({ setActTeams }, payload) => {
  const { clinicId, params } = payload;

  const { items, total } = await api.actTeam.getClinicActTeams(clinicId, params).then(r => r.data);

  const pagination = { ...params, total };

  setActTeams({ items, pagination });
});

export default onGetClinicActTeams;
