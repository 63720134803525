import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import api from '~/services/api';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import Form from '../../reusable/Form';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { ACT_TEAM_MANAGEMENT } from '~/ui/constants/paths';

const AddActTeam = (): ReactElement => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [clinicSelectOptions, setClinicSelectOptions] = useState(null);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      setLoading(true);
      const items = await api.clinic.getAllClinics().then(r => r.data);
      const formattedItems = items.map(item => ({ label: item.name, value: item.id }));
      setClinicSelectOptions(formattedItems);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const breadcrumbItems = [
    {
      to: ACT_TEAM_MANAGEMENT,
      title: 'ACT Team Management',
    },
    {
      to: pathname,
      title: 'Add New ACT Team',
    },
  ];

  if (loading) return <Loader />;

  const onSubmit = async (vals: any) => {
    try {
      setIsCreating(true);
      const { clinicId, name, typeId } = vals;
      await api.actTeam.createClinicActTeam(clinicId, { name, typeId });
      showNotify('ACT Team successfully added.');
      push(ACT_TEAM_MANAGEMENT);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  const leavePageDescription = 'Are you sure you want to discard?';

  return (
    <div>
      <h2>Add New ACT Team</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        clinicSelectOptions={clinicSelectOptions}
        isProcessing={isCreating}
        leavePageDescription={leavePageDescription}
        cancelLinkTo={ACT_TEAM_MANAGEMENT}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AddActTeam;
