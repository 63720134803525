import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkActivateTeamMember } from '../types';

const onActivateTeamMember: IThunkActivateTeamMember = thunk(
  // @ts-ignore
  async ({ activateTeamMember }, payload) => {
    const { clinicId, actTeamId, teamMemberId } = payload;

    await api.actTeamMember.activateTeamMember(clinicId, actTeamId, teamMemberId);

    activateTeamMember(teamMemberId);
  },
);

export default onActivateTeamMember;
