import { ReactElement, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Form from './Form';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import { extractErrorMessage } from '~/utils/error';
import { teamMemberMapper, clientMapper } from '~/utils/clientAllocation';

import {
  ACT_TEAM_MANAGEMENT,
  MY_ACT_TEAM,
  VIEW_ACT_TEAM,
  VIEW_CLIENT_ALLOCATION,
} from '~/ui/constants/paths';
import { IUserRole } from '~/types';

type IParams = {
  actTeamId: string;
  clientAllocationId: string;
};

const EditClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationId } = useParams<IParams>();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [clients, setClients] = useState([]);

  const { clinic: userClinic, roles } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const clientAllocation = useStoreState(state => state.clientAllocation.current);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const onGetClientAllocation = useStoreActions(
    actions => actions.clientAllocation.onGetClientAllocation,
  );

  const isActTeamLeader = roles?.includes(IUserRole.ActTeamLeader);

  const onMount = async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      await Promise.all([
        onGetMyClinic(),
        onGetClinicActTeam(payload),
        api.clientAllocation
          .getRelatedClients(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.map(clientMapper))
          .then(r => setClients(r)),
        api.actTeamMember
          .getActTeamMemberList(String(userClinic.id), actTeamId)
          .then(r => r.data)
          .then(data => data.map(teamMemberMapper))
          .then(r => setTeamMembers(r)),
        onGetClientAllocation({
          clinicId: String(userClinic.id),
          teamId: actTeamId,
          clientAllocationId,
        }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !actTeam || !clientAllocation) return <Loader />;

  const breadcrumbItems = [
    ...(isActTeamLeader
      ? [
          {
            title: actTeam.name,
            to: {
              pathname: MY_ACT_TEAM,
              state: { selectedTab: 0 },
            },
          },
          {
            to: {
              pathname: VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                ':clientAllocationId',
                String(clientAllocation.id),
              ),
            },
            title: 'Client Allocation',
          },
        ]
      : [
          { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} ACT Teams` },
          {
            to: {
              pathname: VIEW_ACT_TEAM.replace(':actTeamId', String(actTeam.id)),
              state: { selectedTab: 2 },
            },
            title: actTeam.name,
          },
        ]),
    { to: pathname, title: 'Edit Client Allocation' },
  ];

  return (
    <div>
      <h2>Edit Client Allocation</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        clientAllocation={clientAllocation}
        teamMembers={teamMembers}
        clients={clients}
        actTeamId={actTeamId}
        clinicId={String(userClinic.id)}
      />
    </div>
  );
};

export default EditClientAllocation;
