import { useState, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import AvatarUpload from '~/ui/components/common/AvatarUpload';

import styles from './AvatarUpload.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
  isPhoto?: boolean;
}

const AvatarUploadComponent = ({
  control,
  isPhoto,
  errors,
  name,
  label = '',
}: IProps): ReactElement => {
  const [uploadError, setUploadError] = useState('');
  const error = get(errors, `${name}.message`, '');

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControl component="fieldset" className={styles.uploadControl} fullWidth>
            <FormLabel component="legend" className={styles.label}>
              {label}
            </FormLabel>
            <AvatarUpload
              isPhoto
              value={value}
              onUpload={file => {
                onChange(file);
                setUploadError('');
              }}
              onRemove={() => onChange('')}
              onError={err => setUploadError(err)}
            />
            {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};

export default AvatarUploadComponent;
