import { thunk } from 'easy-peasy';

import api from '~/services/api';

import { TypeOfVisit, VisitedPerson } from '~/ui/pages/Visits/constants/filterOptions';
import { FilterType, IVisitParams, IVisitRequestParams } from '~/services/api/visits/types';
import { IThunkGetVisits } from '../types';

const onGetVisits: IThunkGetVisits = thunk(
  // @ts-ignore
  async ({ setVisits }, { requestInfo, params }) => {
    const { clinicId, clientId, teamId, includeDeleted = false } = requestInfo;
    const { filterBy, sortBy, dateFrom, dateTo, ...rest } = params as IVisitParams;

    const filterOptions = (): IVisitRequestParams => {
      switch (sortBy) {
        case 1:
          return {
            filtering: FilterType.PERSON,
            personFiltering: VisitedPerson[filterBy].replace(' ', ''),
          };
        case 2:
          return { filtering: FilterType.TYPE, type: TypeOfVisit[filterBy].replace(' ', '') };
        case 3:
          if (dateFrom && dateTo) {
            return {
              filtering: FilterType.DATE,
              startDate: dateFrom.toISOString(),
              endDate: dateTo.toISOString(),
            };
          }
          return {
            sorting: FilterType.DATE,
          };

        default:
          return { sorting: 'all' };
      }
    };

    const visits = await api.visits
      .getVisits({ clinicId, clientId, teamId, includeDeleted }, { ...rest, ...filterOptions() })
      .then(r => r.data);

    setVisits(visits);
  },
);

export default onGetVisits;
