import React, { ReactElement } from 'react';

import Address from './Address';
import PersonalDetails from './PersonalDetails';
import WorkDetails from './WorkDetails';

import { IUserProfile } from './types';
import styles from './UserProfile.module.scss';

interface IProps {
  user: IUserProfile;
  personalDetails?: boolean;
  address?: boolean;
  workDetails?: boolean;
  clinicName?: string;
}

const UserProfile = ({
  user,
  personalDetails = false,
  address = false,
  workDetails = false,
  clinicName,
}: IProps): ReactElement => (
  <div className={styles.content}>
    {personalDetails && <PersonalDetails user={user} />}
    {address && <Address user={user} />}
    {workDetails && <WorkDetails user={user} clinicName={clinicName} />}
  </div>
);

export default UserProfile;
