import Icon from '~/ui/components/common/Icon';

import {
  CLINIC_MANAGEMENT,
  ACT_TEAM_MANAGEMENT,
  DICTIONARIES,
  MY_CLINIC,
  CLIENT,
  MY_ACT_TEAM,
  MY_CLIENTS,
  SUPER_ADMIN,
  MEDICATION_LOGISTICS,
  COORDINATE,
  VISITS,
} from './paths';
import { IUserRole } from '~/types';

import homeRedIcon from '~/ui/assets/images/homeRed.svg';
import homeGreyIcon from '~/ui/assets/images/homeGrey.svg';
import actTeamRedIcon from '~/ui/assets/images/actTeamRed.svg';
import actTeamGreyIcon from '~/ui/assets/images/actTeamGrey.svg';
import clientsGreyIcon from '~/ui/assets/images/clientsGrey.svg';
import clientsRedIcon from '~/ui/assets/images/clientsRed.svg';
import dictionariesRedIcon from '~/ui/assets/images/dictionariesRed.svg';
import dictionariesGreyIcon from '~/ui/assets/images/dictionariesGrey.svg';
import personRedIcon from '~/ui/assets/images/personRed.svg';
import personGreyIcon from '~/ui/assets/images/personGrey.svg';
import pillRedIcon from '~/ui/assets/images/pillRed.svg';
import pillGreyIcon from '~/ui/assets/images/pillGrey.svg';
import coordinateGreyIcon from '~/ui/assets/images/coordinateGrey.svg';
import coordinateRedIcon from '~/ui/assets/images/coordinateRed.svg';
import visitsRedIcon from '~/ui/assets/images/visitsRed.svg';
import visitsGrey from '~/ui/assets/images/visitsGrey.svg';

const sidebarMenuItems = (userRole: IUserRole[]) => {
  const isSuperAdmin = userRole.includes(IUserRole.SuperAdmin);
  const isProductOwner = userRole.includes(IUserRole.ProductOwner);
  const isLocalAdmin = userRole.includes(IUserRole.LocalAdmin);
  const isActTeamMember = userRole.includes(IUserRole.ActTeamMember);
  const isActTeamLeader = userRole.includes(IUserRole.ActTeamLeader);
  const isProgramAssistant = userRole.includes(IUserRole.ProgramAssistant);

  const permissions = {
    clinicManagement: isSuperAdmin || isProductOwner,
    myClinic: isLocalAdmin || isActTeamMember || isActTeamLeader || isProgramAssistant,
    dictionaries: isSuperAdmin || isProductOwner,
    clients: isLocalAdmin || isProgramAssistant,
    superAdmins: isProductOwner,
    myActTeam: isActTeamMember || isActTeamLeader,
    coordinate: false, // isActTeamMember || isActTeamLeader TODO revert back coordinate once it is been tested
    medicationLogistics: isLocalAdmin || isActTeamMember || isActTeamLeader || isProgramAssistant,
    visits: isLocalAdmin || isActTeamMember || isActTeamLeader || isProgramAssistant,
  };

  return [
    ...(permissions.myClinic
      ? [
          {
            url: MY_CLINIC,
            title: 'My Clinic',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon activeIcon={homeRedIcon} inactiveIcon={homeGreyIcon} isActive={isActive} />
            ),
          },
        ]
      : []),

    ...(permissions.clinicManagement
      ? [
          {
            url: CLINIC_MANAGEMENT,
            title: 'Clinic management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon activeIcon={homeRedIcon} inactiveIcon={homeGreyIcon} isActive={isActive} />
            ),
          },
        ]
      : []),

    ...(permissions.dictionaries
      ? [
          {
            url: DICTIONARIES,
            title: 'Dictionaries',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={dictionariesRedIcon}
                inactiveIcon={dictionariesGreyIcon}
                isActive={isActive}
              />
            ),
          },
        ]
      : []),

    ...(permissions.clients
      ? [
          {
            url: ACT_TEAM_MANAGEMENT,
            title: 'ACT Team Management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={actTeamRedIcon}
                inactiveIcon={actTeamGreyIcon}
                isActive={isActive}
              />
            ),
          },
          {
            url: CLIENT,
            title: 'Clients',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={clientsRedIcon}
                inactiveIcon={clientsGreyIcon}
                isActive={isActive}
              />
            ),
          },
        ]
      : []),
    ...(permissions.myActTeam
      ? [
          {
            url: MY_ACT_TEAM,
            title: 'ACT Team Management',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={actTeamRedIcon}
                inactiveIcon={actTeamGreyIcon}
                isActive={isActive}
              />
            ),
          },
          {
            url: MY_CLIENTS,
            title: 'Clients',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={clientsRedIcon}
                inactiveIcon={clientsGreyIcon}
                isActive={isActive}
              />
            ),
          },
        ]
      : []),
    ...(permissions.superAdmins
      ? [
          {
            url: SUPER_ADMIN,
            title: 'Super Admins',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon activeIcon={personRedIcon} inactiveIcon={personGreyIcon} isActive={isActive} />
            ),
          },
        ]
      : []),
    ...(permissions.medicationLogistics
      ? [
          {
            url: MEDICATION_LOGISTICS,
            title: 'Medication Logistics',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon activeIcon={pillRedIcon} inactiveIcon={pillGreyIcon} isActive={isActive} />
            ),
          },
        ]
      : []),
    ...(permissions.coordinate
      ? [
          {
            url: COORDINATE,
            title: 'Coordinate',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon
                activeIcon={coordinateRedIcon}
                inactiveIcon={coordinateGreyIcon}
                isActive={isActive}
              />
            ),
          },
        ]
      : []),
    ...(permissions.visits
      ? [
          {
            url: VISITS,
            title: 'Visits',
            // eslint-disable-next-line react/display-name
            icon: ({ isActive }: { isActive: boolean }) => (
              <Icon activeIcon={visitsRedIcon} inactiveIcon={visitsGrey} isActive={isActive} />
            ),
          },
        ]
      : []),
  ];
};

export default sidebarMenuItems;
