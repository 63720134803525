import { isSaturday, lastDayOfMonth, nextSaturday } from 'date-fns';

const getToDate = (date: Date, dateFormat?: boolean): string | Date => {
  const endOfMonthDate = lastDayOfMonth(date);
  const saturday = isSaturday(endOfMonthDate);
  const nextSat = saturday ? endOfMonthDate : nextSaturday(endOfMonthDate);
  if (dateFormat) {
    return nextSat;
  }
  return new Date(nextSat.setHours(12)).toISOString();
};

export default getToDate;
