import { IVisit, IVisitForm } from '~/services/api/visits/types';
import { destructHours } from '~/utils/date';
import { TypeOfVisit } from '../constants/filterOptions';
import { splitSeconds } from './splitSeconds';

export const formatVisitToForm = (visit: IVisit): IVisitForm => ({
  date: visit?.date,
  type: Object.keys(TypeOfVisit)
    .filter(x => Number.isNaN(Number(x)))
    .map(type => type.replace(' ', ''))
    .indexOf(visit?.type),
  visitedPeople: [
    ...visit?.visitedPeople?.map(people => people.id),
    visit?.isClientVisited ? [visit?.client?.id] : [],
  ].flat(1),
  visitors: visit?.visitedBy?.map(visitor => visitor.id),
  note: visit?.note,
  startTime: visit?.startTime ? destructHours(visit?.startTime).toISOString() : null,
  duration: splitSeconds(visit?.durationSeconds, true),
  city: visit?.address?.city,
  zipCode: visit?.address?.zipCode,
  stateId: visit?.address?.state?.id,
  clientNoShow: visit?.clientNoShow,
  noShowReason: visit?.noShowReason?.id,
  line1: visit?.address?.line1,
});

export default {};
