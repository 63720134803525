import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnUnlockProgramAssistant } from '../types';

const onUnlockProgramAssistant: IThunkOnUnlockProgramAssistant = thunk(
  // @ts-ignore
  async ({ activateProgramAssistant }, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.activateProgramAssistant(clinicId, String(programAssistantId));

    activateProgramAssistant(programAssistantId);
  },
);

export default onUnlockProgramAssistant;
