import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveMedication, IClientDetailsStore } from '../types';

const onArchiveMedication: IThunkArchiveMedication = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.archiveClientMedication(payload);

    const medications = localState.medications.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: true },
    );

    setClientDetails({ medications });
  },
);

export default onArchiveMedication;
