import { ReactElement, useEffect, useState } from 'react';
import api from '~/services/api';

import Table from '../../reusable/Table';
import Loader from '~/ui/components/common/Loader';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import { IClient } from '~/services/api/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import styles from './MyClients.module.scss';

const MyClients = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  // a plug for Table component
  const [, setClient] = useState<IClient>(null);
  const [dictionaries, setDictionaries] = useState<{ id: number; value: string }[]>([]);

  const clinicId = useStoreState(state => state.clinic.current?.id);
  const {
    team: { id: teamId, name },
  } = useStoreState(state => state.user.current);

  const {
    list: clients,
    pagination: { pageSize, pageNumber },
  } = useStoreState(state => state.client);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetTeamClients = useStoreActions(actions => actions.client.onGetTeamClients);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onMount = async () => {
    try {
      setLoading(true);

      if (!clinicId) {
        await onGetMyClinic();
      }
      if (clinicId && teamId) {
        await onGetTeamClients({
          clinicId: String(clinicId),
          teamId: String(teamId),
          params: { pageSize, pageNumber },
        });
      }
      if (!dictionaries.length) {
        const { data } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.DischargeReason,
        );
        setDictionaries(data);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, [clinicId]);

  if (loading) return <Loader />;

  return (
    <div>
      <div className={styles.header}>
        <h2>{`${name} Clients`}</h2>
      </div>
      <Table
        teamId={teamId}
        isLocalAdmin={false}
        noActions
        list={clients}
        onGetClients={onGetTeamClients}
        clinicId={clinicId}
        setClient={setClient}
      />
    </div>
  );
};

export default MyClients;
