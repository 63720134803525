import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import { IProgramAssistant, IProgramAssistantForm } from './types';

export const getProgramAssistants = (
  clinicId: string,
  params?: IParams,
): IResponse<{ items: IProgramAssistant[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/program-assistant/list`,
    params,
  });

export const getProgramAssistant = (
  clinicId: string,
  programAssistantId: string,
): IResponse<IProgramAssistant> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/program-assistant/${programAssistantId}`,
  });

export const activateProgramAssistant = (
  clinicId: string,
  programAssistantId: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/program-assistant/${programAssistantId}/activate`,
  });

export const deactivateProgramAssistant = (
  clinicId: string,
  programAssistantId: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/program-assistant/${programAssistantId}/deactivate`,
  });

export const reInviteProgramAssistant = (
  clinicId: string,
  programAssistantId: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/program-assistant/${programAssistantId}/resend-invitation`,
  });

export const createProgramAssistant = (
  clinicId: string,
  data: IProgramAssistantForm,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/program-assistant`,
    data,
  });

export const updateProgramAssistant = (
  clinicId: string,
  programAssistantId: string,
  data: IProgramAssistantForm,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/program-assistant/${programAssistantId}`,
    data,
  });
export default {};
