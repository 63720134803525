import { ReactElement } from 'react';
import { Divider, Grid } from '@material-ui/core';
import classnames from 'classnames';
import { format } from 'date-fns';

import Avatar from '~/ui/components/common/Avatar';
import Button from '~/ui/components/common/Button';
import MedicationDeliveryInfo from '../MedicationDeliveryInfo';
import MedicationChangeInfo from '../MedicationChangeInfo';

import { destructHours, isoToLocal } from '~/utils/date';
import { IClientMedicationDelivery } from '~/services/api/medicationLogistics/types';
import { notificationOn } from '~/ui/constants/notificationOn';

import styles from './MedicationDeliveryPreview.module.scss';

interface IProps {
  medicationDelivery: IClientMedicationDelivery | null;
  onClose: () => void;
}

/**
 * * Preview component for injectable and non-injectable medication deliveries
 */
const MedicationDeliveryPreview = ({ medicationDelivery, onClose }: IProps): ReactElement => (
  <Grid container spacing={2}>
    <Grid item sm={12}>
      <h3 className={styles.previewTitle}>Details of Archived Medication Delivery</h3>
    </Grid>
    <Grid item sm={12}>
      <MedicationDeliveryInfo
        name={medicationDelivery?.client?.name}
        medication={medicationDelivery}
        fullInfo
        archived
      />
    </Grid>

    {medicationDelivery?.statusChangeLog && (
      <Grid item sm={12}>
        <MedicationChangeInfo
          delivered={medicationDelivery.statusChangeLog.delivered}
          when={medicationDelivery.statusChangeLog.date}
          changedBy={medicationDelivery.statusChangeLog.user.name}
        />
      </Grid>
    )}

    <Grid container item sm={12} spacing={2}>
      <Grid item sm={5}>
        <h4 className={styles.itemTitle}>Responsible Team Member</h4>
        <h4 className={styles.itemValue}>{medicationDelivery?.teamMember.name}</h4>
      </Grid>
      <Grid item sm={4}>
        <h4 className={styles.itemTitle}>Medication Type</h4>
        <h4 className={styles.itemValue}>
          {medicationDelivery?.medication.injectable ? 'Injectable' : 'Non-injectable'}
        </h4>
      </Grid>
      <Grid item sm={3}>
        <h4 className={styles.itemTitle}>Dosage</h4>
        <h4 className={styles.itemValue}>{medicationDelivery?.medication?.dose}</h4>
      </Grid>
    </Grid>

    <Grid item sm={12}>
      {medicationDelivery?.note}
    </Grid>

    {medicationDelivery?.lastInjection && (
      <Grid item sm={12} className={styles.alignCenter}>
        <b>Last Injection</b>
      </Grid>
    )}

    {/* IF medication delivery IS non-injectable OR this is last injection FIELD nextDueDate is irrelevant */}
    <Grid
      container
      item
      sm={12}
      className={classnames({
        [styles.alignCenter]:
          medicationDelivery?.lastInjection || !medicationDelivery?.medication?.injectable,
      })}
    >
      <Grid
        item
        sm={
          medicationDelivery?.lastInjection || !medicationDelivery?.medication?.injectable ? 6 : 4
        }
      >
        <h4 className={styles.itemTitle}>Delivery Date</h4>
        <h4 className={styles.itemValue}>
          {medicationDelivery?.date
            ? format(new Date(medicationDelivery?.date), 'MM/dd/yyyy')
            : null}
        </h4>
      </Grid>
      <Grid
        item
        sm={
          medicationDelivery?.lastInjection || !medicationDelivery?.medication?.injectable ? 6 : 4
        }
      >
        <h4 className={styles.itemTitle}>Delivery Time</h4>
        <h4 className={styles.itemValue}>
          {medicationDelivery?.date ? format(new Date(medicationDelivery?.date), 'hh:mm aa') : null}
        </h4>
      </Grid>
      {!medicationDelivery?.lastInjection && medicationDelivery?.medication?.injectable && (
        <Grid item sm={4}>
          <h4 className={styles.itemTitle}>Next Due Date</h4>
          <h4 className={styles.itemValue}>
            {medicationDelivery?.nextDueDate ? isoToLocal(medicationDelivery?.nextDueDate) : null}
          </h4>
        </Grid>
      )}
    </Grid>

    {medicationDelivery?.hasAlerts && (
      <>
        <Grid item sm={12}>
          <Divider />
        </Grid>

        <Grid container item sm={4}>
          <Grid item sm={12}>
            <h4 className={styles.itemTitle}>Notification On</h4>
            <h4 className={styles.itemValue}>
              {medicationDelivery?.notificationOn
                ? notificationOn.filter(n => n.value === medicationDelivery.notificationOn).pop()
                    .label
                : null}
            </h4>
          </Grid>
          <Grid item sm={12}>
            <h4 className={styles.itemTitle}>Notification Time</h4>
            <h4 className={styles.itemValue}>
              {medicationDelivery?.notificationTime
                ? isoToLocal(
                    destructHours(medicationDelivery?.notificationTime).toString(),
                    'hh:mm aa',
                  )
                : null}
            </h4>
          </Grid>
        </Grid>

        <Grid container item sm={8} className={styles.teamMembersList}>
          <Grid item sm={12}>
            <h4 className={styles.itemTitle}>ACT Team Member(s) to be notified</h4>
          </Grid>
          {medicationDelivery?.teamMembersToNotify.map((tm, index) => (
            <Grid container key={tm.id}>
              <Grid item sm={2} className={styles.avatarContainer}>
                <Avatar url={tm.photoThumbnail} />
              </Grid>
              <Grid item sm={10} className={styles.teamMembersListItem}>
                <Grid item sm={12}>
                  <b>{`${tm.firstName} ${tm.lastName}`}</b>
                </Grid>
                <Grid item sm={12}>
                  {tm.subRoles[0].name}
                </Grid>
              </Grid>
              {medicationDelivery?.teamMembersToNotify?.length !== index + 1 && (
                <Grid item sm={12}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )}

    <Grid item sm={12} className={styles.closeButtonContainer}>
      <Button variant="contained" color="primary" onClick={onClose}>
        Close
      </Button>
    </Grid>
  </Grid>
);

export default MedicationDeliveryPreview;
