import { privateAxios, publicAxios } from '../requestsCollection';
import { IResponse } from '~/services/api/types';
import {
  ILoginData,
  IAuth,
  IUser,
  IRefreshTokenParams,
  ISignUpData,
  IUpdateUser,
  IChangePasswordData,
  ISendbirdData,
} from './types';

export const validateEmail = (data: { email: string }): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/validate-signup-email',
    data,
  });

export const validateCode = (data: { email: string; code: string }): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/validate-signup-code',
    data,
  });

export const resetPasswordEmail = (data: { email: string }): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/send-reset-password-code',
    data,
  });

export const changePassword = (data: IChangePasswordData): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/change-password',
    data,
  });

export const validateRessetPasswordCode = (data: {
  email: string;
  code: string;
}): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/validate-reset-password-code',
    data,
  });

export const resetPassword = (data: ISignUpData): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/reset-password',
    data,
  });

export const signUp = (data: ISignUpData): IResponse<void> =>
  publicAxios({
    method: 'post',
    url: 'Account/signup',
    data,
  });

export const login = (data: ILoginData): IResponse<IAuth> =>
  publicAxios({
    method: 'post',
    url: 'Auth/login',
    data: { ...data, client: 'Web' },
  });

export const getCurrentUser = (): IResponse<IUser> =>
  privateAxios({
    method: 'get',
    url: 'Account/profile',
  });

export const updateCurrentUser = (data: IUpdateUser): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: 'Account/profile',
    data,
  });

export const refreshToken = (params: IRefreshTokenParams): IResponse<IAuth> =>
  publicAxios({
    method: 'put',
    url: 'Auth/refresh',
    params,
  });

export const fetchSendbirdToken = (): IResponse<ISendbirdData> =>
  privateAxios({
    method: 'get',
    url: 'sendbird/token',
  });
