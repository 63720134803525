import { ReactElement, useState, ElementType, useCallback } from 'react';
import DefaultSelect, { ValueType, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import { debounce } from 'lodash';

import CustomSingleValue from '../../reusable/CustomSingleValue';
import CustomOption from '../../reusable/CustomOption';
import { NoOptionsMessage } from './customComponents';
import { formatIsCreatableValues, getCreatableValues } from './helpers';
import { useStoreActions } from '~/store/hooks';
import { red } from '~/ui/constants/colors';
import { calculateOptionsHeight } from '~/utils/calculateOptionsHeight';
import { IOption, IValue, IsMulti, IAction } from './types';

import customStyles from './customStyles';
import styles from './Select.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  isMulti?: boolean;
  placeholder?: string;
  color?: string;
  minHeight?: string;
  isRelativeWindow?: boolean;
  tabIndex?: number;
  isCreatable?: boolean;
  autoFocus?: boolean;
  customOption?: boolean;
}

const SelectComponent = ({
  control,
  errors,
  name,
  options,
  label,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = true,
  maxMenuHeight = 350,
  placeholder = '',
  color = 'black',
  minHeight,
  isRelativeWindow = false,
  tabIndex,
  isCreatable = false,
  autoFocus = false,
  customOption = false,
}: IProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const formatValue = (option: ValueType<IOption | IOption[], IsMulti>) => {
    if (isCreatable) {
      return formatIsCreatableValues(option as IOption[], showError);
    }

    const value = isMulti
      ? (option as IOption[]).map((item: IOption) => item.value)
      : (option as IOption)?.value ?? '';
    return value;
  };

  const getValue = (value: IValue | IValue[]): IOption | IOption[] => {
    if (options) {
      return isMulti && Array.isArray(value)
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value) || ('' as any);
    }

    return isMulti ? [] : ('' as any);
  };

  const handleOnChange = (
    val: ValueType<IOption | IOption[], IsMulti>,
    action: IAction,
    onChange: (val: any) => void,
  ) => {
    if (action.name === 'emailDomains' && action.action === 'remove-value') {
      const { removedValue } = action;

      const isAlreadyExist = !!options.filter(option => removedValue.value === option.value)[0];

      if (isAlreadyExist) {
        showError('You can not delete initial values for email domains');
        return;
      }
    }
    onChange(formatValue(val));
  };

  const handleClick = useCallback(() => {
    debounce(() => {
      setOpen(!open);
    }, 100)();
  }, [open, debounce, setOpen]);

  const customStylesAdditionally = (isOpen: boolean) => ({
    option: (provided: any, { data, isFocused }: any) => {
      const dataColor = data.color || red;
      const backgroundColor = data.backgroundColor || '#ebebeb';

      return {
        ...provided,
        wordBreak: 'break-word',
        fontWeight: 300,
        backgroundColor: isFocused ? backgroundColor : 'transparent',
        color: isFocused ? dataColor : 'black',
      };
    },
    control: (provided: any, { isFocused }: any) => ({
      ...provided,
      borderWidth: isFocused ? '2px' : '1px',
      borderStyle: 'solid',
      borderColor: isFocused || !!error ? '#d83713!important' : '#c4c4c4',
      borderRadius: '10px',
      minHeight: minHeight || '40px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      ':hover': {
        borderColor: '#1e1f21',
      },
    }),
    singleValue: (provided: any, { data }: any) => {
      const dataColor = data.color;
      return {
        ...provided,
        color: dataColor || color,
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      fontWeight: 400,
    }),
    menu: (provided: any) => ({
      ...provided,
      position: isRelativeWindow ? 'relative' : 'absolute',
      height: isOpen ? calculateOptionsHeight(options, maxMenuHeight, isCreatable) : '0px',
      marginTop: isOpen ? '8px' : '0px',
      marginBottom: isOpen ? '8px' : '0px',
      opacity: isOpen ? 1 : 0,
      overflow: 'hidden',
      visibility: isOpen ? 'visible' : 'hidden',
      transition: 'all 0.3s ease-in-out',
      zIndex: 2,
    }),
    noOptionsMessage: () => ({
      fontWeight: 300,
      paddingLeft: 10,
      paddingTop: 5,
    }),
    options: () => ({
      fontWeight: 300,
      paddingLeft: 10,
      paddingTop: 5,
    }),
  });

  const mergedStyles = { ...customStyles, ...customStylesAdditionally(open) };
  const labelStyles = classNames(styles.label, { [styles.labelError]: error });

  const Select: ElementType = isCreatable ? CreatableSelect : DefaultSelect;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={labelStyles} error={isError}>
            {label}
          </FormLabel>
          <div role="presentation" onFocus={() => handleClick()}>
            <Select
              autoFocus={autoFocus}
              tabSelectsValue={false}
              tabIndex={String(tabIndex)}
              instanceId={name}
              styles={mergedStyles}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isSearchable={isSearchable}
              maxMenuHeight={maxMenuHeight}
              name={name}
              components={{
                NoOptionsMessage: isCreatable ? NoOptionsMessage : components.NoOptionsMessage,
                Option: customOption ? CustomOption : components.Option,
                SingleValue: customOption ? CustomSingleValue : components.SingleValue,
              }}
              menuShouldScrollIntoView={false}
              menuIsOpen={!isDisabled}
              options={options}
              placeholder={placeholder}
              isMulti={isMulti}
              value={isCreatable ? getCreatableValues(value, options) : getValue(value)}
              onChange={(val: ValueType<IOption | IOption[], IsMulti>, action: IAction) => {
                handleOnChange(val, action, onChange);
                setOpen(false);
              }}
              onBlur={() => {
                onBlur();
                setOpen(false);
              }}
              menuPortalTarget={document.body}
            />
          </div>
          {error && (
            <FormHelperText error className={styles.error}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
