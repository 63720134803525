export enum SortByOption {
  'All',
  'Visited Person',
  'Type of Visit',
  'Date of Visit',
}

export enum TypeOfVisit {
  'In Person',
  'Voice Call',
  'Video Call',
}
export enum VisitedPerson {
  'Client',
  'Collateral Contact',
  'No Show',
}

export default {};
