import { ReactElement, useState, useEffect, useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';

import api from '~/services/api';
import DictionariesList from './components/DictionariesList';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';
import Table from './components/Table';
import ModalForm from './components/ModalForm';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import customDictionaryTypes from '~/ui/constants/customDictionaryTypes';
import {
  ICustomDictionaryItem,
  ICustomDictionaryType,
  IDictionaryItem,
} from '~/services/api/dictionaries/types';

import styles from './Dictionaries.module.scss';

export enum ActionTYPE {
  add = 'ADD',
  edit = 'EDIT',
}

const Dictionaries = (): ReactElement => {
  // dictionaryTypes -> all dictionaryTypes, set in onMount function
  // dictionaryType -> selected dictionaryType, default value set in onMount function (first element of dictionariesTypes)
  // dictionaryItem -> selected dictionaryItem
  const [dictionaryTypes, setDictionaryTypes] = useState<Array<ICustomDictionaryType>>([]);
  const [dictionaryType, setDictionaryType] = useState<ICustomDictionaryType>(
    {} as ICustomDictionaryType,
  );
  const [dictionaryItem, setDictionaryItem] = useState<ICustomDictionaryItem | null>(null);

  const [actionType, setActionType] = useState<ActionTYPE | null>(null);

  // importing function from store
  const { onArchiveTypeItem, onRestoreTypeItem, onUpdateTypeItem, onAddTypeItem } = useStoreActions(
    actions => actions.dictionaries,
  );
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  // checking if current dictionaryType has custom fields and return memoized value
  const hasCustomFields = useMemo(() => !!dictionaryType.customFields, [dictionaryType]);

  const handleDictionaryItemUpdateOrAdd = async (updatedItem: any) => {
    if (!updatedItem?.value) {
      showError('Value can not be blank');
      return;
    }
    if (
      hasCustomFields &&
      !updatedItem?.abbreviation &&
      dictionaryType.customFields.some(item => item.title === 'Abbreviation')
    ) {
      showError('Abbreviation can not be blank');
      return;
    }

    const method = actionType === ActionTYPE.add ? onAddTypeItem : onUpdateTypeItem;
    const word = actionType === ActionTYPE.add ? 'created' : 'updated';

    try {
      await method({ ...updatedItem, type: dictionaryType.type });
      setActionType(null);
      setDictionaryItem(null);
      showNotify(`Dictionary successfully ${word}`);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onAddDictionaryItem = (): void => {
    setActionType(ActionTYPE.add);
    setDictionaryItem({} as ICustomDictionaryItem);
  };

  const onEditDictionaryItem = (item: any): void => {
    setActionType(ActionTYPE.edit);
    setDictionaryItem(item);
  };

  const onClose = (): void => {
    setActionType(null);
    setDictionaryItem(null);
  };

  const confirmText = dictionaryItem?.isArchived ? 'Restore' : 'Archive';
  const confirmMethod = dictionaryItem?.isArchived ? onRestoreTypeItem : onArchiveTypeItem;
  const description = dictionaryItem?.isArchived
    ? `Are you sure you want to restore the dictionary ${dictionaryItem?.value}?`
    : `Are you sure you want to archive the dictionary ${dictionaryItem?.value}? You will be able to restore it any time.`;

  const actionModalText = actionType === ActionTYPE.add ? 'Add Dictionary' : 'Save';

  const onConfirmation = (): void => {
    confirmMethod({ type: dictionaryType?.type, id: dictionaryItem.id });
    setDictionaryItem(null);
  };

  // function which used when component is mounted (rendered)
  const onMount = async () => {
    // getting default dictionaryTypes from api call and contact it with custom types
    // set combinedDictionaryTypes to dictionaryTypes state and set default dictionaryType as first of combinedDictionaryTypes
    const { data: defaultDictionaryTypes } = await api.dictionaries.getTypes();
    const combinedDictionaryTypes = [...defaultDictionaryTypes, ...customDictionaryTypes].sort(
      (a, b) => (a.name < b.name ? -1 : 1),
    );

    setDictionaryTypes(combinedDictionaryTypes);
    setDictionaryType(combinedDictionaryTypes[0]);
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <h2>Dictionaries</h2>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => onAddDictionaryItem()}
        >
          Add {dictionaryType?.name}
        </Button>
      </div>
      <div className={styles.content}>
        <DictionariesList
          dictionaryTypes={dictionaryTypes}
          selectedDictionaryType={dictionaryType.type}
          setDictionaryType={setDictionaryType}
        />

        {dictionaryType.type && (
          <div className={styles.tableContainer}>
            <Table
              dictionaryType={dictionaryType}
              hasCustomFields={hasCustomFields}
              setDictionaryItem={setDictionaryItem}
              onEditDictionaryItem={onEditDictionaryItem}
            />
          </div>
        )}

        {actionType && dictionaryItem && (
          <ModalForm
            hasCustomFields={hasCustomFields}
            dictionaryType={dictionaryType}
            dictionaryItem={dictionaryItem}
            handleDictionaryItemUpdateOrAdd={handleDictionaryItemUpdateOrAdd}
            onClose={onClose}
            actionModalText={actionModalText}
          />
        )}

        {!actionType && dictionaryItem && (
          <ConfirmModal
            confirmText={confirmText}
            description={description}
            onConfirm={() => {
              onConfirmation();
            }}
            onClose={() => onClose()}
          />
        )}
      </div>
    </div>
  );
};

export default Dictionaries;
