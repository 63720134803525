import { ISuperAdminStore } from './types';

const initStore: ISuperAdminStore = {
  current: null,
  list: [],
  pagination: {
    pageSize: 5,
    pageNumber: 1,
    total: 0,
  },
};

export default initStore;
