import { ReactElement } from 'react';
import { NavigationConfirm } from 'react-router-navigation-confirm';

import ConfirmModal from '~/ui/components/common/ConfirmModal';

interface IProps {
  when: boolean;
  description: string;
}

const NavigationConfirmModal = ({
  when = true,
  description = 'Are you sure you want to leave this page?',
}: IProps): ReactElement => (
  <NavigationConfirm when={when}>
    {({ onConfirm, onCancel }) => (
      <ConfirmModal description={description} onClose={onCancel} onConfirm={onConfirm} />
    )}
  </NavigationConfirm>
);

export default NavigationConfirmModal;
