import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreClient, IClientStore } from '../types';

const onRestoreClient: IThunkRestoreClient = thunk(
  // @ts-ignore
  async ({ setClients }, payload, { getState }) => {
    const localState = getState() as IClientStore;

    await api.client.restoreClient(payload);

    const updatedList = localState.list.map(item =>
      String(item.id) !== payload.clientId ? item : { ...item, isArchived: false },
    );

    setClients({ items: updatedList });
  },
);

export default onRestoreClient;
