import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTrigger } from '../types';

// @ts-ignore
const onGetTrigger: IThunkGetTrigger = thunk(async ({ setCurrent }, payload) => {
  const current = await api.clientDetails.getClientTrigger(payload).then(r => r.data);

  setCurrent(current);
});

export default onGetTrigger;
