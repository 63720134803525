import { ICustomDictionaryType } from '~/services/api/dictionaries/types';
import DiagnosisDictionary from './diagnosisDictionary';
import MedicationDeliveryRefuseReason from './medicationDeliveryRefuseReason';

const customDictionaryTypes: Array<ICustomDictionaryType> = [
  {
    type: MedicationDeliveryRefuseReason.TYPE,
    name: MedicationDeliveryRefuseReason.NAME,
    customFields: [{ title: 'Abbreviation', name: 'abbreviation' }],
  },
  {
    type: DiagnosisDictionary.TYPE,
    name: DiagnosisDictionary.NAME,
    customFields: [
      {
        title: 'ICD-10',
        name: 'icd10',
      },
    ],
  },
];

export default customDictionaryTypes;
