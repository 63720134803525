import { ReactElement, ReactNode } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

import editIcon from '~/ui/assets/images/editGrey.svg';
import injectionIcon from '~/ui/assets/images/injection.svg';
import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import circleIcon from '~/ui/assets/images/circle.svg';
import styles from './ListItem.module.scss';

interface IProps {
  label: string | ReactElement;
  dose?: string;
  content?: string | ReactNode;
  showArchived?: boolean;
  injectable?: boolean;
  showEdit?: boolean;
  showRestore?: boolean;
  noActions?: boolean;
  onArchive?: () => void;
  onEdit?: () => void;
  onRestore?: () => void;
  className?: any;
  showCircle?: boolean;
  additionalText?: string;
  moreLabelWidth?: boolean;
  labelGrey?: boolean;
}

const ListItem = ({
  label,
  content,
  showArchived,
  showEdit,
  showRestore,
  onArchive,
  onEdit,
  onRestore,
  dose,
  injectable,
  noActions,
  className,
  showCircle = false,
  additionalText = '',
  moreLabelWidth = false,
  labelGrey,
}: IProps): ReactElement => (
  <div className={classNames(styles.item, className)}>
    <div className={styles.contentWrapper}>
      <div
        className={classNames(styles.label, {
          [styles.grey]: showRestore || labelGrey,
          [styles.moreLabelWidth]: moreLabelWidth,
        })}
      >
        {showCircle && (
          <div>
            <img src={circleIcon} alt="circle" />
          </div>
        )}
        {label}
      </div>
      <div className={styles.column}>
        <div className={styles.row}>
          {injectable && <img src={injectionIcon} alt="injection" className={styles.injection} />}
          <span>{dose}</span>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
    {!noActions && (
      <div>
        {additionalText && <h5 className={styles.additionalText}>{additionalText}</h5>}
        {showEdit && (
          <IconButton onClick={onEdit}>
            <img src={editIcon} alt="edit" />
          </IconButton>
        )}
        {showArchived && (
          <IconButton onClick={onArchive} edge="end">
            <img src={archiveIcon} alt="archiveIcon" />
          </IconButton>
        )}
        {showRestore && (
          <div role="presentation" onClick={onRestore} className={styles.restoreButton}>
            Restore
          </div>
        )}
      </div>
    )}
  </div>
);

export default ListItem;
