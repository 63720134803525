import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement } from 'react';

import CalendarInitials from '../CalendarInitials';
import DeliveryTime from '../DeliveryTime';
import RefuseLabel from '../RefuseLabel';

import {
  IMedicationDelivery,
  IMedicationDeliveryMedication,
} from '~/services/api/medicationLogistics';
import styles from './CellContent.module.scss';

interface IProps {
  medicationDeliveries?: IMedicationDelivery[];
  medication: IMedicationDeliveryMedication;
  weekMode: boolean;
  archived?: boolean;
  onEdit?: (id: number, name: string, isArchived?: boolean) => void;
}

const CellContent = ({
  medicationDeliveries,
  weekMode,
  archived,
  medication,
  onEdit,
}: IProps): ReactElement => {
  const cellClick = (e: React.MouseEvent<HTMLElement>, d: IMedicationDelivery) => {
    e.stopPropagation();
    onEdit(d.id, medication.medication.name, medication.isArchived);
  };

  return (
    <Box className={classNames(styles.cellContent, { [styles.grey]: archived })}>
      {medicationDeliveries.map((d, index) => (
        <Box
          onClick={(e: React.MouseEvent<HTMLElement>) => cellClick(e, d)}
          key={d.id}
          className={classNames(styles.innerContent, {
            [styles.green]: !d.notDelivered,
            [styles.bottomBorder]: medicationDeliveries.length !== index + 1,
            [styles.bottomBorderWeekMode]: medicationDeliveries.length !== index + 1 && weekMode,
          })}
        >
          <Box
            className={classNames(styles.column, {
              [styles.row]: d.notDelivered && weekMode,
              [styles.weekMode]: weekMode,
            })}
          >
            <CalendarInitials
              name={d.teamMember.name}
              refused={d.notDelivered}
              archived={medication.isArchived}
            />
            {d.notDelivered && weekMode && <span className={styles.separator}>|</span>}
            {d.notDelivered && (
              <RefuseLabel
                refuseReason={d.nonDeliveryReason.name}
                archived={medication.isArchived}
              />
            )}
            {!d.notDelivered && (
              <DeliveryTime time={d.date} weekMode={weekMode} archived={medication.isArchived} />
            )}
          </Box>
          {weekMode && <span className={styles.note}>{d.note}</span>}
        </Box>
      ))}
    </Box>
  );
};

export default CellContent;
