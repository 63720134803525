import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkClinicArchive, IClinicStore } from '../types';

const onArchiveClinic: IThunkClinicArchive = thunk(
  // @ts-ignore
  async ({ setClinics }, clinicId, { getState }) => {
    const localState = getState() as IClinicStore;

    await api.clinic.archiveClinic(clinicId);

    const updatedList = localState.list.map(clinic =>
      clinic.id !== clinicId ? clinic : { ...clinic, isArchived: true },
    );

    setClinics({ items: updatedList });
  },
);

export default onArchiveClinic;
