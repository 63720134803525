import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkActivateSuperAdmin, ISuperAdminStore } from '../types';

const onActivateSuperAdmin: IThunkActivateSuperAdmin = thunk(
  // @ts-ignore
  async ({ setSuperAdmins }, superAdminId, { getState }) => {
    const localState = getState() as ISuperAdminStore;
    await api.superAdmin.activateSuperAdmin(superAdminId);

    const updatedList = localState.list.map(superAdmin =>
      superAdmin.id !== superAdminId ? superAdmin : { ...superAdmin, status: 'Active' },
    );

    setSuperAdmins({ items: updatedList });
  },
);

export default onActivateSuperAdmin;
