import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetAvailableActTeamMembers } from '../types';

const onGetAvailableActTeamMembers: IThunkGetAvailableActTeamMembers = thunk(
  // @ts-ignore
  async ({ setAvailableActTeamMembers }, payload) => {
    const { clinicId, actTeamId } = payload;

    const { data } = await api.actTeamMember.getActTeamMemberList(clinicId, actTeamId);

    setAvailableActTeamMembers(data);
  },
);

export default onGetAvailableActTeamMembers;
