import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactElement } from 'react';

import styles from './DeliveryTime.module.scss';

interface IProps {
  time: string;
  weekMode: boolean;
  archived: boolean;
}

const DeliveryTime = ({ time, weekMode, archived }: IProps): ReactElement => (
  <div
    className={classNames(styles.deliveryTime, styles.weekMode, { [styles.archived]: archived })}
  >
    <div className={classNames(styles.icon, { [styles.archivedIcon]: archived })} />
    {!weekMode && <span>{format(new Date(time), 'aaa')}</span>}
    <div className={classNames(styles.column, { [styles.rowReverse]: weekMode })}>
      {weekMode && <span>{format(new Date(time), 'aaa')}</span>}
      <span className={classNames({ [styles.margin]: weekMode })}>
        {format(new Date(time), 'hh:mm')}
      </span>
    </div>
  </div>
);

export default DeliveryTime;
