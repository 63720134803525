/* eslint no-underscore-dangle: 0 */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { getAccessToken, getRefreshToken, setTokens } from '~/services/auth';
import { IResponse, ITokenParams, IRefreshAccessToken } from './types';

const formatErrorResponse = (err: any) => err?.response;

const getNewTokens = (tokens: ITokenParams): IResponse<IRefreshAccessToken> =>
  axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}auth/refresh`,
    data: tokens,
  });

const requestInterceptor = (instance: AxiosInstance, isPrivate: boolean) => {
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (!isPrivate) return config;

    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });
};

const refreshTokens = async (instance: AxiosInstance, error: AxiosError) => {
  const currentAccessToken = getAccessToken();
  const currentRefreshToken = getRefreshToken();

  const originalConfig = error.config;

  const shouldRefreshToken =
    currentAccessToken &&
    currentRefreshToken &&
    error.response?.status === 401 &&
    // @ts-ignore
    !originalConfig._retry;

  if (!shouldRefreshToken) {
    return Promise.reject(formatErrorResponse(error));
  }

  // @ts-ignore
  originalConfig._retry = true; // set retry option

  try {
    const { accessToken, refreshToken } = await getNewTokens({
      accessToken: currentAccessToken,
      refreshToken: currentRefreshToken,
    }).then(res => res.data);
    // Set new tokens
    // Request interceptor will get and use new updated `accessToken` from LocalStorage
    setTokens({ accessToken, refreshToken });

    return await instance(originalConfig);
  } catch (err) {
    return Promise.reject(formatErrorResponse(err));
  }
};

const responseInterceptor = (instance: AxiosInstance, isPrivate: boolean) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (!isPrivate) {
        return Promise.reject(formatErrorResponse(error));
      }

      return refreshTokens(instance, error);
    },
  );
};

const addInterceptors = (instance: AxiosInstance, isPrivate: boolean): void => {
  requestInterceptor(instance, isPrivate);
  responseInterceptor(instance, isPrivate);
};

const axiosInstance = (baseURL: string, isPrivate = false): AxiosInstance => {
  const instance = axios.create({ baseURL });
  addInterceptors(instance, isPrivate);
  return instance;
};

export default axiosInstance;
