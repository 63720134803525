import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IOption } from '~/types';
import styles from './SimpleSelectWithoutGrid.module.scss';

interface IProps {
  value: number;
  label: string;
  options: IOption[];
  setValue: Dispatch<SetStateAction<number>>;
}

const SimpleSelectWithoutGrid = ({ value, options, label, setValue }: IProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleChange = (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setValue(event.target.value as number);
  };

  const renderItem = (item: IOption) => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  );

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <InputLabel id="label-select">{label}</InputLabel>
      <Select
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        labelId="label-select"
        id={label}
        value={value}
        label={label}
        onChange={handleChange}
        IconComponent={() =>
          open ? (
            <ExpandLessIcon className={styles.arrow} color="secondary" />
          ) : (
            <ExpandMoreIcon className={styles.arrow} color="secondary" />
          )
        }
      >
        {options.map(renderItem)}
      </Select>
    </FormControl>
  );
};

export default SimpleSelectWithoutGrid;
