import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkAddVisit } from '../types';

const onAddVisit: IThunkAddVisit = thunk(
  // @ts-ignore
  async ({ setVisit }, payload) => {
    const {
      requestInfo: { clinicId, teamId },
      requestPayload,
    } = payload;
    const data = await api.visits.addVisit({ clinicId, teamId }, requestPayload);

    setVisit(data);
  },
);

export default onAddVisit;
