import React, { ReactElement, useMemo } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';

import Status from '~/ui/components/common/Status';

import {
  ICustomDictionaryItem,
  ICustomDictionaryType,
  IDictionaryItem,
} from '~/services/api/dictionaries/types';

import editGrey from '~/ui/assets/images/editGrey.svg';
import archiveIcon from '~/ui/assets/images/archiveIcon.svg';

import styles from '../../../Dictionaries.module.scss';

export interface IProps {
  dictionaryItem: any;
  dictionaryType: ICustomDictionaryType;
  hasCustomFields: boolean;
  setDictionaryItem: (item: ICustomDictionaryItem | null) => void;
  onEditDictionaryItem: (item: ICustomDictionaryItem) => void;
}

export const TableBodyItem = ({
  dictionaryItem,
  hasCustomFields,
  dictionaryType,
  setDictionaryItem,
  onEditDictionaryItem,
}: IProps): ReactElement => {
  const bodyCustomFields = useMemo(() => {
    if (hasCustomFields)
      return dictionaryType.customFields.map(({ name }) => (
        <TableCell key={name}>{dictionaryItem[name as keyof typeof dictionaryItem]}</TableCell>
      ));
    return null;
  }, [dictionaryItem]);

  return (
    <TableRow key={dictionaryItem.id} className={styles.tableRowHeight}>
      <TableCell>{dictionaryItem.value}</TableCell>
      {hasCustomFields && bodyCustomFields}
      <TableCell>
        <Status isArchived={dictionaryItem.isArchived} />
      </TableCell>
      <TableCell>
        {dictionaryItem.isArchived ? (
          <div
            role="presentation"
            className={styles.actionButton}
            onClick={() => {
              setDictionaryItem(dictionaryItem);
            }}
          >
            Restore
          </div>
        ) : (
          <>
            <IconButton
              onClick={() => {
                onEditDictionaryItem(dictionaryItem);
              }}
            >
              <img src={editGrey} alt="edit" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDictionaryItem(dictionaryItem);
              }}
            >
              <img src={archiveIcon} alt="archive" />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
