import { IClientDetailsStore } from './types';

const initStore: IClientDetailsStore = {
  current: null,
  supportContacts: [],
  deEscalationTechniques: [],
  diagnoses: [],
  medications: [],
  triggers: [],
  allergies: [],
};

export default initStore;
