import { useEffect, ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import { requiredField } from '~/ui/constants/errorMessages';

import styles from './EmailForm.module.scss';

const resolver = yupResolver(
  object().shape({ email: string().required(requiredField).email('Invalid Email') }),
);

interface IProps {
  onSubmit: (val: { email: string }) => void;
  error: string;
  resetError: () => void;
}

const EmailForm = ({ onSubmit, error, resetError }: IProps): ReactElement => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({ resolver });
  const emailValue = watch('email');

  useEffect(() => {
    resetError();
  }, [emailValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={styles.signUp}>
        <h2 className={styles.title}>Welcome to VelloHealth</h2>
        <p className={styles.subTitle}>Please enter your email address below</p>

        <Input register={register} errors={errors} name="email" label="Email" />
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Sign Up
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default EmailForm;
