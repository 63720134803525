import { ReactElement, useState } from 'react';
import Select, { ValueType, components } from 'react-select';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

import { red, grey } from '~/ui/constants/colors';
import { calculateOptionsHeight } from '~/utils/calculateOptionsHeight';
import { IOption } from '~/types';

import customStyles from './customStyles';
import indicatorSvg from '~/ui/assets/images/selectIcon.svg';
import styles from './SelectEmailDomain.module.scss';

type IValue = string | number;

type IsMulti = boolean;

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  isMulti?: boolean;
  placeholder?: string;
  color?: string;
  minHeight?: string;
  isRelativeWindow?: boolean;
  tabIndex?: number;
}

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <img src={indicatorSvg} alt="indicator" />
  </components.DropdownIndicator>
);

const SelectEmailDomain = ({
  control,
  errors,
  name,
  options,
  label,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isSearchable = false,
  maxMenuHeight = 350,
  placeholder = '',
  color = 'black',
  minHeight,
  isRelativeWindow = false,
  tabIndex,
}: IProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const formatValue = (option: ValueType<IOption | IOption[], IsMulti>) => {
    const value = isMulti
      ? (option as IOption[]).map((item: IOption) => item.value)
      : (option as IOption)?.value ?? '';
    return value;
  };

  const getValue = (value: IValue | IValue[]): IOption | IOption[] => {
    if (options) {
      return isMulti && Array.isArray(value)
        ? options.filter(option => value.indexOf(option.value) >= 0)
        : options.find(option => option.value === value) || { label: '', value: '' };
    }

    return isMulti ? [] : { label: '', value: '' };
  };

  const customStylesAdditionaly = (isOpen: boolean) => ({
    option: (provided: any, { data, isFocused }: any) => {
      const dataColor = data.color || red;
      const backgroundColor = data.backgroundColor || '#ebebeb';

      return {
        ...provided,
        wordBreak: 'break-word',
        fontWeight: 300,
        backgroundColor: isFocused ? backgroundColor : 'transparent',
        color: isFocused ? dataColor : 'black',
      };
    },
    control: (provided: any, { isFocused }: any) => ({
      ...provided,
      borderWidth: isFocused ? '2px' : '1px',
      borderStyle: 'solid',
      borderColor: isFocused || !!error ? `${red} !important` : '#c4c4c4',
      borderRadius: '10px',
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minHeight: minHeight || '40px',
      boxShadow: 'none',
      backgroundColor: grey,
      ':hover': {
        borderColor: red,
      },
    }),
    singleValue: (provided: any, { data }: any) => {
      const dataColor = data.color;
      return {
        ...provided,
        color: dataColor || color,
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      fontWeight: 600,
      display: 'flex',
      justifyContent: 'center',
    }),
    menu: (provided: any) => ({
      ...provided,
      position: isRelativeWindow ? 'relative' : 'absolute',
      height: isOpen ? calculateOptionsHeight(options, maxMenuHeight) : '0px',
      marginTop: isOpen ? '8px' : '0px',
      marginBottom: isOpen ? '8px' : '0px',
      opacity: isOpen ? 1 : 0,
      overflow: 'hidden',
      visibility: isOpen ? 'visible' : 'hidden',
      transition: 'all 0.3s ease-in-out',
      zIndex: 3,
    }),
  });

  const mergedStyles = { ...customStyles, ...customStylesAdditionaly(open) };
  const labelStyles = classNames(styles.label, { [styles.labelError]: error });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={labelStyles} error={isError}>
            {label}
          </FormLabel>
          <div role="presentation" onClick={() => setOpen(!open)}>
            <Select
              tabSelectsValue={false}
              tabIndex={String(tabIndex)}
              instanceId={name}
              styles={mergedStyles}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isSearchable={isSearchable}
              maxMenuHeight={maxMenuHeight}
              name={name}
              menuShouldScrollIntoView={false}
              menuIsOpen={!isDisabled}
              options={options}
              placeholder={placeholder}
              isMulti={isMulti}
              components={{
                DropdownIndicator: options?.length > 1 ? DropdownIndicator : null,
              }}
              value={getValue(value)}
              onChange={val => onChange(formatValue(val))}
              onBlur={() => {
                onBlur();
                setOpen(false);
              }}
            />
          </div>
          {error && (
            <FormHelperText error className={styles.error}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectEmailDomain;
