import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Box,
  TableContainer,
  Table as MUITable,
  TableHead,
  TableCell,
  Grid,
  TableBody,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import {
  ICustomDictionaryItem,
  ICustomDictionaryType,
  IDictionariesParams,
} from '~/services/api/dictionaries/types';

import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import { TableBodyItem } from './components/TableBodyItem';

import styles from '../../Dictionaries.module.scss';

const defaultValues = {
  name: '',
};

interface IProps {
  dictionaryType: ICustomDictionaryType;
  hasCustomFields: boolean;
  setDictionaryItem: (item: ICustomDictionaryItem | null) => void;
  onEditDictionaryItem: (item: ICustomDictionaryItem) => void;
}

const Table = ({
  dictionaryType,
  hasCustomFields,
  setDictionaryItem,
  onEditDictionaryItem,
}: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = formMethods;

  const list = useStoreState(state => state.dictionaries.list);
  const pagination = useStoreState(state => state.dictionaries.pagination);
  const onGetTypeList = useStoreActions(actions => actions.dictionaries.onGetTypeList);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const name = watch('name');

  // performing  search
  const onSubmit = (val: { name: string }) =>
    onGetTypeList({ type: dictionaryType.type, params: { ...val, ...pagination } });
  // working with pagination
  const handlePagination = (pageSize: number, pageNumber: number) =>
    onGetTypeList({ type: dictionaryType.type, params: { name, pageSize, pageNumber } });

  // loading table content
  const getTypeList = async (payload: { type: string; params: IDictionariesParams }) => {
    try {
      setLoading(true);
      await onGetTypeList(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  // loading table content on dictionaryType change
  useEffect(() => {
    getTypeList({ type: dictionaryType.type, params: { name, ...pagination, pageNumber: 1 } });
  }, [dictionaryType.type]);

  const headerCustomFields = useMemo(() => {
    if (hasCustomFields)
      return dictionaryType.customFields.map(({ title }) => (
        <TableCell key={title}>{title}</TableCell>
      ));
    return null;
  }, [dictionaryType.type]);

  if (loading) return <Loader />;

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1} className={styles.tableHeader}>
          <Grid item sm={4}>
            <h3>{dictionaryType?.name}</h3>
          </Grid>
          <Grid item sm={8}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search"
                  register={register}
                  name="name"
                  errors={errors}
                />
                <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>

      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>{dictionaryType?.name}</TableCell>
            {hasCustomFields && headerCustomFields}
            <TableCell>Status</TableCell>
            <TableCell className={styles.headerCell}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Loader />
              </TableCell>
            </TableRow>
          ) : (
            list.map(item => (
              <TableBodyItem
                key={item.id}
                dictionaryItem={item}
                setDictionaryItem={setDictionaryItem}
                dictionaryType={dictionaryType}
                hasCustomFields={hasCustomFields}
                onEditDictionaryItem={onEditDictionaryItem}
              />
            ))
          )}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
