import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetAllergy } from '../types';

// @ts-ignore
const onGetAllergy: IThunkGetAllergy = thunk(async ({ setCurrent }, payload) => {
  const current = await api.clientDetails.getClientAllergy(payload).then(r => r.data);

  setCurrent(current);
});

export default onGetAllergy;
