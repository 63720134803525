import { useEffect, ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import api from '~/services/api';
import Loader from '~/ui/components/common/Loader';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { secondaryDiagnosisValidationSchema } from './validates';

import { ISecondaryDiagnosisInitialValues, IRequestInfo } from './types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IOption } from '~/types';
import { IDetails } from '../types';
import styles from './Styles.module.scss';

interface IProps {
  showSecondSubmit: boolean;
  setModalTitle: (v: any) => void;
  setDetails: (v: any) => void;
  defaultValues: ISecondaryDiagnosisInitialValues;
  details: IDetails | null;
  requestInfo: IRequestInfo;
}

const AddSecondaryDiagnosis = ({
  showSecondSubmit,
  setModalTitle,
  setDetails,
  defaultValues,
  details,
  requestInfo,
}: IProps): ReactElement => {
  const [secondaryDiagnoses, setsecondaryDiagnoses] = useState<IOption[]>([] as IOption[]);
  const [loading, setLoading] = useState(false);

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: secondaryDiagnosisValidationSchema });

  const setCurrent = useStoreActions(actions => actions.clientDetails.setCurrent);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onCreateSecondaryDiagnosis, onUpdateSecondaryDiagnosis } = useStoreActions(
    actions => actions.clientDetails,
  );

  const onMount = async () => {
    try {
      const secondaryDiagnosisDictionaries = await api.dictionaries
        .getAvailableTypeList(IDictionaryTypes.Diagnosis)
        .then(r => formatDictionaryOptions(r.data));

      setsecondaryDiagnoses(secondaryDiagnosisDictionaries);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onSubmit = async (vals: ISecondaryDiagnosisInitialValues, evt: any) => {
    const { name } = evt.nativeEvent.submitter;
    try {
      if (details && details.id) {
        const payload = {
          requestInfo: { ...requestInfo, id: String(details.id) },
          requestPayload: vals,
        };

        onUpdateSecondaryDiagnosis(payload);

        setDetails(null);
        setCurrent(null);
      } else {
        const payload = {
          requestInfo,
          requestPayload: vals,
        };

        await onCreateSecondaryDiagnosis(payload);

        reset({ id: null, note: '' });
      }
      setModalTitle(name);
      const type = details?.id ? 'update' : 'added';
      showNotify(`Secondary diagnosis successfully ${type}`);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <SelectComponent
            isRelativeWindow
            name="id"
            options={secondaryDiagnoses}
            control={control}
            errors={errors}
            label="Select Secondary Dx"
            isDisabled={!showSecondSubmit}
          />
        </Grid>
        <Grid item sm={12}>
          <Input
            size="medium"
            name="note"
            register={register}
            label="Secondary Dx Note"
            multiline
            errors={errors}
          />
        </Grid>
      </Grid>
      <div className={styles.buttonsWrapper}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setDetails(null);
            setModalTitle(null);
            setCurrent(null);
          }}
        >
          Cancel
        </Button>
        {showSecondSubmit && (
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            name="Add Secondary Diagnosis"
            className={styles.margin}
          >
            Save and Add another
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          type="submit"
          name={null}
          className={!showSecondSubmit ? styles.margin : ''}
        >
          {showSecondSubmit ? 'Save' : 'Update'}
        </Button>
      </div>
    </form>
  );
};

export default AddSecondaryDiagnosis;
