import { useRef, ReactElement } from 'react';
import { FileDrop } from 'react-file-drop';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import PrivateImage from '../PrivateImage';
import { isFile, validateFileType } from '~/utils/file';

import trashRedIcon from '~/ui/assets/images/trashRed.svg';
import trashGreyIcon from '~/ui/assets/images/trashGrey.svg';
import profileIcon from '~/ui/assets/images/profilePhoto.svg';
import uploadIcon from '~/ui/assets/images/upload.svg';

import styles from './ProfilePhotoUpload.module.scss';

interface IProps {
  value?: any;
  acceptTypes?: string;
  maxFileSize?: number;
  disabled: boolean;
  onUpload: (file: File) => void;
  onRemove: () => void;
  onError: (err: string) => void;
  showButtons?: boolean;
}

const ProfilePhotoUpload = ({
  showButtons = true,
  value,
  acceptTypes = 'image/jpeg,image/png,image/gif',
  maxFileSize = 2, // 2Mb
  onUpload,
  onRemove,
  onError,
  disabled,
}: IProps): ReactElement => {
  const fileInputRef = useRef(null);

  const validateFile = (file: File) => {
    if (!isFile(file)) return 'No file';

    if (!validateFileType(file, acceptTypes)) {
      const shortTypeNames = acceptTypes
        .split(',')
        .map(type => type.split('/').pop().toLowerCase())
        .join(',');

      return `Only ${shortTypeNames} formats are valid`;
    }

    // convert fileSize from Mb to bytes (1 Mb = 1048576 Bytes)
    const maxFileSizeBytes = maxFileSize * 1048576;

    if (maxFileSize && file.size > maxFileSizeBytes) return `Max file size - ${maxFileSize}Mb`;

    return '';
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onChange = (file: File) => {
    const err = validateFile(file);

    if (err) {
      onError(err);
    } else {
      // Reset input value
      fileInputRef.current.value = '';
      onUpload(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={classnames(styles.dragAndDropContainer)}>
        <FileDrop
          onTargetClick={onTargetClick}
          onDrop={(files, event) => {
            event.preventDefault();
            onChange(files[0]);
          }}
          className={styles.dropzone}
          targetClassName={classnames(styles.dropzoneTarget, { [styles.standartCursor]: disabled })}
          draggingOverTargetClassName={styles.draggingOverTargetClassName}
        />
        {/* Use input for onClick handler */}
        <input
          ref={fileInputRef}
          type="file"
          disabled={disabled}
          accept={acceptTypes}
          className={styles.hidden}
          onChange={event => {
            const { files } = event.target;
            onChange(files[0]);
          }}
        />
        <div className={styles.dragAndDropDisplayContent}>
          <div className={styles.contentIcon}>
            {value ? (
              <PrivateImage
                src={isFile(value) ? URL.createObjectURL(value) : value}
                className={styles.image}
                alt="Preview"
              />
            ) : (
              <img src={profileIcon} alt="profile_photo" />
            )}
          </div>
        </div>
        {showButtons && (
          <div className={styles.buttonsWrapper}>
            <IconButton onClick={onTargetClick} title="Edit" disabled={disabled}>
              <img src={uploadIcon} alt="uploadIcon" />
            </IconButton>
            <div className={styles.divider} />
            <IconButton onClick={onRemove} title="Remove" disabled={!value || disabled}>
              <img src={value ? trashRedIcon : trashGreyIcon} alt="remove" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePhotoUpload;
