import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import { IClient, IClientDetailed, IClientRequest } from './types';

export const getClients = (
  clinicId: string,
  params: IParams,
): IResponse<{ items: IClient[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/list`,
    params,
  });

export const getTeamClients = (
  clinicId: string,
  teamId: string,
  params: IParams,
): IResponse<{ items: IClient[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/list`,
    params,
  });

export const getClient = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientDetailed> =>
  privateAxios({
    method: 'get',
    url: `/clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
  });

export const archiveClient = (
  {
    clinicId,
    teamId,
    clientId,
  }: {
    clinicId: string;
    teamId: string;
    clientId: string;
  },
  data: { dischargeReasonId: number; dischargeDate: string },
): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
    data,
  });

export const restoreClient = ({
  clinicId,
  teamId,
  clientId,
}: {
  clinicId: string;
  teamId: string;
  clientId: string;
}): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/recover`,
  });

export const addClient = (
  { clinicId, teamId }: { clinicId: string; teamId: string },
  data: IClientRequest,
): IResponse<IClientDetailed> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client`,
    data,
  });

export const editClient = (
  {
    clinicId,
    teamId,
    clientId,
  }: {
    clinicId: string;
    teamId: string;
    clientId: string;
  },
  data: IClientRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
    data,
  });

export const uploadClientPhoto = (
  {
    clinicId,
    teamId,
    clientId,
  }: {
    clinicId: string;
    teamId: string;
    clientId: string;
  },
  photo: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/photo`,
    data: { photo },
  });

export default {};
