import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { concat } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStoreState } from '~/store/hooks';
import {
  CLINIC_MANAGEMENT,
  EDIT_CLINIC,
  CLINIC_CREATE_ACT_TEAM,
  CLINIC_ADD_LOCAL_ADMIN,
  CLINIC_ADD_PROGRAM_ASSISTANT,
} from '~/ui/constants/paths';

import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import PrivateImage from '~/ui/components/common/PrivateImage';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from './Header.module.scss';

const itemForBreadcrumbs = [
  {
    title: 'Clinic Management',
    to: CLINIC_MANAGEMENT,
  },
];

interface IProps {
  id: string;
  name: string;
  tab: number;
  setTab: (num: number) => void;
  currentTab: number;
}

const Header = ({ name, tab, id, setTab, currentTab }: IProps): ReactElement => {
  const { location, push } = useHistory();

  const logo = useStoreState(state => state.clinic.current?.logo);

  const itemsForBreadcrumbs = concat(itemForBreadcrumbs, {
    title: name,
    to: location.pathname,
  });

  return (
    <>
      <div className={styles.header}>
        <div>
          <h2>{name}</h2>
          <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
        </div>
        <div>
          <div className={styles.headerRightSide}>
            <div className={styles.imageContainer}>
              {logo && <PrivateImage src={logo} alt="logo" className={styles.logo} withLoader />}
            </div>
            {currentTab === 0 && (
              <Button
                startIcon={<img src={editWhite} alt="edit" />}
                variant="contained"
                color="primary"
                onClick={() => push(EDIT_CLINIC.replace(':id', id))}
              >
                Edit Clinic Profile
              </Button>
            )}
            {currentTab === 1 && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={() => push(CLINIC_CREATE_ACT_TEAM.replace(':clinicId', id))}
              >
                Add New ACT Team
              </Button>
            )}
            {currentTab === 2 && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={() => push(CLINIC_ADD_LOCAL_ADMIN.replace(':id', id))}
              >
                Add New Local Admin
              </Button>
            )}
            {currentTab === 3 && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={() => push(CLINIC_ADD_PROGRAM_ASSISTANT.replace(':id', id))}
              >
                Add Program Assistant
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.tabsWrapper}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tab}
          onChange={(_e, val) => setTab(val)}
        >
          <Tab label="CLINIC PROFILE" />
          <Tab label="ACT TEAM" />
          <Tab label="LOCAL ADMIN" />
          <Tab label="PROGRAM ASSISTANT" />
        </Tabs>
      </div>
    </>
  );
};

export default Header;
