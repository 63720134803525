import { Grid } from '@material-ui/core';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';

import { extractErrorMessage } from '~/utils/error';
import { formatActTeamMemberOptions } from '~/utils/formatActTeamMemberOptions';
import { formatDictionaryOptions } from '~/utils/formatDictionaryOptions';
import { formatEnumToOptions } from '../../utils/formatEnumToOptions';
import { getSeconds } from '../../utils/getSeconds';

import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import TimePicker from '~/ui/components/inputs/TimePicker';
import InputMask from '~/ui/components/inputs/InputMask';

import validation from './validation';
import { PREVIEW_VISIT, VISITS } from '~/ui/constants/paths';
import { TypeOfVisit } from '../../constants/filterOptions';
import { IOption } from '~/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IVisitForm, IVisitRequestPayload } from '~/services/api/visits/types';

import { ReactComponent as ClockIcon } from '~/ui/assets/images/clockIcon.svg';
import styles from './Form.module.scss';

interface IProps {
  visitId: string;
  defaultValues: IVisitForm | null;
  fromPreview?: boolean;
}
const Form = ({ visitId, defaultValues, fromPreview }: IProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [noShowReasons, setNoShowReasons] = useState<IOption[]>([]);
  const [teamMembers, setTeamMembers] = useState<IOption[]>([]);
  const [states, setStates] = useState<IOption[]>([]);
  const { push } = useHistory();

  const query = useQuery();
  const teamId = query.get('teamId');
  const clientId = query.get('clientId');

  const {
    control,
    register,
    unregister,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: validation, defaultValues });
  const clientNoShow = watch('clientNoShow');
  const type = watch('type');

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { current: visit } = useStoreState(state => state.visits);

  const { onGetClient } = useStoreActions(action => action.client);
  const { onAddVisit, onEditVisit } = useStoreActions(action => action.visits);
  const { showError } = useStoreActions(action => action.snackbar);

  const visitedPersonsList = useMemo((): IOption[] => {
    if (client) {
      const array = [
        { label: `${client.firstName} ${client.lastName} | Client`, value: client.id },
      ];
      client.supportContacts.forEach(contact => {
        if (!contact.isArchived) {
          array.push({
            label: `${contact.firstName} ${contact.lastName} | ${contact.relationship.name}`,
            value: contact.id,
          });
        }
      });
      return array;
    }
    return [];
  }, [client]);

  const onCancel = (): void => {
    if (fromPreview) {
      push(
        PREVIEW_VISIT.replace(':visitId', String(visitId)).concat(
          `?teamId=${teamId}&clientId=${clientId}`,
        ),
      );
    } else {
      push(VISITS.concat(`?teamId=${teamId}&clientId=${clientId}`));
    }
  };

  const onSubmit = (values: IVisitForm): void => {
    const {
      type: typeOfVisit,
      visitedPeople,
      date,
      startTime,
      line1,
      city,
      stateId,
      zipCode,
      duration,
      noShowReason,
      ...rest
    } = values;

    const requestPayload: IVisitRequestPayload = {
      type: TypeOfVisit[Number(typeOfVisit)].replace(' ', ''),
      durationSeconds: !clientNoShow ? getSeconds(duration) : null,
      date,
      startTime: startTime.toString().split(' ')[4],
      clientId: Number(clientId),
      isClientVisited: !clientNoShow ? visitedPeople.includes(client.id) : false,
      visitedPeople: !clientNoShow ? visitedPeople.filter(person => person !== client.id) : null,
      address: line1 && stateId ? { line1, stateId, city, zipCode } : null,
      noShowReason: clientNoShow ? noShowReason : null,
      ...rest,
    };

    try {
      if (!defaultValues) {
        onAddVisit({
          requestInfo: { clinicId: String(current?.clinic?.id), teamId },
          requestPayload,
        });
      } else {
        onEditVisit({
          requestInfo: { clinicId: String(current?.clinic?.id), teamId, visitId },
          requestPayload,
        });
      }
      onCancel();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onMount = async () => {
    setLoading(true);
    await onGetClient({
      clinicId: String(current?.clinic?.id),
      teamId,
      clientId,
    });

    const { data: members } = await api.actTeamMember.getActTeamMemberList(
      String(current?.clinic?.id),
      teamId,
    );
    setTeamMembers(formatActTeamMemberOptions(members));

    const { data: reasons } = await api.dictionaries.getAvailableTypeList(
      IDictionaryTypes.CLIENT_NO_SHOW_REASON,
    );
    setNoShowReasons(formatDictionaryOptions(reasons));
    const { data: dictionaryStates } = await api.dictionaries.getAvailableTypeList(
      IDictionaryTypes.State,
    );
    setStates(formatDictionaryOptions(dictionaryStates));

    setLoading(false);
  };

  useEffect(() => {
    onMount();
  }, [visitId]);

  const isPersonVisit = type === 0;

  useEffect(() => {
    if (!clientNoShow) {
      setValue('noShowReason', null);
    } else {
      unregister(['startTime']);
    }
    if (!isPersonVisit) {
      unregister(['stateId', 'zipCode', 'line1', 'city']);
    }
  }, [clientNoShow, isPersonVisit]);

  if (loading) return <Loader />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className={styles.form}>
        <Grid item sm={12}>
          <h4>Main information</h4>
        </Grid>
        <Grid item sm={3}>
          <DatePicker
            control={control}
            errors={errors}
            name="date"
            label="Date of Visit"
            maxDate={new Date().toISOString()}
          />
        </Grid>
        <Grid item sm={3}>
          <Select
            control={control}
            errors={errors}
            name="type"
            options={formatEnumToOptions(TypeOfVisit)}
            label="Type of Visit"
          />
        </Grid>
        <Grid item sm={3}>
          <TimePicker
            control={control}
            errors={errors}
            name="startTime"
            label={clientNoShow ? 'Time of Visit' : 'Start time'}
          />
        </Grid>
        {!clientNoShow && (
          <Grid item sm={3}>
            <Input
              type="time"
              register={register}
              errors={errors}
              name="duration"
              label="Duration"
              endAdornment={<ClockIcon />}
            />
          </Grid>
        )}
        {!clientNoShow && (
          <>
            <Grid item sm={10}>
              <Select
                control={control}
                errors={errors}
                name="visitedPeople"
                options={visitedPersonsList}
                label="Visited Person"
                isMulti
              />
            </Grid>
            <Grid item sm={1} />
          </>
        )}

        <Grid item sm={10}>
          <Select
            control={control}
            errors={errors}
            name="visitors"
            isMulti
            label="Visited By"
            options={teamMembers}
          />
        </Grid>

        <Grid item sm={10}>
          <Input
            register={register}
            errors={errors}
            name="note"
            label="Add Note"
            multiline
            minRows={4}
          />
        </Grid>

        {isPersonVisit && (
          <>
            <Grid item sm={12}>
              <h4>Address</h4>
            </Grid>
            <Grid item sm={4}>
              <Input type="text" register={register} errors={errors} name="city" label="City" />
            </Grid>
            <Grid item sm={3}>
              <Select
                control={control}
                errors={errors}
                name="stateId"
                label="State"
                options={states}
              />
            </Grid>
            <Grid item sm={3}>
              <InputMask
                control={control}
                errors={errors}
                name="zipCode"
                label="Zip Code"
                alwaysShowMask={false}
                mask="99999"
              />
            </Grid>
            <Grid item sm={10}>
              <Input type="text" register={register} errors={errors} name="line1" label="Address" />
            </Grid>
          </>
        )}
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Checkbox
              control={control}
              errors={errors}
              name="clientNoShow"
              label="Client No Show"
            />
          </Grid>
          {clientNoShow && (
            <Grid item sm={12}>
              <Select
                control={control}
                errors={errors}
                name="noShowReason"
                label="No Show Reasons"
                options={noShowReasons}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container item sm={12} spacing={2} className={styles.buttonHolder}>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            {visit && visitId ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default Form;
