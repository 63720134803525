import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { format, isSameDay } from 'date-fns';
import { ReactElement } from 'react';

import { IMedicationDeliveryMedication } from '~/services/api/medicationLogistics';
import styles from './MedicationInfo.module.scss';

interface IProps {
  medication?: IMedicationDeliveryMedication;
  nextDueDate?: string;
}

const MedicationInfo = ({ medication, nextDueDate }: IProps): ReactElement => {
  const isOverdue = nextDueDate
    ? new Date() > new Date(nextDueDate) && !isSameDay(new Date(nextDueDate), new Date())
    : false;
  const formattedDate = nextDueDate ? format(new Date(nextDueDate), 'MM/dd/yyyy') : null;
  return (
    <Box
      className={classNames(styles.medicationInfo, {
        [styles.grey]: medication.isArchived,
        [styles.overdue]: isOverdue,
      })}
    >
      {medication ? (
        <div className={styles.row}>
          {medication.medication.name}
          {medication.injectable && (
            <div
              className={classNames(styles.injectable, {
                [styles.greyIcon]: medication.isArchived,
              })}
            />
          )}
        </div>
      ) : undefined}
      {medication ? (
        <span className={styles.description}>
          <i>{medication.dose}</i>
        </span>
      ) : undefined}
      {nextDueDate && medication.injectable && !medication.isArchived && (
        <span
          className={classNames(styles.nextDueDate, {
            [styles.overdueDate]: isOverdue,
          })}
        >
          {!isOverdue ? `Next due: ${formattedDate}` : `Over due: ${formattedDate}`}
        </span>
      )}
    </Box>
  );
};

export default MedicationInfo;
