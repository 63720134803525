import { ReactElement, useState } from 'react';
import {
  Box,
  TableContainer,
  Table as MUITable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';

import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Status from '~/ui/components/common/Status';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

import { red } from '~/ui/constants/colors';
import { EDIT_SUPER_ADMIN } from '~/ui/constants/paths';
import { getButtonText } from '~/utils/getButtonText';
import { extractErrorMessage } from '~/utils/error';
import { multipleStatusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import { IParams as IValsParams, Sorting } from '~/services/api/types';
import { ISuperAdminList } from '~/services/api/superAdmin/types';

import editGrey from '~/ui/assets/images/editGrey.svg';
import archiveGrey from '~/ui/assets/images/archiveIcon.svg';

import styles from './SuperAdminsManagement.module.scss';

interface IProps {
  superAdmins: ISuperAdminList[];
}

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

const Table = ({ superAdmins = [] }: IProps): ReactElement => {
  const [currentSuperAdmin, setCurrentSuperAdmin] = useState<ISuperAdminList>(null);
  const { push } = useHistory();
  const formMethods = useForm({ defaultValues });

  const { pagination } = useStoreState(state => state.superAdmin);
  const { onGetSuperAdmins, onActivateSuperAdmin, onDeactivateSuperAdmin, onUnlockSuperAdmin } =
    useStoreActions(actions => actions.superAdmin);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const watchValues = watch();

  const onSubmit = (vals: IValsParams) => {
    const { pageSize, pageNumber } = pagination;
    onGetSuperAdmins({ pageSize, pageNumber, ...vals });
  };

  const handlePagination = (pageSize: number, pageNumber: number) =>
    onGetSuperAdmins({ pageSize, pageNumber, ...watchValues });

  const getConfirmProps = (status: string) => {
    if (status === 'Active')
      return {
        confirmText: 'Deactivate',
        description: 'Are you sure you want to deactivate this user?',
        method: () => onDeactivateSuperAdmin(currentSuperAdmin.id),
      };

    if (status === 'Deactivated')
      return {
        confirmText: 'Activate',
        description: 'Are you sure you want to activate this user?',
        method: () => onActivateSuperAdmin(currentSuperAdmin.id),
      };

    if (status === 'Locked')
      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockSuperAdmin(currentSuperAdmin.id),
      };

    return {
      confirmText: 'Resend',
      description: 'Are you sure you want to resend invitation?',
      method: () => api.superAdmin.resendInvitation(currentSuperAdmin.id),
    };
  };

  const { confirmText, description, method } = getConfirmProps(currentSuperAdmin?.status);

  const onConfirm = async () => {
    try {
      await method();
      setCurrentSuperAdmin(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setCurrentSuperAdmin(null);
    }
  };

  return (
    <>
      <TableContainer>
        <Box sx={{ p: 2 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item sm={4}>
                  <Input
                    startAdornment={<SearchIcon color="primary" />}
                    placeholder="Search admins"
                    register={register}
                    name="name"
                    errors={errors}
                  />
                </Grid>
                <Grid item sm={2}>
                  <SelectComponent
                    label="Sort By"
                    control={control}
                    name="sorting"
                    errors={errors}
                    options={sortingOptions}
                    color={red}
                  />
                </Grid>
                <Grid item sm={2}>
                  <SelectComponent
                    label="Filter By Status"
                    control={control}
                    name="status"
                    errors={errors}
                    options={multipleStatusesOptions}
                    color={red}
                  />
                </Grid>
                <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
              </Grid>
            </form>
          </FormProvider>
        </Box>
        <MUITable>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {superAdmins.map(superAdmin => {
              const toEdit = EDIT_SUPER_ADMIN.replace(':superAdminId', String(superAdmin.id));

              return (
                <TableRow key={superAdmin.id}>
                  <TableCell>{superAdmin.firstName}</TableCell>
                  <TableCell>{superAdmin.lastName}</TableCell>
                  <TableCell>{superAdmin.email}</TableCell>
                  <TableCell>
                    <Status status={superAdmin.status} />
                  </TableCell>
                  <TableCell>
                    <Link to={toEdit}>
                      {['Deactivated', 'Locked'].includes(superAdmin.status) ? (
                        <IconButton disabled>
                          <img src={archiveGrey} alt="disabled" />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <img src={editGrey} alt="edit" />
                        </IconButton>
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div
                      role="presentation"
                      onClick={() => setCurrentSuperAdmin(superAdmin)}
                      className={styles.button}
                    >
                      {getButtonText(superAdmin.status)}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
        <TablePagination
          component="div"
          count={pagination.total}
          onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
          onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
          page={pagination.pageNumber - 1}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
      {currentSuperAdmin && (
        <ConfirmModal
          onConfirm={onConfirm}
          onClose={() => setCurrentSuperAdmin(null)}
          confirmText={confirmText}
          description={description}
        />
      )}
    </>
  );
};

export default Table;
