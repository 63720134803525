import { ReactElement, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Table as MUITable,
  TableHead,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select';
import Status from '~/ui/components/common/Status';
import { getButtonText } from '~/utils/getButtonText';

import { multipleStatusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { IParams as IValsParams, Sorting } from '~/services/api/types';
import { EDIT_TEAM_MEMBER, VIEW_ACT_TEAM_MEMBER } from '~/ui/constants/paths';
import { red } from '~/ui/constants/colors';
import { Role } from '~/types';

import editDisabledIcon from '~/ui/assets/images/editDisabled.svg';
import editIcon from '~/ui/assets/images/editGrey.svg';
import teamLeaderIcon from '~/ui/assets/images/teamLeader.svg';

import styles from './TeamMembers.module.scss';

const defaultValues = {
  name: '',
  sorting: Sorting.ByEditDate,
  status: 0,
};

interface IProps {
  clinicId: string;
  actTeamId: string;
  noActions?: boolean;
  setTeamMember: (v: any) => void;
}

const Table = ({ clinicId, actTeamId, noActions, setTeamMember }: IProps): ReactElement => {
  const { push } = useHistory();
  const formMethods = useForm({ defaultValues });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const watchValues = watch();

  const actTeamMembers = useStoreState(state => state.actTeamMember.list);
  const pagination = useStoreState(state => state.actTeamMember.pagination);

  const showError = useStoreActions(state => state.snackbar.showError);
  const onGetActTeamMembers = useStoreActions(actions => actions.actTeamMember.onGetActTeamMembers);

  const onMount = async () => {
    try {
      const { pageSize } = pagination;
      const payload = { clinicId, actTeamId, params: { pageSize, pageNumber: 1 } };
      await onGetActTeamMembers(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onSubmit = async (vals: IValsParams) => {
    const { pageSize, pageNumber } = pagination;
    const payload = { clinicId, actTeamId, params: { pageSize, pageNumber, ...vals } };
    await onGetActTeamMembers(payload);
  };

  useEffect(() => {
    onMount();
  }, []);

  const handlePagination = (pageNumber: number, pageSize: number) => {
    onGetActTeamMembers({
      clinicId,
      actTeamId,
      params: { pageNumber, pageSize, ...watchValues },
    });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search members"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={multipleStatusesOptions}
                  color={red}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell width={1} />
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            {!noActions && (
              <>
                <TableCell>Actions</TableCell>
                <TableCell />
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {actTeamMembers.map(actTeamMember => {
            const isTeamLeader = actTeamMember.primaryRole.id === Role.ActTeamLeader;

            return (
              <TableRow
                key={actTeamMember.id}
                style={{ borderLeft: isTeamLeader ? '3px solid #d83713' : '' }}
              >
                <TableCell width={1}>
                  {isTeamLeader && (
                    <span>
                      <img src={teamLeaderIcon} alt="teamLeader" />
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  <Link
                    className={styles.link}
                    to={{
                      pathname: VIEW_ACT_TEAM_MEMBER.replace(':actTeamId', actTeamId).replace(
                        ':teamMemberId',
                        String(actTeamMember.id),
                      ),
                    }}
                  >
                    {actTeamMember.firstName}
                  </Link>
                </TableCell>

                <TableCell>{actTeamMember.lastName}</TableCell>
                <TableCell>{actTeamMember.email}</TableCell>
                <TableCell>
                  <Status status={actTeamMember.status} />
                </TableCell>
                {!noActions && (
                  <TableCell>
                    {['Deactivated', 'Locked'].includes(actTeamMember.status) ? (
                      <IconButton disabled>
                        <img src={editDisabledIcon} alt="disabled" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          push(
                            EDIT_TEAM_MEMBER.replace(':actTeamId', actTeamId).replace(
                              ':teamMemberId',
                              String(actTeamMember.id),
                            ),
                          )
                        }
                      >
                        <img src={editIcon} alt="edit" />
                      </IconButton>
                    )}
                  </TableCell>
                )}
                {!noActions && (
                  <TableCell>
                    <button
                      type="button"
                      className={styles.actionButton}
                      onClick={() => setTeamMember(actTeamMember)}
                    >
                      {getButtonText(actTeamMember.status)}
                    </button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          handlePagination(pageNumber + 1, pagination.pageSize);
        }}
        onRowsPerPageChange={e => {
          handlePagination(1, Number(e.target.value));
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
