import { Checkbox, makeStyles } from '@material-ui/core';
import { red } from '~/ui/constants/colors';

const useStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: '8px',
    color: 'black',
  },
  checked: {
    color: `${red} !important`,
  },
});

const CustomCheckbox = (props: any) => {
  const classes = useStyles();
  return <Checkbox classes={{ root: classes.root, checked: classes.checked }} {...props} />;
};

export default CustomCheckbox;
