import { ReactElement, useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useStoreActions, useStoreState } from '~/store/hooks';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import {
  ACT_TEAM_MANAGEMENT,
  DUPLICATE_CLIENT_ALLOCATION,
  EDIT_CLIENT_ALLOCATION,
  MY_ACT_TEAM,
  VIEW_ACT_TEAM,
} from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import { isoToLocal } from '~/utils/date';

import { IUserRole } from '~/types';
import EditIcon from '~/ui/assets/images/editWhiteSecond.svg';
import styles from './ViewClientAllocation.module.scss';

type IParams = {
  actTeamId: string;
  clientAllocationId: string;
};

const ViewClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationId } = useParams<IParams>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const { clinic: userClinic, roles } = useStoreState(state => state.user.current);
  const clientAllocation = useStoreState(state => state.clientAllocation.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const { isArchived } = actTeam || {};

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const onGetClientAllocation = useStoreActions(
    actions => actions.clientAllocation.onGetClientAllocation,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const isActTeamLeader = roles?.includes(IUserRole.ActTeamLeader);

  const onMount = async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };

      await Promise.all([onGetMyClinic(), onGetClinicActTeam(payload)]);
      await onGetClientAllocation({
        clinicId: String(userClinic.id),
        teamId: actTeamId,
        clientAllocationId,
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading || !actTeam || !clientAllocation) return <Loader />;

  const breadcrumbItems = [
    ...(isActTeamLeader
      ? [
          {
            title: actTeam.name,
            to: {
              pathname: MY_ACT_TEAM,
              state: { selectedTab: 0 },
            },
          },
        ]
      : [
          { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} ACT Teams` },
          {
            to: {
              pathname: VIEW_ACT_TEAM.replace(':actTeamId', String(actTeam.id)),
              state: { selectedTab: 2 },
            },
            title: actTeam.name,
          },
        ]),
    { to: pathname, title: 'Client Allocation' },
  ];

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>
            {clientAllocation.name} | {isoToLocal(clientAllocation.date)}
          </h2>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
        </div>
        {!isArchived && (
          <>
            <div>
              <Button
                color="primary"
                variant="outlined"
                className={styles.buttonWrapper}
                onClick={() =>
                  push(
                    DUPLICATE_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                      ':clientAllocationId',
                      String(clientAllocation.id),
                    ),
                  )
                }
              >
                Duplicate
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<img src={EditIcon} alt="edit" />}
                onClick={() =>
                  push(
                    EDIT_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                      ':clientAllocationId',
                      String(clientAllocation.id),
                    ),
                  )
                }
              >
                Edit Client Allocation
              </Button>
            </div>
          </>
        )}
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ACT Team Members</TableCell>
              <TableCell>Sub-Role</TableCell>
              <TableCell>Clients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientAllocation.users.map(allocation => (
              <TableRow key={allocation.id}>
                <TableCell>{allocation.name}</TableCell>
                <TableCell>{allocation.subRoles.map(subRole => subRole.name).join(', ')}</TableCell>
                <TableCell>
                  {allocation.clients
                    .map(client => `${client.firstName} ${client.lastName}`)
                    .join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewClientAllocation;
