import * as auth from './auth';
import * as users from './users';
import * as file from './file';
import * as clinic from './clinic';
import * as actTeam from './actTeam';
import * as actTeamMember from './actTeamMember';
import * as localAdmin from './localAdmin';
import * as dictionaries from './dictionaries';
import * as client from './client';
import * as clientDetails from './clientDetails';
import * as clientAllocation from './clientAllocation';
import * as superAdmin from './superAdmin';
import * as emailValidation from './emailValidation';
import * as medicationLogistics from './medicationLogistics';
import * as coordinate from './coordinate';
import * as programAssistant from './programAssistant';
import * as visits from './visits';

export default {
  auth,
  users,
  file,
  clinic,
  actTeam,
  actTeamMember,
  localAdmin,
  dictionaries,
  client,
  clientDetails,
  clientAllocation,
  superAdmin,
  emailValidation,
  medicationLogistics,
  coordinate,
  programAssistant,
  visits,
};
