import { secondsToHours, secondsToMinutes } from 'date-fns';

export const splitSeconds = (seconds: number, withColon = false): string => {
  const hours = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds) - hours * 60;

  const defaultDuration = !hours && !minutes ? '1 min' : '';

  let res = '';
  if (!withColon) {
    res = `${hours ? `${hours} hours` : ''} ${minutes ? `${minutes} min` : defaultDuration} `;
  } else {
    res = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }

  return res;
};

export default {};
