import setUser from './setUser';
import resetUser from './resetUser';
import setAuthorized from './setAuthorized';
import setAvatar from './setAvatar';
import removeAvatar from './removeAvatar';

export default {
  resetUser,
  setAuthorized,
  setUser,
  setAvatar,
  removeAvatar,
};
