import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatSecondaryDiagnosis } from '~/utils/formatSecondaryDiagnosis';
import { IThunkCreateSecondaryDiagnosis, IClientDetailsStore } from '../types';

const onCreateSecondaryDiagnosis: IThunkCreateSecondaryDiagnosis = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const secondaryDiagnosis = await api.clientDetails
      .createClientSecondaryDiagnosis(requestInfo, requestPayload)
      .then(r => formatSecondaryDiagnosis(r.data));

    const diagnoses = [secondaryDiagnosis, ...localState.diagnoses];

    setClientDetails({ diagnoses });
  },
);

export default onCreateSecondaryDiagnosis;
