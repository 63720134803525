import { ReactElement, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ListItem from '~/ui/components/common/ListItem';
import Button from '~/ui/components/common/Button';
import { TriggerType } from '~/services/api/clientDetails/types';
import { IHealthDetailGroup } from '../types';
import styles from './DetailsBlock.module.scss';

interface IProps {
  title: string;
  buttonText?: string;
  onAdd?: () => void;
  onEdit?: (num: number, triggerName: string) => void;
  showArchivedButton?: boolean;
  showEdit?: boolean;
  showArchive?: boolean;
  showRestore?: boolean;
  setDetailsInfo?: (data: any) => void;
  healthDetailGroup: IHealthDetailGroup;
  labelGrey?: boolean;
  noActions?: boolean;
}

const DetailsBlock = ({
  title,
  buttonText,
  onAdd,
  onEdit,
  showArchivedButton = true,
  showEdit = true,
  showArchive = true,
  showRestore = true,
  setDetailsInfo,
  healthDetailGroup,
  labelGrey,
  noActions,
}: IProps): ReactElement => {
  const [showArchivedList, setShowArchivedList] = useState(false);

  const toggle = () => setShowArchivedList(val => !val);

  const icon = !showArchivedList ? (
    <ExpandMoreIcon color="primary" />
  ) : (
    <ExpandLessIcon color="primary" />
  );

  const { active, archived } = healthDetailGroup;

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <h4 className={styles.title}>{title}</h4>
        {buttonText && (
          <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={onAdd}>
            {buttonText}
          </Button>
        )}
      </div>
      {!!active.length &&
        active.map((item, idx) => (
          <ListItem
            noActions={noActions}
            className={active.length === 1 || active.length - 1 === idx ? styles.noBorder : ''}
            key={item.id}
            label={item.name}
            dose={item.dose}
            injectable={item.injectable}
            content={item.note}
            onEdit={() => onEdit(item.id, item.name)}
            showEdit={showEdit}
            showArchived={showArchive}
            showCircle={item.type === TriggerType.Recurring}
            onArchive={() => setDetailsInfo({ method: 'archive', id: item.id, name: item.name })}
          />
        ))}
      {showArchivedButton && !!archived.length && (
        <div className={styles.archivedButtonWrapper}>
          <button type="button" onClick={toggle} className={styles.archivedButton}>
            Archived {icon}
          </button>
        </div>
      )}
      {!!archived.length &&
        showArchivedList &&
        archived.map((item, idx) => (
          <ListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.id}${idx}`}
            label={item.name}
            dose={item.dose}
            injectable={item.injectable}
            content={item.note}
            labelGrey={labelGrey}
            showRestore={showRestore}
            noActions={noActions}
            showCircle={item.type === TriggerType.Recurring}
            onRestore={() => setDetailsInfo({ method: 'restore', id: item.id, name: item.name })}
          />
        ))}
    </div>
  );
};

export default DetailsBlock;
