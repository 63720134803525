import { ReactElement } from 'react';
import { Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import Input from '~/ui/components/inputs/Input';
import Button from '~/ui/components/common/Button';
import InfoMessage from '~/ui/components/common/InfoMessage';
import validate from './validate';

import { IFormValues } from './types';
import styles from '../../ChangePassword.module.scss';

interface IProps {
  onSubmit: (val: IFormValues) => void;
  error: string;
  title: string;
  buttonText: string;
}

const ChangePasswordForm = ({ onSubmit, error, title, buttonText }: IProps): ReactElement => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: validate });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={styles.changePassword}>
        <h2 className={styles.title}>{title}</h2>
        <InfoMessage message="Your current password has expired, please create a new one." />
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="oldPassword"
            label="Current Password"
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="newPassword"
            label="New Password"
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            type="password"
            register={register}
            errors={errors}
            name="passwordConfirmation"
            label="Confirm Password"
          />
        </div>
        <span className={styles.error}>{error}</span>
        <div className={styles.buttonWrapper}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {buttonText}
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default ChangePasswordForm;
