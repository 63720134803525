import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import { extractErrorMessage } from '~/utils/error';
import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import DatePicker from '~/ui/components/inputs/DatePicker';
import validate from './validate';

import { useStoreActions } from '~/store/hooks';

import { IClient } from '~/services/api/client/types';

import styles from '../ClientsManagement.module.scss';

interface IProps {
  client: IClient;
  setClient: (payload: IClient) => void;
  options: { label: string; value: number }[];
  clinicId: number;
}

const ModalForm = ({ setClient, client, options, clinicId }: IProps): ReactElement => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: validate });

  const onArchiveClient = useStoreActions(actions => actions.client.onArchiveClient);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const onSubmit = async (vals: { dischargeReasonId: number; dischargeDate: string }) => {
    try {
      await onArchiveClient({
        clinicId: String(clinicId),
        clientId: String(client.id),
        teamId: String(client.actTeam.id),
        ...vals,
      });
      showNotify('Client successfully archived');
      setClient(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <Modal open onClose={() => setClient(null)} maxWidth="lg" className={styles.modal}>
      <>
        <p>
          Are you sure you want to archive {client.firstName} {client.lastName}?
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.input}>
            <SelectComponent
              isRelativeWindow
              maxMenuHeight={350}
              options={options}
              name="dischargeReasonId"
              control={control}
              label="Reason for Discharge"
              errors={errors}
            />
          </div>
          <DatePicker
            name="dischargeDate"
            control={control}
            errors={errors}
            label="Date of Discharge"
          />
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonMargin}>
              <Button color="primary" variant="outlined" onClick={() => setClient(null)}>
                Cancel
              </Button>
            </div>
            <Button type="submit" color="primary" variant="contained">
              Archive
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default ModalForm;
