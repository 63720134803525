import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetClinicClients } from '../types';

// @ts-ignore
const onGetClinicClients: IThunkGetClinicClients = thunk(async ({ setClients }, payload) => {
  const { clinicId, params } = payload;

  const { items, total } = await api.client.getClients(clinicId, params).then(r => r.data);

  const pagination = { ...params, total };

  setClients({ items, pagination });
});

export default onGetClinicClients;
