import { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TableContainer,
  Box,
  Grid,
  Table as MUITable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { getViewActTeamLink } from '~/utils/getViewActTeamLink';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/Select/Select';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Status from '~/ui/components/common/Status';

import { CLINIC_EDIT_ACT_TEAM, VIEW_CLINIC, ACT_TEAM_MANAGEMENT } from '~/ui/constants/paths';
import { red } from '~/ui/constants/colors';
import { sortingOptions, statusesOptions } from '~/ui/constants/sortingOptions';
import { IPagination, IParams, Sorting } from '~/services/api/types';
import { IActTeam } from '~/services/api/actTeam/types';

import editIcon from '~/ui/assets/images/editGrey.svg';
import archiveIcon from '~/ui/assets/images/archiveIcon.svg';
import styles from './Table.module.scss';

const defaultValues = {
  name: '',
  sorting: Sorting.ByName,
  status: 0,
};

interface IProps {
  actTeams: IActTeam[];
  onGetAllActTeams: (params: IParams) => void;
  pagination: IPagination;
  isLocalAdmin?: boolean;
  setActTeam?: (actTeam: any) => void;
}

const Table = ({
  actTeams,
  onGetAllActTeams,
  pagination,
  isLocalAdmin,
  setActTeam,
}: IProps): ReactElement => {
  const { push } = useHistory();
  const formMethods = useForm({ defaultValues });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const watchValues = watch();

  const onSubmit = (vals: IParams) => {
    const { pageSize, pageNumber } = pagination;
    onGetAllActTeams({ pageSize, pageNumber, ...vals });
  };

  const handlePagination = (pageSize: number, pageNumber: number) => {
    onGetAllActTeams({ pageSize, pageNumber, ...watchValues });
  };

  const handleEditClick = (actTeam: IActTeam) => {
    const to = CLINIC_EDIT_ACT_TEAM.replace(':clinicId', String(actTeam.clinic.id)).replace(
      ':actTeamId',
      String(actTeam.id),
    );
    const state = { from: ACT_TEAM_MANAGEMENT };
    push(to, state);
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  register={register}
                  placeholder="Search ACT Team"
                  name="name"
                  errors={errors}
                  startAdornment={<SearchIcon color="primary" />}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={statusesOptions}
                  color={red}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={defaultValues} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>ACT Team Name</TableCell>
            {!isLocalAdmin && <TableCell>Clinic</TableCell>}
            <TableCell>Status</TableCell>
            {!isLocalAdmin && (
              <TableCell>
                <span className={styles.actionsCell}>Actions</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {actTeams.map(actTeam => (
            <TableRow key={actTeam.id}>
              <TableCell>
                <Link to={getViewActTeamLink(actTeam, isLocalAdmin)} className={styles.linkView}>
                  {actTeam.name}
                </Link>
              </TableCell>
              {!isLocalAdmin && (
                <TableCell>
                  <Link
                    className={styles.linkView}
                    to={VIEW_CLINIC.replace(':id', String(actTeam.clinic.id))}
                  >
                    {actTeam.clinic.name}
                  </Link>
                </TableCell>
              )}
              <TableCell>
                <Status isArchived={actTeam.isArchived} />
              </TableCell>
              {!isLocalAdmin && (
                <TableCell>
                  {actTeam.isArchived ? (
                    <button
                      className={styles.restoreButton}
                      type="button"
                      onClick={() => setActTeam(actTeam)}
                    >
                      Restore
                    </button>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEditClick(actTeam)}>
                        <img src={editIcon} alt="editIcon" />
                      </IconButton>
                      <IconButton onClick={() => setActTeam(actTeam)}>
                        <img src={archiveIcon} alt="archive" />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;
