import { ReactElement } from 'react';
import { components } from 'react-select';

import styles from '../../Select/Select.module.scss';

const MenuList = ({ children, ...props }: any): ReactElement => (
  <components.MenuList {...props}>
    <div className={styles.helperText}>
      Clients who are not assigned to any team member are highlighted in red.
    </div>
    {children}
  </components.MenuList>
);

export default MenuList;
