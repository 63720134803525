import { Box } from '@material-ui/core';
import { ReactElement } from 'react';

import AllergiesItem from './AllergiesItem';

import { IClientAllergy } from '~/services/api/clientDetails/types';
import styles from './Allergies.module.scss';

interface IProps {
  allergies: IClientAllergy[];
}

const Allergies = ({ allergies }: IProps): ReactElement => {
  const allergiesList = allergies.filter(item => !item.isArchived);

  return (
    <Box className={styles.container}>
      <div>Allergies</div>
      <div className={styles.list}>
        {allergiesList.map((item, index) => (
          <AllergiesItem
            key={`allergy-${item.allergy.id}`}
            item={item}
            last={allergiesList.length === index + 1}
          />
        ))}
      </div>
    </Box>
  );
};

export default Allergies;
