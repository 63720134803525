import { thunk } from 'easy-peasy';

import { deleteSendbirdData, deleteTokens } from '~/services/auth';
import { IThunkLogout } from '../types';

// @ts-ignore
const onLogout: IThunkLogout = thunk(async ({ resetUser, setAuthorized }) => {
  resetUser();
  setAuthorized({ authorized: false });
  deleteTokens();
  deleteSendbirdData();
});

export default onLogout;
