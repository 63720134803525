import { IDictionary } from '../dictionaries/types';
import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { IVisit, IVisitRequestParams, IVisitRequestPayload, IVisitsRequestInfo } from './types';

export const getVisits = (
  { clinicId, teamId, clientId, includeDeleted = false }: IVisitsRequestInfo,
  params: IVisitRequestParams,
): IResponse<{ visits: { items: IVisit[]; total: number }; monitoredBy: IDictionary[] }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/overview?clientId=${clientId}&includeDeleted=${includeDeleted}`,
    params,
  });

export const getVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<IVisit> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
  });

export const addVisit = (
  { clinicId, teamId }: IVisitsRequestInfo,
  data: IVisitRequestPayload,
): IResponse<IVisit> =>
  privateAxios({ method: 'post', url: `clinic/${clinicId}/act-team/${teamId}/visits`, data });

export const updateVisit = (
  { clinicId, teamId, visitId }: IVisitsRequestInfo,
  data: IVisitRequestPayload,
): IResponse<IVisit> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
    data,
  });

export const archiveVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<null> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
  });

export const restoreVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<null> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}/recover`,
  });

export default {};
