import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeactivateLocalAdmin } from '../types';

const onDeactivateLocalAdmin: IThunkDeactivateLocalAdmin = thunk(
  // @ts-ignore
  async ({ deactivateLocalAdmin }, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.deactivateLocalAdmin(clinicId, localAdminId);

    deactivateLocalAdmin(localAdminId);
  },
);

export default onDeactivateLocalAdmin;
