import { ReactElement, Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Paper } from '@material-ui/core';

import api from '~/services/api';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Checkbox from '~/ui/components/inputs/Checkbox';
import Input from '~/ui/components/inputs/Input';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Button from '~/ui/components/common/Button';
import PrivateImage from '~/ui/components/common/PrivateImage';
import { useStoreActions, useStoreState } from '~/store/hooks';
import validate, { IFormValues } from './validate';

import { extractErrorMessage } from '~/utils/error';
import { formValuesMapper, ITeamMemberMapped, prepareUsers } from '~/utils/clientAllocation';
import { MY_ACT_TEAM, VIEW_ACT_TEAM, VIEW_CLIENT_ALLOCATION } from '~/ui/constants/paths';

import { IOption } from '~/ui/components/inputs/Select/types';
import { IUserRole } from '~/types';
import smallAvatarIcon from '~/ui/assets/images/smallAvatar.svg';
import styles from './ClientAllocationForm.module.scss';

interface IProps {
  teamMembers: ITeamMemberMapped[];
  clients: IOption[];
  actTeamId: string;
  defaultValues?: any;
  clientAllocationId?: number;
  clinicId: string;
}

const defValues = {
  to: '',
  from: '',
  name: '',
  includeWeekends: false,
};

const Form = ({
  actTeamId,
  teamMembers,
  clients,
  clinicId,
  clientAllocationId,
  defaultValues = defValues,
}: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);
  const { push } = useHistory();
  const {
    control,
    register,
    watch,
    clearErrors,
    setError,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<IFormValues>({ defaultValues, resolver: validate });
  const { from, includeWeekends, name, to, ...formValues } = watch();

  const { roles } = useStoreState(state => state.user.current);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const isActTeamLeader = roles?.includes(IUserRole.ActTeamLeader);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit = async ({ name, from, to, includeWeekends, ...vals }: IFormValues) => {
    try {
      setIsAdding(true);
      const users = prepareUsers(vals);

      const payload = {
        name,
        from,
        to,
        includeWeekends,
        users,
      };

      if (!users.length) {
        showError('Please provide at least one client');
        return;
      }
      await api.clientAllocation.addClientAllocation({ clinicId, teamId: actTeamId }, payload);

      showNotify('Client allocation successfully added');
      if (isActTeamLeader) {
        push(MY_ACT_TEAM, { selectedTab: 0 });
      } else {
        push(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), { selectedTab: 0 });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const unique = new Set(formValuesMapper(formValues));

  const additionalStyleHandler = () => ({
    option: (provided: any, { data }: any) => {
      const notSelected = !unique.has(data.value);

      return {
        ...provided,
        backgroundColor: notSelected ? '#FFF1EE' : 'inherit',
        fontWeight: '300',
      };
    },
  });

  const validateClientAllocationRange = async () => {
    const payload = { from, to, includeWeekends };

    const { isValid } = await api.clientAllocation
      .validateDateRange({ clinicId, teamId: actTeamId }, payload)
      .then(r => r.data);

    if (!isValid) {
      setError('from', {
        type: 'manual',
        message: 'Allocation for this date is already existing.',
      });
      return;
    }
    clearErrors('from');
  };

  useEffect(() => {
    if (from || to) {
      clearErrors('from');
    }
    if (to && from) {
      validateClientAllocationRange();
    }
  }, [to, from]);

  const leavePageDescription =
    'Are you sure you want to cancel “Add Client Allocation”? All the changes will be discarded.';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper>
        <Box p={3}>
          <div className={styles.header}>
            <span>Client Allocation Details</span>
            <Checkbox
              name="includeWeekends"
              size="small"
              control={control}
              label="Include weekends"
              errors={errors}
            />
          </div>

          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Input name="name" register={register} errors={errors} label="Allocation Name" />
            </Grid>
            <Grid item sm={3}>
              <DatePicker name="from" control={control} errors={errors} label="From" maxDate={to} />
            </Grid>
            <Grid item sm={3}>
              <DatePicker name="to" control={control} errors={errors} label="To" minDate={from} />
            </Grid>
            <Grid item sm={3}>
              <h5 className={styles.subTitle}>Team Members</h5>
            </Grid>
            <Grid item sm={9}>
              <h5 className={styles.subTitle}>Assigned Clients</h5>
            </Grid>
            <Grid container spacing={3} className={styles.formWrapper}>
              {teamMembers.map(teamMember => (
                <Fragment key={teamMember.id}>
                  <Grid item sm={3}>
                    <div className={styles.row}>
                      <PrivateImage
                        className={styles.image}
                        src={teamMember.photo || smallAvatarIcon}
                        height={30}
                        alt="avatar"
                      />
                      <span className={styles.text}>{teamMember.fullName}</span>
                    </div>
                  </Grid>
                  <Grid item sm={9}>
                    <SelectComponent
                      closeMenuOnSelect={false}
                      showCustomComponents
                      hideSelectedOptions={false}
                      additionalStyleHandler={() => additionalStyleHandler()}
                      isMulti
                      label="Assign Clients"
                      options={clients}
                      name={`clients_${teamMember.id}`}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div className={styles.buttons}>
        <div className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (clientAllocationId) {
                push(
                  VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                    ':clientAllocationId',
                    String(clientAllocationId),
                  ),
                );
              } else if (isActTeamLeader) {
                push(MY_ACT_TEAM, { selectedTab: 0 });
              } else
                push(VIEW_ACT_TEAM.replace(':actTeamId', String(actTeamId)), { selectedTab: 0 });
            }}
          >
            Cancel
          </Button>
        </div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={!!Object.keys(errors).length}
        >
          Add Client Allocation
        </Button>
      </div>
      <NavigationConfirmModal when={isDirty && !isAdding} description={leavePageDescription} />
    </form>
  );
};

export default Form;
