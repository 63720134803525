import { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import { isoToLocal } from '~/utils/date';
import styles from './MedicationChangeInfo.module.scss';

interface IProps {
  delivered: boolean;
  changedBy: string;
  when: string;
}

const MedicationChangeInfo = ({ delivered, changedBy, when }: IProps): ReactElement => {
  const deliveryStatus = delivered ? '"Delivered"' : '"Not Delivered"';
  return (
    <Box className={styles.infoBox}>
      Status was changed to {deliveryStatus} by {changedBy} on{' '}
      {isoToLocal(when, 'MM/dd/yyyy h:mm aa')}
    </Box>
  );
};

export default MedicationChangeInfo;
