import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { formatEnumToOptions } from '../../utils/formatEnumToOptions';
import { destructHours } from '~/utils/date';
import { extractErrorMessage } from '~/utils/error';
import { splitSeconds } from '../../utils/splitSeconds';
import { getTypeString } from '../../utils/getTypeString';

import Loader from '~/ui/components/common/Loader';
import SimpleSelectWithoutGrid from '../SimpleSelectWithoutGrid';
import VisitsTableFilters from '../VisitsTableFilters';
import Button from '~/ui/components/common/Button';

import { SortByOption } from '../../constants/filterOptions';
import { EDIT_VISIT, PREVIEW_VISIT } from '~/ui/constants/paths';

import editGreyIcon from '~/ui/assets/images/editGrey.svg';
import styles from './VisitsTable.module.scss';

const VisitsTable = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  /* sortBy values explanation
    0 - All -> display all filters
    1 - VisitedPerson -> display select with VisitedPerson enum options
    2 - TypeOfVisit -> display select with TypeOfVisit enum options
    3 - DateOfVisit -> display date picker
  */
  const [sortBy, setSortBy] = useState<number>(0);
  const [filterBy, setFilterBy] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const { push } = useHistory();

  const query = useQuery();
  const clientId = query.get('clientId');
  const teamId = query.get('teamId');

  const { list, pagination } = useStoreState(state => state.visits);
  const { current } = useStoreState(state => state.user);
  const { availableClients } = useStoreState(state => state.client);
  const { onGetVisits } = useStoreActions(action => action.visits);
  const { showError } = useStoreActions(action => action.snackbar);

  const [pageNumber, setPNumber] = useState<number>(pagination.pageNumber);
  const [pageSize, setPSize] = useState<number>(pagination.pageSize);

  const redirectToPreviewPage = (visitId: number): void => {
    push(
      PREVIEW_VISIT.replace(':visitId', String(visitId)).concat(
        `?teamId=${teamId}&clientId=${clientId}`,
      ),
    );
  };
  const redirectToEditPage = (visitId: number): void => {
    push(
      EDIT_VISIT.replace(':visitId', String(visitId)).concat(
        `?teamId=${teamId}&clientId=${clientId}`,
      ),
    );
  };

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetVisits({
        requestInfo: {
          clinicId: String(current?.clinic?.id),
          clientId,
          teamId,
          includeDeleted: true,
        },
        params: {
          pageSize,
          pageNumber,
          filterBy,
          sortBy,
          dateFrom,
          dateTo,
        },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = async (pSize: number, pNumber: number): Promise<void> => {
    if (clientId) {
      setLoading(true);
      setPNumber(pNumber);
      setPSize(pSize);
      await onGetVisits({
        requestInfo: {
          clinicId: String(current?.clinic?.id),
          clientId,
          teamId,
          includeDeleted: true,
        },
        params: { pageSize: pSize, pageNumber: pNumber, sortBy, filterBy, dateFrom, dateTo },
      });
      setLoading(false);
    }
  };

  const isOneDateMissing = useMemo(
    () => (dateFrom && !dateTo) || (dateTo && !dateFrom),
    [dateFrom, dateTo],
  );

  const resetDateFilter = useCallback(() => {
    if (sortBy !== 3) {
      setDateFrom(null);
      setDateTo(null);
    }
  }, [sortBy, setDateTo, setDateFrom]);

  useEffect(() => {
    if (teamId && clientId) {
      if (isOneDateMissing) {
        return;
      }
      onMount();
      setPNumber(1);
      handlePagination(pageSize, 1);

      resetDateFilter();
    }
  }, [teamId, clientId, sortBy, filterBy, isOneDateMissing, dateFrom, dateTo]);

  if (loading) return <Loader />;
  return (
    clientId &&
    teamId &&
    !!availableClients.length && (
      <TableContainer className={styles.tableContainer}>
        <Grid container item xs={12} sm={12} md={8} spacing={1} className={styles.filtersContainer}>
          <Grid item sm={3}>
            <SimpleSelectWithoutGrid
              label="Sort By"
              value={sortBy}
              setValue={setSortBy}
              options={formatEnumToOptions(SortByOption)}
            />
          </Grid>
          {!!sortBy && (
            <Grid item sm={9}>
              <VisitsTableFilters
                sortBy={sortBy}
                filterBy={filterBy}
                dateFrom={dateFrom}
                dateTo={dateTo}
                setFilterBy={setFilterBy}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              />
            </Grid>
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Visited Person</TableCell>
              <TableCell>Date of Visit</TableCell>
              <TableCell>Time of Visit</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Type of Visit</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.map(item => (
                <TableRow key={item.id}>
                  <TableCell
                    className={styles.visitedPersonCell}
                    onClick={() => {
                      redirectToPreviewPage(item.id);
                    }}
                  >
                    {item.isClientVisited && !item.clientNoShow && <h5>Client</h5>}
                    {!!item.visitedPeople &&
                      item?.visitedPeople?.map(person => (
                        <div key={person.id}>
                          <h5>{person.name}</h5>
                          <h6>{person.relationship}</h6>
                        </div>
                      ))}
                    {item.clientNoShow && (
                      <h5 className={classnames({ [styles.noShow]: item.clientNoShow })}>
                        No Show
                      </h5>
                    )}
                  </TableCell>
                  {item?.date ? (
                    <TableCell>{format(new Date(item.date), 'MM/dd/yyyy')}</TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>{format(destructHours(item.startTime), 'hh:mm a')}</TableCell>
                  <TableCell className={classnames({ [styles.noShow]: item.clientNoShow })}>
                    {splitSeconds(item.durationSeconds)}
                  </TableCell>
                  <TableCell>{getTypeString(item.type)}</TableCell>
                  <TableCell className={styles.noteContainer}>
                    <Box className={styles.note}>{item.note.length ? item.note : 'No'}</Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        redirectToEditPage(item.id);
                      }}
                      className={styles.editButton}
                    >
                      <img src={editGreyIcon} alt="Edit" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pagination.total}
          onPageChange={(_, pNumber) => handlePagination(pageSize, pNumber + 1)}
          onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
          page={pageNumber - 1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    )
  );
};

export default VisitsTable;
