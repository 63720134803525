import { useState, useEffect, ReactElement } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import capitalize from 'lodash/capitalize';
import { curry } from 'lodash';

import Modal from '~/ui/components/common/Modal';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import DetailsBlock from './DetailsBlock';
import Form from './Form';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { formatSupportContactDefaultValues } from '~/utils/formatDefaultValues';
import { extractErrorMessage } from '~/utils/error';
import { IClientDetailed } from '~/services/api/client/types';

import styles from './SupportContacts.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId: string;
  actTeamId: string;
  noActions?: boolean;
}

interface ContactInfo {
  fullName: string;
  contactId: number;
}

type FormType = 'create' | 'edit';
type ContactStatusAction = 'archive' | 'restore';

const Btn = ({ onClick }: { onClick: () => void }) => (
  <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={onClick}>
    Add Collateral Contact
  </Button>
);

const SupportContacts = ({ client, clinicId, actTeamId, noActions }: IProps): ReactElement => {
  const [confirmType, setConfirmType] = useState<ContactStatusAction>(null);
  const [formType, setFormType] = useState<FormType>(null);
  const [contactInfo, setContactInfo] = useState<ContactInfo>(null);
  const [loading, setLoading] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const toggle = () => setShowArchived(val => !val);

  const { supportContacts, current } = useStoreState(state => state.clientDetails);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const {
    setCurrent,
    onGetSupportContacts,
    onGetSupportContact,
    onArchiveSupportContact,
    onRestoreSupportContact,
  } = useStoreActions(actions => actions.clientDetails);

  const onMount = async () => {
    try {
      setLoading(true);
      const payload = { clinicId, teamId: actTeamId, clientId: String(client.id) };

      await onGetSupportContacts(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;

  const [activeContacts, archivedContacts] = [
    supportContacts.filter(item => !item.isArchived),
    supportContacts.filter(item => item.isArchived),
  ];

  const icon = showArchived ? (
    <ExpandMoreIcon color="primary" />
  ) : (
    <ExpandLessIcon color="primary" />
  );

  const onConfirm = async () => {
    const payload = {
      clinicId,
      teamId: actTeamId,
      clientId: String(client.id),
      id: String(contactInfo.contactId),
    };

    try {
      if (confirmType === 'archive') {
        await onArchiveSupportContact(payload);
      }
      if (confirmType === 'restore') {
        await onRestoreSupportContact(payload);
      }

      showNotify(`Collateral contact successfully ${confirmType}d`);
      setConfirmType(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };
  const confirmText = capitalize(confirmType);

  const description = `Are you sure you want to ${confirmType} ${contactInfo?.fullName} collateral contact?`;

  const toggleContactStatus = curry((statusTypeAction: ContactStatusAction, info: ContactInfo) => {
    setConfirmType(statusTypeAction);
    setContactInfo(info);
  });

  const onEdit = (id: number) => onGetSupportContact(id);
  const requestInfo = { clientId: String(client.id), clinicId, teamId: actTeamId };
  const isClientActive = !client.isArchived;

  return (
    <>
      {supportContacts.length ? (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h4>Collateral Contacts</h4>
            {isClientActive && !noActions && <Btn onClick={() => setFormType('create')} />}
          </div>
          {activeContacts.map((item, idx) => (
            <DetailsBlock
              showEdit={isClientActive}
              showArchived={isClientActive}
              key={item.id}
              noActions={noActions}
              supportContact={item}
              onArchive={toggleContactStatus('archive')}
              onEdit={onEdit}
              className={
                activeContacts.length === 1 || activeContacts.length - 1 === idx
                  ? styles.noBorder
                  : ''
              }
            />
          ))}
          {!!archivedContacts.length && (
            <div className={styles.archivedButtonWrapper}>
              <button type="button" onClick={toggle} className={styles.archivedButton}>
                Archived {icon}
              </button>
            </div>
          )}
          {showArchived &&
            archivedContacts.map(item => (
              <DetailsBlock
                noActions={noActions}
                key={item.id}
                supportContact={item}
                showRestore={isClientActive}
                onRestore={toggleContactStatus('restore')}
              />
            ))}
        </div>
      ) : (
        <div className={styles.noData}>
          <h2>No Collateral Contacts have been added</h2>
          {!noActions && <Btn onClick={() => setFormType('create')} />}
        </div>
      )}
      {confirmType && (
        <ConfirmModal
          confirmText={confirmText}
          description={description}
          onConfirm={onConfirm}
          onClose={() => setConfirmType(null)}
        />
      )}
      {(formType || current) && (
        <Modal open>
          <Form
            onClose={() => {
              setCurrent(null);
              setFormType(null);
            }}
            formType={formType}
            defaultValues={current ? formatSupportContactDefaultValues(current) : undefined}
            supportContactId={current?.id}
            requestInfo={requestInfo}
          />
        </Modal>
      )}
    </>
  );
};

export default SupportContacts;
