export const triggerNotificationOn = [
  {
    label: 'Same Day',
    value: 'SameDay',
  },
  {
    label: 'One Day Before',
    value: 'OneDayBefore',
  },
  {
    label: 'One Week Before',
    value: 'OneWeekBefore',
  },
  {
    label: 'One Month Before',
    value: 'OneMonthBefore',
  },
];

export default {};
