import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTeamClients } from '../types';

// @ts-ignore
const onGetTeamClients: IThunkGetTeamClients = thunk(async ({ setClients }, payload) => {
  const { clinicId, teamId, params } = payload;

  const { items, total } = await api.client
    .getTeamClients(clinicId, teamId, params)
    .then(r => r.data);

  const pagination = { ...params, total };

  setClients({ items, pagination });
});

export default onGetTeamClients;
