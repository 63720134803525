import { isFile, validateFileType } from '~/utils/file';

export const acceptPhotoTypes = 'image/jpeg,image/png,image/gif';
export const acceptFileTypes = 'text/plain,application/pdf';
const acceptTypes = `${acceptPhotoTypes},${acceptFileTypes}`;

const validateFile = (file: File, maxFileSize = 25): string => {
  if (!isFile(file)) return 'No file';

  if (!validateFileType(file, acceptTypes)) {
    const shortTypeNames = acceptTypes
      .split(',')
      .map(type => type.split('/').pop().toLowerCase())
      .join(',');

    return `Only ${shortTypeNames} formats are valid`;
  }

  // convert fileSize from Mb to bytes (1 Mb = 1048576 Bytes)
  const maxFileSizeBytes = maxFileSize * 1048576;

  if (maxFileSize && file.size > maxFileSizeBytes) return `Max file size - ${maxFileSize}Mb`;

  return '';
};

export default validateFile;
