import { IClientAllocationStore } from './types';

const initStore: IClientAllocationStore = {
  current: null,
  list: [],
  pagination: {
    total: 0,
    pageNumber: 1,
    pageSize: 5,
  },
};

export default initStore;
