import { thunk } from 'easy-peasy';
import { IThunkGetMedicationDelivery } from '../types';
import api from '~/services/api';

const onGetMedicationDelivery: IThunkGetMedicationDelivery = thunk(
  // @ts-ignore
  async ({ setMedicationDelivery }, { clinicId, teamId, medicationDeliveryId }) => {
    const { data } = await api.medicationLogistics.getMedicationDelivery(
      { clinicId, teamId },
      medicationDeliveryId,
    );
    setMedicationDelivery(data);
  },
);

export default onGetMedicationDelivery;
