import { ReactElement } from 'react';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

import extractInitials from '~/utils/extractInitials';

import styles from './Initials.module.scss';

interface IProps {
  name: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}

const Initials = ({ name, className, active, onClick }: IProps): ReactElement => (
  <Box
    className={classNames(styles.initialsContainer, className, {
      [styles.active]: active,
      [styles.button]: onClick,
    })}
    onClick={onClick}
  >
    <span className={styles.label}>{extractInitials(name)}</span>
  </Box>
);

export default Initials;
