import { ReactElement, useEffect, useState } from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Input from '~/ui/components/inputs/Input';
import InputMask from '~/ui/components/inputs/InputMask';
import SelectEmailDomain from '~/ui/components/inputs/SelectEmailDomain';
import Button from '~/ui/components/common/Button';
import { SUPER_ADMIN } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';

import validate from './validate';
import { IFormValues } from './types';
import { IOption } from '~/types';

import styles from './Form.module.scss';

interface IProps {
  defaultValues: IFormValues;
  submitText?: string;
  isProcessing: boolean;
  leavePageDescription: string;
  isEmailInputDisabled?: boolean;
  isPending?: boolean;
  onSubmit: (formValues: IFormValues) => Promise<void>;
}

const Form = ({
  defaultValues,
  isProcessing,
  submitText = 'Send Invite',
  leavePageDescription,
  isPending,
  isEmailInputDisabled = false,
  onSubmit,
}: IProps): ReactElement => {
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormValues>({
    defaultValues: isPending
      ? { ...defaultValues, email: defaultValues.email.split('@')[0] }
      : defaultValues,
    resolver: validate,
  });

  const onMount = async () => {
    if (isEmailInputDisabled) return;

    try {
      setLoading(true);

      const { validDomainExtensions, validDomainName } = await api.emailValidation
        .getEmailDomains()
        .then(r => r.data);

      const emailDomains = validDomainExtensions.map(str => `@${validDomainName}.${str}`);

      const emailDomainOptions = emailDomains.map(str => ({ value: str, label: str }));

      setOptions(emailDomainOptions);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (options.length && !isEmailInputDisabled) {
      setValue('emailDomain', options[0].value as number);
    }
  }, [options.length]);

  if (loading || (!isEmailInputDisabled && !options.length)) return <Loader />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box sx={{ p: 2 }}>
            <h5 className={styles.subTitle}>Personal Details</h5>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Input name="firstName" register={register} errors={errors} label="First Name" />
              </Grid>
              <Grid item sm={6}>
                <InputMask name="phone" control={control} errors={errors} label="Phone" />
              </Grid>
              <Grid item sm={6}>
                <Input name="lastName" register={register} errors={errors} label="Last Name" />
              </Grid>
              <Grid item sm={6}>
                <div className={styles.combinedInputWrapper}>
                  <Input
                    name="email"
                    register={register}
                    errors={errors}
                    label="Email"
                    disabled={isEmailInputDisabled}
                    combinedInput={!isEmailInputDisabled}
                  />
                  {!isEmailInputDisabled && (
                    <SelectEmailDomain
                      isDisabled={isPending}
                      name="emailDomain"
                      control={control}
                      errors={errors}
                      options={options}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.buttonsWrapper}>
          <Button
            color="primary"
            variant="outlined"
            className={styles.margin}
            disabled={isProcessing}
            onClick={() => push(SUPER_ADMIN)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isProcessing || !isDirty}
          >
            {submitText}
          </Button>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </>
  );
};

export default Form;
