import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import EmailForm from './components/EmailForm';
import CodeForm from './components/CodeForm';
import PasswordForm from './components/PasswordForm';
import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error';

import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './SignUp.module.scss';
import { SIGN_IN } from '~/ui/constants/paths';

const SignUp = (): ReactElement => {
  const { push } = useHistory();
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentCode, setCurrentCode] = useState('');
  const [error, setError] = useState<string>(null);
  const [step, setStep] = useState(0);

  const onSubmitEmail = async (vals: { email: string }) => {
    try {
      const { email } = vals;
      await api.auth.validateEmail({ email });
      setCurrentEmail(email);
      setStep(1);
      setError(null);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const onSubmitCode = async (vals: any) => {
    const codeValue = Object.values(vals).join('');
    try {
      await api.auth.validateCode({ email: currentEmail, code: codeValue });
      setCurrentCode(codeValue);
      setStep(2);
      setError(null);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const onSubmitPassword = async (vals: any) => {
    try {
      const payload = { email: currentEmail, registrationCode: currentCode, ...vals };
      await api.auth.signUp(payload);
      push(SIGN_IN);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  return (
    <div className={styles.container}>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      {step === 0 && (
        <EmailForm onSubmit={onSubmitEmail} error={error} resetError={() => setError(null)} />
      )}
      {step === 1 && (
        <CodeForm
          onSubmit={onSubmitCode}
          error={error}
          subTitle="Enter a code from email to verify your email"
        />
      )}
      {step === 2 && (
        <PasswordForm
          title="Welcome to VelloHealth"
          subTitle="Please set up your password"
          buttonText="Sign Up"
          onSubmit={onSubmitPassword}
          error={error}
        />
      )}
    </div>
  );
};

export default SignUp;
