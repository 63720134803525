import { ReactElement, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Profile from '../../reusable/Profile';
import TeamMembers from '../TeamMembers';
import Loader from '~/ui/components/common/Loader';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Button from '~/ui/components/common/Button';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import ClientAllocation from '../ClientAllocation';
import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { ACT_TEAM_MANAGEMENT, ADD_TEAM_MEMBER, ADD_CLIENT_ALLOCATION } from '~/ui/constants/paths';

import styles from './ViewActTeam.module.scss';
import { IUserRole } from '~/types';

type IParams = {
  actTeamId: string;
};

const ViewActTeam = (): ReactElement => {
  const { actTeamId } = useParams<IParams>();
  const { pathname, state: locationState } = useLocation<{ selectedTab: number }>();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(locationState?.selectedTab || 0);
  const [teamMember, setTeamMember] = useState(null);

  const { clinic: userClinic, roles } = useStoreState(state => state.user.current);
  const actTeam = useStoreState(state => state.actTeam.current);
  const { isArchived } = actTeam || {};

  const { onUnlockTeamMember, onActivateTeamMember, onDeactivateTeamMember, resetPagination } =
    useStoreActions(actions => actions.actTeamMember);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      setLoading(true);
      resetPagination();
      const payload = { clinicId: String(userClinic.id), actTeamId };

      const promises = [onGetClinicActTeam(payload)];

      if (roles.includes(IUserRole.LocalAdmin)) {
        promises.push(onGetMyClinic());
      }

      await Promise.all(promises);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const getConfirmProps = (status: string) => {
    const payload = {
      clinicId: userClinic.id,
      actTeamId: Number(actTeamId),
      teamMemberId: teamMember?.id,
    };
    if (status === 'Active')
      return {
        confirmText: 'Deactivate',
        description: 'Are you sure you want to deactivate this user?',
        method: () => onDeactivateTeamMember(payload),
      };

    if (status === 'Deactivated')
      return {
        confirmText: 'Activate',
        description: 'Are you sure you want to activate this user?',
        method: () => onActivateTeamMember(payload),
      };

    if (status === 'Locked')
      return {
        confirmText: 'Unlock',
        description: 'Are you sure you want to unlock this user?',
        method: () => onUnlockTeamMember(payload),
      };

    return {
      confirmText: 'Resend',
      description: 'Are you sure you want to resend invitation?',
      method: () =>
        api.actTeamMember.resendInvitation(userClinic.id, Number(actTeamId), teamMember.id),
    };
  };

  if (loading || !actTeam) return <Loader />;

  const breadcrumbItems = [
    { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} ACT Teams` },
    { to: pathname, title: actTeam.name },
  ];

  const { description, confirmText, method } = getConfirmProps(teamMember?.status);

  const onConfirm = async () => {
    try {
      await method();
      setTeamMember(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setTeamMember(false);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>{actTeam.name}</h2>
        {!isArchived && (
          <div>
            {tab === 1 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => push(ADD_TEAM_MEMBER.replace(':actTeamId', actTeamId))}
              >
                Add Team Member
              </Button>
            )}
            {tab === 0 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => push(ADD_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId))}
              >
                Add Client Allocation
              </Button>
            )}
          </div>
        )}
      </div>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <div className={styles.tabsWrapper}>
        <Tabs
          value={tab}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_e, val) => setTab(val)}
        >
          <Tab label="CLIENT ALLOCATION" />
          <Tab label="TEAM MEMBERS" />
          <Tab label="TEAM PROFILE" />
        </Tabs>
      </div>
      {tab === 2 && <Profile actTeam={actTeam} />}
      {tab === 1 && (
        <TeamMembers
          clinicId={String(userClinic.id)}
          actTeamId={actTeamId}
          setTeamMember={setTeamMember}
          noActions={isArchived}
        />
      )}
      {tab === 0 && (
        <ClientAllocation
          clinicId={String(userClinic.id)}
          actTeamId={actTeamId}
          noActions={isArchived}
        />
      )}

      {teamMember && (
        <ConfirmModal
          onConfirm={onConfirm}
          confirmText={confirmText}
          description={description}
          onClose={() => setTeamMember(null)}
        />
      )}
    </div>
  );
};

export default ViewActTeam;
