import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getSubroleName } from './helper';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ProfilePhotoUpload from '~/ui/components/common/ProfilePhotoUpload';
import ListItem from '~/ui/components/common/ListItem';

import { EDIT_PROFILE } from '~/ui/constants/paths';
import { IUserRole } from '~/types';
import { isFile } from '~/utils/file';

import editWhite from '~/ui/assets/images/editWhite.svg';
import styles from './Profile.module.scss';

const Profile = (): ReactElement => {
  const { push } = useHistory();

  const current = useStoreState(state => state.user.current);
  const [showConfirm, setShowConfirm] = useState<'profile' | 'logout'>(null);
  const [value, setValue] = useState(current.photo || null);
  const [error, setError] = useState(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const { onLogout, onUploadAvatar, onRemoveAvatar } = useStoreActions(actions => actions.user);
  const { setChannels, resetMessages } = useStoreActions(actions => actions.coordinate);

  const isSuperAdmin = current.roles.includes(IUserRole.SuperAdmin);
  const isLogout = showConfirm === 'logout';
  const description = isLogout
    ? 'Are you sure you want to log out?'
    : 'Are you sure you want to remove profile photo?';
  const confirmText = isLogout ? 'Log Out' : 'Remove';

  const onUpload = async (file: any) => {
    try {
      setLoadingPhoto(true);
      if (!isFile(file)) return;

      await onUploadAvatar(file);
      setValue(file);
      showNotify('Photo successfully updated');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onRemove = async () => {
    try {
      setLoadingPhoto(true);
      await onRemoveAvatar();
      setValue('');
      showNotify('Photo successfully removed!');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoadingPhoto(false);
    }
  };

  const onConfirm = isLogout
    ? () => {
        onLogout();
        resetMessages();
        setChannels([]);
      }
    : () => {
        onRemove();
        setShowConfirm(null);
      };

  return (
    <div>
      <div className={styles.header}>
        <h2>Your Profile</h2>
        {isSuperAdmin && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<img src={editWhite} alt="edit" />}
            onClick={() => push(EDIT_PROFILE)}
          >
            Edit
          </Button>
        )}
      </div>
      <Grid container spacing={6}>
        <Grid item sm={5}>
          <div className={styles.itemsWrapper}>
            <div className={styles.item}>
              <h3 className={styles.header}>Profile Details</h3>
            </div>
            <ListItem label="First Name" content={current?.firstName} />
            <ListItem label="Last Name" content={current?.lastName} />
            {!isSuperAdmin && (
              <ListItem label="Sub-Role" content={getSubroleName(current?.subRoles)} />
            )}
            <ListItem label="Phone" content={current?.phone} />
            <ListItem label="Email" content={current?.email} />
          </div>
        </Grid>
        <Grid item sm={7}>
          {!isSuperAdmin && (
            <div className={styles.uploaderWrapper}>
              <ProfilePhotoUpload
                disabled={loadingPhoto}
                value={value}
                onUpload={onUpload}
                onError={setError}
                onRemove={() => setShowConfirm('profile')}
              />
              {error && <div>{error}</div>}
            </div>
          )}
        </Grid>
      </Grid>
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          startIcon={<ExitToAppIcon />}
          onClick={() => setShowConfirm('logout')}
        >
          Log Out
        </Button>
      </div>
      {showConfirm && (
        <ConfirmModal
          confirmText={confirmText}
          description={description}
          onConfirm={() => {
            onConfirm();
          }}
          onClose={() => setShowConfirm(null)}
        />
      )}
    </div>
  );
};

export default Profile;
