import { action } from 'easy-peasy';
import { IActionDeactivateTeamMember } from '../types';
import { Status } from '~/services/api/actTeamMember/types';

const deactivateTeamMember: IActionDeactivateTeamMember = action((state, teamMemberId) => {
  const updatedList = state.list.map(item =>
    item.id !== teamMemberId ? item : { ...item, status: 'Deactivated' as Status },
  );

  state.list = updatedList;
});

export default deactivateTeamMember;
