import { destructHours, getLocalDateNoFormat } from './date';
import { IClientDetailed, IDiagnosis } from '~/services/api/client/types';
import { IInitialValues as IClientInitialValues } from '~/ui/pages/Clients/LocalAdmin/Form/initialValues';
import {
  IClientTriggerResponse,
  IClientMedicationResponse,
  IClientDeEscalationResponse,
  IClientSecondaryDiagnosisResponse,
  IClientSupportContanctResponse,
  IClientAllergyResponse,
  IClientMedicationDeliveryResponse,
} from '~/services/api/clientDetails/types';
import {
  ITriggerInitialValues,
  IMedicationInitialValues,
  IMedicationDeliveryInitialValues,
  IDeEscalationInitialValues,
  ISecondaryDiagnosisInitialValues,
  IPrimaryDiagnosisInitialValues,
  IAllergyInitialValues,
} from '~/ui/pages/Clients/reusable/HealthDetails/Forms/types';
import { IInitialValues as SupportContactInitialValues } from '~/ui/pages/Clients/reusable/SupportContacts/Form/types';

export const formatDefaultValues = (currentUser: any) => {
  const { address } = currentUser;
  const { workDetails } = currentUser;
  const formattedValues = {
    ...currentUser,
    ...(currentUser.status === 'Pending' ? { email: currentUser.email.split('@')[0] } : {}),
    subRoleIds: currentUser.subRoles.map((subRole: any) => subRole.id),
    address: {
      ...address,
      stateId: address.state.id,
    },
    workDetails: {
      ...workDetails,
      credentialsId: workDetails.credentials.id,
      educationId: workDetails.education.id,
      employmentStatusId: workDetails.employmentStatus.id,
      hireDate: getLocalDateNoFormat(workDetails.hireDate).toISOString(),
    },
  };
  if (currentUser.teams)
    return { ...formattedValues, teams: currentUser.teams.map((team: any) => team.id) };
  return formattedValues;
};

export const formatClientDefaultValues = (
  currentClient: IClientDetailed,
): IClientInitialValues => ({
  ...currentClient,
  genderId: currentClient.gender.id,
  teamId: currentClient.actTeam.id,
  admissionDetails: {
    referralSourceId: currentClient.admissionDetails.referralSource.id,
    admissionDate: getLocalDateNoFormat(currentClient.admissionDetails.admissionDate).toISOString(),
  },
  dateOfBirth: getLocalDateNoFormat(currentClient.dateOfBirth).toISOString(),
  address: {
    ...currentClient.address,
    stateId: currentClient.address.state.id,
  },
});

export const formatTriggerDefaultValues = (
  currentTrigger: IClientTriggerResponse,
): ITriggerInitialValues => ({
  frequency: currentTrigger.frequency,
  hasAlerts: currentTrigger.hasAlerts,
  note: currentTrigger.note,
  notificationOn: currentTrigger.notificationOn,
  triggerDate: currentTrigger.triggerDate,
  triggerId: currentTrigger.trigger.id,
  teamMembersToNotify: currentTrigger.teamMembersToNotify.map(item => item.id),
  type: currentTrigger.type,
  time: currentTrigger.triggerDate ? new Date(currentTrigger.triggerDate) : null,
});

export const formatMedicationDefaultValues = (
  currentMedication: IClientMedicationResponse,
): IMedicationInitialValues => ({
  id: currentMedication.medication.id,
  note: currentMedication.note,
  dose: currentMedication.dose,
  injectable: currentMedication.injectable,
});

export const formatMedicationDeliveryDefaultValues = (
  currentMedicationDelivery: IClientMedicationDeliveryResponse,
): IMedicationDeliveryInitialValues => ({
  id: currentMedicationDelivery.id,
  note: currentMedicationDelivery.note,
  notDelivered: currentMedicationDelivery.notDelivered,
  nonDeliveryReasonId: currentMedicationDelivery.nonDeliveryReason?.id,
  date: currentMedicationDelivery.date,
  nextDueDate: currentMedicationDelivery.nextDueDate,
  teamMemberId: currentMedicationDelivery.teamMember?.id,
  lastInjection: currentMedicationDelivery?.lastInjection,
  archiveMedication: currentMedicationDelivery?.isArchived,
  hasAlerts: currentMedicationDelivery?.hasAlerts,
  medicationIds: currentMedicationDelivery?.medicationIds,
  clientId: currentMedicationDelivery.client?.id,
  statusChangeLog: currentMedicationDelivery?.statusChangeLog,
  notificationOn: currentMedicationDelivery?.notificationOn,
  notificationTime: currentMedicationDelivery?.notificationTime
    ? destructHours(currentMedicationDelivery?.notificationTime)
    : null,
  teamMembersToNotify: currentMedicationDelivery?.teamMembersToNotify?.map(tm => tm.id) || [],
  time:
    currentMedicationDelivery.date && currentMedicationDelivery.id
      ? new Date(currentMedicationDelivery.date)
      : null,
});

export const formatDeEsclationDefaultValues = (
  currentDeEscalation: IClientDeEscalationResponse,
): IDeEscalationInitialValues => ({
  id: currentDeEscalation.deEscalationTechnique.id,
  note: currentDeEscalation.note,
});

export const formatAllergyDefaultValues = (
  currentAllergy: IClientAllergyResponse,
): IAllergyInitialValues => ({
  id: currentAllergy.allergy.id,
  note: currentAllergy.note,
});

export const formatSecondaryDiagnosisDefaultValues = (
  secondaryDiagnosis: IClientSecondaryDiagnosisResponse,
): ISecondaryDiagnosisInitialValues => ({
  id: secondaryDiagnosis.diagnosis.id,
  note: secondaryDiagnosis.note,
});

export const formatPrimaryDiagnosisDefaultValues = (
  primaryDiagnosis: IDiagnosis,
): IPrimaryDiagnosisInitialValues => ({
  id: primaryDiagnosis.diagnosis.id,
  note: primaryDiagnosis.note,
});

export const formatSupportContactDefaultValues = (
  supportContact: IClientSupportContanctResponse,
): SupportContactInitialValues => {
  const { address } = supportContact;

  return {
    firstName: supportContact.firstName,
    lastName: supportContact.lastName,
    phone: supportContact.phone,
    email: supportContact.email,
    relationshipId: supportContact.relationship.id,
    address: {
      stateId: (address.state || {}).id,
      city: address.city,
      zipCode: address.zipCode,
      line1: address.line1,
      line2: address.line2,
    },
  };
};

export default {};
