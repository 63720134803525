import { ReactElement } from 'react';

interface IProps {
  isActive: boolean;
  activeIcon: any;
  inactiveIcon: any;
}

const Icon = ({ isActive, activeIcon, inactiveIcon }: IProps): ReactElement => (
  <img src={isActive ? activeIcon : inactiveIcon} alt="icon" />
);

export default Icon;
