import { ReactElement, useEffect, useRef, useState } from 'react';

import Loader from '~/ui/components/common/Loader';
import { fetchWithAuthentication } from './helper';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getAccessToken } from '~/services/auth';

import transparentSquare from '~/ui/assets/images/transparentSquare.svg';

import styles from './PrivateImage.module.scss';

interface IProps {
  src: string;
  height?: number;
  alt?: string;
  className?: string;
  width?: string | number;
  withLoader?: boolean;
}

const PrivateImage = ({
  src,
  height,
  alt = '',
  className,
  width,
  withLoader,
}: IProps): ReactElement => {
  const imageEl = useRef<HTMLImageElement>();

  const [loading, setLoading] = useState(false);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const fetchImage = async (source: string) => {
    // Prvent blinking when fetching image (1 px image)
    if (imageEl.current) {
      imageEl.current.src = transparentSquare;
    }

    try {
      if (withLoader) setLoading(true);
      const accessToken = getAccessToken();

      const response = await fetchWithAuthentication(source, accessToken);
      if (response.ok) {
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        if (imageEl.current) {
          imageEl.current.src = objectUrl;
        }
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchImage(src);
  }, [src]);

  return (
    <>
      {loading && <Loader size={20} className={styles.loader} />}
      <img ref={imageEl} alt={alt} height={height} width={width} className={className} />
    </>
  );
};

export default PrivateImage;
