import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatTrigger } from '~/utils/formatTrigger';
import { IThunkUpdateTrigger, IClientDetailsStore } from '../types';

const onUpdateTrigger: IThunkUpdateTrigger = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;
    const { requestInfo, requestPayload } = payload;

    const trigger = await api.clientDetails
      .updateClientTrigger(requestInfo, requestPayload)
      .then(r => formatTrigger(r.data));

    const triggers = localState.triggers.map(item => (item.id !== trigger.id ? item : trigger));

    setClientDetails({ triggers });
  },
);

export default onUpdateTrigger;
