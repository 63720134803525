import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useQuery from '~/store/medicationLogistics/hooks/useQuery';

const useQueryParam = (name: string, value: string): void => {
  const query = useQuery();
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (value) {
      query.set(name, value);
    } else {
      query.delete(name);
    }

    push({
      pathname,
      search: query.toString(),
    });
  }, [value]);
};

export default useQueryParam;
