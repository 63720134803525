import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetAllActTeams } from '../types';

// @ts-ignore
const onGetAllActTeams: IThunkGetAllActTeams = thunk(async ({ setActTeams }, params) => {
  const { items, total } = await api.actTeam.getAllActTeams(params).then(r => r.data);

  const pagination = { ...params, total };

  setActTeams({ items, pagination });
});

export default onGetAllActTeams;
