import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';

import { validateLengthField, phone, zipCode } from '~/ui/configs/form/validation';
import { requiredField, fieldMax, validEmail } from '~/ui/constants/errorMessages';

const validationSchema = object().shape({
  firstName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  lastName: string().required(requiredField).test('length', fieldMax, validateLengthField),
  phone,
  email: string().test('length', fieldMax, validateLengthField).email(validEmail),
  dateOfBirth: string().required(requiredField),
  medicaId: string().nullable(),
  emrMrn: string().nullable(),
  teamId: number().required(requiredField),
  suicideRisk: string().required(requiredField),
  genderId: number().required(requiredField),
  address: object().shape({
    line1: string().required(requiredField).test('length', fieldMax, validateLengthField),
    line2: string().test('length', fieldMax, validateLengthField),
    city: string().required(requiredField).test('length', fieldMax, validateLengthField),
    stateId: number().required(requiredField),
    zipCode,
  }),
  admissionDetails: object().shape({
    referralSourceId: number().required(requiredField),
    admissionDate: string().required(requiredField),
  }),
});

export default yupResolver(validationSchema);
