import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { formatMedication } from '~/utils/formatMedication';
import { IThunkCreateMedication, IClientDetailsStore } from '../types';

const onCreateMedication: IThunkCreateMedication = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    const { requestInfo, requestPayload } = payload;

    const clientMedications = await api.clientDetails
      .createClientMedication(requestInfo, requestPayload)
      .then(r => r.data);

    const medications = [formatMedication(clientMedications), ...localState.medications];

    setClientDetails({ medications });
  },
);

export default onCreateMedication;
