import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRestoreDeEscalation, IClientDetailsStore } from '../types';

const onRestoreDeEscalation: IThunkRestoreDeEscalation = thunk(
  // @ts-ignore
  async ({ setClientDetails }, payload, { getState }) => {
    const localState = getState() as IClientDetailsStore;

    await api.clientDetails.restoreDeEscalation(payload);

    const deEscalationTechniques = localState.deEscalationTechniques.map(item =>
      item.id !== Number(payload.id) ? item : { ...item, isArchived: false },
    );

    setClientDetails({ deEscalationTechniques });
  },
);

export default onRestoreDeEscalation;
